import axios from "./axios.interceptor";
import baseUrl from "./api.util";

export const BOOKINGS = `${baseUrl}/account/vendor/booking`;
export const JOIN_CALL = `${baseUrl}/account/vendor/booking/video/token`;
export const REVENUE = `${baseUrl}/account/vendor/revenue`;
export const NOTES = `${baseUrl}/account/vendor/booking/submit-notes`;
export const BOOKED_USER = `${baseUrl}/account/vendor/online-event/customer-list/`;
export const SLOTS = `${baseUrl}/account/vendor/booking/get-slot-map`;

const getISOString = (date) => {
    return new Date(new Date(new Date(new Date(new Date(date).setHours(5)).setMinutes(30)).setSeconds(0)).setMilliseconds(0)).toISOString();
};

export const getBookings = async (date) => {
  const res = await axios.post(`${BOOKINGS}/list?skip=0&limit=0`,{date: getISOString(date)});
  return res;
}

export const getBookingDetails = async (id) => {
  const res = await axios.get(`${BOOKINGS}/details/${id}`);
  return res;
}

export const completeBooking = async (id, data) => {
  const res = await axios.post(`${BOOKINGS}/complete/${id}`,data);
  return res;
}

export const joinCall = async (requestBody) => {
  const res = await axios.post(`${JOIN_CALL}`, requestBody);
  return res;
}

export const cancelBook = async (id) => {
  const res = await axios.put(`${BOOKINGS}/cancel/${id}`);
  return res;
}

export const blockSlotAPI = async (requestBody) => {
  const res = await axios.post(`${BOOKINGS}/block-slot`, requestBody);
  return res;
}

export const unblockSlotAPI = async (id) => {
  const res = await axios.put(`${BOOKINGS}/cancel-block-slot/${id}`);
  return res;
}

export const getRevenue = async (requestBody) => {
  const res = await axios.post(`${REVENUE}/list?skip=0&limit=0`,requestBody);
  return res;
}

export const submitNotes = async (id, requestBody) => {
  const res = await axios.post(`${NOTES}/${id}`,requestBody);
  return res;
}

export const getBookedUserList = async (id) => {
  const res = await axios.get(`${BOOKED_USER}${id}`);
  return res;
}

export const updateAppointmentMeetingDetails = async (id, reqBody) => {
  const res = await axios.put(`${BOOKINGS}/add-meeting-link/${id}`, reqBody);
  return res;
}

export const getSlots = async (requestBody) => {
  const res = await axios.post(`${SLOTS}`,requestBody);
  return res;
}

export const unblockIndividualSlot = async (slotId, slotNo) => {
  const res = await axios.put(`${BOOKINGS}/unblock-with-slot-number/${slotId}/${slotNo}`);
  return res;
}

export const unblockAllSlots = async (reqBody) => {
  const res = await axios.put(`${BOOKINGS}/cancel-multiple-block-slots`, reqBody);
  return res;
}

export const getEventsRevenue = async (requestBody) => {
  const res = await axios.post(`${REVENUE}/event-list`, requestBody);
  return res;
}

export const getEventDetailsRevenue = async (requestBody) => {
  const res = await axios.post(`${REVENUE}/event-details`, requestBody);
  return res;
}

export const getWorkshopsRevenue = async (requestBody) => {
  const res = await axios.post(`${REVENUE}/workshop-list`, requestBody);
  return res;
}

export const getWorkshopDetailsRevenue = async (requestBody) => {
  const res = await axios.post(`${REVENUE}/workshop-details`, requestBody);
  return res;
}

export const getCalendar = async (requestBody) => {
  const res = await axios.post(`${BOOKINGS}/calendar`, requestBody);
  return res;
}


