import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as routerHelpers from "../../router/RouterHelpers";
export const actionTypes = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  LOGOUT: "LOGOUT",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
};

const initialAuthState = {
  user: undefined,
  authTokenVendorWeb: undefined
};

export const reducer = persistReducer(
    { storage, key: "pc-web-vendor-auth", whitelist: ["user", "authTokenVendorWeb"] },
    (state = initialAuthState, action) => {
      switch (action.type) {
        case actionTypes.LOGIN_SUCCESS: {
          const { token, vendor } = action.payload;
          localStorage.setItem('authTokenPCVendorWeb', token);
          return { authTokenVendorWeb: token, user: vendor };
        }

        case actionTypes.UPDATE_PROFILE_SUCCESS: {
          const localState = {...state};
          localState.user = {...action.payload};
          return localState;
        }

        case actionTypes.LOGOUT: {
          localStorage.removeItem('authTokenPCVendorWeb');
          routerHelpers.forgotLastLocation();
          return initialAuthState;
        }

        case actionTypes.LOGOUT_SUCCESS: {
          localStorage.removeItem('authTokenPCVendorWeb');
          routerHelpers.forgotLastLocation();
          return initialAuthState;
        }

        default:
          return state;
      }
    }
);

export const actions = {
  login: (data) => ({ type: actionTypes.LOGIN_SUCCESS, payload: data }),
  updateProfile: (data) => ({ type: actionTypes.UPDATE_PROFILE_SUCCESS, payload: data }),
  logout: () => ({ type: actionTypes.LOGOUT })
};


export function* saga() {
  // yield takeLatest(actionTypes.LOGOUT, function* logoutProfile() {
  //     yield logout();
  //     yield put({type: actionTypes.LOGOUT_SUCCESS, payload: {}});
  // });
}
