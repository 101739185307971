import React from "react";
import "../dashboard.css"
import * as auth from "../../../store/ducks/auth.duck";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

const UserDetails = (props) => {
    return <div className="col-md-6" style={{
        margin: "0 auto"
    }}>
        <div className="row profile-details-wrapper" style={{ margin: 0 }}>
            <div className="col-12 p-0">
                <div className="row" style={{ margin: 0 }}>
                    <div className={`col-sm-8 col-xs-12 pt-2 pb-3 pl-0 pr-0`}>

                        <h5 className={`mb-0`}>{props.selectedProfile.status === props.VendorConfigStatus.approvalPending || props.selectedProfile.status === props.VendorConfigStatus.approvalRejected ?
                            <i class="fas fa-arrow-left mr-2" style={{ cursor: "pointer" }} onClick={() => { props.nextStep() }}></i> : null} Edit Profile</h5>
                    </div>
                    {props.selectedProfile.status !== props.VendorConfigStatus.approvalPending && props.selectedProfile.status !== props.VendorConfigStatus.approvalRejected ?
                        <div className="col-sm-4 col-xs-12 pr-0 text-right mb-3">
                            <button type="button" className="btn btn-outline-primary btn-sm ml-2" onClick={() => { props.addresses.length ? props.nextStep() : props.savePersonalInfo() }}
                                disabled={!props.selectedProfile.profilePicture || !props.selectedProfile?.name?.trim().length
                                    || !props.selectedProfile?.experience?.year ||
                                    !props.selectedProfile.emailVerified || !props.selectedProfile?.email?.trim().length ||
                                    !props.categoryList?.filter(item => item.selected).length
                                }
                            >Next</button>
                        </div> : null}
                </div>
            </div>
            <div className="col-12 mb-3" style={{
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "7px"
            }}>
                Account status: {props.VendorConfigTextStatus[props.selectedProfile.status]}
            </div>
            <div className="col-12 p-0 mb-3 add-edit-service-button-wrapper">
                <button type="button" className="btn btn-primary btn-sm save-button" onClick={props.savePersonalInfo}>Save</button>
                <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => { props.updateProfileError({ ...props.initialProfileError }); props.getProfileDetails('personalInfo') }}>Reset</button>
            </div>
            {!props.uploadedProfileImage ?
                <div className="col-12 p-0 profile-image-wrapper mb-3">
                    {props.selectedProfile.profilePicture ?
                        <div className="profile-image" style={{ backgroundImage: "url(" + props.selectedProfile.profilePicture + ")" }}>
                            <div className="file-uploader">
                                <input type="file" id="profileImage" accept="image/*" hidden onChange={props.uploadProfileImage} />
                                <i className="fa fa-camera" aria-hidden="true" onClick={() => props.openFileUploader("profileImage")}></i>
                            </div>
                        </div> :
                        <div className="profile-image" style={{ backgroundImage: "url(" + require("../../../../images/imgAvtr.png") + ")" }}>
                            <div className="file-uploader">
                                <input type="file" id="profileImage" accept="image/*" hidden onChange={props.uploadProfileImage} />
                                <i className="fa fa-camera" aria-hidden="true" onClick={() => props.openFileUploader("profileImage")}></i>
                            </div>
                        </div>
                    }

                </div>
                :
                <div className="col-12 p-0 profile-image-wrapper mb-3">
                    <div className="profile-image" id="profileImageId" style={{ backgroundImage: "url(" + props.uploadedProfileImage + ")" }}>
                        <div className="file-uploader">
                            <input type="file" id="profileImage" accept="image/*" hidden onChange={props.uploadProfileImage} />
                            <i className="fa fa-camera" aria-hidden="true" onClick={() => props.openFileUploader("profileImage")}></i>
                        </div>
                    </div>
                </div>
            }
            {/* {props.selectedProfile.profilePicture ?
                <div className="text-center col-12 font-12"><button className="btn btn-link pt-0" onClick={props.removeProfilePicture}>Remove profile photo</button></div> : null} */}
            <div className="col-12 p-0 mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">{props.selectedProfile.subType === 2 ? "Organisation " : ""}Name</label>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className="fa fa-user" /></div>
                    </div>
                    <input type="text" className={`form-control ${props.profileError.name ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder={`Enter ${props.selectedProfile.subType === 2 ? "Organisation " : ""}Name`} value={props.selectedProfile.name} onChange={e => props.changePersonalInfo(e.target.value, 'name')} disabled={props.selectedProfile.status === props.VendorConfigStatus.approved} />
                </div>
                {props.profileError.name ?
                    <div className="input-error">{props.profileError.name}</div> : null}
            </div>
            <div className="col-12 p-0 mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Phone Number</label>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className="fas fa-phone-alt" /></div>
                    </div>
                    <input type="text" className={`form-control`} value={props.selectedProfile.phoneNumber} disabled />
                </div>
            </div>
            {props.categoryList.length ?
                <div className="col-12 p-0 mb-3 custom-category-dropdown-wrapper">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Professional Category</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className="fas fa-lock" /></div>
                        </div>
                        <div className="custom-category-dropdown-select" onClick={() => props.toggleCategory(!props.categoryDropdownOpened)}>
                            {props.selectedProfile.categories && props.selectedProfile.categories.length ? `${props.selectedProfile.categories.length} Selected` : 'Select'}
                            <i className="fa fa-caret-down" aria-hidden="true"></i>
                        </div>
                    </div>
                    {props.categoryDropdownOpened ?
                        <div className="row" style={{ margin: 0 }}>
                            {props.categoryList.length ? props.categoryList.map((dayValue, index) => {
                                return <div className="col-12" key={dayValue._id}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id={dayValue._id} checked={dayValue.selected} onChange={() => props.toggleCategorySelection(dayValue._id, index)} />
                                        <label className="form-check-label" htmlFor={dayValue._id}>
                                            {dayValue.title}
                                        </label>
                                    </div>
                                </div>
                            }) : null}
                        </div> : null}
                    {props.profileError.category ?
                        <div className="input-error">{props.profileError.category}</div> : null}
                </div> : null}

            <div className="col-12 p-0 mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Total Exp</label>
                <div className="row m-0">
                    <div className="col-12 p-0">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><i className="far fa-user-circle" /></div>
                            </div>
                            <input type="text" className={`form-control ${props.profileError.experience && !props.selectedProfile.experience.year ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="in year" value={props.selectedProfile.experience ? props.selectedProfile.experience.year : ""} onChange={e => { props.changePersonalInfo(e.target.value.replace(/\D/, ''), 'year') }} />
                        </div>
                    </div>
                    {/* <div className="col-6 pr-0">
                    <input type="text" className={`form-control ${props.profileError.experience && (!props.selectedProfile.experience.month || props.profileError.experience === INVALID_MONTH) ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="in month" value={props.selectedProfile.experience ? props.selectedProfile.experience.month : ""} onChange={e => { props.changePersonalInfo(e.target.value.replace(/\D/, ''), 'month') }} />
                </div> */}
                </div>
                {props.profileError.experience ?
                    <div className="input-error">{props.profileError.experience}</div> : null}
            </div>
            <div className="col-12 p-0 mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Email</label>
                {!props.selectedProfile.emailVerified ?
                    <button disabled={!props.selectedProfile?.email?.trim().length || !props.validateEmail(props.selectedProfile?.email)} className="btn btn-link p-0 float-right font-12" onClick={() => props.openVerifyEmailModal(false)}>Verify Email</button> : null}
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className="far fa-envelope" /></div>
                    </div>
                    <input type="text" className={`form-control ${props.profileError.email ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter Email" value={props.selectedProfile.email} onChange={e => props.changePersonalInfo(e.target.value, 'email')} disabled={props.selectedProfile.emailVerified} />
                </div>
                {props.profileError.email ?
                    <div className="input-error">{props.profileError.email}</div> : null}
                {!props.selectedProfile.emailVerified ? <div className='pt-2' style={{
                    fontSize: "12px",
                    color: "#d77c2b"
                }}>Email needs to be verified</div> : <div style={{
                    fontSize: "12px",
                    color: "#3bb233"
                }} className='pt-2'>Verified</div>}
            </div>
            <div className="col-12 p-0 mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Website</label>
                {props.selectedProfile.websiteUrl && props.selectedProfile.websiteUrl.length ?
                    <button type="button" class="btn btn-link p-0 pull-right" onClick={props.checkLink}>Check Link</button> : null}
                {/* {props.selectedProfile.websiteUrl ?
                    <img src={require('../../../../images/globe.svg')} style={{ marginLeft: "5px", cursor: "pointer" }} alt="website" onClick={() => window.open(props.selectedProfile.websiteUrl)} /> : null} */}
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className="fas fa-globe" /></div>
                    </div>
                    <input type="text" className={`form-control ${props.profileError.websiteUrl ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter Website" value={props.selectedProfile.websiteUrl} onChange={e => props.changePersonalInfo(e.target.value, 'websiteUrl')} />
                </div>
                <div className="example-text">Website url e.g.: https://your-website.com</div>
                {props.profileError.websiteUrl ?
                    <div className="input-error">{props.profileError.websiteUrl}</div> : null}
            </div>
            <div className="col-12 p-0 mb-3">
                <label htmlFor="exampleFormControlTextarea1" className="form-label">Description</label>
                <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={props.selectedProfile.description} onChange={e => props.changePersonalInfo(e.target.value, 'description')} placeholder="Short bio"></textarea>
            </div>
            <div className="col-12 p-0 text-center">
                <button type="button" className="btn btn-primary w-50" onClick={props.savePersonalInfo}>Save</button>
            </div>
        </div>
    </div>
};

const mapStateToProps = state => {
    return { data: state };
};

export default injectIntl(
    connect(
        mapStateToProps,
        auth.actions
    )(UserDetails)
);