// google map api key is in index.html

export const serviceTimeHoursConstant = [{
    text: "30 mins",
    value: 1
}, {
    text: "1 hr",
    value: 2
}, {
    text: "1 hr 30 mins",
    value: 3
}, {
    text: "2 hrs",
    value: 4
}, {
    text: "2 hrs 30 mins",
    value: 5
}, {
    text: "3 hrs",
    value: 6
}, {
    text: "3 hrs 30 mins",
    value: 7
}, {
    text: "4 hrs",
    value: 8
}, {
    text: "4 hrs 30 mins",
    value: 9
}, {
    text: "5 hrs",
    value: 10
}];

export const timeArrConst = ["12:00 AM", "12:30 AM", "1:00 AM", "1:30 AM", "2:00 AM", "2:30 AM", "3:00 AM", "3:30 AM", "4:00 AM", "4:30 AM", "5:00 AM", "5:30 AM", "6:00 AM", "6:30 AM", "7:00 AM", "7:30 AM", "8:00 AM", "8:30 AM", "9:00 AM", "9:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM", "12:00 PM", "12:30 PM", "1:00 PM", "1:30 PM", "2:00 PM", "2:30 PM", "3:00 PM", "3:30 PM", "4:00 PM", "4:30 PM", "5:00 PM", "5:30 PM", "6:00 PM", "6:30 PM", "7:00 PM", "7:30 PM", "8:00 PM", "8:30 PM", "9:00 PM", "9:30 PM", "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM"];

export const daysConstant = [{
    day: 1,
    text: "Monday",
    selected: false
}, {
    day: 2,
    text: "Tuesday",
    selected: false
}, {
    day: 3,
    text: "Wednesday",
    selected: false
}, {
    day: 4,
    text: "Thursday",
    selected: false
}, {
    day: 5,
    text: "Friday",
    selected: false
}, {
    day: 6,
    text: "Saturday",
    selected: false
}, {
    day: 7,
    text: "Sunday",
    selected: false
}];

export const BookingConfig = {
    Pending: 1,
    Confirmed: 2,
    Completed: 3,
    CanceledByCustomer: 4,
    CanceledByVendor: 5,
    Failed: 6,
    Timedout: 7
};

export const SessionConfig = {
    1: "Approval Pending",
    2: "Approved",
    3: "Rejected",
    4: "Completed",
    5: "Payment Pending",
    6: "Failed",
    7: "Cancelled",
    8: "Rejected"
};
export const monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const SessionConfigNumber = {
    ApprovalPending: 1,
    Confirmed: 2,
    Rejected: 3,
    Completed: 4,
    PaymentPending: 5,
    Failed: 6,
    CancelledByVendor: 7,
    AutoRejected: 8
};

export const compressionPercentage = 0.7;
export const compressionWidth = 1200;
export const maxImageSize = 1000000;

export const VendorConfigStatus = {
    active: 1,
    blocked: 2,
    deleted: 3,
    approvalPending: 4,
    approvalRejected: 5,
    approved: 6
};

export const VendorConfigTextStatus = {
    1: "Not Approved",
    2: "Blocked",
    3: "Deleted",
    4: "Approval Pending",
    5: "Approval Rejected",
    6: "Approved"
};

export const BookingNumberStatus = {
    1: "Pending",
    2: "Confirmed",
    3: "Completed",
    4: "Cancelled", // by customer
    5: "Cancelled", // by vendor
    6: "Failed",
    7: "Timedout",
    8: "Cancelled" // by admin
};
export const SessionBookingNumberStatus = {
    1: "Pending",
    2: "Confirmed",
    3: "Completed",
    4: "Failed",
    5: "Timedout"
};

export const urls = {
    "/dashboard": "/dashboard",
    "/service": "/service",
    "/scheduler": "/scheduler",
    "/booking-details": "/scheduler",
    "/revenue": "/revenue",
    "/forum": "/forum",
    "/myQA": "/forum",
    "/faq": "/faq",
    "/video-call": "/scheduler",
    "/session": "/session",
    "/notifications": "/notifications",
    "/event": "/event",
    "/workshop": "/workshop"
};

export const months = [{
    value: "1",
    text: "January"
}, {
    value: "2",
    text: "February"
}, {
    value: "3",
    text: "March"
}, {
    value: "4",
    text: "April"
}, {
    value: "5",
    text: "May"
}, {
    value: "6",
    text: "June"
}, {
    value: "7",
    text: "July"
}, {
    value: "8",
    text: "August"
}, {
    value: "9",
    text: "September"
}, {
    value: "10",
    text: "October"
}, {
    value: "11",
    text: "November"
}, {
    value: "12",
    text: "December"
}];

export const monthArrConfig = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const notificationTypesRedirectionURLMapObj = {
    "appointmentBooked": "/booking-details/",
    "appointmentCanceledByCustomer": "/booking-details/",
    "bookingCanceledByAdmin": "/booking-details/",
    "sessionBooked": "/session",
    "sessionApproved": "/session",
    "sessionRejected": "/session",
    "serviceRequestApprovedByOrganiser": "/service",
    "serviceRequestRejectedByOrganiser": "/service",
    "profileVerificationPending": "/dashboard",
    "accountApproved": "/dashboard",
    "accountRejected": "/dashboard",
    "eventVerificationPending": "/event",
    "eventApproved": "/event",
    "eventRejected": "/event",
    "eventAutoRejected": "/event",
    "eventBooked": "/event",
    "workshopVerificationPending": "/workshop",
    "workshopApproved": "/workshop",
    "workshopRejected": "/workshop",
    "workshopAutoRejected": "/workshop",
    "workshopBooked": "/workshop",
    "linkReminderBeforeFifteenMinute": "/booking-details/",
    "linkReminderAterTenMinute": "/booking-details/",
    "linkReminderStartTime": "/booking-details/",
    "linkReminderAfterFifteenMinute": "/booking-details/"
};