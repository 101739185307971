import React, { useEffect, useState, useCallback } from 'react';
import "./dashboard.css"
import * as auth from "../../store/ducks/auth.duck";
import Compressor from 'compressorjs';
import { toast } from 'react-toastify';
import {MyLocation} from '@material-ui/icons';
import {
    getProfile,
    updateBank,
    updateProfile,
    getLocationList,
    addLocation,
    updateLocation,
    removeLocation,
    getCategories,
    updateProfilePhoto,
    addPhoto,
    removeImage,
    removeCertificate,
    requestEmailOtp,
    confirmEmailOtp
} from "../../crud/profile.crud";
import DateFnsUtils from '@date-io/date-fns';
import {
    TimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import RemoveModal from "../admins/remove-modal";
import {
    REQUIRED_ERROR,
    INVALID_FROM_TO_DATE,
    CHOOSE_ERROR,
    INVALID_EMAIL,
    CHOOSE_CATEGORY_ERROR,
    API_ERROR,
    INVALID_WEBSITE,
    INVALID_LOCATION
} from "../../config/error-message";
import { API_SUCCESS } from "../../config/success-message";
import {
    timeArrConst,
    daysConstant,
    VendorConfigStatus,
    VendorConfigTextStatus,
    compressionPercentage,
    compressionWidth,
    maxImageSize
} from "../../config/constants";
import GalleryModal from "../admins/gallery-modal";
import ShareToSocialModal from '../admins/shareToSocialModal';
import VendorTypeModal from "../admins/vendor-type-modal";
import OtpModal from './otp-modal';
import EmailAlert from './email-alert';

const initialMenuOptions = [{
    option: "Gallery",
    selected: true
}, {
    option: "Bank Details",
    selected: false
}, {
    option: "Address Manager",
    selected: false
}];

var dayName = ["Mon", "Tue", "Wed", "Thurs", "Fri", "Sat", "Sun"];

const days = [...daysConstant];

const timeArr = [...timeArrConst];

const initialBankDetailsError = {
    accountHolderName: "",
    bankName: "",
    accountNumber: "",
    ifscCode: "",
    branchName: ""
};

const initialChequeDetailsError = {
    chequeName: "",
    chequeAddress: ""
};

const initialLocationError = {
    title: "",
    address: "",
    selectedFromDate: "",
    selectedToDate: "",
    days: ""
};

const initialLocation = {
    title: "",
    address: "",
    days: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
};

const initialProfileError = {
    name: "",
    email: "",
    category: "",
    experience: "",
    websiteUrl: ""
};

let map;
let marker;
let geocoder;
let myLatLng;

const Dashboard = (props) => {

    const [selectedProfile, selectProfile] = useState({});
    const [selectedMenu, selectMenu] = useState("Gallery");
    const [menuOptions, updateMenuOptions] = useState([...initialMenuOptions]);
    const [addresses, updateAddresses] = useState([]);
    const [mode, updateMode] = useState("list");
    const [selectedFromDate, handleFromDateChange] = useState(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)));
    const [selectedToDate, handleToDateChange] = useState(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(30)).setSeconds(0)));
    const [daysArray, updateDaysArray] = useState([...days]);
    const [uploadedGalleryImage, updateGalleryImage] = useState(null);
    const [displayRemoveModal, openRemoveModal] = useState(false);
    const [removeType, updateRemoveType] = useState("");
    const [bankDetailsError, updateBankDetailsError] = useState({ ...initialBankDetailsError });
    const [locationDetails, modifyLocationDetails] = useState({ ...initialLocation });
    const [locationDetailsError, updateLocationDetailsError] = useState({ ...initialLocationError });
    const [selectedLocationIdToRemove, updateLocationId] = useState("");
    const [categoryList, updateCategoryList] = useState([]);
    const [profileError, updateProfileError] = useState({ ...initialProfileError });
    const [uploadedProfileImage, updateProfileImage] = useState("");
    const [selectedImageIdToRemove, updateImageId] = useState("");
    const [selectedCertificateIdToRemove, updateCertificateId] = useState("");
    const [categoryDropdownOpened, toggleCategory] = useState(false);
    const [disablePage, toggleDisablePage] = useState(false);
    const [selectedCoordinates, updateCoordinate] = useState([]);
    const [openGalleryModal, toggleGalleryModal] = useState(false);
    const [currentImageIndex, updateCurrentImageIndex] = useState(-1);
    const [showPrevButtons, togglePrevButton] = useState(false);
    const [showNextButtons, toggleNextButton] = useState(true);
    const [isCheque, toggleCheque] = useState(false);
    const [chequeDetailsError, updateChequeDetailsError] = useState({ ...initialChequeDetailsError });
    const [displayShareModal, toggleShareModal] = useState(false);
    const [displayVendorTypeModal, openVendorTypeModal] = useState(false);
    const [verifyEmailModalOpened, toggleVerifyEmailModal] = useState(false);
    const [userLocation, updateUserLocation] = useState([]);
    const [draggedAddress, updateDraggedAddress] = useState({});
    const [locationPinned, pinLocation] = useState(true);
    const [openEmailAlert, toggleEmailAlert] = useState(false);

    const handleNextPrevButtons = (type) => {
        if (document.getElementsByClassName(type)[0]) {
            if (document.getElementsByClassName(type)[0].clientWidth < document.getElementsByClassName(type)[0].scrollWidth) {
                if (type === "categories-wrapper") {
                    togglePrevButton(true);
                    toggleNextButton(true);
                }

                if (document.getElementsByClassName(type)[0].scrollLeft === 0) {
                    if (type === "categories-wrapper") {
                        togglePrevButton(false);
                    }
                }
                if (Math.round(document.getElementsByClassName(type)[0].scrollWidth - document.getElementsByClassName(type)[0].clientWidth) === Math.round(document.getElementsByClassName(type)[0].scrollLeft) ||
                    Math.floor(document.getElementsByClassName(type)[0].scrollWidth - document.getElementsByClassName(type)[0].clientWidth) === Math.floor(document.getElementsByClassName(type)[0].scrollLeft)) {
                    if (type === "categories-wrapper") {
                        toggleNextButton(false);
                    }

                }

            } else {
                if (type === "categories-wrapper") {
                    togglePrevButton(false);
                    toggleNextButton(false);
                }
            }
        }
    };

    const sideScroll = (element, direction, speed, distance, step, type) => {
        let scrollAmount = 0;
        let slideTimer = setInterval(function () {
            if (direction === 'left') {
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            if (scrollAmount >= distance) {
                handleNextPrevButtons(type);
                window.clearInterval(slideTimer);
            }
        }, speed);
    }

    const nextClick = (type) => {
        var container = document.getElementsByClassName(type)[0];
        sideScroll(container, 'right', 25, 100, 10, type);
    };

    const prevClick = (type) => {
        var container = document.getElementsByClassName(type)[0];
        sideScroll(container, 'left', 25, 100, 10, type);
    };

    window.onresize = () => {
        handleNextPrevButtons('categories-wrapper');
        if (selectedProfile && selectedProfile.educationProofs && selectedProfile.educationProofs.length) {
            handleNextPrevButtons('qualifications-wrapper');
        }
    };

    window.onload = () => {
        handleNextPrevButtons('categories-wrapper');
        if (selectedProfile && selectedProfile.educationProofs && selectedProfile.educationProofs.length) {
            handleNextPrevButtons('qualifications-wrapper');
        }
    };

    const displayNavs = useCallback((bool) => {
        if (bool) {
            handleNextPrevButtons('categories-wrapper');
            if (selectedProfile && selectedProfile.educationProofs && selectedProfile.educationProofs.length) {
                handleNextPrevButtons('qualifications-wrapper');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    const validateWebsite = (web) => {
        const re = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/;
        return re.test(web);
    };

    const formatAMPM = (date) => {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    };

    const updateMenu = (item) => {
        const localMenuOptions = [...menuOptions];
        localMenuOptions.forEach(each => {
            each.selected = false;
            if (each.option === item.option) {
                each.selected = true;
            }
        });
        selectMenu(item.option);
        updateMenuOptions([...localMenuOptions]);
    };

    const getTime = (timeStr) => {
        var time = timeStr.slice(0, timeStr.length - 3)
        var amPm = timeStr.slice(timeStr.length - 2)
        var d = new Date()
        if (amPm === "AM" && time.split(":")[0] === "12") {
            return new Date(new Date(d.setHours(0)).setMinutes(time.split(":")[1]))
        }
        if (amPm === "PM" && time.split(":")[0] !== "12") {
            return new Date(new Date(d.setHours(Number(time.split(":")[0]) + 12)).setMinutes(time.split(":")[1]))
        }
        return new Date(new Date(d.setHours(time.split(":")[0])).setMinutes(time.split(":")[1]))
    };

    const getDuration = (times) => {
        times.sort((a, b) => { return a - b });
        const startTime = timeArr[times[0] - 1];
        const endTime = timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0];
        return `${startTime} - ${endTime}`;
    };

    const getStartTime = (times) => {
        times.sort((a, b) => { return a - b });
        return getTime(timeArr[times[0] - 1])
    };

    const getEndTime = (times) => {
        times.sort((a, b) => { return a - b });
        return getTime(timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0])
    };

    const getAllLocations = useCallback(async () => {
        toggleDisablePage(true);
        const locationsResult = await getLocationList();
        toggleDisablePage(false);
        if (locationsResult.data.errorCode) {
            toast.error(API_ERROR[locationsResult.data.errorCode]);
            return;
        }
        console.log("locationsResult ", locationsResult)
        const localAddresses = [];
        if (locationsResult.data.data && locationsResult.data.data.length) {
            let localSingleAddress = {};
            locationsResult.data.data.forEach(each => {
                localSingleAddress.title = each.title;
                localSingleAddress.address = each.address;
                localSingleAddress.addressLine1 = each.addressLine1;
                localSingleAddress.addressLine2 = each.addressLine2;
                localSingleAddress.addressLine3 = each.addressLine3;
                localSingleAddress._id = each._id;
                localSingleAddress.selected = false;
                localSingleAddress.days = "";
                localSingleAddress.duration = getDuration(each.workingTimeSlots);
                localSingleAddress.daysOfWeek = each.daysOfWeek;
                localSingleAddress.workingTimeSlots = each.workingTimeSlots;
                localSingleAddress.location = each.location;

                if (each.daysOfWeek && each.daysOfWeek.length) {
                    each.daysOfWeek.forEach((item, index) => {
                        localSingleAddress.days += dayName[item - 1];
                        if (index !== each.daysOfWeek.length - 1) {
                            localSingleAddress.days += ", ";
                        }
                    });
                }
                localAddresses.push({ ...localSingleAddress });
                localSingleAddress = {};
            });
        }
        updateAddresses([...localAddresses]);
    }, []);

    const getAllCategories = async (categoryIds) => {
        toggleDisablePage(true);
        const categoriesResult = await getCategories();
        toggleDisablePage(false);
        if (categoriesResult.data.errorCode) {
            toast.error(API_ERROR[categoriesResult.data.errorCode]);
            return;
        }
        if (categoriesResult.data.data && categoriesResult.data.data.length) {
            categoriesResult.data.data.forEach(each => {
                if (categoryIds.includes(each._id)) {
                    each.selected = true;
                } else {
                    each.selected = false;
                }
                if (each.title.charAt(each.title.length - 1) === 's') {
                    each.title = each.title.substr(0, each.title.length - 1);
                }
            });
            updateCategoryList(categoriesResult.data.data);
        }
    };

    const getUserLocation = useCallback(async () => {
        if (window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition((position) => {
                if (position && position.coords && position.coords.latitude && position.coords.longitude) {
                    updateUserLocation([position.coords.longitude, position.coords.latitude]);
                }
            }, (error) => {
                if (error.code === 1) {
                    console.log("Not able access your current location. If you have denied the permission then please refresh the page or Please change the location access from your browser's settings");
                }
            });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }, []);

    const getProfileDetails = async (resetType) => {
        toggleDisablePage(true);
        const profileData = await getProfile();
        toggleDisablePage(false);
        if (profileData.data.errorCode) {
            toast.error(API_ERROR[profileData.data.errorCode]);
            return;
        }
        if (profileData.data.data) {
            const localSelectedProfile = { ...selectedProfile };
            if ((!resetType || resetType === "bank") && profileData.data.data.bankingDetails) {
                localSelectedProfile.bankDetails = { ...profileData.data.data.bankingDetails };
                if (profileData.data.data.bankingDetails.isCheque) {
                    toggleCheque(profileData.data.data.bankingDetails.isCheque);
                }
            }
            if ((!resetType || resetType === "personalInfo") && profileData.data.data.personalInfo) {
                localSelectedProfile._id = profileData.data.data._id;
                localSelectedProfile.idProof = "";
                localSelectedProfile.idBackProof = "";
                localSelectedProfile.educationProof = "";
                localSelectedProfile.educationProofs = [];
                localSelectedProfile.rciCertificate = "";
                localSelectedProfile.profilePicture = profileData.data.data.personalInfo.profilePicture;
                localSelectedProfile.name = profileData.data.data.personalInfo.name;
                localSelectedProfile.followedBy = profileData.data.data.followedBy ? profileData.data.data.followedBy.length : 0;
                localSelectedProfile.description = profileData.data.data.personalInfo.description;
                localSelectedProfile.email = profileData.data.data.personalInfo.email;
                localSelectedProfile.status = profileData.data.data.accountStatus;
                localSelectedProfile.phoneNumber = '+91' + profileData.data.data.personalInfo.phone.number;
                localSelectedProfile.websiteUrl = profileData.data.data.personalInfo.website;
                if (profileData.data.data.personalInfo.experience && profileData.data.data.personalInfo.experience.year) {
                    localSelectedProfile.experience = {
                        ...profileData.data.data.personalInfo.experience,
                        month: "0"
                    };
                } else {
                    localSelectedProfile.experience = {
                        year: "",
                        month: "0"
                    };
                }
                localSelectedProfile.categories = [];
                if (profileData.data.data.personalInfo.categories && profileData.data.data.personalInfo.categories.length) {
                    profileData.data.data.personalInfo.categories.forEach(each => {
                        localSelectedProfile.categories.push(each._id);
                    });
                }
                if (profileData.data.data.idPhoto) {
                    if (profileData.data.data.idPhoto.govtId) {
                        localSelectedProfile.idProof = profileData.data.data.idPhoto.govtId;
                    }
                    if (profileData.data.data.idPhoto.govtIdBack) {
                        localSelectedProfile.idBackProof = profileData.data.data.idPhoto.govtIdBack;
                    }
                    if (profileData.data.data.idPhoto.gumasta) {
                        localSelectedProfile.gumasta = profileData.data.data.idPhoto.gumasta;
                    }
                    if (profileData.data.data.idPhoto.certificate) {
                        localSelectedProfile.educationProof = profileData.data.data.idPhoto.certificate;
                    }
                    if (profileData.data.data.idPhoto.certificates && profileData.data.data.idPhoto.certificates.length) {
                        localSelectedProfile.educationProofs = profileData.data.data.idPhoto.certificates;
                    }
                    if (profileData.data.data.idPhoto.rciCertificate) {
                        localSelectedProfile.rciCertificate = profileData.data.data.idPhoto.rciCertificate;
                    }
                }
            }
            if ((!resetType || resetType === "photos") && profileData.data.data.personalInfo) {
                localSelectedProfile.descriptionImages = profileData.data.data.photos;
            }
            localSelectedProfile.subType = profileData.data.data.subType || 1;
            localSelectedProfile.emailVerified = profileData.data.data.emailVerified;
            console.log("localSelectedProfile ", localSelectedProfile)
            selectProfile({ ...localSelectedProfile });
            props.updateProfile({ ...profileData.data.data });
            if (!resetType) {
                getAllCategories(localSelectedProfile.categories);
            }
            setTimeout(() => {
                displayNavs(true);
            }, 1000);
        }
    };

    const getInitialProfileDetails = useCallback(async () => {
        toggleDisablePage(true);
        const profileData = await getProfile();
        toggleDisablePage(false);
        console.log("profileData ", profileData)
        if (profileData.data.errorCode) {
            toast.error(API_ERROR[profileData.data.errorCode]);
            return;
        }
        if (profileData.data.data) {

            if (profileData.data.data.accountStatus !== VendorConfigStatus.approved) {
                props.history.push('/onboarding');
            } else {
                if (!profileData.data.data.emailVerified && !sessionStorage.getItem("emailAlertDisplayed")) {
                    toggleEmailAlert(true);
                    sessionStorage.setItem("emailAlertDisplayed", JSON.stringify(true));
                }
            }

            if (profileData.data.data.personalInfo && !profileData.data.data.personalInfo.name) {
                openVendorTypeModal(true);
            }

            const localSelectedProfile = {};
            if (profileData.data.data.bankingDetails) {
                localSelectedProfile.bankDetails = { ...profileData.data.data.bankingDetails };
                if (profileData.data.data.bankingDetails.isCheque) {
                    toggleCheque(profileData.data.data.bankingDetails.isCheque);
                }
            }
            if (profileData.data.data.personalInfo) {
                localSelectedProfile._id = profileData.data.data._id;
                localSelectedProfile.idProof = "";
                localSelectedProfile.idBackProof = "";
                localSelectedProfile.educationProof = "";
                localSelectedProfile.educationProofs = [];
                localSelectedProfile.rciCertificate = "";
                localSelectedProfile.profilePicture = profileData.data.data.personalInfo.profilePicture;
                localSelectedProfile.name = profileData.data.data.personalInfo.name;
                localSelectedProfile.followedBy = profileData.data.data.followedBy ? profileData.data.data.followedBy.length : 0;
                localSelectedProfile.description = profileData.data.data.personalInfo.description;
                localSelectedProfile.email = profileData.data.data.personalInfo.email;
                localSelectedProfile.phoneNumber = '+91' + profileData.data.data.personalInfo.phone.number;
                localSelectedProfile.websiteUrl = profileData.data.data.personalInfo.website;
                localSelectedProfile.status = profileData.data.data.accountStatus;
                if (profileData.data.data.personalInfo.experience && profileData.data.data.personalInfo.experience.year) {
                    localSelectedProfile.experience = {
                        ...profileData.data.data.personalInfo.experience,
                        month: "0"
                    };
                } else {
                    localSelectedProfile.experience = {
                        year: "",
                        month: "0"
                    };
                }
                localSelectedProfile.categories = [];
                if (profileData.data.data.personalInfo.categories && profileData.data.data.personalInfo.categories.length) {
                    profileData.data.data.personalInfo.categories.forEach(each => {
                        localSelectedProfile.categories.push(each._id);
                    });
                }
                if (profileData.data.data.idPhoto) {
                    if (profileData.data.data.idPhoto.govtId) {
                        localSelectedProfile.idProof = profileData.data.data.idPhoto.govtId;
                    }
                    if (profileData.data.data.idPhoto.govtIdBack) {
                        localSelectedProfile.idBackProof = profileData.data.data.idPhoto.govtIdBack;
                    }
                    if (profileData.data.data.idPhoto.gumasta) {
                        localSelectedProfile.gumasta = profileData.data.data.idPhoto.gumasta;
                    }
                    if (profileData.data.data.idPhoto.certificate) {
                        localSelectedProfile.educationProof = profileData.data.data.idPhoto.certificate;
                    }
                    if (profileData.data.data.idPhoto.certificates && profileData.data.data.idPhoto.certificates.length) {
                        localSelectedProfile.educationProofs = profileData.data.data.idPhoto.certificates;
                    }
                    if (profileData.data.data.idPhoto.rciCertificate) {
                        localSelectedProfile.rciCertificate = profileData.data.data.idPhoto.rciCertificate;
                    }
                }
            }
            if (profileData.data.data.personalInfo) {
                localSelectedProfile.descriptionImages = profileData.data.data.photos;
            }
            localSelectedProfile.subType = profileData.data.data.subType || 1;
            localSelectedProfile.emailVerified = profileData.data.data.emailVerified;
            console.log("localSelectedProfile ", localSelectedProfile)
            selectProfile({ ...localSelectedProfile });
            props.updateProfile({ ...profileData.data.data });
            getAllCategories(localSelectedProfile.categories);
            setTimeout(() => {
                displayNavs(true);
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayNavs]);

    const resetMenuOptions = () => {
        const localMenuOptions = [...initialMenuOptions];
        localMenuOptions.forEach(each => {
            each.selected = false;
            if (each.option === "Gallery") {
                each.selected = true;
            }
        });
        updateMenuOptions([...localMenuOptions]);
    };

    useEffect(() => {
        getInitialProfileDetails();
        getAllLocations();
        resetMenuOptions();
        getUserLocation();
    }, [getInitialProfileDetails, getAllLocations, getUserLocation]);

    useEffect(() => {
        window.onclick = (e) => {
            if (e.target && e.target.className && e.target.className.indexOf && e.target.className.indexOf("custom-category-dropdown-select") <= -1 && e.target.className.indexOf("form-check") <= -1) {
                toggleCategory(false);
            }
        };
    });

    const openFileUploader = (imageId) => {
        if (document.getElementById(imageId)) {
            document.getElementById(imageId).click();
        }
    };

    const uploadGalleryImage = () => {
        if (document.getElementById("galleryImage")) {
            let imageFile = document.getElementById("galleryImage");
            if (imageFile.files[0]) {
                console.log("imageFile.files[0] ", imageFile.files[0]);

                if (imageFile.files[0].type.indexOf("jpeg") > -1
                    || imageFile.files[0].type.indexOf("png") > -1
                    || imageFile.files[0].type.indexOf("jpg") > -1) {
                    updateGalleryImage(imageFile.files[0]);
                    saveGalleryImage(imageFile.files[0]);
                } else {
                    toast.error("Please upload .jpeg/.jpg/.png file");
                    removeUploadedImage();
                    return;
                }
            }
        }
    };

    const commonGalleryUploadFunction = async (newFile) => {
        const result = await addPhoto(newFile);
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            return;
        }
        toast.success(API_SUCCESS.ADD_IMAGE);
        getProfileDetails("photos");

        setTimeout(() => {
            removeUploadedImage();
        }, 100);
    };

    const saveGalleryImage = async (file) => {
        if (file.size <= maxImageSize) {
            commonGalleryUploadFunction(file);
        } else {
            new Compressor(file, {
                quality: compressionPercentage,
                width: compressionWidth,
                success: async (data) => {
                    const newFile = new File([data], file.name, { type: file.type });
                    await commonGalleryUploadFunction(newFile);
                },
                error: () => {
                    toast.error("Error while uploading");
                    removeUploadedImage();
                },
            });
        }
    };

    const commonProfileUploadFunction = async (imageFile) => {
        toggleDisablePage(true);
        const result = await updateProfilePhoto(imageFile);
        toggleDisablePage(false);
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            return;
        }
        toast.success(API_SUCCESS.UPDATE_PROFILE_IMAGE);
    };

    const uploadProfileImage = async () => {
        if (document.getElementById("profileImage")) {
            let imageFile = document.getElementById("profileImage");
            if (imageFile.files[0]) {
                console.log("imageFile.files[0] ", imageFile.files[0]);
                if (imageFile.files[0].type.indexOf("jpeg") > -1
                    || imageFile.files[0].type.indexOf("png") > -1
                    || imageFile.files[0].type.indexOf("jpg") > -1) {
                    updateProfileImage(URL.createObjectURL(imageFile.files[0]));

                    if (imageFile.files[0].size <= maxImageSize) {
                        commonProfileUploadFunction(imageFile.files[0]);
                    } else {
                        new Compressor(imageFile.files[0], {
                            quality: compressionPercentage,
                            width: compressionWidth,
                            success: async (data) => {
                                const newFile = new File([data], imageFile.files[0].name, { type: imageFile.files[0].type });
                                await commonProfileUploadFunction(newFile);
                            },
                            error: () => {
                                toast.error("Error while uploading");
                                updateProfileImage(null);
                                if (document.getElementById("profileImage")) {
                                    document.getElementById("profileImage").value = "";
                                }
                            },
                        });
                    }
                    
                } else {
                    toast.error("Please upload .jpeg/.jpg/.png file");
                    updateProfileImage(null);
                    if (document.getElementById("profileImage")) {
                        document.getElementById("profileImage").value = "";
                    }
                    return;
                }
            }
        }
    };

    const openRemove = (item, type) => {
        updateRemoveType(type);
        if (type === "Location") {
            updateLocationId(item._id);
            if (addresses.length === 1) {
                toast.error("Minimum 1 address is mandatory");
                return;
            }
        } else if (type === "Image") {
            updateImageId(item._id);
        } else if (type === "Certificate") {
            updateCertificateId(item._id);
            if (selectedProfile.educationProofs.length === 1) {
                toast.error("Minimum 1 qualification document is mandatory");
                return;
            }
        }
        openRemoveModal(true);
    };

    const removeUploadedImage = () => {
        updateGalleryImage(null);
        if (document.getElementById("galleryImage")) {
            document.getElementById("galleryImage").value = "";
        }
    };

    const updateBankDetails = (text, fieldName) => {
        const localProfile = { ...selectedProfile };
        const localBank = { ...localProfile.bankDetails };
        const localBankError = { ...bankDetailsError };
        const localChequeError = { ...chequeDetailsError };
        const localChequeStringArray = ["isCheque", "chequeName", "chequeAddress"];
        const alphaRegex = /^[a-zA-Z ]*$/;
        const alphaNumRegex = /^[a-z\d\-_\s]+$/i;
        if (fieldName === "bankName" || fieldName === "accountHolderName" || fieldName === "chequeName") {
            if (alphaRegex.test(text)) {
                localBank[fieldName] = text;
                localProfile.bankDetails = { ...localBank };
                if (!localChequeStringArray.includes(fieldName)) {
                    localBankError[fieldName] = "";
                } else {
                    localChequeError[fieldName] = "";
                }
            }
        } else if (fieldName === "ifscCode" || fieldName === "branchName") {
            if (alphaNumRegex.test(text) || !text.length) {
                localBank[fieldName] = text;
                localProfile.bankDetails = { ...localBank };
                localBankError[fieldName] = "";
            }
        } else {
            localBank[fieldName] = text;
            localProfile.bankDetails = { ...localBank };
            if (!localChequeStringArray.includes(fieldName)) {
                localBankError[fieldName] = "";
            } else {
                localChequeError[fieldName] = "";
            }
        }

        selectProfile({ ...localProfile });
        if (!localChequeStringArray.includes(fieldName)) {
            updateBankDetailsError({ ...localBankError });
        } else {
            updateChequeDetailsError({ ...localChequeError });
        }
    };

    const validateBankDetails = () => {
        const localProfile = { ...selectedProfile };
        const localBank = { ...localProfile.bankDetails };
        const localBankError = { ...bankDetailsError };
        const localChequeError = { ...chequeDetailsError };
        const localChequeStringArray = ["isCheque", "chequeName", "chequeAddress"];
        let noErrorFlag = true;
        if (!isCheque) {
            for (let item in localBank) {
                if (!localChequeStringArray.includes(item) && item !== "gstNumber" && !localBank[item].toString().trim().length) {
                    localBankError[item] = REQUIRED_ERROR;
                    noErrorFlag = false;
                } else {
                    localBankError[item] = "";
                }
            }
            updateBankDetailsError({ ...localBankError });
            updateChequeDetailsError({ ...initialChequeDetailsError });
        } else {
            for (let item in localBank) {
                if (localChequeStringArray.includes(item) && !localBank[item].toString().trim().length) {
                    localChequeError[item] = REQUIRED_ERROR;
                    noErrorFlag = false;
                } else {
                    localChequeError[item] = "";
                }
            }
            if (localBank.bankName.trim().length) {
                let hasBankNameAndOtherBankDetails = true;
                for (let item in localBank) {
                    if (!localChequeStringArray.includes(item) && item !== "gstNumber" && !localBank[item].toString().trim().length) {
                        hasBankNameAndOtherBankDetails = false;
                        noErrorFlag = false;
                    }
                }
                if (!hasBankNameAndOtherBankDetails) {
                    toast.error("Please enter other bank details or remove bank name if you want to opt for cheque payment.");
                }
            }
            updateChequeDetailsError({ ...localChequeError });
            updateBankDetailsError({ ...initialBankDetailsError });
        }
        return noErrorFlag;
    };

    const saveBankDetails = async () => {
        console.log("selectedProfile.bankDetails ", selectedProfile.bankDetails)
        if (validateBankDetails()) {
            console.log("isscuccess");
            // return;
            const localBankDetails = { ...selectedProfile.bankDetails };
            localBankDetails.isCheque = isCheque;
            if (!localBankDetails.gstNumber) {
                localBankDetails.gstNumber = "_blank_";
            }
            toggleDisablePage(true);
            const result = await updateBank(localBankDetails);
            if (result.data.errorCode) {
                toast.error(API_ERROR[result.data.errorCode]);
                toggleDisablePage(false);
                return;
            }
            toast.success(API_SUCCESS.UPDATE_BANK);
            getProfileDetails("bank");
        }
    };

    const changePersonalInfo = (text, fieldName) => {
        const localProfile = { ...selectedProfile };
        const localProfileError = { ...profileError };
        if (fieldName !== "year" && fieldName !== "month") {
            localProfile[fieldName] = fieldName === "email" ? text.trim() : text;
            localProfileError[fieldName] = "";
        } else {
            localProfile.experience = { ...localProfile.experience };
            localProfile.experience[fieldName] = text;
            localProfileError.experience = "";
        }
        selectProfile({ ...localProfile });
        updateProfileError({ ...localProfileError });
    };

    const validateProfile = () => {
        const localProfileError = { ...profileError };
        let noErrorFlag = true;
        for (let item in localProfileError) {
            if (item === "name" || item === "email" || item === "websiteUrl") {
                if (item !== "websiteUrl" && !selectedProfile[item].trim().length) {
                    localProfileError[item] = REQUIRED_ERROR;
                    noErrorFlag = false;
                } else {
                    localProfileError[item] = "";
                }

                if (item === "email" && selectedProfile[item].trim().length) {
                    if (!validateEmail(selectedProfile[item])) {
                        localProfileError[item] = INVALID_EMAIL;
                        noErrorFlag = false;
                    } else {
                        localProfileError[item] = "";
                    }
                }

                if (item === "websiteUrl" && selectedProfile[item].trim().length) {
                    if (!validateWebsite(selectedProfile[item])) {
                        localProfileError[item] = INVALID_WEBSITE;
                        noErrorFlag = false;
                    } else {
                        localProfileError[item] = "";
                    }
                }
            }
            if (item === "experience") {
                console.log("selectedProfile.experience ", selectedProfile.experience);
                if (selectedProfile.experience.year === "" || selectedProfile.experience.month === "") {
                    localProfileError.experience = REQUIRED_ERROR;
                    noErrorFlag = false;
                } else {
                    localProfileError.experience = "";
                }

                // if (selectedProfile.experience.month !== "") {
                //     if (Number(selectedProfile.experience.month) > 11) {
                //         localProfileError.experience = INVALID_MONTH;
                //         noErrorFlag = false;
                //     } else {
                //         localProfileError.experience = "";
                //     }
                // }

            }
            if (item === "category") {
                let categoryError = true;
                if (categoryList.length) {
                    categoryList.forEach(each => {
                        if (each.selected) {
                            categoryError = false;
                        }
                    });
                }

                if (categoryError) {
                    localProfileError.category = CHOOSE_CATEGORY_ERROR;
                    noErrorFlag = false;
                } else {
                    localProfileError.category = "";
                }
            }
        }
        console.log("localProfileError ", localProfileError)
        updateProfileError({ ...localProfileError });
        return noErrorFlag;
    };

    const savePersonalInfo = async () => {
        console.log("profile--------- ", selectedProfile);
        if (validateProfile()) {
            const localSelectedCategories = [];
            if (categoryList.length) {
                categoryList.forEach(each => {
                    if (each.selected) {
                        localSelectedCategories.push(each._id);
                    }
                });
            }
            const requestBody = {
                name: selectedProfile.name,
                email: selectedProfile.email,
                categories: localSelectedCategories,
                experience: {
                    year: Number(selectedProfile.experience.year),
                    month: Number(selectedProfile.experience.month)
                }
            };
            if (selectedProfile.websiteUrl) {
                requestBody.website = selectedProfile.websiteUrl;
                if (!requestBody.website.includes('http://') && !requestBody.website.includes('https://')) {
                    requestBody.website = 'http://' + requestBody.website;
                }
            } else {
                requestBody.website = "_blank_";
            }
            if (selectedProfile.description) {
                requestBody.description = selectedProfile.description;
            } else {
                requestBody.description = "_blank_";
            }

            toggleDisablePage(true);
            const result = await updateProfile(requestBody);
            if (result.data.errorCode) {
                toast.error(API_ERROR[result.data.errorCode]);
                toggleDisablePage(false);
                return;
            }
            toast.success(API_SUCCESS.UPDATE_PROFILE);
            getProfileDetails("personalInfo");
            // props.updateProfile({ ...result.data.data });
        }
    };

    const updateLocationDetails = (text, fieldName, isChosen) => {
        const localLocation = { ...locationDetails };
        const localLocationError = { ...locationDetailsError };

        localLocationError[fieldName] = "";
        if (fieldName === "address" && isChosen) {
            localLocation.addressLine1 = text.formatted_address;
            localLocation[fieldName] = text.formatted_address;
            for (let i = 0; i < text.address_components.length; i++) {
                for (let j = 0; j < text.address_components[i].types.length; j++) {
                    if (text.address_components[i].types[j] === "postal_code") {
                        localLocation.addressLine2 = text.address_components[i].long_name;
                    }
                }
            }
            // pinLocation(true);
        } else {
            localLocation[fieldName] = text;
        }
        console.log("localLocation ", localLocation, locationDetails)
        updateLocationDetailsError({ ...localLocationError });
        modifyLocationDetails({ ...localLocation });
    };

    const toggleDaySelection = (index) => {
        const localDays = [...daysArray];
        const localLocationError = { ...locationDetailsError };
        localDays[index].selected = !localDays[index].selected;
        localLocationError.days = "";
        updateDaysArray([...localDays]);
        updateLocationDetailsError({ ...localLocationError });
    };

    const updateDateError = () => {
        const localLocationError = { ...locationDetailsError };
        localLocationError.selectedFromDate = "";
        updateLocationDetailsError({ ...localLocationError });
    };

    const validateLocation = () => {
        const localLocationError = { ...locationDetailsError };
        console.log("locationDetails ", locationDetails)
        let daysError = true;
        let noError = true;
        if (!locationDetails.title.trim().length) {
            localLocationError.title = REQUIRED_ERROR;
            noError = false;
        } else {
            localLocationError.title = "";
        }

        if (!locationDetails.address.trim().length) {
            localLocationError.address = REQUIRED_ERROR;
            noError = false;
        } else {
            localLocationError.address = "";
        }

        if (new Date(selectedFromDate) > new Date(selectedToDate)) {
            localLocationError.selectedFromDate = INVALID_FROM_TO_DATE;
            noError = false;
        } else {
            localLocationError.selectedFromDate = "";
        }

        daysArray.forEach(each => {
            if (each.selected) {
                daysError = false;
            }
        });

        if (daysError) {
            localLocationError.days = CHOOSE_ERROR;
            noError = false;
        } else {
            localLocationError.days = "";
        }

        if (!selectedCoordinates.length) {
            localLocationError.address = INVALID_LOCATION;
            noError = false;
        } else {
            localLocationError.address = "";
        }

        updateLocationDetailsError({ ...localLocationError });
        return noError;
    };

    const openLocationList = () => {
        const localDays = [...daysArray];
        localDays.forEach(each => {
            each.selected = false;
        });
        updateDaysArray([...localDays]);
        updateMode("list");
        updateLocationDetailsError({ ...initialLocationError });
        getAllLocations();
        modifyLocationDetails({
            ...initialLocation
        });
        updateCoordinate([]);
        handleFromDateChange(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)));
        handleToDateChange(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(30)).setSeconds(0)));
    };

    const saveLocation = async (currentMode) => {
        if (!validateLocation()) {
            return;
        }
        let result;
        const fromIndex = (timeArr.indexOf(formatAMPM(selectedFromDate)) + 1);
        const toIndex = (timeArr.indexOf(formatAMPM(selectedToDate)));
        const localWorkingTimeSlots = [];
        const localDays = [];
        daysArray.forEach(each => {
            if (each.selected) {
                localDays.push(each.day);
            }
        });
        if (fromIndex === toIndex) {
            localWorkingTimeSlots.push(fromIndex);
        } else {
            for (let i = fromIndex; i <= toIndex; i++) {
                localWorkingTimeSlots.push(i);
            }
        }
        if (!localWorkingTimeSlots.length) {
            toast.error("Please choose correct start and end time");
            return;
        }
        const requestBody = {
            title: locationDetails.title,
            address: locationDetails.address,
            addressLine1: locationDetails.addressLine1,
            addressLine2: locationDetails.addressLine2,
            addressLine3: locationDetails.addressLine3,
            workingTimeSlots: localWorkingTimeSlots,
            "location": {
                "coordinates": selectedCoordinates
            },
            daysOfWeek: localDays
        }
        toggleDisablePage(true);
        // return;
        if (currentMode === "add") {
            result = await addLocation(requestBody);
        } else {
            requestBody._id = locationDetails._id;
            result = await updateLocation(requestBody);
        }
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            toggleDisablePage(false);
            return;
        }
        toast.success(currentMode === "add" ? API_SUCCESS.ADD_LOCATION : API_SUCCESS.UPDATE_LOCATION);

        openLocationList();

    };

    const chooseLocation = (locationData) => {
        console.log("locationdata ", locationData)
        updateMode("edit");
        pinLocation(true);
        const localLocationDetails = { ...locationDetails };
        const localDays = [...daysArray];
        localLocationDetails.title = locationData.title;
        localLocationDetails.address = locationData.address;
        localLocationDetails.addressLine1 = locationData.addressLine1;
        localLocationDetails.addressLine2 = locationData.addressLine2;
        localLocationDetails.addressLine3 = locationData.addressLine3;
        localLocationDetails._id = locationData._id;
        handleFromDateChange(getStartTime(locationData.workingTimeSlots));
        handleToDateChange(getEndTime(locationData.workingTimeSlots));
        updateCoordinate(locationData.location.coordinates);

        if (locationData.daysOfWeek && locationData.daysOfWeek.length) {
            locationData.daysOfWeek.forEach((each, index) => {
                localDays.forEach(each1 => {
                    if (each === each1.day) {
                        each1.selected = true;
                    }
                });
            });
        }
        updateDaysArray([...localDays]);
        modifyLocationDetails({ ...localLocationDetails });
        initMap(locationData.location.coordinates);
    };

    const removeData = async () => {
        if (removeType === "Location") {
            toggleDisablePage(true);
            const result = await removeLocation(selectedLocationIdToRemove);
            if (result.data.errorCode) {
                toast.error(API_ERROR[result.data.errorCode]);
                toggleDisablePage(false);
                return;
            }
            toast.success(API_SUCCESS.REMOVE_LOCATION);
            updateLocationId("");
            openLocationList();
            openRemoveModal(false);
        } else if (removeType === "Image") {
            toggleDisablePage(true);
            const result = await removeImage(selectedImageIdToRemove);
            if (result.data.errorCode) {
                toast.error(API_ERROR[result.data.errorCode]);
                toggleDisablePage(false);
                return;
            }
            toast.success(API_SUCCESS.REMOVE_IMAGE);
            updateImageId("");
            getProfileDetails("photos");
            openRemoveModal(false);
        } else if (removeType === "Certificate") {
            toggleDisablePage(true);
            const result = await removeCertificate(selectedCertificateIdToRemove);
            if (result.data.errorCode) {
                toast.error(API_ERROR[result.data.errorCode]);
                toggleDisablePage(false);
                return;
            }
            toast.success(API_SUCCESS.REMOVE_IMAGE);
            updateCertificateId("");
            getProfileDetails("personalInfo");
            openRemoveModal(false);
        }
    };

    const toggleCategorySelection = (categoryValue, index) => {
        const localCateories = [...categoryList];
        const localProfileError = { ...profileError };
        const localProfileDetails = { ...selectedProfile };

        localCateories[index].selected = !localCateories[index].selected;
        localProfileError.category = "";

        if (localProfileDetails.categories.includes(categoryValue)) {
            localProfileDetails.categories.splice(localProfileDetails.categories.indexOf(categoryValue), 1);
        } else {
            if (localCateories[index].selected) {
                localProfileDetails.categories.push(categoryValue);
            }
        }

        selectProfile({ ...localProfileDetails });

        console.log("selectedProfile ", localProfileDetails.categories, categoryValue)
        updateCategoryList([...localCateories]);
        updateProfileError({ ...localProfileError });
    };

    const initAutocomplete = () => {
        updateCoordinate([]);
        if (window.google) {
            // const map = new window.google.maps.Map(document.getElementById("map"), {
            //     center: { lat: -33.8688, lng: 151.2195 },
            //     zoom: 13,
            //     mapTypeId: "roadmap",
            // });
            // Create the search box and link it to the UI element.
            const input = document.getElementById("location-search-input");
            const searchBox = new window.google.maps.places.Autocomplete(input);

            searchBox.setComponentRestrictions({
                country: ["in"],
            });
            // map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);
            // Bias the SearchBox results towards current map's viewport.
            map.addListener("bounds_changed", () => {
                searchBox.setBounds(map.getBounds());
            });
            // Listen for the event fired when the user selects a prediction and retrieve
            // more details for that place.
            searchBox.addListener("place_changed", () => {
                const place = searchBox.getPlace();

                if (!place || (place && !Object.keys(place).length)) {
                    return;
                }

                // For each place, get the icon, name and location.
                const bounds = new window.google.maps.LatLngBounds();
                // places.forEach((place) => {
                if (!place.geometry || !place.geometry.location) {
                    console.log("Returned place contains no geometry");
                    return;
                }

                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
                marker.setMap(null);
                myLatLng = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                };
                setMarker({
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                });
                updateCoordinate([place.geometry.location.lng(), place.geometry.location.lat()]);
                updateLocationDetails(place, 'address', true);
                // console.log("places ",place)
                // });
                map.fitBounds(bounds);
            });
        }

    };

    const chooseGalleryImage = (index) => {
        updateCurrentImageIndex(index);
        toggleGalleryModal(true);
    };

    // const removeProfilePicture = async () => {
    //     toggleDisablePage(true);
    //     const result = await removePhoto();
    //     toggleDisablePage(false);
    //     console.log("result ", result);
    //     if (result.data.errorCode) {
    //         toast.error(API_ERROR[result.data.errorCode]);
    //         return;
    //     }

    //     toast.success(API_SUCCESS.PROFILE_IMAGE_REMOVED);
    //     if (document.getElementById("profileImageId")) {
    //         document.getElementById("profileImageId").style.backgroundImage = "url(" + require("../../../images/imgAvtr.png") + ")";
    //     }
    //     if (document.getElementById("profileImage")) {
    //         document.getElementById("profileImage").value = "";
    //     }
    //     getProfileDetails();
    // };

    const changeChequeSelection = (chequeSelected) => {
        toggleCheque(chequeSelected);
        if (!chequeSelected) {
            updateChequeDetailsError({ ...initialChequeDetailsError });
        } else {
            updateBankDetailsError({ ...initialBankDetailsError });
        }
    };

    const saveVendorType = async (vendorType) => {
        toggleDisablePage(true);
        const result = await updateProfile({ subType: Number(vendorType) });
        toggleDisablePage(false);
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            return;
        }
        toast.success(API_SUCCESS.SAVE_VENDOR_TYPE);
        openVendorTypeModal(false);
        getProfileDetails("personalInfo");
    };

    const openVerifyEmailModal = async (isFromModal) => {
        toggleDisablePage(true);
        const result = await requestEmailOtp({
            "email": selectedProfile.email
        });
        toggleDisablePage(false);
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            return;
        }
        console.log("result ", result);
        if (!isFromModal && result.data.success) {
            toggleVerifyEmailModal(true);
        }
        toast.success(API_SUCCESS.EMAIL_SENT);
    };

    const verifyOtp = async (otp) => {
        toggleDisablePage(true);
        const result = await confirmEmailOtp({
            "otp": otp
        });
        toggleDisablePage(false);
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            return;
        }
        console.log("result ", result);
        if (result.data.success) {
            toast.success(API_SUCCESS.EMAIL_VERIFIED);
            toggleVerifyEmailModal(false);
            getProfileDetails("personalInfo");
        }
    };

    const geocodePosition = (pos) => {
        console.log("geocode ", pos)
        geocoder.geocode({
            latLng: pos
        }, (responses) => {
            console.log("responses ", responses)
            if (responses && responses.length > 0) {
                updateCoordinate([pos.lng(), pos.lat()]);
                updateDraggedAddress(responses[0]);
            } else {
                console.log('Cannot determine address at this location.');
            }
        });
    };

    useEffect(() => {
        if (draggedAddress && Object.keys(draggedAddress).length) {
            updateLocationDetails(draggedAddress, 'address', true);
            setTimeout(() => {
                updateDraggedAddress({});
            }, 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [draggedAddress]);


    const setMarker = (pos, isCurrent) => {
        marker = new window.google.maps.Marker({
            position: pos,
            map,
            draggable: true
        });
        if (isCurrent) {
            geocodePosition(marker.getPosition());
        }
        marker.addListener("dragend", () => {
            // console.log("getpos ", marker.getPosition().lat(), marker.getPosition().lng());
            // geocodePosition(marker.getPosition());
            myLatLng = { lat: marker.getPosition().lat(), lng: marker.getPosition().lng() };
        });
    };

    const initMap = (locationData) => {
        setTimeout(() => {
            geocoder = new window.google.maps.Geocoder();
            myLatLng = { lat: 22.7196, lng: 75.8577 };
            if (locationData && locationData.length) {
                myLatLng = { lat: locationData[1], lng: locationData[0] }
            } else if (userLocation.length) {
                myLatLng = { lat: userLocation[1], lng: userLocation[0] }
            }
            map = new window.google.maps.Map(document.getElementById("mapAddress"), {
                center: myLatLng,
                zoom: 18,
                mapTypeId: "roadmap",
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControlOptions: {
                    position: window.google.maps.ControlPosition.LEFT_BOTTOM
                }
            });
            setMarker(myLatLng);
        }, 100);
    };

    const setCurrentLocation = () => {
        setMarker(myLatLng, true);
    };

    const checkLink = () => {
        if (!selectedProfile.websiteUrl.includes('http://') && !selectedProfile.websiteUrl.includes('https://')) {
            window.open('http://' + selectedProfile.websiteUrl);
        } else {
            window.open(selectedProfile.websiteUrl);
        }
    };

    const chooseCurrentUserLocation = () => {
        if (userLocation.length) {
            marker.setMap(null);
            myLatLng = { lat: userLocation[1], lng: userLocation[0] };
            setMarker(myLatLng);
            map.setCenter(myLatLng);
        } else {
            if (window.navigator.geolocation) {
                window.navigator.geolocation.getCurrentPosition((position) => {
                    if (position && position.coords && position.coords.latitude && position.coords.longitude) {
                        updateUserLocation([position.coords.longitude, position.coords.latitude]);
                        marker.setMap(null);
                        myLatLng = { lat: position.coords.latitude, lng: position.coords.longitude };
                        setMarker(myLatLng);
                        map.setCenter(myLatLng);
                    }
                }, (error) => {
                    if (error.code === 1) {
                        toast.error("Not able access your current location. If you have denied the permission then please refresh the page or Please change the location access from your browser's settings");
                    }
                });
            } else {
                toast.error("Geolocation is not supported by this browser.");
            }
        }
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={`row dashboard ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
                {Object.keys(selectedProfile).length ?
                    <ShareToSocialModal displayShareModal={displayShareModal}
                        toggleShareModal={toggleShareModal} bookingDetails={selectedProfile} /> : null}
                <RemoveModal
                    displayRemoveModal={displayRemoveModal}
                    openRemoveModal={openRemoveModal}
                    removeType={removeType}
                    removeData={removeData} />
                {displayVendorTypeModal ?
                    <VendorTypeModal displayModal={displayVendorTypeModal} closeModal={openVendorTypeModal}
                        saveVendorType={saveVendorType} subType={selectedProfile.subType} /> : null}
                {openEmailAlert ?
                    <EmailAlert displayModal={openEmailAlert} toggleEmailAlert={toggleEmailAlert} /> : null}
                {verifyEmailModalOpened ?
                    <OtpModal displayModal={verifyEmailModalOpened}
                        closeModal={toggleVerifyEmailModal} verifyOtp={verifyOtp} resend={openVerifyEmailModal} /> : null}
                <div className="col-12 categories-heading">
                    <h4 className={`mb-0`}>My Profile</h4>
                </div>
                <div className="col-md-4">
                    <div className="row profile-details-wrapper" style={{ margin: 0 }}>
                        <div className="col-12 mb-3" style={{
                            padding: "10px",
                            border: "1px solid #ccc",
                            borderRadius: "7px"
                        }}>
                            Account status: {VendorConfigTextStatus[selectedProfile.status]}
                        </div>
                        <div className="col-12 p-0 mb-3 add-edit-service-button-wrapper">
                            <button type="button" className="btn btn-primary btn-sm save-button" onClick={savePersonalInfo}>Save</button>
                            <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => { updateProfileError({ ...initialProfileError }); getProfileDetails('personalInfo') }}>Reset</button>
                        </div>
                        {!uploadedProfileImage ?
                            <div className="col-12 p-0 profile-image-wrapper mb-3">
                                {selectedProfile.profilePicture ?
                                    <div className="profile-image" style={{ backgroundImage: "url(" + selectedProfile.profilePicture + ")" }}>
                                        <div className="file-uploader">
                                            <input type="file" id="profileImage" accept="image/*" hidden onChange={uploadProfileImage} />
                                            <i className="fa fa-camera" aria-hidden="true" onClick={() => openFileUploader("profileImage")}></i>
                                        </div>
                                    </div> :
                                    <div className="profile-image" style={{ backgroundImage: "url(" + require("../../../images/imgAvtr.png") + ")" }}>
                                        <div className="file-uploader">
                                            <input type="file" id="profileImage" accept="image/*" hidden onChange={uploadProfileImage} />
                                            <i className="fa fa-camera" aria-hidden="true" onClick={() => openFileUploader("profileImage")}></i>
                                        </div>
                                    </div>
                                }

                            </div>
                            :
                            <div className="col-12 p-0 profile-image-wrapper mb-3">
                                <div className="profile-image" id="profileImageId" style={{ backgroundImage: "url(" + uploadedProfileImage + ")" }}>
                                    <div className="file-uploader">
                                        <input type="file" id="profileImage" accept="image/*" hidden onChange={uploadProfileImage} />
                                        <i className="fa fa-camera" aria-hidden="true" onClick={() => openFileUploader("profileImage")}></i>
                                    </div>
                                </div>
                            </div>
                        }
                        {/* {selectedProfile.profilePicture ?
                            <div className="text-center col-12 font-12"><button className="btn btn-link pt-0" onClick={removeProfilePicture}>Remove profile photo</button></div> : null} */}
                        <div className="col-12 p-0 text-center">
                            <label htmlFor="exampleFormControlInput1" className="form-label font-12">Followed By: {selectedProfile.followedBy} Customer{selectedProfile.followedBy > 1 ? 's' : ''}</label>
                        </div>
                        <div className="col-12 p-0 mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">{selectedProfile.subType === 2 ? "Organisation " : ""}Name</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="fa fa-user" /></div>
                                </div>
                            <input type="text" className={`form-control ${profileError.name ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder={`Enter ${selectedProfile.subType === 2 ? "Organisation " : ""}Name`} value={selectedProfile.name} onChange={e => changePersonalInfo(e.target.value, 'name')} disabled={selectedProfile.status === VendorConfigStatus.approved} />
                            </div>
                            {profileError.name ?
                                <div className="input-error">{profileError.name}</div> : null}
                        </div>
                        <div className="col-12 p-0 mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Phone Number</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="fas fa-phone-alt" /></div>
                                </div>
                                <input type="text" className={`form-control`} value={selectedProfile.phoneNumber} disabled />
                            </div>
                        </div>
                        {categoryList.length ?
                            <div className="col-12 p-0 mb-3 custom-category-dropdown-wrapper">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Professional Category</label>
                                <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="fas fa-lock" /></div>
                                </div>
                                <div className="custom-category-dropdown-select" onClick={() => toggleCategory(!categoryDropdownOpened)}>
                                    {selectedProfile.categories && selectedProfile.categories.length ? `${selectedProfile.categories.length} Selected` : 'Select'}
                                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                                </div>
                                </div>
                                {categoryDropdownOpened ?
                                    <div className="row" style={{ margin: 0 }}>
                                        {categoryList.length ? categoryList.map((dayValue, index) => {
                                            return <div className="col-12" key={dayValue._id}>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id={dayValue._id} checked={dayValue.selected} onChange={() => toggleCategorySelection(dayValue._id, index)} />
                                                    <label className="form-check-label" htmlFor={dayValue._id}>
                                                        {dayValue.title}
                                                    </label>
                                                </div>
                                            </div>
                                        }) : null}
                                    </div> : null}
                                {profileError.category ?
                                    <div className="input-error">{profileError.category}</div> : null}
                            </div> : null}

                        <div className="col-12 p-0 mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Total Exp</label>
                            <div className="row m-0">
                                <div className="col-12 p-0">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text"><i className="far fa-user-circle" /></div>
                                        </div>
                                        <input type="text" className={`form-control ${profileError.experience && !selectedProfile.experience.year ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="in year" value={selectedProfile.experience ? selectedProfile.experience.year : ""} onChange={e => { changePersonalInfo(e.target.value.replace(/\D/, ''), 'year') }} />
                                    </div>
                                </div>
                                {/* <div className="col-6 pr-0">
                                    <input type="text" className={`form-control ${profileError.experience && (!selectedProfile.experience.month || profileError.experience === INVALID_MONTH) ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="in month" value={selectedProfile.experience ? selectedProfile.experience.month : ""} onChange={e => { changePersonalInfo(e.target.value.replace(/\D/, ''), 'month') }} />
                                </div> */}
                            </div>
                            {profileError.experience ?
                                <div className="input-error">{profileError.experience}</div> : null}
                        </div>
                        <div className="col-12 p-0 mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Email</label>
                            {!selectedProfile.emailVerified ?
                                <button disabled={!selectedProfile?.email?.trim().length || !validateEmail(selectedProfile?.email)} className="btn btn-link p-0 float-right font-12" onClick={() => openVerifyEmailModal(false)}>Verify Email</button> : null}
                                <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="far fa-envelope" /></div>
                                </div>
                            <input type="text" className={`form-control ${profileError.email ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter Email" value={selectedProfile.email} onChange={e => changePersonalInfo(e.target.value, 'email')} disabled={selectedProfile.status === VendorConfigStatus.approved && selectedProfile.emailVerified} />
                            </div>
                            {profileError.email ?
                                <div className="input-error">{profileError.email}</div> : null}
                            {!selectedProfile.emailVerified ? <div className='pt-2' style={{
                                fontSize: "12px",
                                color: "#d77c2b"
                            }}>Email needs to be verified.</div> : <div style={{
                                fontSize: "12px",
                                color: "#3bb233"
                            }} className='pt-2'>Verified</div>}
                        </div>
                        <div className="col-12 p-0 mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Website</label>
                            {selectedProfile.websiteUrl && selectedProfile.websiteUrl.length ?
                                <button type="button" class="btn btn-link p-0 pull-right" onClick={checkLink}>Check Link</button> : null}
                                <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="fas fa-globe" /></div>
                                </div>
                            <input type="text" className={`form-control ${profileError.websiteUrl ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter Website" value={selectedProfile.websiteUrl} onChange={e => changePersonalInfo(e.target.value, 'websiteUrl')} />
                            </div>
                            <div className="example-text">Website url e.g.: https://your-website.com</div>
                            {profileError.websiteUrl ?
                                <div className="input-error">{profileError.websiteUrl}</div> : null}
                        </div>
                        <div className="col-12 p-0 mb-3">
                            <label htmlFor="exampleFormControlTextarea1" className="form-label">Description</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={selectedProfile.description} onChange={e => changePersonalInfo(e.target.value, 'description')} placeholder="Short bio"></textarea>
                        </div>
                        <div className="col-12 p-0 text-center">
                            <button type="button" className="btn btn-primary w-50" onClick={savePersonalInfo}>Save</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-8 margin-top-less-768">
                    <div className="row profile-details-wrapper" style={{ margin: 0 }}>
                        <div className="col-12 p-0">
                            <ul className="nav nav-tabs profile-menu-options">
                                {menuOptions.map((menu, index) => {
                                    return <li className="nav-item" key={index} onClick={() => updateMenu(menu)}>
                                        <span className={`nav-link ${menu.selected ? 'active' : ''}`}>{menu.option}</span>
                                    </li>
                                })}
                            </ul>
                        </div>
                        <div className="col-12 p-0">
                            {selectedMenu === "Gallery" ?
                                <div className="col-12 p-0">
                                    <div className="row" style={{ margin: "0" }}>
                                        <div className="col-12 p-0">
                                            <div className="row" style={{ margin: 0 }}>
                                                <div className={`col-sm-6 col-xs-12 pl-0 pt-2 ${selectedProfile.descriptionImages && selectedProfile.descriptionImages.length ? 'mt-2' : ''}`}>
                                                    <h5 className={`mb-0`}>Gallery</h5>
                                                </div>
                                                {!uploadedGalleryImage && selectedProfile.descriptionImages && selectedProfile.descriptionImages.length ?
                                                    <div className="col-sm-6 col-xs-12 pr-0 text-right category-arrows" onMouseEnter={() => { displayNavs(true) }}>
                                                        <button className="prev-button-wrapper" onClick={() => prevClick('categories-wrapper')} id="slideprev" disabled={!showPrevButtons}>
                                                            <i className="fas fa-arrow-left"></i>
                                                        </button>
                                                        <button className="next-button-wrapper" onClick={() => nextClick('categories-wrapper')} id="slide" disabled={!showNextButtons}>
                                                            <i className="fas fa-arrow-right"></i>
                                                        </button>
                                                    </div> : null}

                                            </div>
                                        </div>
                                        {!uploadedGalleryImage ?
                                            <div className={`about-image-wrapper pl-0 pt-2`}>
                                                <input type="file" accept="image/*" id="galleryImage" hidden onChange={uploadGalleryImage} />
                                                <div className="profile-image description-image" style={{ backgroundColor: "#eee", backgroundImage: "url(" + require("../../../images/plus.svg") + ")" }} onClick={() => openFileUploader("galleryImage")} />
                                            </div> :
                                            <div className={`about-image-wrapper pl-0 pt-2`}>
                                                <input type="file" accept="image/*" id="galleryImage" hidden onChange={uploadGalleryImage} />
                                                <div className="profile-image description-image" style={{ backgroundColor: "#eee", backgroundImage: "url(" + URL.createObjectURL(uploadedGalleryImage) + ")" }} />
                                            </div>
                                        }
                                        <div className={`categories-wrapper gallery-images-wrapper pl-0 pr-0 pt-2 pb-0`} style={{ margin: 0 }}>
                                            {selectedProfile.descriptionImages && selectedProfile.descriptionImages.length ?
                                                selectedProfile.descriptionImages.map((image, index) => {
                                                    return <div className={`about-image-wrapper pl-0`} key={index} onClick={() => chooseGalleryImage(index)}>
                                                        <div className="profile-image description-image" style={{ backgroundImage: "url(" + image.url + ")" }} />
                                                        <img src={require("../../../images/remove.svg")} onClick={e => { e.stopPropagation(); openRemove(image, "Image") }} alt={`remove gallery`} />
                                                    </div>
                                                })
                                                : null}
                                        </div>
                                    </div>
                                </div>
                                : null}
                            {selectedMenu === "Bank Details" ?
                                <div className="col-12 p-0">
                                    <div className="row" style={{ margin: 0 }}>
                                        <div className="col-12 p-0">
                                            <div className="row" style={{ margin: 0 }}>
                                                <div className="col-sm-6 col-xs-12 pl-0 pt-2">
                                                    <h5 className={`mb-0`}>Bank Details</h5>
                                                </div>
                                                <div className="col-sm-6 col-xs-12 pr-0 text-right mb-3">
                                                    <button type="button" className="btn btn-outline-primary btn-sm mr-2" onClick={() => { updateBankDetailsError({ ...initialBankDetailsError }); getProfileDetails("bank") }}>Reset</button>
                                                    <button type="button" className="btn btn-primary btn-sm" onClick={saveBankDetails}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xs-12 left-padding mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Bank Name</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text"><i className="fas fa-university" /></div>
                                                </div>
                                                    <input type="text" className={`form-control ${bankDetailsError.bankName ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter Bank Name" value={selectedProfile.bankDetails.bankName} disabled={isCheque} onChange={e => updateBankDetails(e.target.value, 'bankName')} />
                                            </div>
                                            {bankDetailsError.bankName ?
                                                <div className="input-error">{bankDetailsError.bankName}</div> : null}
                                        </div>
                                        <div className="col-md-6 col-xs-12 right-padding mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Account Holder Name</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text"><i className="fa fa-user" aria-hidden="true" /></div>
                                                </div>
                                                <input type="text" className={`form-control ${bankDetailsError.accountHolderName ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter Account Holder Name" value={selectedProfile.bankDetails.accountHolderName} onChange={e => updateBankDetails(e.target.value, 'accountHolderName')} disabled={isCheque} />
                                            </div>
                                            {bankDetailsError.accountHolderName ?
                                                <div className="input-error">{bankDetailsError.accountHolderName}</div> : null}
                                        </div>

                                        <div className="col-md-6 col-xs-12 left-padding mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Account Number</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text"><i className="fas fa-university" /></div>
                                                </div>
                                            <input type="text" className={`form-control ${bankDetailsError.accountNumber ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter Account Number" value={selectedProfile.bankDetails.accountNumber} onChange={e => updateBankDetails(e.target.value.replace(/\D/, ''), 'accountNumber')} disabled={isCheque} />
                                            </div>
                                            {bankDetailsError.accountNumber ?
                                                <div className="input-error">{bankDetailsError.accountNumber}</div> : null}
                                        </div>
                                        <div className="col-md-6 col-xs-12 right-padding mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">IFSC Code</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text"><i className="fas fa-university" /></div>
                                                </div>
                                            <input type="text" className={`form-control ${bankDetailsError.ifscCode ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter IFSC Code" value={selectedProfile.bankDetails.ifscCode} disabled={isCheque} onChange={e => updateBankDetails(e.target.value, 'ifscCode')} />
                                            </div>
                                            {bankDetailsError.ifscCode ?
                                                <div className="input-error">{bankDetailsError.ifscCode}</div> : null}
                                        </div>
                                        <div className="col-md-6 col-xs-12 left-padding mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Branch</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text"><i className="fas fa-university" /></div>
                                                </div>
                                            <input type="text" className={`form-control ${bankDetailsError.branchName ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter Branch Name" value={selectedProfile.bankDetails.branchName} disabled={isCheque} onChange={e => updateBankDetails(e.target.value, 'branchName')} />
                                            </div>
                                            {bankDetailsError.branchName ?
                                                <div className="input-error">{bankDetailsError.branchName}</div> : null}
                                        </div>
                                        <div className="col-md-6 col-xs-12 right-padding mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">GST Number <span style={{
                                                fontSize: "11px",
                                                fontWeight: 500
                                            }}>(*Not mandatory)</span></label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text"><i className="fas fa-university" /></div>
                                                </div>
                                            <input type="text" className={`form-control`} id="exampleFormControlInput1" placeholder="Enter GST Number" value={selectedProfile.bankDetails.gstNumber} onChange={e => updateBankDetails(e.target.value, 'gstNumber')} disabled={isCheque} />
                                            </div>
                                        </div>
                                        <div className="col-12 form-check right-padding text-right">
                                            <input className="form-check-input" type="checkbox" id="chequeOption" value="" checked={isCheque} onChange={() => changeChequeSelection(!isCheque)} />
                                            <label className="form-check-label" htmlFor='chequeOption'>
                                                Opt for cheque payment
                                            </label>
                                        </div>
                                        <div className="col-12 p-0 mb-3 cheque-message">
                                            Note: Opting for cheque payments will invite a processing and handling fee of <i className="fas fa-rupee-sign"></i>250 per settlement.
                                        </div>
                                        {isCheque ? <>
                                            <div className="col-md-6 col-xs-12 left-padding mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Name</label>
                                                <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text"><i className="fas fa-university" /></div>
                                                </div>
                                                <input type="text" className={`form-control ${chequeDetailsError.chequeName ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Your name on the cheque" value={selectedProfile.bankDetails.chequeName} onChange={e => updateBankDetails(e.target.value, 'chequeName')} disabled={!isCheque} />
                                                </div>
                                                {chequeDetailsError.chequeName ?
                                                    <div className="input-error">{chequeDetailsError.chequeName}</div> : null}
                                            </div>
                                            <div className="col-md-6 col-xs-12 right-padding mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Address</label>
                                                <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text"><i className="fas fa-university" /></div>
                                                </div>
                                                <input type="text" className={`form-control ${chequeDetailsError.chequeAddress ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Cheque delivery address" value={selectedProfile.bankDetails.chequeAddress} onChange={e => updateBankDetails(e.target.value, 'chequeAddress')} disabled={!isCheque} />
                                                </div>
                                                {chequeDetailsError.chequeAddress ?
                                                    <div className="input-error">{chequeDetailsError.chequeAddress}</div> : null}
                                            </div>
                                        </> : null}
                                        {/* {isCheque ? */}

                                        {/* : null} */}
                                    </div>
                                </div>
                                : null}
                            {selectedMenu === "Address Manager" ?
                                <div className="col-12 p-0">
                                    {mode === "list" ?
                                        <div className="row" style={{ margin: 0 }}>
                                            <div className="col-12 p-0">
                                                <div className="row" style={{ margin: 0 }}>
                                                    <div className="col-sm-8 col-xs-12 pl-0 pt-2">
                                                        <h5 className={`mb-2`}>Address Manager</h5>
                                                    </div>
                                                    {(selectedProfile.subType === 1 && addresses.length < 1) ||
                                                        selectedProfile.subType === 2 ?
                                                        <div className="col-sm-4 col-xs-12 pr-0 text-right mb-3">
                                                            <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => { updateMode("add"); pinLocation(true) }}>Add Address</button>
                                                        </div> : null}
                                                </div>
                                            </div>
                                            {addresses.length ?
                                                addresses.map((professional, index) => {
                                                    return <div className={`col-sm-6 col-xs-12 professional-wrapper
                                        ${index % 2 === 0 ? 'problem-more-768-pr-15-for-2' : ''}
                                        ${index !== addresses.length - 1 && index !== addresses.length - 2 && index !== addresses.length - 3 ? 'problem-more-768-pb-15' : ''}
                                        ${index !== addresses.length - 1 && index !== addresses.length - 2 ? 'problem-more-768-pb-15-for-2' : ''}
                                        ${index !== addresses.length - 1 ? 'problem-more-768-pb-15-for-1' : ''}
                                        `} key={index} onClick={() => chooseLocation(professional)}>
                                                        <div className="row service-row" style={{ margin: 0 }}>
                                                            <div className="col-12 p-0">
                                                                <div className="row" style={{ margin: 0 }}>
                                                                    <div className="professional-name col-12 p-0">{professional.title}
                                                                    </div>
                                                                    <div className="professional-location service-name col-12 p-0">{professional.addressLine1 ? professional.addressLine1 : professional.address}</div>
                                                                    <div className="col-5 pl-0">
                                                                        <div className="service-cost">{professional.days}</div>
                                                                    </div>
                                                                    <div className="col-7 pr-0 text-right">
                                                                        <div className="service-hour">{professional.duration}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {addresses.length > 1 ?
                                                                <img src={require("../../../images/remove.svg")} onClick={(e) => { e.stopPropagation(); openRemove(professional, "Location") }} alt="remove location" /> : null}
                                                        </div>
                                                    </div>
                                                })
                                                : <div className="text-center well col-12 mb-3 color-black">
                                                    No saved address
                                                </div>}
                                        </div> : null}
                                    {mode === "add" || mode === "edit" ?
                                        <div className="row" style={{ margin: 0 }}>
                                            <div className="col-12 p-0">
                                                <div className="row" style={{ margin: 0 }}>
                                                    <div className="col-sm-6 col-xs-12 pl-0 pt-2">
                                                        <h5 className={`mb-0`}>{mode === "add" ? "Add" : "Edit"} Location</h5>
                                                    </div>
                                                    <div className="col-sm-6 col-xs-12 pr-0 text-right mb-3">
                                                        <button type="button" className="btn btn-outline-primary btn-sm mr-2" onClick={() => { updateLocationDetailsError({ ...initialLocationError }); openLocationList() }}>Cancel</button>
                                                        <button type="button" className="btn btn-primary btn-sm" onClick={() => saveLocation(mode)}>Save</button>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ margin: 0 }}>
                                                    <div className="col-12 text-center mb-3 form-label">
                                                        Your Current Location Of Practice
                                                    </div>
                                                    <div className="col-md-6 col-xs-12 left-padding mb-3">
                                                        <label htmlFor="exampleFormControlInput1" className="form-label">City, State</label>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text"><i className="fas fa-map-marker-alt" /></div>
                                                            </div>
                                                            <input type="text" className={`form-control ${locationDetailsError.title ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter City, State" value={locationDetails.title} onChange={e => updateLocationDetails(e.target.value, "title")} />
                                                        </div>
                                                        {locationDetailsError.title ?
                                                            <div className="input-error">{locationDetailsError.title}</div> : null}
                                                    </div>
                                                    <div className="col-md-6 col-xs-12 right-padding mb-3">
                                                        <label htmlFor="exampleFormControlInput1" className="form-label">{!locationPinned ? "Search on Google maps" : "Choose map location"}</label>
                                                        <br />
                                                        {locationPinned ?
                                                            <button className='btn btn-default' style={{
                                                                backgroundColor: "#eff5ff",
                                                                color: "#566986"
                                                            }} onClick={() => { pinLocation(false); initMap(selectedCoordinates.length ? selectedCoordinates : []) }}>
                                                                {selectedCoordinates.length ?
                                                                    <i className={`fa fa-check-circle mr-2 status-icon`} style={{ color: "#48cc39" }} aria-hidden="true" /> : <i className={`fa fa-exclamation-circle mr-2 status-icon`} style={{ color: "#f29900" }} aria-hidden="true" />}
                                                                {!selectedCoordinates.length ? 'Choose map location ' : 'Location selected'}</button> :
                                                            <input type="text" className={`form-control ${locationDetailsError.address ? 'error-border' : ''}`} id="location-search-input" placeholder="Search on Google maps" value={locationDetails.address} onChange={e => { initAutocomplete(); updateLocationDetails(e.target.value, "address") }} />}
                                                        {locationDetailsError.address ?
                                                            <div className="input-error">{locationDetailsError.address}</div> : null}
                                                        {!locationPinned ?
                                                            <><button className="btn btn-sm btn-primary" onClick={() => { pinLocation(true); setCurrentLocation() }} style={{
                                                                position: 'absolute',
                                                                right: "10px",
                                                                bottom: "-60px",
                                                                zIndex: 9
                                                            }}>Confirm Location</button>
                                                                <button className="btn btn-sm btn-primary" onClick={() => { chooseCurrentUserLocation() }} style={{
                                                                    position: 'absolute',
                                                                    right: "10px",
                                                                    bottom: "-110px",
                                                                    zIndex: 9
                                                                }}><MyLocation /></button></> : null}
                                                    </div>
                                                    {!locationPinned ?
                                                        <div id="mapAddress" className='col-12 mb-3' style={{
                                                            height: "250px"
                                                        }} /> : null}
                                                    <div className="col-md-6 col-xs-12 left-padding mb-3">
                                                        <label htmlFor="exampleFormControlInput1" className="form-label">Full address</label>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text"><i className="fas fa-map-marker-alt" /></div>
                                                            </div>
                                                        <input type="text" className={`form-control ${locationDetailsError.addressLine1 ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter full address" value={locationDetails.addressLine1} onChange={e => updateLocationDetails(e.target.value, "addressLine1")} />
                                                        </div>
                                                        {locationDetailsError.addressLine1 ?
                                                            <div className="input-error">{locationDetailsError.addressLine1}</div> : null}
                                                    </div>
                                                    <div className="col-md-6 col-xs-12 right-padding mb-3">
                                                        <label htmlFor="exampleFormControlInput1" className="form-label">Pincode</label>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text"><i className="fas fa-map-marker-alt" /></div>
                                                            </div>
                                                        <input type="text" className={`form-control ${locationDetailsError.addressLine2 ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter pincode" value={locationDetails.addressLine2} maxLength={6} onChange={e => updateLocationDetails(e.target.value.replace(/\D/, ''), "addressLine2")} />
                                                        </div>
                                                        {locationDetailsError.addressLine2 ?
                                                            <div className="input-error">{locationDetailsError.addressLine2}</div> : null}
                                                    </div>
                                                    <div className="col-md-6 col-xs-12 left-padding mb-3">
                                                        <label htmlFor="exampleFormControlInput1" className="form-label">Landmark</label>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text"><i className="fas fa-map-marker-alt" /></div>
                                                            </div>
                                                        <input type="text" className={`form-control ${locationDetailsError.addressLine3 ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter landmark" value={locationDetails.addressLine3} onChange={e => updateLocationDetails(e.target.value, "addressLine3")} />
                                                        </div>
                                                        {locationDetailsError.addressLine3 ?
                                                            <div className="input-error">{locationDetailsError.addressLine3}</div> : null}
                                                    </div>
                                                    <div className="col-12 text-center mb-3 form-label">
                                                        Your work hours and workdays
                                                    </div>
                                                    <div className="col-md-6 col-xs-12 left-padding mb-3 custom-time-picker-design">
                                                        <label htmlFor="exampleFormControlInput1" className="form-label">Start time</label><br />
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text"><i className="far fa-hourglass" /></div>
                                                            </div>
                                                        <TimePicker className={`${locationDetailsError.selectedFromDate ? 'timepicker-error-border' : ''} time-picker-with-icon`} value={selectedFromDate} onChange={handleFromDateChange} onClose={updateDateError} minutesStep={30} />
                                                        </div>
                                                        {locationDetailsError.selectedFromDate ?
                                                            <div className="input-error">{locationDetailsError.selectedFromDate}</div> : null}
                                                    </div>
                                                    <div className="col-md-6 col-xs-12 right-padding mb-3 custom-time-picker-design">
                                                        <label htmlFor="exampleFormControlInput1" className="form-label">End time</label><br />
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text"><i className="far fa-hourglass" /></div>
                                                            </div>
                                                        <TimePicker className={`${locationDetailsError.selectedFromDate ? 'timepicker-error-border' : ''} time-picker-with-icon`} value={selectedToDate} onChange={handleToDateChange} minutesStep={30} onClose={updateDateError} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 p-0 mb-3">
                                                        <label htmlFor="exampleFormControlInput1" className="form-label">Choose Day(s)</label>
                                                        <div className="row" style={{ maargin: 0 }}>
                                                            {daysArray.length ? daysArray.map((dayValue, index) => {
                                                                return <div className="col-md-3 col-6" key={index}>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id={`${dayValue.text}-checkbox`} checked={dayValue.selected} onChange={() => toggleDaySelection(index)} />
                                                                        <label className="form-check-label" htmlFor={`${dayValue.text}-checkbox`}>
                                                                            {dayValue.text}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            }) : null}
                                                        </div>
                                                        {locationDetailsError.days ?
                                                            <div className="input-error">{locationDetailsError.days}</div> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                                : null}
                        </div>
                    </div>
                    <div className="row ml-0 mr-0 mt-3 profile-details-wrapper">
                        <div className="col-sm-8 col-xs-12 pl-0" style={{
                            color: "#000",
                            fontWeight: 400,
                            fontFamily: 'Poppins Regular',
                            fontSize: '14px',
                            lineHeight: 2.5
                        }}>
                            <b>Vendor type:</b> {selectedProfile.subType === 1 ? "Individual Practitioner" : "Organisation"}
                        </div>
                        {selectedProfile.status !== VendorConfigStatus.approved ?
                            <div className="col-sm-4 col-xs-12 text-right pr-0">
                                <button className="btn btn-outline-primary" onClick={() => openVendorTypeModal(true)}>Change</button>
                            </div> : null}
                    </div>
                    <div className="row ml-0 mr-0 mt-3 profile-details-wrapper">
                        <div className="col-sm-8 col-xs-12 pl-0" style={{
                            color: "#000",
                            fontWeight: 400,
                            fontFamily: 'Poppins Regular'
                        }}>
                            Check how it is going to appear in customer application. Clicking on preview button will open customer app in a new tab.
                        </div>
                        <div className="col-sm-4 col-xs-12 text-right pr-0">
                            <img src={require('../../../images/profile-share.png')} className="mr-2" style={{ width: "60px", cursor: "pointer" }} alt="share" onClick={() => toggleShareModal(true)} />
                            <button className="btn btn-outline-primary" onClick={() => window.open(`https://parentcraftindia.com/professional-details/${selectedProfile._id}/${selectedProfile.name.split(" ").join("-")}`)}>Preview</button>
                        </div>
                    </div>

                </div>
            </div>
            {selectedProfile.descriptionImages && openGalleryModal ?
                <GalleryModal openGalleryModal={openGalleryModal} galleryImages={selectedProfile.descriptionImages} currentImageIndex={currentImageIndex} toggleGalleryModal={toggleGalleryModal} updateCurrentImageIndex={updateCurrentImageIndex} /> : null}
        </MuiPickersUtilsProvider>
    )
};

const mapStateToProps = state => {
    return { data: state };
};

export default injectIntl(
    connect(
        mapStateToProps,
        auth.actions
    )(Dashboard)
);