import React, { useEffect, useState } from 'react';
import "./forum.css";

const SuccessPayment = (props) => {
    const [pageInitialized, updatePageInitialization] = useState(false);

    useEffect(() => {
        if (!pageInitialized) {
            const el = document.getElementsByClassName("custom-navigation-bar")[0];
            const el1 = document.getElementsByClassName("footer")[0];
            if (el) {
                el.style.visibility = "hidden";
            }
            if (el1) {
                el1.style.visibility = "hidden";
            }
            updatePageInitialization(true);
        }
    }, [pageInitialized]);

    const openAppointmentPage = () => {
        props.history.push(`/event`);
    };

    return (
        <div className="forum success-payment-outside">
            <div className={`success-payment-wrapper`}>
                <>
                    <h3 className="header-1">
                        You have successfully created the event
                    </h3>
                    <div className="button-wrapper">
                        <button className="btn btn-outline-primary" onClick={() => props.history.push("/dashboard")}>Move to Dashboard</button>
                        <button className="btn btn-primary" onClick={openAppointmentPage}>See Events</button>
                    </div>
                </>
            </div>
        </div>
    )
}

export default SuccessPayment;