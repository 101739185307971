export const API_SUCCESS = {
    EMAIL_VERIFIED: 'Email verified successfully!',
    EMAIL_SENT: 'Email sent successfully!',
    UPDATE_PROFILE: "Profile updated successfully",
    ADD_LOCATION: "Location added successfully",
    UPDATE_LOCATION: "Location updated successfully",
    REMOVE_LOCATION: "Location removed successfully",
    REMOVE_IMAGE: "Image removed successfully",
    ADD_IMAGE: "Image added successfully",
    UPDATE_PROFILE_IMAGE: "Profile picture updated successfully",
    UPDATE_BANK: "Bank details updated successfully",
    ADD_SERVICE: "Service added successfully",
    UPDATE_SERVICE: "Service updated successfully",
    REMOVE_SERVICE: "Service removed successfully",
    LOGIN_SUCCESS: "Login successfully",
    APPOINTMENT_COMPLETED: "Appointment completed successfully",
    CANCEL_SUCCESS: "Appointment cancelled successfully",
    COMPLAIN_SUBMITTED: "Complain/ Suggestion submitted successfully",
    SLOT_BLOCKED: "Slot blocked successfully",
    MARK_HOLIDAY: "The day is marked as holiday",
    SLOT_UNBLOCKED: "Slot unblocked successfully",
    ADD_NOTE: "Note submitted successfully",
    PROFILE_IMAGE_REMOVED: "Profile photo removed successfully",
    GOVT_ID_UPLOADED: "Government Id front image uploaded successfully",
    GOVT_ID_BACK_UPLOADED: "Government Id back image uploaded successfully",
    CERT_UPLOADED: "Certificate uploaded successfully",
    RCI_UPLOADED: "RCI Certificate uploaded successfully",
    GUMASTA_UPLOADED: "License/ Gumasta uploaded successfully",
    APPROVAL_REQUESTED: "Requested for approval successfully",
    ADD_SESSION: "Session added successfully",
    UPDATE_SESSION: "Session updated successfully",
    REMOVE_SESSION: "Session removed successfully",
    EVENT_IMAGE_UPLOADED: "Image uploaded successfully",
    ADD_EVENT: "Event added successfully",
    UPDATE_EVENT: "Event updated successfully",
    REMOVE_EVENT: "Event cancelled successfully",
    UPDATE_ANSWER: "Your comment updated successfully",
    MEETING_DETAILS_UPDATED: "Meeting details updated successfully",
    ADD_WORKSHOP: "Workshop added successfully",
    UPDATE_WORKSHOP: "Workshop updated successfully",
    REMOVE_WORKSHOP: "Workshop cancelled successfully",
    SEND_CERTIFICATE: "Certificate send successfully",
    SAVE_VENDOR_TYPE: "Profile type saved successfully!",
    ACCOUNT_DELETE: 'Account deleted successfully!'
};