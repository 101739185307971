import React, { useEffect, useState, useCallback } from 'react';
import "./service.css";
import DateFnsUtils from '@date-io/date-fns';
import { shallowEqual, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import {
    TimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
    getServices,
    addService,
    getOrganizations,
    getLocationsForOrganization,
    updateService,
    removeService
} from "../../crud/service.crud";
import {
    getLocationList,
    addLocation,
    getProfile
} from "../../crud/profile.crud";
import RemoveModal from "./remove-modal";
import { REQUIRED_ERROR, INVALID_FROM_TO_DATE, CHOOSE_ERROR, API_ERROR, INVALID_LOCATION } from "../../config/error-message";
import { API_SUCCESS } from "../../config/success-message";
import {
    serviceTimeHoursConstant,
    timeArrConst,
    daysConstant,
    VendorConfigStatus
} from "../../config/constants";

const days = [...daysConstant];

const timeArr = [...timeArrConst];

const initialServiceDetails = {
    title: "",
    fees: "",
    description: ""
};

const initialServiceError = {
    title: "",
    fees: ""
};

const initialOrgServiceError = {
    days: "",
    selectedFromDate: ""
};

const serviceTimeHours = [...serviceTimeHoursConstant];

const initialLocationError = {
    title: "",
    address: "",
    selectedLocationFromDate: "",
    selectedLocationToDate: "",
    days: ""
};

const serviceTypeObj = {
    1: "Online Only",
    2: "Offline Only",
    3: "Both Online and Offline"
};

const Service = (props) => {
    const [services, updateServices] = useState([]);
    const [selectedFromDate, handleFromDateChange] = useState(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)));
    const [selectedToDate, handleToDateChange] = useState(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(30)).setSeconds(0)));
    const [daysArray, updateDaysArray] = useState([]);
    const [selectedServiceType, changeServiceType] = useState("individual");
    const [selectedServiceMode, changeServiceMode] = useState(1);
    const [displayLocationModal, openCreateLocationModal] = useState(false);
    const [displayRemoveModal, openRemoveModal] = useState(false);
    const [removeType, updateRemoveType] = useState("");
    const [individualLocations, updateIndividualLocations] = useState([]);
    const [selectedIndividualLocation, updateSelectedIndividualLocation] = useState("");
    const [selectedIndividualLocationObj, updateSelectedIndividualLocationObj] = useState({});
    const [serviceDetails, updateServiceDetails] = useState({ ...initialServiceDetails });
    const [serviceDetailsError, updateServiceErrorDetails] = useState({ ...initialServiceError });
    const [selectedServiceTime, updateSelectedServiceTime] = useState(serviceTimeHours[0].value);

    const [organizations, updateOrganizations] = useState([]);
    const [selectedOrganization, updateSelectedOrganization] = useState("");
    const [locationsForOrganization, updateLocationsForOrganization] = useState([]);
    const [selectedLocationForOrganization, updateSelectedLocationForOrganization] = useState("");
    const [selectedLocationForOrganizationObj, updateSelectedLocationForOrganizationObj] = useState({});
    const [orgServiceDetailsError, updateOrgServiceDetailsError] = useState({ ...initialOrgServiceError });
    const [mode, changeMode] = useState("add");
    const [selectedServiceIdToRemove, updateServiceId] = useState("");
    const [disablePage, toggleDisablePage] = useState(false);
    const [selectedLocationFromDate, handleLocationFromDateChange] = useState(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)));
    const [selectedLocationToDate, handleLocationToDateChange] = useState(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(30)).setSeconds(0)));
    const [daysLocationArray, updateDaysLocationArray] = useState([...days]);
    const [locationDetails, modifyLocationDetails] = useState({});
    const [locationDetailsError, updateLocationDetailsError] = useState({ ...initialLocationError });
    const [selectedCoordinates, updateCoordinate] = useState([]);
    const [displayMode, changeDisplayMode] = useState("list");
    const { authData } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user != null,
            authData: auth.user
        }),
        shallowEqual
    );

    const getDuration = (times) => {
        times.sort((a, b) => { return a - b });
        const startTime = timeArr[times[0] - 1];
        const endTime = timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0];
        return `${startTime} - ${endTime}`;
    };

    const formatAMPM = (date) => {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    };

    const getTime = (timeStr) => {
        var time = timeStr.slice(0, timeStr.length - 3)
        var amPm = timeStr.slice(timeStr.length - 2)
        var d = new Date()
        if (amPm === "AM" && time.split(":")[0] === "12") {
            return new Date(new Date(d.setHours(0)).setMinutes(time.split(":")[1]))
        }
        if (amPm === "PM" && time.split(":")[0] !== "12") {
            return new Date(new Date(d.setHours(Number(time.split(":")[0]) + 12)).setMinutes(time.split(":")[1]))
        }
        return new Date(new Date(d.setHours(time.split(":")[0])).setMinutes(time.split(":")[1]))
    };

    const getStartTime = (times) => {
        times.sort((a, b) => { return a - b });
        return getTime(timeArr[times[0] - 1])
    };

    const getEndTime = (times) => {
        times.sort((a, b) => { return a - b });
        return getTime(timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0])
    };

    const getAllServices = async () => {
        toggleDisablePage(true);
        const servicesResult = await getServices();
        toggleDisablePage(false);
        if (servicesResult.data.errorCode) {
            toast.error(API_ERROR[servicesResult.data.errorCode]);
            return;
        }
        console.log("servicesResult ", servicesResult);
        const localServices = [];
        if (servicesResult.data.data && servicesResult.data.data.length) {
            let localSingleService = {};
            servicesResult.data.data.forEach(each => {
                localSingleService = { ...each };
                localSingleService.selected = false;
                serviceTimeHours.forEach(item => {
                    if (item.value === each.sessionTime) {
                        localSingleService.sessionDuration = item.text;
                    }
                });
                localServices.push({ ...localSingleService });
                localSingleService = {};
            });
        }
        if (props.getServiceList) {
            props.getServiceList([...localServices]);
        }
        updateServices([...localServices]);
    };

    const getAllLocations = async () => {
        toggleDisablePage(true);
        const locationsResult = await getLocationList();
        toggleDisablePage(false);
        if (locationsResult.data.errorCode) {
            toast.error(API_ERROR[locationsResult.data.errorCode]);
            return;
        }
        console.log("locationsResult ", locationsResult)
        if (locationsResult.data.data && locationsResult.data.data.length) {
            updateIndividualLocations([...locationsResult.data.data]);
            updateSelectedIndividualLocation(locationsResult.data.data[0]._id);
            updateSelectedIndividualLocationObj({ ...locationsResult.data.data[0] });
        }
    };

    const getAllLocationsForOrganization = async (id, addressId) => {
        const orgAddressesResult = await getLocationsForOrganization(id);
        if (orgAddressesResult.data.errorCode) {
            toast.error(API_ERROR[orgAddressesResult.data.errorCode]);
            return;
        }
        console.log("orgAddressesResult ", orgAddressesResult)
        if (orgAddressesResult.data.data && orgAddressesResult.data.data.length) {
            updateLocationsForOrganization([...orgAddressesResult.data.data]);
            if (!addressId) {
                updateSelectedLocationForOrganization(orgAddressesResult.data.data[0]._id);
                updateSelectedLocationForOrganizationObj({ ...orgAddressesResult.data.data[0] });
            } else {
                orgAddressesResult.data.data.forEach(each => {
                    if (each._id === addressId) {
                        updateSelectedLocationForOrganization(each._id);
                        updateSelectedLocationForOrganizationObj({ ...each });
                    }
                });
            }
        } else {
            updateLocationsForOrganization([]);
            updateSelectedLocationForOrganization("");
            updateSelectedLocationForOrganizationObj({});
        }
    };

    const getAllOrganizations = useCallback(async () => {
        const orgResult = await getOrganizations();
        if (orgResult.data.errorCode) {
            toast.error(API_ERROR[orgResult.data.errorCode]);
            return;
        }
        console.log("orgResult ", orgResult)
        if (orgResult.data.data && orgResult.data.data.length) {
            updateOrganizations([...orgResult.data.data]);
            updateSelectedOrganization(orgResult.data.data[0]._id);
            getAllLocationsForOrganization(orgResult.data.data[0]._id);
        }
    }, []);

    const fetchProfile = async () => {
        if (authData 
            && authData.accountStatus === VendorConfigStatus.approved
            && !authData.emailVerified) {
                const profileResult = await getProfile();
                if (profileResult.data.data && !profileResult.data.data.emailVerified) {
                    toast.error("Please verify your email");
                    props.history.push('/dashboard');
                    return;
                }
        }
        updateDaysArray([...days]);
        getAllServices();
        getAllLocations();
        getAllOrganizations();
    };

    useEffect(() => {
        fetchProfile();
        if (!props.getServiceList && authData.accountStatus !== VendorConfigStatus.approved) {
            props.history.push('/onboarding');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAllOrganizations]);

    const selectIndividualLocation = (e) => {
        individualLocations.forEach(each => {
            if (each._id === e.target.value) {
                updateSelectedIndividualLocation(e.target.value);
                updateSelectedIndividualLocationObj({ ...each });
            }
        });
    };

    const changeServiceDetails = (text, fieldName) => {
        const localService = { ...serviceDetails };
        const localServiceError = { ...serviceDetailsError };
        localService[fieldName] = text;
        localServiceError[fieldName] = "";
        updateServiceDetails({ ...localService });
        updateServiceErrorDetails({ ...localServiceError });
    };

    const validateService = () => {
        let noError = true;
        const localServiceError = { ...serviceDetailsError };
        if (selectedServiceType === "individual") {
            if (!individualLocations.length) {
                // need toastr error
                toast.error("No locations available to select. Please add a location.");
                noError = false;
            }
            if (selectedIndividualLocationObj && selectedIndividualLocationObj.workingTimeSlots && Math.ceil(selectedIndividualLocationObj.workingTimeSlots.length) < Number(selectedServiceTime)) {
                toast.error("Service time cannot be more than service duration");
                noError = false;
            }
        } else {
            let daysError = true;
            const localOrgServiceError = { ...orgServiceDetailsError };
            if (!locationsForOrganization.length) {
                // need toastr error
                toast.error("No locations available to select.");
                noError = false;
            }
            if (selectedLocationForOrganizationObj && selectedLocationForOrganizationObj.workingTimeSlots && Math.ceil(selectedLocationForOrganizationObj.workingTimeSlots.length) < Number(selectedServiceTime)) {
                toast.error("Service time cannot be more than service duration");
                noError = false;
            }

            if (selectedLocationForOrganizationObj && selectedLocationForOrganizationObj.workingTimeSlots) {
                const fromIndex = timeArr.indexOf(formatAMPM(selectedFromDate));
                const toIndex = (timeArr.indexOf(formatAMPM(selectedToDate)));
                const startTimeIndex = selectedLocationForOrganizationObj.workingTimeSlots[0] - 1;
                const endTimeIndex = selectedLocationForOrganizationObj.workingTimeSlots[selectedLocationForOrganizationObj.workingTimeSlots.length - 1];
                console.log("fromIndex ", fromIndex, toIndex, startTimeIndex, endTimeIndex)
                if (fromIndex < startTimeIndex || toIndex > endTimeIndex) {
                    toast.error(`Please choose correct time within ${getDuration(selectedLocationForOrganizationObj.workingTimeSlots)}`);
                    noError = false;
                }
            }
            if (new Date(selectedFromDate) > new Date(selectedToDate)) {
                localOrgServiceError.selectedFromDate = INVALID_FROM_TO_DATE;
                noError = false;
            } else {
                localOrgServiceError.selectedFromDate = "";
            }

            daysArray.forEach(each => {
                if (each.selected) {
                    daysError = false;
                }
            });

            if (daysError) {
                localOrgServiceError.days = CHOOSE_ERROR;
                noError = false;
            } else {
                localOrgServiceError.days = "";
            }
            updateOrgServiceDetailsError({ ...localOrgServiceError });
        }
        for (let item in localServiceError) {
            if (item !== "fees") {
                if (!serviceDetails[item].trim().length && item !== "description") {
                    localServiceError[item] = REQUIRED_ERROR;
                    noError = false;
                } else {
                    localServiceError[item] = "";
                }
            } else {
                if (!serviceDetails[item].toString().trim().length) {
                    localServiceError[item] = REQUIRED_ERROR;
                    noError = false;
                }
            }
        }
        updateServiceErrorDetails({ ...localServiceError });
        return noError;
    };

    const resetServiceForm = () => {
        changeMode("add");
        updateServiceDetails({ ...initialServiceDetails });
        updateServiceErrorDetails({ ...initialServiceError });
        updateSelectedServiceTime(serviceTimeHours[0].value);
        changeServiceMode(1);
        if (selectedServiceType === "individual") {
            updateSelectedIndividualLocation(individualLocations[0]._id);
            updateSelectedIndividualLocationObj({ ...individualLocations[0] });
        } else {
            updateSelectedOrganization(organizations[0]._id);
            getAllLocationsForOrganization(organizations[0]._id);
            const localDays = [...daysArray];
            localDays.forEach(each => {
                each.selected = false;
            });
            updateDaysArray([...localDays]);
            updateOrgServiceDetailsError({ ...initialOrgServiceError });
            handleFromDateChange(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)));
            handleToDateChange(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(30)).setSeconds(0)));
        }
        getAllServices();
        if (window.innerWidth <= 768 || props.getServiceList) {
            changeDisplayMode('list');
        }
    };

    const saveService = async () => {
        if (!validateService()) {
            return;
        }
        console.log("service ", serviceDetails, selectedIndividualLocationObj, selectedServiceTime, selectedLocationForOrganizationObj);
        let requestBody = {};
        let result;
        if (Number(serviceDetails.fees) === 0) {
            toast.error("Fees cannot be zero");
            return;
        }
        if (selectedServiceType === "individual") {
            requestBody = {
                "title": serviceDetails.title,
                "description": serviceDetails.description || " ",
                "address": selectedIndividualLocationObj.address,
                "addressLine1": selectedIndividualLocationObj.addressLine1,
                "addressLine2": selectedIndividualLocationObj.addressLine2,
                "addressLine3": selectedIndividualLocationObj.addressLine3,
                "sessionTime": Number(selectedServiceTime),
                "fees": Number(serviceDetails.fees),
                "ownerShipType": selectedServiceType === "individual" ? 1 : 2,
                "availableSlots": selectedIndividualLocationObj.workingTimeSlots,
                "daysOfWeek": selectedIndividualLocationObj.daysOfWeek,
                "addressId": selectedIndividualLocationObj._id,
                "addressTitle": selectedIndividualLocationObj.title,
                "location": selectedIndividualLocationObj.location,
                "serviceType": selectedServiceMode
            };
        } else {
            const fromIndex = (timeArr.indexOf(formatAMPM(selectedFromDate)) + 1);
            const toIndex = (timeArr.indexOf(formatAMPM(selectedToDate)));
            const localWorkingTimeSlots = [];
            const localDays = [];
            daysArray.forEach(each => {
                if (each.selected) {
                    localDays.push(each.day);
                }
            });
            if (fromIndex === toIndex) {
                localWorkingTimeSlots.push(fromIndex);
            } else {
                for (let i = fromIndex; i <= toIndex; i++) {
                    localWorkingTimeSlots.push(i);
                }
            }
            if (!localWorkingTimeSlots.length) {
                toast.error("Please choose correct start and end time");
                return;
            }
            requestBody = {
                "title": serviceDetails.title,
                "description": serviceDetails.description || " ",
                "address": selectedLocationForOrganizationObj.address,
                "addressLine1": selectedIndividualLocationObj.addressLine1,
                "addressLine2": selectedIndividualLocationObj.addressLine2,
                "addressLine3": selectedIndividualLocationObj.addressLine3,
                "sessionTime": Number(selectedServiceTime),
                "fees": Number(serviceDetails.fees),
                "ownerShipType": selectedServiceType === "individual" ? 1 : 2,
                "availableSlots": localWorkingTimeSlots,
                "daysOfWeek": localDays,
                "addressId": selectedLocationForOrganizationObj._id,
                "addressTitle": selectedLocationForOrganizationObj.title,
                "location": selectedLocationForOrganizationObj.location,
                "organiserRef": selectedOrganization,
                "serviceType": selectedServiceMode
            };
        }
        console.log("requestBody ", requestBody);
        // return;
        toggleDisablePage(true);
        if (mode === "add") {
            result = await addService(requestBody);
        } else {
            requestBody._id = serviceDetails._id;
            result = await updateService(requestBody);
        }
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            toggleDisablePage(false);
            return;
        }
        toast.success(mode === "add" ? API_SUCCESS.ADD_SERVICE : API_SUCCESS.UPDATE_SERVICE);

        resetServiceForm();
    };

    const selectServiceTime = (e) => {
        updateSelectedServiceTime(e.target.value);
    };

    const selectOrganization = (e) => {
        updateSelectedOrganization(e.target.value);
        getAllLocationsForOrganization(e.target.value);
    };

    const selectLocationForOrganization = (e) => {
        const localDays = [...daysArray];
        localDays.forEach(each => {
            each.selected = false;
        });
        updateDaysArray([...localDays]);
        locationsForOrganization.forEach(each => {
            if (each._id === e.target.value) {
                updateSelectedLocationForOrganization(e.target.value);
                updateSelectedLocationForOrganizationObj({ ...each });
            }
        });
    };

    const updateDateError = () => {
        const localOrgServiceError = { ...orgServiceDetailsError };
        localOrgServiceError.selectedFromDate = "";
        updateOrgServiceDetailsError({ ...localOrgServiceError });
    };

    const toggleDaySelection = (index) => {
        const localDays = [...daysArray];
        const localOrgServiceError = { ...orgServiceDetailsError };
        localDays[index].selected = !localDays[index].selected;
        localOrgServiceError.days = "";
        updateDaysArray([...localDays]);
        updateOrgServiceDetailsError({ ...localOrgServiceError });
    };

    const chooseService = (serviceData) => {
        const localServiceDetails = { ...serviceDetails };
        console.log("serviceData ", serviceData);
        const localDays = [...daysArray];
        localServiceDetails.title = serviceData.title;
        localServiceDetails.description = serviceData.description;
        localServiceDetails._id = serviceData._id;
        localServiceDetails.fees = serviceData.fees;
        if (serviceData.serviceType) {
            changeServiceMode(Number(serviceData.serviceType));
        }
        updateSelectedServiceTime(serviceData.sessionTime);
        if (serviceData.ownerShipType === 1) {
            changeServiceType("individual");
            if (individualLocations.length) {
                updateSelectedIndividualLocation(serviceData.addressId);
                individualLocations.forEach(each => {
                    if (each._id === serviceData.addressId) {
                        updateSelectedIndividualLocationObj({ ...each });
                    }
                });
            }
        } else {
            changeServiceType("organization");
            handleFromDateChange(getStartTime(serviceData.availableSlots));
            handleToDateChange(getEndTime(serviceData.availableSlots));

            if (serviceData.daysOfWeek && serviceData.daysOfWeek.length) {
                serviceData.daysOfWeek.forEach((each, index) => {
                    localDays.forEach(each1 => {
                        if (each === each1.day) {
                            each1.selected = true;
                        }
                    });
                });
            }
            updateDaysArray([...localDays]);
            if (organizations.length) {
                updateSelectedOrganization(serviceData.organiserRef);
                getAllLocationsForOrganization(serviceData.organiserRef, serviceData.addressId);
            }
        }
        changeMode("edit");
        if (window.innerWidth <= 768 || props.getServiceList) {
            changeDisplayMode('add');
        }
        updateServiceDetails({ ...localServiceDetails });
    };

    const openRemove = (item, type) => {
        updateRemoveType(type);

        if (type === "Service") {
            updateServiceId(item._id);
            if (services.length === 1) {
                toast.error("Minimum 1 service is mandatory");
                return;
            }
        }
        openRemoveModal(true);
    };

    const removeData = async () => {
        if (removeType === "Service") {
            toggleDisablePage(true);
            const result = await removeService(selectedServiceIdToRemove);
            if (result.data.errorCode) {
                toast.error(API_ERROR[result.data.errorCode]);
                toggleDisablePage(false);
                return;
            }
            toast.success(API_SUCCESS.REMOVE_SERVICE);
            updateServiceId("");
            openRemoveModal(false);
            if (mode === "edit") {
                resetServiceForm();
            } else {
                getAllServices();
            }
        }
    };

    const updateLocationDetails = (text, fieldName) => {
        const localLocation = { ...locationDetails };
        const localLocationError = { ...locationDetailsError };
        localLocation[fieldName] = text;
        localLocationError[fieldName] = "";
        updateLocationDetailsError({ ...localLocationError });
        modifyLocationDetails({ ...localLocation });
    };

    const toggleLocationDaySelection = (index) => {
        const localDays = [...daysLocationArray];
        const localLocationError = { ...locationDetailsError };
        localDays[index].selected = !localDays[index].selected;
        localLocationError.days = "";
        updateDaysLocationArray([...localDays]);
        updateLocationDetailsError({ ...localLocationError });
    };

    const updateLocationDateError = () => {
        const localLocationError = { ...locationDetailsError };
        localLocationError.selectedLocationFromDate = "";
        updateLocationDetailsError({ ...localLocationError });
    };

    const validateLocation = () => {
        const localLocationError = { ...locationDetailsError };
        let daysError = true;
        let noError = true;
        if (!locationDetails.title) {
            localLocationError.title = REQUIRED_ERROR;
            noError = false;
        } else {
            localLocationError.title = "";
        }

        if (!locationDetails.address) {
            localLocationError.address = REQUIRED_ERROR;
            noError = false;
        } else {
            localLocationError.address = "";
        }

        if (new Date(selectedLocationFromDate) > new Date(selectedLocationToDate)) {
            localLocationError.selectedLocationFromDate = INVALID_FROM_TO_DATE;
            noError = false;
        } else {
            localLocationError.selectedLocationFromDate = "";
        }

        daysLocationArray.forEach(each => {
            if (each.selected) {
                daysError = false;
            }
        });

        if (daysError) {
            localLocationError.days = CHOOSE_ERROR;
            noError = false;
        } else {
            localLocationError.days = "";
        }

        if (!selectedCoordinates.length) {
            localLocationError.address = INVALID_LOCATION;
            noError = false;
        } else {
            localLocationError.address = "";
        }

        updateLocationDetailsError({ ...localLocationError });
        return noError;
    };

    const resetLocationForm = () => {
        const localDays = [...days];
        localDays.forEach(each => {
            each.selected = false;
        });
        updateDaysLocationArray([...localDays]);
        updateLocationDetailsError({ ...initialLocationError });
        getAllLocations();
        modifyLocationDetails({
            title: "",
            address: "",
            addressLine1: "",
            addressLine2: "",
            addressLine3: ""
        });
        updateCoordinate([]);
        handleLocationFromDateChange(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)));
        handleLocationToDateChange(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(30)).setSeconds(0)));
    };

    const saveLocation = async () => {
        if (!validateLocation()) {
            return;
        }
        let result;
        const fromIndex = (timeArr.indexOf(formatAMPM(selectedLocationFromDate)) + 1);
        const toIndex = (timeArr.indexOf(formatAMPM(selectedLocationToDate)));
        const localWorkingTimeSlots = [];
        const localDays = [];
        daysLocationArray.forEach(each => {
            if (each.selected) {
                localDays.push(each.day);
            }
        });
        if (fromIndex === toIndex) {
            localWorkingTimeSlots.push(fromIndex);
        } else {
            for (let i = fromIndex; i <= toIndex; i++) {
                localWorkingTimeSlots.push(i);
            }
        }
        if (!localWorkingTimeSlots.length) {
            toast.error("Please choose correct start and end time");
            return;
        }
        const requestBody = {
            title: locationDetails.title,
            address: locationDetails.address,
            addressLine1: locationDetails.addressLine1,
            addressLine2: locationDetails.addressLine2,
            addressLine3: locationDetails.addressLine3,
            workingTimeSlots: localWorkingTimeSlots,
            "location": {
                "coordinates": selectedCoordinates
            },
            daysOfWeek: localDays
        }
        toggleDisablePage(true);
        result = await addLocation(requestBody);
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            toggleDisablePage(false);
            return;
        }
        toast.success(API_SUCCESS.ADD_LOCATION);
        resetLocationForm();
        openCreateLocationModal(false);
    };

    const initAutocomplete = () => {
        updateCoordinate([]);
        if (window.google) {
            const map = new window.google.maps.Map(document.getElementById("map"), {
                center: { lat: -33.8688, lng: 151.2195 },
                zoom: 13,
                mapTypeId: "roadmap",
            });
            // Create the search box and link it to the UI element.
            const input = document.getElementById("pac-input");
            const searchBox = new window.google.maps.places.SearchBox(input);
            map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);
            // Bias the SearchBox results towards current map's viewport.
            map.addListener("bounds_changed", () => {
                searchBox.setBounds(map.getBounds());
            });
            // Listen for the event fired when the user selects a prediction and retrieve
            // more details for that place.
            searchBox.addListener("places_changed", () => {
                const places = searchBox.getPlaces();

                if (places.length === 0) {
                    return;
                }

                // For each place, get the icon, name and location.
                const bounds = new window.google.maps.LatLngBounds();
                places.forEach((place) => {
                    if (!place.geometry || !place.geometry.location) {
                        console.log("Returned place contains no geometry");
                        return;
                    }

                    if (place.geometry.viewport) {
                        // Only geocodes have viewport.
                        bounds.union(place.geometry.viewport);
                    } else {
                        bounds.extend(place.geometry.location);
                    }
                    updateCoordinate([place.geometry.location.lng(), place.geometry.location.lat()]);
                    updateLocationDetails(place.formatted_address, 'address');
                    // console.log("places ",place)
                });
                map.fitBounds(bounds);
            });
        }

    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={`row problem-area ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
                <RemoveModal
                    displayRemoveModal={displayRemoveModal}
                    openRemoveModal={openRemoveModal}
                    removeType={removeType}
                    removeData={removeData} />
                <div className={`${!props.getServiceList ? 'col-md-7 col-xs-12' : 'col-md-7 m-0-auto'} p-0`}>
                    <div className="row m-0">
                        {/* {props.getServiceList && props.selectedProfile.status === props.VendorConfigStatus.active ?
                            <div className='col-12 text-right mb-2'>
                                <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => props.nextStep()}>Next</button>
                            </div>
                            : null} */}
                        {displayMode === 'list' ? <>
                            <div className="col-6 categories-heading">
                                <h4 className={`mb-0`}>
                                    {props.getServiceList ?
                                    <i className="fas fa-arrow-left mr-2" style={{cursor: "pointer"}} onClick={() => { props.selectedProfile.status === props.VendorConfigStatus.approvalPending ||
                                        props.selectedProfile.status === props.VendorConfigStatus.approvalRejected ? props.nextStep() : props.backStep(true)}}></i> : null}Services <i className="fas fa-info-circle ml-3" aria-hidden="true" style={{ fontSize: "18px", cursor: "pointer" }} /></h4>
                            </div>
                            <div className={`col-6 ${props.getServiceList ? '' : 'less-than-768 mt-3'} text-right`}>
                                <button type="button" className="btn btn-outline-primary btn-sm mb-3" onClick={() => changeDisplayMode('add')}>Add Service</button>
                            </div>
                        </> : null}
                        <div className={`col-12 ${displayMode === 'add' ? 'd-none' : ''}`}>
                            <div className="row" style={{ margin: 0 }}>
                                {services.length ?
                                    services.map((professional, index) => {
                                        return <div className={`col-sm-6 col-xs-12 professional-wrapper
                                ${index % 2 === 0 ? 'problem-more-768-pr-15-for-2' : ''}
                                ${index !== services.length - 1 && index !== services.length - 2 && index !== services.length - 3 ? 'problem-more-768-pb-15' : ''}
                                ${index !== services.length - 1 && index !== services.length - 2 ? 'problem-more-768-pb-15-for-2' : ''}
                                ${index !== services.length - 1 ? 'problem-more-768-pb-15-for-1' : ''}
                                `} key={index} onClick={() => chooseService(professional)}>
                                            <div className="row service-row" style={{ margin: 0 }}>
                                                <div className="col-12 p-0">
                                                    <div className="row" style={{ margin: 0 }}>
                                                        <div className="professional-name col-12 p-0">{professional.title}
                                                        </div>
                                                        {professional.description && professional.description.trim().length ?
                                                        <div className="professional-location service-name col-12 p-0">{professional.description}</div> : null}
                                                        <div className="col-5 pl-0">
                                                            <div className="service-cost"><i className="fas fa-rupee-sign"></i>{professional.fees}</div>
                                                        </div>
                                                        <div className="col-7 pr-0 text-right">
                                                            <div className="service-hour">{professional.sessionDuration} session</div>
                                                        </div>
                                                        <div className="col-12 p-0 service-cost">{professional.serviceType ? serviceTypeObj[professional.serviceType] : serviceTypeObj[1]}</div>
                                                    </div>
                                                </div>
                                                <img src={require("../../../images/remove.svg")} alt="remove service" onClick={(e) => { e.stopPropagation(); openRemove(professional, "Service") }} />
                                            </div>
                                        </div>
                                    })
                                    : <div className="text-center well col-12 color-black">
                                        No saved service
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${!props.getServiceList ? 'col-md-5 col-xs-12' : 'col-md-7 m-0-auto'} p-0 ${!props.getServiceList ? displayMode === 'list' ? 'more-than-768' : 'less-than-768' : displayMode === "list" ? 'd-none' : ''}`}>
                    <div className="col-12 categories-heading">
                        <h4 className={`mb-0`}>Add/ Edit Service</h4>
                    </div>
                    <div className="col-12">
                        <div className="row professional-near-you-wrapper" style={{ margin: 0 }}>
                            <div className="col-12 p-0 mb-3 add-edit-service-button-wrapper">
                                <button type="button" className="btn btn-primary btn-sm" onClick={saveService}>Save</button>
                                <button type="button" className="btn btn-outline-primary btn-sm" onClick={resetServiceForm}>Reset</button>
                            </div>
                            <div className="col-12 p-0 mb-3">
                                <label for="exampleFormControlInput1" className="form-label">Service Name</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text"><i className="fa fa-user" aria-hidden="true" /></div>
                                    </div>
                                    <input type="text" className={`form-control ${serviceDetailsError.title ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter Service Name" value={serviceDetails.title} onChange={e => changeServiceDetails(e.target.value, "title")} />
                                </div>
                                {serviceDetailsError.title ?
                                    <div className="input-error">{serviceDetailsError.title}</div> : null}
                            </div>
                            <div className="col-6 pl-0 mb-3">
                                <label for="exampleFormControlInput1" className="form-label">Service Fee</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text"><i className="fas fa-rupee-sign" /></div>
                                    </div>
                                <input type="text" className={`form-control ${serviceDetailsError.fees ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Service Fee (in Rs)" value={serviceDetails.fees} onChange={e => changeServiceDetails(e.target.value.replace(/\D/, ''), "fees")} />
                                </div>
                                {serviceDetailsError.fees ?
                                    <div className="input-error">{serviceDetailsError.fees}</div> : null}
                            </div>
                            <div className="col-6 pr-0 mb-3">
                                <label for="exampleFormControlInput1" className="form-label">Service Time</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text"><i className="far fa-hourglass" /></div>
                                    </div>
                                <select className="form-select form-control" aria-label="Service Time" value={selectedServiceTime} onChange={selectServiceTime}>
                                    {serviceTimeHours.map((loc, index) => {
                                        return <option key={index} value={loc.value}>{loc.text}</option>
                                    })}
                                </select>
                                </div>
                            </div>
                            <div className="col-12 appointment-type-wrapper mb-3">
                                <label for="exampleFormControlTextarea1" className="form-label">Appointment Type</label><br />
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="serviceMode" id="onlineServiceMode" checked={selectedServiceMode === 1} onChange={() => changeServiceMode(1)} />
                                    <label className="form-check-label" htmlFor="onlineServiceMode">
                                        Online Only
                                    </label>
                                </div>
                                <br />
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="serviceMode" id="offlineServiceMode" checked={selectedServiceMode === 2} onChange={() => changeServiceMode(2)} />
                                    <label className="form-check-label" htmlFor="offlineServiceMode">
                                        Offline Only
                                    </label>
                                </div>
                                <br />
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="serviceMode" id="bothServiceMode" checked={selectedServiceMode === 3} onChange={() => changeServiceMode(3)} />
                                    <label className="form-check-label" htmlFor="bothServiceMode">
                                        Both Online and Offline
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 p-0 mb-3">
                                <label for="exampleFormControlTextarea1" className="form-label">Description</label>
                                <textarea className={`form-control ${serviceDetailsError.description ? 'error-border' : ''}`} id="exampleFormControlTextarea1" rows="3" placeholder='Short description about the service...' value={serviceDetails.description} onChange={e => changeServiceDetails(e.target.value, "description")}></textarea>
                                {serviceDetailsError.description ?
                                    <div className="input-error">{serviceDetailsError.description}</div> : null}
                            </div>
                            {selectedServiceType === "organization" && organizations.length ?
                                <>
                                    <div className="col-12 p-0 mb-3">
                                        <label for="exampleFormControlInput1" className="form-label">Organization</label>
                                        <select className="form-select form-control" aria-label="Organization" value={selectedOrganization} onChange={selectOrganization}>
                                            {organizations.map((loc, index) => {
                                                return <option key={index} value={loc._id}>{loc.personalInfo.name}</option>
                                            })}
                                        </select>
                                    </div>
                                    {locationsForOrganization && locationsForOrganization.length ?
                                        <div className="col-12 p-0 mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">Service Location</label>
                                            <select className="form-select form-control" aria-label="Service Location" value={selectedLocationForOrganization} onChange={selectLocationForOrganization}>
                                                {locationsForOrganization.map((loc, index) => {
                                                    return <option key={index} value={loc._id}>{loc.title}</option>
                                                })}
                                            </select>
                                            {selectedLocationForOrganizationObj && selectedLocationForOrganizationObj.workingTimeSlots ?
                                                <div>
                                                    Service Duration: {getDuration(selectedLocationForOrganizationObj.workingTimeSlots)}
                                                </div> : null}
                                        </div> : null}
                                    <div className="col-6 pl-0 mb-3 custom-time-picker-design">
                                        <label for="exampleFormControlInput1" className="form-label">Time From</label><br />
                                        <TimePicker value={selectedFromDate} className={`${orgServiceDetailsError.selectedFromDate ? 'timepicker-error-border' : ''}`} onClose={updateDateError} onChange={handleFromDateChange} minutesStep={30} />
                                        {orgServiceDetailsError.selectedFromDate ?
                                            <div className="input-error">{orgServiceDetailsError.selectedFromDate}</div> : null}
                                    </div>
                                    <div className="col-6 pr-0 mb-3 custom-time-picker-design">
                                        <label for="exampleFormControlInput1" className="form-label">Time To</label><br />
                                        <TimePicker value={selectedToDate} className={`${orgServiceDetailsError.selectedFromDate ? 'timepicker-error-border' : ''}`} onClose={updateDateError} onChange={handleToDateChange} minutesStep={30} />
                                    </div>
                                    {selectedLocationForOrganizationObj && selectedLocationForOrganizationObj.daysOfWeek ?
                                        <div className="col-12 p-0 mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">Choose Day(s)</label>
                                            <div className="row" style={{ margin: 0 }}>
                                                {daysArray.length ? daysArray.map((dayValue, index) => {
                                                    return <div className="col-6" key={index}>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={dayValue.selected} onChange={() => toggleDaySelection(index)} disabled={!selectedLocationForOrganizationObj.daysOfWeek.includes(index + 1)} />
                                                            <label className="form-check-label" for="flexCheckDefault">
                                                                {dayValue.text}
                                                            </label>
                                                        </div>
                                                    </div>
                                                }) : null}
                                            </div>
                                            {orgServiceDetailsError.days ?
                                                <div className="input-error">{orgServiceDetailsError.days}</div> : null}
                                        </div> : null}
                                </> :
                                <>

                                    <div className="col-12 p-0 mb-3">
                                        {authData && authData.subType === 2 ?
                                            <>

                                                <label for="exampleFormControlInput1" className="form-label">Service Location</label>

                                                {individualLocations && individualLocations.length ? <>
                                                    <select className="form-select form-control" aria-label="Service Location" value={selectedIndividualLocation} onChange={selectIndividualLocation}>
                                                        {individualLocations.map((loc, index) => {
                                                            return <option key={index} value={loc._id}>{loc.title}</option>
                                                        })}
                                                    </select>
                                                    {selectedIndividualLocationObj && selectedIndividualLocationObj.workingTimeSlots ?
                                                        <div>
                                                            Service Duration: {getDuration(selectedIndividualLocationObj.workingTimeSlots)}
                                                        </div> : null}</>
                                                    : null}
                                            </> : null}
                                        {/* {authData && (authData.subType === 2 || (authData.subType === 1 && individualLocations.length < 1)) ?
                                            <span className="span-link" onClick={() => openCreateLocationModal(true)}>Add New Service Location</span> : null} */}
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className={`modal login-modal ask-question-modal fade ${displayLocationModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add Location</h5>
                            </div>
                            <div className="modal-body">
                                <div className="row" style={{ margin: 0 }}>
                                    <div className="col-12 p-0">
                                        <div className="row" style={{ margin: 0 }}>
                                            <div className="col-12 p-0 mb-3">
                                                <label for="exampleFormControlInput1" className="form-label">City, State</label>
                                                <input type="text" className={`form-control ${locationDetailsError.title ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter City, State" value={locationDetails.title} onChange={e => updateLocationDetails(e.target.value, "title")} />
                                                {locationDetailsError.title ?
                                                    <div className="input-error">{locationDetailsError.title}</div> : null}
                                            </div>
                                            <div className="col-12 p-0 mb-3">
                                                <label for="exampleFormControlInput1" className="form-label">Pin location</label>
                                                <input type="text" className={`form-control ${locationDetailsError.address ? 'error-border' : ''}`} id="pac-input" placeholder="Pin location" value={locationDetails.address} onChange={e => { initAutocomplete(); updateLocationDetails(e.target.value, "address") }} />
                                                {locationDetailsError.address ?
                                                    <div className="input-error">{locationDetailsError.address}</div> : null}
                                            </div>
                                            <div className="col-md-6 col-xs-12 pl-0 mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">House/Flat/Block No.</label>
                                                <input type="text" className={`form-control ${locationDetailsError.addressLine1 ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter House/Flat/Block No." value={locationDetails.addressLine1} onChange={e => updateLocationDetails(e.target.value, "addressLine1")} />
                                                {locationDetailsError.addressLine1 ?
                                                    <div className="input-error">{locationDetailsError.addressLine1}</div> : null}
                                            </div>
                                            <div className="col-md-6 col-xs-12 pr-0 mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Apartment/Road/Area</label>
                                                <input type="text" className={`form-control ${locationDetailsError.addressLine2 ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter Apartment/Road/Area" value={locationDetails.addressLine2} onChange={e => updateLocationDetails(e.target.value, "addressLine2")} />
                                                {locationDetailsError.addressLine2 ?
                                                    <div className="input-error">{locationDetailsError.addressLine2}</div> : null}
                                            </div>
                                            <div className="col-6 pl-0 mb-3 custom-time-picker-design">
                                                <label for="exampleFormControlInput1" className="form-label">Start time</label><br />
                                                <TimePicker value={selectedLocationFromDate} className={`${locationDetailsError.selectedLocationFromDate ? 'timepicker-error-border' : ''}`} onChange={handleLocationFromDateChange} minutesStep={30} onClose={updateLocationDateError} />
                                                {locationDetailsError.selectedLocationFromDate ?
                                                    <div className="input-error">{locationDetailsError.selectedLocationFromDate}</div> : null}
                                            </div>
                                            <div className="col-6 pr-0 mb-3 custom-time-picker-design">
                                                <label for="exampleFormControlInput1" className="form-label">End time</label><br />
                                                <TimePicker value={selectedLocationToDate} className={`${locationDetailsError.selectedLocationFromDate ? 'timepicker-error-border' : ''}`} onChange={handleLocationToDateChange} onClose={updateLocationDateError} minutesStep={30} />
                                            </div>
                                            <div className="col-12 p-0 mb-3">
                                                <label for="exampleFormControlInput1" className="form-label">Choose Day(s)</label>
                                                <div className="row" style={{ margin: 0 }}>
                                                    {daysLocationArray.length ? daysLocationArray.map((dayValue, index) => {
                                                        return <div className="col-md-3 col-6" key={index} onChange={() => toggleLocationDaySelection(index)}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={dayValue.selected} />
                                                                <label className="form-check-label" for="flexCheckDefault">
                                                                    {dayValue.text}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    }) : null}
                                                </div>
                                                {locationDetailsError.days ?
                                                    <div className="input-error">{locationDetailsError.days}</div> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 login-button">
                                    <button type="button" className="btn btn-primary" onClick={saveLocation}>Submit</button>
                                    <button type="button" className="btn btn-link" onClick={() => { resetLocationForm(); openCreateLocationModal(false) }}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MuiPickersUtilsProvider>
    )
}

export default Service;