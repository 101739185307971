import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import "./forum-new.css";
import { toast } from 'react-toastify';
import {
    API_ERROR
} from "../../config/error-message";
import { getForumList } from "../../crud/qa-forum-crud";

const defaultImage = require('../../../images/imgAvtr.png');

const Forum = (props) => {
    const [forumList, updateForumList] = useState([]);
    const [disablePage, toggleDisablePage] = useState(false);
    const [loadingQuestions, toggleLoadingQuestions] = useState(true);
    const { isAuthorized } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user != null,
            authData: auth.user
        }),
        shallowEqual
    );

    const getAllForumList = async () => {
        toggleDisablePage(true);
        const forumResults = await getForumList();
        toggleDisablePage(false);
        toggleLoadingQuestions(false);
        if (forumResults.data.errorCode) {
            toast.error(API_ERROR[forumResults.data.errorCode]);
            return;
        }
        if (forumResults.data.data && forumResults.data.data.errorCode) {
            toast.error(API_ERROR[forumResults.data.data.errorCode]);
            return;
        }
        console.log("forumResults ", forumResults)
        if (forumResults.data.data && forumResults.data.data.data && forumResults.data.data.data.length) {
            let forumObj = {};
            const forumArr = [];
            forumResults.data.data.data.forEach(each => {
                forumObj = {};
                forumObj._id = each._id;
                forumObj.question = each.question;
                forumObj.selected = false;
                forumObj.noOfComments = each.answers.length;
                forumObj.answers = [];
                forumArr.push({ ...forumObj });
            });
            updateForumList([...forumArr]);
        }
    };

    const chooseAnswer = (item) => {
        props.history.push(`/forum-details/${item._id}`);
    };

    useEffect(() => {
        getAllForumList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openMyQa = () => {
        if (isAuthorized) {
            props.history.push("/myQA");
        } else {
            toast.info("Please login to ask a question");
        }
    };

    return (
        <div className={`row forum-new ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
            <div className="col-lg-12 p-0">
                <div className="row" style={{ margin: 0 }}>
                    <div className={`col-6 categories-heading`}>
                        <h4 className={`mb-0`}>Forum</h4>
                    </div>
                    <div className={`col-6 text-right language-dropdown-wrapper`}>
                        <button type="button" className="btn btn-outline-primary" onClick={openMyQa}>My Answers</button>
                    </div>
                </div>

                <div className="col-12">
                    <div className="row" style={{ margin: 0 }}>
                        {forumList.length ?
                            forumList.map((professional, index) => {
                                return <div className={`col-12 professional-wrapper ${professional.selected ? 'professional-wrapper-selected' : ''}`} key={index}>
                                    <div className="row professional-wrapper-row p-0" style={{ margin: 0 }}>
                                        <div className="col-12 p-0">
                                            <div className={`professional-designation ${!professional.selected ? 'professional-designation-not-selected' : 'professional-designation-selected'}`} onClick={() => chooseAnswer({ ...professional })}>
                                            <div style={{ width: "calc(100% - 70px)", display: "inline-block" }}>
                                                {professional.selected ?
                                                    professional.askedByProfilePhoto ?
                                                        <div style={{ backgroundImage: "url(" + professional.askedByProfilePhoto + ")" }} className="background-image-professional mb-0" /> :
                                                        <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional mb-0" /> : null}
                                                {!professional.selected ? <>
                                                    {professional.question} <div className="no-of-comments" style={{ display: "inline-block" }}> ({professional.noOfComments} <i className="fas fa-comment"></i>) </div> </>
                                                    : <div className="question-asked-details">
                                                        <div className="question-commentedby">{professional.askedByName}</div>
                                                        {professional.question} <div className="no-of-comments" style={{ display: "inline-block" }}> ({professional.noOfComments} <i className="fas fa-comment"></i>) </div>
                                                    </div>}
                                                </div>
                                                {/* <img src={require('../../../images/favourite2_Fill.svg')} alt="make favorite" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                            : <div className="text-center well col-12 color-black">
                                {!loadingQuestions ? 'No Questions Available!' : 'loading...'}
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Forum;