import React, { useState } from 'react';
import "../admins/forum.css";

const OtpModal = (props) => {
    const [otpArray, updateOtpArray] = useState(['', '', '', '']);

    const modifyOtp = (otpText, index) => {
        const localOtpArray = [...otpArray];
        const regex = /^-?\d+\.?\d*$/;
        localOtpArray[index] = otpText;
        if (regex.test(otpText) && document.getElementById(`otp${index + 1}`)) {
            document.getElementById(`otp${index + 1}`).focus();
        }
        updateOtpArray([...localOtpArray]);
    };

    const resendOtp = () => {
        props.resend(true);
        updateOtpArray(['', '', '', '']);
    };

    return (
        <div className={`row forum`} style={{ margin: 0 }}>
            <div className={`modal ask-question-modal fade ${props.displayModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Verify Email</h5>
                        </div>
                        <div className="modal-body">
                            <div className="col-12 pt-3 pb-3">
                                <div>Please submit the 4 digit OTP sent to your email for verification</div>
                                <div className="col-12 login-phone-input pl-0 pr-0">

                                    <div className="row form-group login-otp-input-wrapper" style={{ margin: 0 }}>
                                        {otpArray.map((item, index) => {
                                            return <div className="col-3 pl-0" key={index}>
                                                <input type="text" className="form-control" id={`otp${index}`} value={item} onChange={e => modifyOtp(e.target.value.replace(/\D/, ''), index)} maxLength={1} />
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 pt-3 pb-3 text-right">
                                <button type="button" className="btn btn-primary" onClick={() => props.verifyOtp(otpArray.join(''))} disabled={!otpArray[0].length || !otpArray[1].length || !otpArray[2].length || !otpArray[3].length}>Confirm OTP</button>
                                <button type="button" className="btn btn-link cancel-otp-page" onClick={() => props.closeModal(false)}>Back</button>
                                <button type="button" className="btn btn-link float-right" onClick={resendOtp}>Resend OTP</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default OtpModal;