import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import { SessionBookingNumberStatus, BookingNumberStatus } from '../../config/constants';
import "./forum.css";
const defaultImage = require('../../../images/imgAvtr.png');
const RevenueModal = (props) => {
    const [vendorDetails, updateVendorDetails] = useState({});
    const { authData } = useSelector(
        ({ auth }) => ({
            authData: auth.user
        }),
        shallowEqual
    );

    const closeModal = () => {
        props.closeModal(false);
    };

    useEffect(() => {
        const localProfessionalObj = {};
        if (authData.personalInfo) {
            localProfessionalObj.profilePicture = authData.personalInfo.profilePicture;
        }
        if (authData.bankingDetails) {
            localProfessionalObj.bankDetails = authData.bankingDetails;
        }
        updateVendorDetails({ ...localProfessionalObj });
    }, [authData]);

    return (
        <div className={`row forum test-modal`} style={{ margin: 0 }}>
            <div className={`modal ask-question-modal fade ${props.displayModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="col-12 pt-3 pb-3">
                                <div className='row m-0 '>
                                    <div className="col-12 category-arrows">
                                        <i className="fas fa-times close-modal pull-right mt-0" onClick={() => closeModal()}></i>
                                        <h5 className='pre-confirmation-header'>Payment Details</h5>

                                    </div>
                                    <div className='col-12 p-0 scrollable-modal'>
                                        <div className='row m-0'>

                                            {props.selectedInvoice.revenueType === "appointment" ?
                                                <>
                                                    <div className='col-12 mt-4 mb-4'>
                                                        <h6>Appointment</h6>
                                                        <div className='customer-vendor-wrapper'>
                                                            <div className="profile-image-wrapper p-0">
                                                                {props.selectedInvoice.customer.personalInfo.profilePicture ?
                                                                    <div style={{ backgroundImage: "url(" + props.selectedInvoice.customer.personalInfo.profilePicture + ")" }} className="profile-image" /> :
                                                                    <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="profile-image" />}
                                                            </div>
                                                            <div className="profile-other-details profile-name-wrapper">
                                                                <div className="profile-name"><b>Customer Name:</b> {props.selectedInvoice.bookingForName}</div>
                                                                <div className="profile-name"><b>Service Name:</b> {props.selectedInvoice.serviceName}</div>
                                                                <div className='profile-name'><b>Type:</b> {props.selectedInvoice.appointmentBookingType === 2 ? "Offline " : "Online "}Appointment</div>
                                                                <div className='profile-name'><b>Date:</b> {props.selectedInvoice.displayDate}</div>
                                                                <div className='profile-name'><b>Time:</b> {props.selectedInvoice.startTime} - {props.selectedInvoice.endTime}</div>
                                                                <div className="profile-name" style={{ color: `${props.selectedInvoice.bookingStatus === 3 ? "#08b12a" : "#fd924b"}` }}><b>Status:</b> {BookingNumberStatus[props.selectedInvoice.bookingStatus]}</div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    {props.selectedInvoice.addressLine1 || props.selectedInvoice.address ?
                                                    <div className='col-12 mb-3'>
                                                        <h6>Location</h6>
                                                        <div className='customer-vendor-wrapper'>
                                                            {props.selectedInvoice.addressLine1 ? props.selectedInvoice.addressLine2 ? `${props.selectedInvoice.addressLine1}, ${props.selectedInvoice.addressLine2}` : props.selectedInvoice.addressLine1 : props.selectedInvoice.address}
                                                        </div>
                                                    </div> : null} </> :
                                                <>
                                                    <div className='col-12 mt-3'>
                                                        <h6>{props.selectedInvoice.revenueType === "event" ? "Event" : "Workshop"} Details</h6>
                                                        <div className='customer-vendor-wrapper'>
                                                            <div className="profile-name"><b>{props.selectedInvoice.revenueType === "event" ? "Event" : "Workshop"} Name:</b> {props.selectedInvoice.eventName}</div>
                                                            <div className="profile-name"><b>Date{props.selectedInvoice.revenueType === "event" ? "" : "s"}:</b> {props.selectedInvoice.revenueType === "event" ? props.selectedInvoice.eventDate : `${props.selectedInvoice.eventStartDate} ${props.selectedInvoice.eventEndDate ? props.selectedInvoice.eventEndDate : ''}`}</div>
                                                            <div className="profile-name"><b>Time:</b> {props.selectedInvoice.startTime} - {props.selectedInvoice.endTime}</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 mt-4 mb-4'>
                                                        <h6>Customers</h6>
                                                        {props.selectedInvoice.bookings && props.selectedInvoice.bookings.length ?
                                                            <>
                                                                {props.selectedInvoice.bookings.map((eachService, index) => {
                                                                    return <div key={index} className="row mr-0 ml-0 mb-2 customer-vendor-wrapper">
                                                                        <div className="profile-name col-12 pl-0 pr-0">{eachService.bookingForName}</div>
                                                                        <div className={`profile-name col-8 pl-0`} style={{ color: `${eachService.bookingStatus === 3 ? "#08b12a" : "#fd924b"}` }}>
                                                                            {SessionBookingNumberStatus[eachService.bookingStatus]}
                                                                        </div>
                                                                        <div className="profile-name col-4 text-right pr-0">
                                                                            <i className="fas fa-rupee-sign"></i>{eachService.amount.toFixed(2)}
                                                                        </div>
                                                                    </div>
                                                                })}
                                                            </>
                                                            : null}
                                                    </div> </>
                                            }
                                            {props.selectedInvoice.revenueType === "appointment" ?
                                                <div className='col-12'>
                                                    <h6>Payment Breakdown</h6>
                                                    <div className='professional-near-you-wrapper payment-each-wrapper row m-0'>
                                                        <div className='profile-name col-8 pl-0'>Service fee</div>
                                                        <div className='profile-name col-4 pr-0 text-right'><i className="fas fa-rupee-sign"></i>{props.selectedInvoice?.serviceFee?.toFixed(2)}</div>
                                                        <div className='profile-name col-8 pl-0'>Convenience fee ({props.selectedInvoice?.vendorCutPercentage}%)</div>
                                                        <div className='profile-name col-4 pr-0 text-right'><i className="fas fa-rupee-sign"></i>{props.selectedInvoice?.vendorCut?.toFixed(2)}</div>
                                                        <div className='profile-name col-8 pl-0'>GST ({props.selectedInvoice.gstPercentage}%)</div>
                                                        <div className='profile-name col-4 pr-0 text-right'><i className="fas fa-rupee-sign"></i>{props.selectedInvoice?.gst?.toFixed(2)}</div>
                                                        <hr style={{
                                                            borderTop: "1px solid #ccc",
                                                            width: "100%",
                                                            margin: "5px 0"
                                                        }} />
                                                        <div className='profile-name col-8 pl-0' style={{ color: `${props.selectedInvoice.wholeStatus === "PAID" ? "#08b12a" : "#fd924b"}` }}>Net Payable</div>
                                                        <div className='profile-name col-4 pr-0 text-right' style={{ color: `${props.selectedInvoice.wholeStatus === "PAID" ? "#08b12a" : "#fd924b"}` }}><i className="fas fa-rupee-sign"></i>{props.selectedInvoice.amountAfterVendorCut.toFixed(2)}</div>
                                                    </div>
                                                </div> :
                                                <div className='col-12'>
                                                    <h6>Payment Breakdown</h6>
                                                    <div className='professional-near-you-wrapper payment-each-wrapper row m-0'>
                                                        <div className='profile-name col-8 pl-0'>Service fee</div>
                                                        <div className='profile-name col-4 pr-0 text-right'><i className="fas fa-rupee-sign"></i>{props.selectedInvoice?.amountAfterVendorCut?.toFixed(2)}</div>
                                                        <div className='profile-name col-8 pl-0'>Commission ({props.selectedInvoice?.commissionPercentage || 0}%)</div>
                                                        <div className='profile-name col-4 pr-0 text-right'><i className="fas fa-rupee-sign"></i>{(props.selectedInvoice?.commission || 0)?.toFixed(2)}</div>
                                                        <div className='profile-name col-8 pl-0'>GST ({props.selectedInvoice.gstPercentage}%)</div>
                                                        <div className='profile-name col-4 pr-0 text-right'><i className="fas fa-rupee-sign"></i>{props.selectedInvoice?.gst?.toFixed(2)}</div>
                                                        
                                                        <div className='profile-name col-8 pl-0'>Other deduction</div>
                                                        <div className='profile-name col-4 pr-0 text-right'><i className="fas fa-rupee-sign"></i>{props.selectedInvoice?.otherPayment ? props.selectedInvoice?.otherPayment?.toFixed(2) : "0.00"}</div>
                                                        <hr style={{
                                                            borderTop: "1px solid #ccc",
                                                            width: "100%",
                                                            margin: "5px 0"
                                                        }} />
                                                        <div className='profile-name col-8 pl-0' style={{ color: `${props.selectedInvoice.wholeStatus === "PAID" ? "#08b12a" : "#fd924b"}` }}>Net Payable</div>
                                                        <div className='profile-name col-4 pr-0 text-right' style={{ color: `${props.selectedInvoice.wholeStatus === "PAID" ? "#08b12a" : "#fd924b"}` }}><i className="fas fa-rupee-sign"></i>{props.selectedInvoice.totalPayable.toFixed(2)}</div>
                                                    </div>
                                                </div>
                                            }
                                            <div className='col-12 mt-4 mb-4'>
                                                <div className='professional-near-you-wrapper payment-each-wrapper row m-0'>
                                                    {props.selectedInvoice.wholeStatus === "PAID" ?
                                                        <div className='profile-name col-12 p-0' style={{ color: "#08b12a", wordBreak: "break-word" }}>
                                                            The amount of <i className="fas fa-rupee-sign"></i>{props.selectedInvoice.revenueType === "appointment" ? props.selectedInvoice.amountAfterVendorCut.toFixed(2) : props.selectedInvoice.totalPayable.toFixed(2)} was transferred {props.selectedInvoice.paymentMode ? props.selectedInvoice.paymentMode !== 5 ? `to your bank account ending with xx${props.selectedInvoice?.bankDetails?.accountNumber?.slice(props.selectedInvoice?.bankDetails?.accountNumber?.length - 2)}` : 'via cheque' : 
                                                            !props.selectedInvoice?.bankDetails?.isCheque ? `to your bank account ending with xx${props.selectedInvoice?.bankDetails?.accountNumber?.slice(props.selectedInvoice?.bankDetails?.accountNumber?.length - 2)}` : 'via cheque'} on {props.selectedInvoice.paymentDate}{props.selectedInvoice.referenceNumber ? ` with ${props.selectedInvoice.paymentMode === 5 ? 'cheque ': 'transaction reference '}number ${props.selectedInvoice.referenceNumber}` : ''}. </div> :
                                                        <div className='profile-name col-12 p-0' style={{ color: "#fd924b", wordBreak: "break-word" }}>
                                                            On the payroll date, the amount of <i className="fas fa-rupee-sign"></i>{props.selectedInvoice.revenueType === "appointment" ? props.selectedInvoice.amountAfterVendorCut.toFixed(2) : props.selectedInvoice.totalPayable.toFixed(2)} will be transferred {!vendorDetails?.bankDetails?.isCheque ? `to your bank account ending with xx${vendorDetails?.bankDetails?.accountNumber?.slice(vendorDetails?.bankDetails?.accountNumber?.length - 2)}` : 'via cheque'}. </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-12 mb-3'>
                                                <div className='professional-near-you-wrapper payment-each-wrapper row m-0'>
                                                    {props.selectedInvoice.wholeStatus === "PAID" ?
                                                        <div className='profile-name col-12 p-0' style={{ color: "#08b12a" }}>
                                                            Status: Payment Settled <i className='fa fa-check-circle pull-right'></i> </div> :
                                                        <div className='profile-name col-12 p-0' style={{ color: "#fd924b" }}>
                                                            Status: Settlement pending <i className="fas fa-hourglass-end pull-right"></i> </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default RevenueModal;