import React from "react";
import "../dashboard.css";
import * as auth from "../../../store/ducks/auth.duck";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

const Gallery = (props) => {
    return <div className="col-md-6 pr-2 pl-2 gallery-manager" style={{
        margin: "0 auto"
    }}>
        <div className="row" style={{ margin: "0" }}>
            <div className="col-12 p-0">
                <div className="row" style={{ margin: 0 }}>
                    <div className="col-12 p-0">
                        <div className="row" style={{ margin: 0 }}>
                            <div className={`col-sm-8 col-xs-12 pt-2 ${props.selectedProfile.descriptionImages && props.selectedProfile.descriptionImages.length ? 'mt-2' : ''}`}>
                                <h5 className={`mb-0`}><i class="fas fa-arrow-left mr-2" style={{cursor: "pointer"}} onClick={() => { props.selectedProfile.status === props.VendorConfigStatus.approvalPending || props.selectedProfile.status === props.VendorConfigStatus.approvalRejected ? props.nextStep() : props.backStep(true)}}></i>Gallery</h5>
                                {props.selectedProfile.descriptionImages && props.selectedProfile.descriptionImages.length ? null :
                                <span style={{
                                    fontSize: "11px",
                                    fontWeight: 500
                                }}>*Not mandatory - You can upload them later also.</span>}
                            </div>
                            {props.selectedProfile.status === props.VendorConfigStatus.active ?
                            <div className="col-sm-4 col-xs-12 pl-0 text-right mb-3">
                                <button type="button" className="btn btn-outline-primary btn-sm ml-2" onClick={() => { props.nextStep() }}>{props.selectedProfile.descriptionImages && props.selectedProfile.descriptionImages.length ? "Next" : "Skip"}</button>
                            </div> : null}
                        </div>
                    </div>
                    
                </div>
            </div>
            {!props.uploadedGalleryImage ?
                <div className={`about-image-wrapper pl-0 pt-2`}>
                    <input type="file" accept="image/*" id="galleryImage" hidden onChange={props.uploadGalleryImage} />
                    <div className="profile-image description-image" style={{ backgroundColor: "#eee", backgroundImage: "url(" + require("../../../../images/plus.svg") + ")" }} onClick={() => props.openFileUploader("galleryImage")} />
                </div> :
                <div className={`about-image-wrapper pl-0 pt-2`}>
                    <input type="file" accept="image/*" id="galleryImage" hidden onChange={props.uploadGalleryImage} />
                    <div className="profile-image description-image" style={{ backgroundColor: "#eee", backgroundImage: "url(" + URL.createObjectURL(props.uploadedGalleryImage) + ")" }} />
                </div>
            }
            {/* <div className={`categories-wrapper gallery-images-wrapper pl-0 pr-0 pt-2 pb-0`} style={{ margin: 0 }}> */}
                {props.selectedProfile.descriptionImages && props.selectedProfile.descriptionImages.length ?
                    props.selectedProfile.descriptionImages.map((image, index) => {
                        return <div className={`about-image-wrapper pl-0 pt-2`} key={index} onClick={() => props.chooseGalleryImage(index)}>
                            <div className="profile-image description-image" style={{ backgroundImage: "url(" + image.url + ")" }} />
                            <img src={require("../../../../images/remove.svg")} onClick={e => { e.stopPropagation(); props.openRemove(image, "Image") }} alt={`remove gallery`} style={{top: "2px", right: "-10px"}} />
                        </div>
                    })
                    : null}
            {/* </div> */}
        </div>
    </div>
};

const mapStateToProps = state => {
    return { data: state };
};

export default injectIntl(
    connect(
        mapStateToProps,
        auth.actions
    )(Gallery)
);