import React, { useEffect, useState } from 'react';
import "./booking-details.css";
import DateFnsUtils from '@date-io/date-fns';
import JoinSessionModal from "../../router/join-session-modal";
import { toast } from 'react-toastify';
import {
    DatePicker,
    TimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
    getSessions,
    addSession,
    updateSession,
    removeSession
} from "../../crud/service.crud";
import {getProfile} from "../../crud/profile.crud";
import RemoveModal from "./remove-modal";
import { REQUIRED_ERROR, INVALID_FROM_TO_DATE, API_ERROR, CALL_TIME_PASSED } from "../../config/error-message";
import { API_SUCCESS } from "../../config/success-message";
import {
    timeArrConst,
    SessionConfig,
    SessionConfigNumber
} from "../../config/constants";
import { useCallback } from 'react';

const timeArr = [...timeArrConst];

const initialServiceDetails = {
    title: "",
    fees: "",
    description: "",
    noOfSeats: "",
    meetingLink: "",
    meetingPlatform: ""
};

const initialServiceError = {
    title: "",
    fees: "",
    description: "",
    noOfSeats: "",
    meetingLink: "",
    meetingPlatform: ""
};

const initialOrgServiceError = {
    days: "",
    selectedFromDate: ""
};
const today = new Date();
const yesterday = new Date(today);
const initialSessionDate = new Date(yesterday.setDate(yesterday.getDate() + 1));
// const serviceTimeHours = [...serviceTimeHoursConstant];

const Service = (props) => {
    const [sessionsList, updateUpcomingSessions] = useState([]);
    const [pastSessionsList, updatePastSessions] = useState([]);
    const [selectedFromDate, handleFromDateChange] = useState(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)));
    const [selectedToDate, handleToDateChange] = useState(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(30)).setSeconds(0)));
    const [displayRemoveModal, openRemoveModal] = useState(false);
    const [removeType, updateRemoveType] = useState("");
    const [serviceDetails, updateServiceDetails] = useState({ ...initialServiceDetails });
    const [serviceDetailsError, updateServiceErrorDetails] = useState({ ...initialServiceError });
    // const [selectedServiceTime, updateSelectedServiceTime] = useState(serviceTimeHours[0].value);

    const [orgServiceDetailsError, updateOrgServiceDetailsError] = useState({ ...initialOrgServiceError });
    const [mode, changeMode] = useState("add");
    const [selectedServiceIdToRemove, updateServiceId] = useState("");
    const [disablePage, toggleDisablePage] = useState(false);
    const [displayMode, changeDisplayMode] = useState("list");
    const [selectedDate, handleDateChange] = useState(initialSessionDate);
    const [currentTab, updateCurrentTab] = useState("upcoming");
    const [displayJoinSessionModal, toggleJoinSessionModal] = useState(false);
    const [callableSessionObject, updateCallableSessionObject] = useState({});

    const getFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const month = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const getDisplayedFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const month = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const getDuration = (times) => {
        times.sort((a, b) => { return a - b });
        const startTime = timeArr[times[0] - 1];
        const endTime = timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0];
        return `${startTime} - ${endTime}`;
    };

    const formatAMPM = (date) => {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    };

    const getTime = (timeStr, date) => {
        var time = timeStr.slice(0, timeStr.length - 3)
        var amPm = timeStr.slice(timeStr.length - 2)
        var d = new Date()
        if (date) {
            d = new Date(date);
        }
        if (amPm === "AM" && time.split(":")[0] === "12") {
            return new Date(new Date(d.setHours(0)).setMinutes(time.split(":")[1]))
        }
        if (amPm === "PM" && time.split(":")[0] !== "12") {
            return new Date(new Date(d.setHours(Number(time.split(":")[0]) + 12)).setMinutes(time.split(":")[1]))
        }
        return new Date(new Date(d.setHours(time.split(":")[0])).setMinutes(time.split(":")[1]))
    };

    const getStartTime = (times) => {
        times.sort((a, b) => { return a - b });
        return getTime(timeArr[times[0] - 1])
    };

    const getEndTime = (times) => {
        times.sort((a, b) => { return a - b });
        return getTime(timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0])
    };

    const checkCurrentDay = (date) => {
        const today = new Date(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
        const day = new Date(new Date(new Date(new Date(new Date(date).setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
        // console.log("today ", today, day)
        return today === day;
    };

    const checkJoiningCallTime = useCallback((localBookingData) => {
        if (checkCurrentDay(localBookingData.date)) {
            const today = new Date().getTime();
            const startTime = getTime(timeArr[localBookingData.slots[0] - 2] ? timeArr[localBookingData.slots[0] - 2] : timeArr[localBookingData.slots[0] - 1]);
            const endTime = getTime(timeArr[localBookingData.slots[localBookingData.slots.length - 1]]);
            console.log("start-------> ", startTime, endTime, today)
            return (startTime <= today && endTime > today);
        }
        return false;
    }, []);

    const checkEditable = useCallback((localBookingData) => {
        console.log("checkCurrentDay(localBookingData.date) ",localBookingData)
        // if (checkCurrentDay(localBookingData.date)) {
            const today = new Date().getTime();
            const startTime = getTime(timeArr[localBookingData.slots[0] - 1], localBookingData.date);
            const lessThirtyStartTime = new Date(startTime).setMinutes(new Date(startTime).getMinutes() - 30);
            console.log("start edit-------> ", timeArr[localBookingData.slots[0] - 1], startTime, today)
            return (lessThirtyStartTime > today);
        // }
        // return true;
    }, []);

    const getAllUpcomingServices = useCallback(async () => {
        toggleDisablePage(true);
        const servicesResult = await getSessions("upcoming");
        toggleDisablePage(false);
        if (servicesResult.data.errorCode) {
            toast.error(API_ERROR[servicesResult.data.errorCode]);
            return;
        }
        console.log("servicesResult ", servicesResult);
        const localServices = [];
        if (servicesResult.data.data && servicesResult.data.data.data && servicesResult.data.data.data.length) {
            let localSingleService = {};
            servicesResult.data.data.data.forEach(each => {
                localSingleService = { ...each };
                localSingleService.selected = false;
                localSingleService.fee = each.eventFees;
                localSingleService.isCallJoinable = checkJoiningCallTime(localSingleService);
                localSingleService.isEditable = checkEditable(localSingleService);
                localSingleService.status = each.status;
                localServices.push({ ...localSingleService });
                localSingleService = {};
            });
        }
        updateUpcomingSessions([...localServices]);
    }, [checkJoiningCallTime, checkEditable]);

    const getAllPastServices = async () => {
        toggleDisablePage(true);
        const servicesResult = await getSessions("past");
        toggleDisablePage(false);
        if (servicesResult.data.errorCode) {
            toast.error(API_ERROR[servicesResult.data.errorCode]);
            return;
        }
        console.log("servicesResult ", servicesResult);
        const localServices = [];
        if (servicesResult.data.data && servicesResult.data.data.data && servicesResult.data.data.data.length) {
            let localSingleService = {};
            servicesResult.data.data.data.forEach(each => {
                localSingleService = { ...each };
                localSingleService.fee = each.eventFees;
                localServices.push({ ...localSingleService });
                localSingleService = {};
            });
        }
        updatePastSessions([...localServices]);
    };

    useEffect(() => {
        getAllUpcomingServices();
        getAllPastServices();
    }, [getAllUpcomingServices]);

    const changeServiceDetails = (text, fieldName) => {
        const localService = { ...serviceDetails };
        const localServiceError = { ...serviceDetailsError };
        localService[fieldName] = text;
        localServiceError[fieldName] = "";
        updateServiceDetails({ ...localService });
        updateServiceErrorDetails({ ...localServiceError });
    };

    const validateService = () => {
        let noError = true;
        const localServiceError = { ...serviceDetailsError };
        const localOrgServiceError = { ...orgServiceDetailsError };

        if (new Date(selectedFromDate) > new Date(selectedToDate)) {
            localOrgServiceError.selectedFromDate = INVALID_FROM_TO_DATE;
            noError = false;
        } else {
            localOrgServiceError.selectedFromDate = "";
        }

        updateOrgServiceDetailsError({ ...localOrgServiceError });
        for (let item in localServiceError) {
            if (!serviceDetails[item].toString().trim().length) {
                localServiceError[item] = REQUIRED_ERROR;
                noError = false;
            } else {
                localServiceError[item] = "";
            }
        }
        updateServiceErrorDetails({ ...localServiceError });
        return noError;
    };

    const resetServiceForm = () => {
        changeMode("add");
        updateServiceDetails({ ...initialServiceDetails });
        updateServiceErrorDetails({ ...initialServiceError });
        // updateSelectedServiceTime(serviceTimeHours[0].value);

        updateOrgServiceDetailsError({ ...initialOrgServiceError });
        handleFromDateChange(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)));
        handleToDateChange(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(30)).setSeconds(0)));

        handleDateChange(initialSessionDate);

        getAllUpcomingServices();
        if (window.innerWidth <= 768) {
            changeDisplayMode('list');
        }
    };

    const saveService = async () => {
        if (!validateService()) {
            return;
        }

        if (mode === "add") {
            const profileData = await getProfile();
            if (profileData.data.data && !profileData.data.data.personalInfo.isOnline) {
                toast.error("Your profile is in offline mode. Please contact with admin to make it online");
                return;
            }
        }
        if (Number(serviceDetails.fees) === 0) {
            toast.error("Price cannot be zero");
            return;
        }
        if (Number(serviceDetails.noOfSeats) === 0) {
            toast.error("Seats cannot be zero");
            return;
        }
        if (Number(serviceDetails.noOfSeats) > 50) {
            toast.error("Seats cannot be more than 50");
            return;
        }
        let requestBody = {};
        let result;

        const fromIndex = (timeArr.indexOf(formatAMPM(selectedFromDate)) + 1);
        const toIndex = (timeArr.indexOf(formatAMPM(selectedToDate)));
        const localWorkingTimeSlots = [];

        if (fromIndex === toIndex) {
            localWorkingTimeSlots.push(fromIndex);
        } else {
            for (let i = fromIndex; i <= toIndex; i++) {
                localWorkingTimeSlots.push(i);
            }
        }
        if (!localWorkingTimeSlots.length) {
            toast.error("Please choose correct start and end time");
            return;
        }
        requestBody = {
            "title": serviceDetails.title,
            "description": serviceDetails.description,
            "fees": Number(serviceDetails.fees),
            "eventType": 1,
            "slots": localWorkingTimeSlots,
            "numberOfSeats": Number(serviceDetails.noOfSeats),
            "meetingLink": serviceDetails.meetingLink,
            "meetingPlatform": serviceDetails.meetingPlatform,
            "date": new Date(new Date(new Date(new Date(new Date(getFullDateFormat(new Date(selectedDate))).setHours(5)).setMinutes(30)).setSeconds(0)).setMilliseconds(0)).toISOString()
        };

        console.log("requestBody ", requestBody);
        // return;
        toggleDisablePage(true);
        if (mode === "add") {
            result = await addSession(requestBody);
        } else {
            requestBody._id = serviceDetails._id;
            result = await updateSession(requestBody);
        }
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            toggleDisablePage(false);
            return;
        }
        toast.success(mode === "add" ? API_SUCCESS.ADD_SESSION : API_SUCCESS.UPDATE_SESSION);

        resetServiceForm();
    };

    // const selectServiceTime = (e) => {
    //     updateSelectedServiceTime(e.target.value);
    // };

    const updateDateError = () => {
        const localOrgServiceError = { ...orgServiceDetailsError };
        localOrgServiceError.selectedFromDate = "";
        updateOrgServiceDetailsError({ ...localOrgServiceError });
    };

    const chooseService = (serviceData) => {
        const localServiceDetails = { ...serviceDetails };
        console.log("serviceData ", serviceData);
        localServiceDetails.title = serviceData.title;
        localServiceDetails.description = serviceData.description;
        localServiceDetails._id = serviceData._id;
        localServiceDetails.noOfSeats = serviceData.numberOfSeats;
        localServiceDetails.oldNoOfSeats = serviceData.numberOfSeats;
        localServiceDetails.availableSeats = serviceData.availableSeats;
        localServiceDetails.fees = serviceData.fee;
        localServiceDetails.meetingLink = serviceData.meetingLink;
        localServiceDetails.meetingPlatform = serviceData.meetingPlatform;
        localServiceDetails.status = serviceData.status;
        handleDateChange(new Date(serviceData.date));
        // updateSelectedServiceTime(serviceData.sessionTime);

        handleFromDateChange(getStartTime(serviceData.slots));
        handleToDateChange(getEndTime(serviceData.slots));

        changeMode("edit");
        if (window.innerWidth <= 768) {
            changeDisplayMode('add');
        }
        updateServiceDetails({ ...localServiceDetails });
    };

    const openRemove = (item, type) => {
        updateRemoveType(type);
        openRemoveModal(true);
        if (type === "Session") {
            updateServiceId(item._id);
        }
    };

    const removeData = async () => {
        if (removeType === "Session") {
            toggleDisablePage(true);
            const result = await removeSession(selectedServiceIdToRemove);
            if (result.data.errorCode) {
                toast.error(API_ERROR[result.data.errorCode]);
                toggleDisablePage(false);
                return;
            }
            toast.success(API_SUCCESS.REMOVE_SESSION);
            updateServiceId("");
            openRemoveModal(false);
            if (mode === "edit") {
                resetServiceForm();
            } else {
                getAllUpcomingServices();
            }
        }
    };

    const changeDate = (date) => {
        handleDateChange(new Date(date));
    };

    const openJoinSessionModal = (sessionData) => {
        console.log("sessionData ", sessionData);
        if (!checkJoiningCallTime(sessionData)) {
            toast.error(CALL_TIME_PASSED);
            return;
        }
        toggleJoinSessionModal(true);
        updateCallableSessionObject({ ...sessionData });
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <JoinSessionModal displayJoinSessionModal={displayJoinSessionModal}
                toggleJoinSessionModal={toggleJoinSessionModal} callableSessionObject={callableSessionObject} />
            <div className={`row booking-details problem-area ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
                <RemoveModal
                    displayRemoveModal={displayRemoveModal}
                    openRemoveModal={openRemoveModal}
                    removeType={removeType}
                    removeData={removeData} />
                <div className="col-md-7 col-xs-12 p-0">
                    <div className="row m-0">
                        {displayMode === 'list' ? <>
                            <div className="col-sm-4 col-xs-12 categories-heading">
                                <h4 className={`mb-0`}>Sessions</h4>
                            </div>
                            <div className="col-sm-8 col-xs-12 pl-0 upcoming-past-button-wrapper text-right pt-3 mb-3">
                                <button onClick={() => updateCurrentTab("upcoming")} className={`btn btn-primary services-sessions-button ${currentTab === 'upcoming' ? 'active' : ''}`}>Upcoming</button>
                                <button onClick={() => updateCurrentTab("past")} className={`btn btn-primary services-sessions-button ${currentTab === 'past' ? 'active' : ''}`}>Past</button>
                                <button type="button" className="btn btn-outline-primary btn-sm less-than-iniline-block-768" onClick={() => changeDisplayMode('add')}>Add Session</button>
                            </div>
                        </> : null}
                        <div className={`col-12 ${displayMode === 'add' ? 'display-none' : ''}`}>
                            <div className="row" style={{ margin: 0 }}>
                                {currentTab === 'upcoming' ?
                                    sessionsList.length ?
                                        sessionsList.map((service, index) => {
                                            return <div className={`col-12 professional-wrapper service-wrapper
                                            `} key={index}>
                                                <div className={`row ${index === sessionsList.length - 1 ? 'pb-20' : ''} ${index === 0 ? 'bor-rad-top' : ''} ${index === sessionsList.length - 1 ? 'bor-rad-bot' : ''}`} style={{ margin: 0 }}>
                                                    <div className="col-12">
                                                        <div className="service-list-name pr-3">{service.title}</div>
                                                        {service.status !== SessionConfigNumber.Confirmed ?
                                                            <img className="remove-session-image" src={require("../../../images/remove.svg")} alt="remove service" onClick={() => openRemove(service, "Session")} /> : null}
                                                    </div>
                                                    <div className="col-12" style={{ margin: "5px 0" }}>
                                                        <div className="service-description">{service.description}</div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="profile-date-time row ml-0 mr-0 pt-2 pl-0">
                                                            <div className="col-sm-6 col-xs-12 pl-0">
                                                                <div className="label">Date</div>
                                                                <div>
                                                                    {getDisplayedFullDateFormat(new Date(service.date))}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col-xs-12 pl-0 time-slot-less-575">
                                                                <div className="label">Time</div>
                                                                <div>
                                                                    {getDuration(service.slots)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-100 col-12 p-0">
                                                        <div className="fee-text seat-count bor-right mt-3">Total Seats: {service.numberOfSeats}</div>
                                                        <div className="fee-text seat-count mt-3">Available Seats: {service.availableSeats}</div>
                                                    </div>
                                                    <div className="w-100 col-12 p-0">
                                                        <div className="fee-text mt-3">Service Fee: <i className="fas fa-rupee-sign"></i>{service.fee.toFixed(2)}</div>
                                                        {service.isEditable ?
                                                        <>
                                                            <button type="button" className="btn btn-primary small-book-now-button edit-session-button float-right mt-2" onClick={() => chooseService(service)}>Edit -{'>'}</button>
                                                            <div className="seat-count mt-2 text-right">Status: {SessionConfig[service.status]}</div>
                                                        </> : null}
                                                        {service.isCallJoinable ?
                                                            <button className="btn btn-primary details-button float-right" onClick={() => openJoinSessionModal(service)}>Join</button> : null}
                                                    </div>
                                                    {index !== sessionsList.length - 1 ?
                                                        <hr style={{ width: "100%", margin: "20px 0 0" }} /> : null}
                                                </div>
                                            </div>
                                        })
                                        : <div className="text-center well col-12 color-black">
                                            No Upcoming Sessions Available!
                                        </div> :
                                    pastSessionsList.length ?
                                        pastSessionsList.map((service, index) => {
                                            return <div className={`col-12 professional-wrapper service-wrapper
                                            `} key={index}>
                                                <div className={`row ${index === pastSessionsList.length - 1 ? 'pb-20' : ''} ${index === 0 ? 'bor-rad-top' : ''} ${index === pastSessionsList.length - 1 ? 'bor-rad-bot' : ''}`} style={{ margin: 0 }}>
                                                    <div className="col-12">
                                                        <div className="service-list-name pr-3">{service.title}</div>
                                                    </div>
                                                    <div className="col-12" style={{ margin: "5px 0" }}>
                                                        <div className="service-description">{service.description}</div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="profile-date-time row ml-0 mr-0 pt-2 pl-0">
                                                            <div className="col-sm-6 col-xs-12 pl-0">
                                                                <div className="label">Date</div>
                                                                <div>
                                                                    {getDisplayedFullDateFormat(new Date(service.date))}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col-xs-12 pl-0 time-slot-less-575">
                                                                <div className="label">Time</div>
                                                                <div>
                                                                    {getDuration(service.slots)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-100 col-12 p-0">
                                                        <div className="fee-text seat-count bor-right mt-3">Total Seats: {service.numberOfSeats}</div>
                                                        <div className="fee-text seat-count mt-3">Available Seats: {service.availableSeats}</div>
                                                    </div>
                                                    <div className="w-100 col-12 p-0">
                                                        <div className="fee-text mt-3">Service Fee: <i className="fas fa-rupee-sign"></i>{service.fee.toFixed(2)}</div>
                                                    </div>
                                                    {index !== pastSessionsList.length - 1 ?
                                                        <hr style={{ width: "100%", margin: "20px 0 0" }} /> : null}
                                                </div>
                                            </div>
                                        })
                                        : <div className="text-center well col-12 color-black">
                                            No Past Sessions Available!
                                        </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`col-md-5 col-xs-12 p-0 ${displayMode === 'list' ? 'more-than-768' : 'less-than-768'}`}>
                    <div className="col-12 categories-heading">
                        <h4 className={`mb-0`}>Add/ Edit Session</h4>
                    </div>
                    <div className="col-12">
                        <div className="row professional-near-you-wrapper" style={{ margin: 0 }}>
                            <div className="col-12 p-0 mb-3 add-edit-service-button-wrapper">
                                <button type="button" className="btn btn-primary btn-sm" onClick={saveService}>Save</button>
                                <button type="button" className="btn btn-outline-primary btn-sm" onClick={resetServiceForm}>Reset</button>
                            </div>
                            <div className="col-12 p-0 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Title</label>
                                <input type="text" className={`form-control ${serviceDetailsError.title ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter Title" value={serviceDetails.title} onChange={e => changeServiceDetails(e.target.value, "title")} disabled={mode === "edit" && serviceDetails.status === SessionConfigNumber.Confirmed} />
                                {serviceDetailsError.title ?
                                    <div className="input-error">{serviceDetailsError.title}</div> : null}
                            </div>
                            <div className="col-6 pl-0 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Price</label>
                                <input type="text" className={`form-control ${serviceDetailsError.fees ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Price (in Rs)" value={serviceDetails.fees} onChange={e => changeServiceDetails(e.target.value.replace(/\D/, ''), "fees")} disabled={mode === "edit" && serviceDetails.status === SessionConfigNumber.Confirmed} />
                                {serviceDetailsError.fees ?
                                    <div className="input-error">{serviceDetailsError.fees}</div> : null}
                            </div>
                            {/* <div className="col-6 pr-0 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Session Time</label>
                                <select className="form-select form-control" aria-label="Session Time" value={selectedServiceTime} onChange={selectServiceTime}>
                                    {serviceTimeHours.map((loc, index) => {
                                        return <option key={index} value={loc.value}>{loc.text}</option>
                                    })}
                                </select>
                            </div> */}
                            <div className="col-6 pr-0 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">No of Seats (max 50)</label>
                                <input type="text" className={`form-control ${serviceDetailsError.noOfSeats ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="No of Seats" value={serviceDetails.noOfSeats} onChange={e => changeServiceDetails(e.target.value.replace(/\D/, ''), "noOfSeats")} disabled={mode === "edit" && serviceDetails.status === SessionConfigNumber.Confirmed} />
                                {serviceDetailsError.noOfSeats ?
                                    <div className="input-error">{serviceDetailsError.noOfSeats}</div> : null}
                            </div>
                            <div className="col-12 pl-0 pr-0 mb-3 custom-date-picker-design">
                                <label htmlFor="exampleFormControlInput1" className="form-label display-block">Select Date</label>
                                <DatePicker value={selectedDate} onChange={changeDate} format="dd-MM-yyyy" minDate={initialSessionDate} disabled={mode === "edit" && serviceDetails.status === SessionConfigNumber.Confirmed} />
                            </div>
                            <div className="col-6 pl-0 mb-3 custom-time-picker-design">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Time From</label><br />
                                <TimePicker value={selectedFromDate} className={`${orgServiceDetailsError.selectedFromDate ? 'timepicker-error-border' : ''}`} onClose={updateDateError} onChange={handleFromDateChange} minutesStep={30} disabled={mode === "edit" && serviceDetails.status === SessionConfigNumber.Confirmed} />
                                {orgServiceDetailsError.selectedFromDate ?
                                    <div className="input-error">{orgServiceDetailsError.selectedFromDate}</div> : null}
                            </div>
                            <div className="col-6 pr-0 mb-3 custom-time-picker-design">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Time To</label><br />
                                <TimePicker value={selectedToDate} className={`${orgServiceDetailsError.selectedFromDate ? 'timepicker-error-border' : ''}`} onClose={updateDateError} onChange={handleToDateChange} minutesStep={30} disabled={mode === "edit" && serviceDetails.status === SessionConfigNumber.Confirmed} />
                            </div>
                            <div className="col-12 pr-0 pl-0 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Meeting Platform</label>
                                <input type="text" className={`form-control ${serviceDetailsError.meetingPlatform ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Zoom/ Google Meet etc." value={serviceDetails.meetingPlatform} onChange={e => changeServiceDetails(e.target.value, "meetingPlatform")} />
                                {serviceDetailsError.meetingPlatform ?
                                    <div className="input-error">{serviceDetailsError.meetingPlatform}</div> : null}
                            </div>
                            <div className="col-12 pr-0 pl-0 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Meeting Link</label>
                                <input type="text" className={`form-control ${serviceDetailsError.meetingLink ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Meeting Link" value={serviceDetails.meetingLink} onChange={e => changeServiceDetails(e.target.value, "meetingLink")} />
                                {serviceDetailsError.meetingLink ?
                                    <div className="input-error">{serviceDetailsError.meetingLink}</div> : null}
                            </div>
                            <div className="col-12 p-0 mb-3">
                                <label htmlFor="exampleFormControlTextarea1" className="form-label">Description</label>
                                <textarea className={`form-control ${serviceDetailsError.description ? 'error-border' : ''}`} id="exampleFormControlTextarea1" rows="3" value={serviceDetails.description} onChange={e => changeServiceDetails(e.target.value, "description")} disabled={mode === "edit" && serviceDetails.status === SessionConfigNumber.Confirmed}></textarea>
                                {serviceDetailsError.description ?
                                    <div className="input-error">{serviceDetailsError.description}</div> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MuiPickersUtilsProvider>
    )
}

export default Service;