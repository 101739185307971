import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./DashboardNew";
import Service from "../admins/service";
import Session from "../admins/session";
import Event from "../admins/event";
import Workshop from "../admins/workshop";
import QuestionDetails from "../admins/question-details";
import Scheduler from "../admins/scheduler";
import BookingDetails from "../admins/booking-details";
import Forum from "../admins/forum";
import FAQ from "../admins/faq";
import MyQA from "../admins/my-qa";
import Revenue from "../admins/revenue";
import Notifications from "../admins/notifications";
import SuccessPaymentOnlineEvent from "../admins/success-payment-online-event";
import FailurePaymentOnlineEvent from "../admins/failure-payment-online-event";
import PrivacyPolicy from './privacy-policy';
import TermsCondition from './terms-condition';
import Onboarding from "./onboarding/onboarding";
import { LayoutSplashScreen } from "../../../_metronic";

export default function HomePage() {
  // useEffect(() => {
  //   console.log('Home page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/service" component={Service} />
        <Route path="/session" component={Session} />
        <Route path="/event" component={Event} />
        <Route path="/workshop" component={Workshop} />
        <Route path="/scheduler" component={Scheduler} />
        <Route path="/booking-details/:bookingId" component={BookingDetails} />
        <Route path="/revenue" component={Revenue} />
        <Route path="/forum" component={Forum} />
        <Route path="/forum-details/:id" component={QuestionDetails} />
        <Route path="/myqa-details/:id" component={QuestionDetails} />
        <Route path="/faq" component={FAQ} />
        <Route path="/myQA" component={MyQA} />
        <Route path="/notifications" component={Notifications} />
        <Route path="/success-payment-online-event-create" component={SuccessPaymentOnlineEvent} />
        <Route path="/failure-payment-online-event-create" component={FailurePaymentOnlineEvent} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/terms-and-conditions" component={TermsCondition} />
        <Route path="/onboarding" component={Onboarding} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
