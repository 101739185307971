import React, { useEffect, useState, useCallback } from 'react';
import "./booking-details.css"
import * as auth from "../../store/ducks/auth.duck";
import { getBookingDetails, cancelBook, completeBooking, submitNotes } from "../../crud/booking.crud";
import JoinSessionModal from "../../router/join-session-modal";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { toast } from 'react-toastify';
import {
    API_ERROR,
    CALL_TIME_PASSED,
    CANCEL_TIME_PASSED,
    COMPLETE_TIME_PASSED
} from "../../config/error-message";
import {
    timeArrConst,
    BookingConfig,
    BookingNumberStatus,
    monthArr
} from "../../config/constants";
import { API_SUCCESS } from '../../config/success-message';
import CancelModal from "../../router/cancel-modal";
import TestListModal from "./test-list-modal";
import MeetingModal from "./meeting-details-modal";
import CompleteAlert from '../home/complete-alert';
import { joinAppointmentCall } from '../../crud/service.crud';

const defaultImage = require('../../../images/imgAvtr.png');

const timeArr = [...timeArrConst];

const Details = (props) => {

    const [selectedProfile, selectProfile] = useState({});
    const [bookingDetails, updateBookingDetails] = useState({});
    const [otpArray, updateOtpArray] = useState(['', '', '', '']);
    const [pageInitialized, updatePageInitialization] = useState(false);
    const [disablePage, toggleDisablePage] = useState(false);
    const [displayCancelModal, toggleCancelModal] = useState(false);
    const [comments, updateComments] = useState({
        forVendor: "",
        forCustomer: ""
    });
    const [disableComment, toggleDisableComment] = useState(true);
    const [testModalOpened, toggleTestModal] = useState(false);
    const [meetingModalOpened, toggleMeetingModal] = useState(false);
    const [callableSessionObject, updateCallableSessionObject] = useState({});
    const [displayJoinSessionModal, toggleJoinSessionModal] = useState(false);
    const [openCompleteAlert, toggleCompleteAlert] = useState(false);

    const getFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const month = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const getDisplayFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const year = date.getFullYear();
        return day + ' ' + monthArr[date.getMonth()] + ', ' + year;
    };

    const getTime = (timeStr, date) => {
        const time = timeStr.slice(0, timeStr.length - 3)
        const amPm = timeStr.slice(timeStr.length - 2)
        // console.log("time ", amPm, time)
        let d = new Date();
        if (date) {
            d = new Date(date);
        }
        if (amPm === "AM" && time.split(":")[0] === "12") {
            return new Date(new Date(new Date(new Date(d.setHours(0)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
        }
        if (amPm === "PM" && time.split(":")[0] !== "12") {
            return new Date(new Date(new Date(new Date(d.setHours(Number(time.split(":")[0]) + 12)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
        }
        return new Date(new Date(new Date(new Date(d.setHours(time.split(":")[0])).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
    };

    const getDuration = (times) => {
        times.sort((a, b) => { return a - b });
        const startTime = timeArr[times[0] - 1];
        const endTime = timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0];
        return `${startTime} - ${endTime}`;
    };

    const checkCurrentDay = (date) => {
        const today = new Date(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
        const day = new Date(new Date(new Date(new Date(new Date(date).setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
        // console.log("today ", today, day)
        return today === day;
    };

    const isCompleteBeforeEndTime = (localBookingData) => {
        const today = new Date().getTime();
        const endTime = getTime(timeArr[localBookingData.slots[localBookingData.slots.length - 1]]);
        console.log("enddd-------> ", endTime, today)
        return (endTime > today) && localBookingData.status === BookingConfig.Confirmed;
    };

    const checkJoiningCallTime = useCallback((localBookingData) => {
        console.log("hereeeeeeeeee")
        if (checkCurrentDay(localBookingData.slotDate)) {
            const today = new Date().getTime();
            const startTime = getTime(timeArr[localBookingData.slots[0] - 2] ? timeArr[localBookingData.slots[0] - 2] : timeArr[localBookingData.slots[0] - 1]);
            const endTime = getTime(timeArr[localBookingData.slots[localBookingData.slots.length - 1]]);
            console.log("start-------> ", startTime, endTime, today)
            return (startTime <= today && endTime > today) && localBookingData.status === BookingConfig.Confirmed && localBookingData.meetingDetails.link;
        }
        return false;
    }, []);

    const checkIsServiceEnd = useCallback((localBookingData) => {
        if (checkCurrentDay(localBookingData.slotDate)) {
            const today = new Date().getTime();
            const startTime = getTime(timeArr[localBookingData.slots[0] - 1]);
            const extendedStartTime = new Date(startTime).setMinutes(new Date(startTime).getMinutes() + 15);
            // const endTime = getTime(timeArr[localBookingData.slots[localBookingData.slots.length - 1]]);
            console.log("startTime1234-------> ", startTime, extendedStartTime, today)
            if (!localBookingData.meetingDetails.link) {
                return (extendedStartTime < today) && localBookingData.status === BookingConfig.Confirmed;
            } else {
                return (startTime < today) && localBookingData.status === BookingConfig.Confirmed;
            }

        }
        return false;
    }, []);

    const checkCancelTime = useCallback((localBookingData) => {
        const today = new Date().getTime();
        const startTime = getTime(timeArr[localBookingData.slots[0] - 1], localBookingData.slotDate);
        console.log("startTime ", startTime)
        const lessThirtyStartTime = new Date(startTime).setMinutes(new Date(startTime).getMinutes() - 30);
        console.log("start-------> ", lessThirtyStartTime, today)
        return (lessThirtyStartTime > today) && localBookingData.status === BookingConfig.Confirmed;
    }, []);

    const checkCompleteTime = useCallback((localBookingData) => {
        const d = new Date();
        const todayStr = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
        const d1 = new Date(localBookingData.slotDate);
        const slotDateStr = `${d1.getFullYear()}-${d1.getMonth() + 1}-${d1.getDate()}`;
        console.log("checkcomplete ", todayStr, slotDateStr, new Date(todayStr), new Date(slotDateStr))
        // return (startTime < today) && localBookingData.status === BookingConfig.Confirmed && localBookingData.meetingDetails.link;
        return localBookingData.status === BookingConfig.Confirmed && new Date(todayStr).getTime() >= new Date(slotDateStr).getTime();
    }, []);

    const getBookingData = useCallback(async (id) => {
        toggleDisablePage(true);
        const bookingResult = await getBookingDetails(id);
        toggleDisablePage(false);
        if (bookingResult.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.errorCode]);
            return;
        }
        if (bookingResult.data.data && bookingResult.data.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.data.errorCode]);
            return;
        }
        console.log("bookingResult ", bookingResult);
        // return;
        const resultData = bookingResult.data.data;
        // const resultData = JSON.parse(sessionStorage.getItem("bookingData"));
        console.log("resultData ", resultData)
        const localProfessionalObj = {
            name: resultData.bookingForName,
            profilePicture: resultData.customerRef.personalInfo.profilePicture,
            description: resultData.description,
            bookingId: resultData._id,
            cost: resultData.paymentDetails.serviceFee,
            convenienceFee: resultData.paymentDetails.convenienceFee,
            refundAmount: resultData.transactionDetails && resultData.transactionDetails.vendorDue ? resultData.transactionDetails.vendorDue : 0,
            gst: resultData.paymentDetails.gst,
            total: resultData.paymentDetails.total,
            slots: resultData.slots,
            status: resultData.status,
            taggedTests: resultData.taggedTestReports || [],
            appointmentBookingType: resultData.appointmentBookingType || 1,
            bookingNumber: resultData.bookingNumber || ""
        };
        localProfessionalObj.statusColor = resultData.status === 3 ? "#00A365" : (resultData.status !== 1 && resultData.status !== 2) ? "#E52E40" : "#185bdb";
        localProfessionalObj.slotDuration = getDuration(resultData.slots);
        localProfessionalObj.slotDate = getFullDateFormat(new Date(resultData.date));
        localProfessionalObj.isCancelable = checkCancelTime(localProfessionalObj);



        localProfessionalObj.meetingDetails = {
            platform: resultData.meetingDetails && resultData.meetingDetails.platform ? resultData.meetingDetails.platform : "",
            link: resultData.meetingDetails && resultData.meetingDetails.link ? resultData.meetingDetails.link : ""
        };
        localProfessionalObj.isCallJoinable = checkJoiningCallTime(localProfessionalObj);
        localProfessionalObj.isServiceEnd = checkIsServiceEnd(localProfessionalObj);
        localProfessionalObj.isCompletable = checkCompleteTime(localProfessionalObj);
        console.log("localProfessionalObj ", localProfessionalObj);

        if (resultData.notes && resultData.notes.forCustomer && resultData.notes.forCustomer.length) {
            localProfessionalObj.alreadyCustomerNote = true;
        }

        selectProfile({ ...localProfessionalObj });
        localProfessionalObj.name = resultData.serviceRef ? resultData.serviceRef.title : "";
        localProfessionalObj.description = resultData.serviceRef ? resultData.serviceRef.description : "";
        updateBookingDetails({ ...localProfessionalObj });

        const localComments = {
            forVendor: resultData.notes ? resultData.notes.forVendor : "",
            forCustomer: resultData.notes ? resultData.notes.forCustomer : ""
        };

        updateComments({ ...localComments });
        toggleDisableComment(true);
    }, [checkCancelTime, checkJoiningCallTime, checkCompleteTime, checkIsServiceEnd]);

    useEffect(() => {
        if (!pageInitialized) {
            updatePageInitialization(true);
            if (props.match.params.bookingId) {
                getBookingData(props.match.params.bookingId);
            }
        }
    }, [props.match.params, pageInitialized, getBookingData]);

    const joinTwilioCall = async () => {
        if (checkJoiningCallTime(bookingDetails)) {
            if (!bookingDetails.meetingDetails.link) {
                toast.error('No meeting link added');
                return;
            }
            toggleJoinSessionModal(true);
            updateCallableSessionObject({
                meetingPlatform: bookingDetails.meetingDetails.platform,
                meetingLink: bookingDetails.meetingDetails.link
            });
            const resultData = await joinAppointmentCall(bookingDetails.bookingId);
            if (resultData.data.errorCode) {
                toast.error(API_ERROR[resultData.data.errorCode]);
                return;
            }
            if (resultData.data.data && resultData.data.data.errorCode) {
                toast.error(API_ERROR[resultData.data.data.errorCode]);
                return;
            }
        } else {
            toast.error(CALL_TIME_PASSED);
        }
    };

    const cancelBooking = async () => {
        if (checkCancelTime(bookingDetails)) {
            console.log("bookingDetails.bookingId ", bookingDetails)
            // api call
            // return;
            toggleDisablePage(true);
            const cancelBookingResult = await cancelBook(bookingDetails.bookingId);
            toggleDisablePage(false);
            if (cancelBookingResult.data.errorCode) {
                toast.error(API_ERROR[cancelBookingResult.data.errorCode]);
                return;
            }
            if (cancelBookingResult.data.data && cancelBookingResult.data.data.errorCode) {
                toast.error(API_ERROR[cancelBookingResult.data.data.errorCode]);
                return;
            }
            console.log("cancelBookingResult ", cancelBookingResult);
            toast.success(API_SUCCESS.CANCEL_SUCCESS);
            getBookingData(bookingDetails.bookingId);
            toggleCancelModal(false);
            // return;
            // const resultData = bookingResult.data.data;
        } else {
            toast.error(CANCEL_TIME_PASSED);
        }
    };

    const goBack = () => {
        props.history.goBack();
    };

    const modifyOtp = (otpText, index) => {
        const localOtpArray = [...otpArray];
        const regex = /^-?\d+\.?\d*$/;
        localOtpArray[index] = otpText;
        if (regex.test(otpText) && document.getElementById(`otp${index + 1}`)) {
            document.getElementById(`otp${index + 1}`).focus();
        }
        updateOtpArray([...localOtpArray]);
    };

    const completeAppointment = async (bookingData, fromAlert) => {
        if (!checkCompleteTime(bookingData)) {
            toast.error(COMPLETE_TIME_PASSED);
            return;
        }

        if (!fromAlert && isCompleteBeforeEndTime(bookingData)) {
            toggleCompleteAlert(true);
            return;
        }
        toggleDisablePage(true);
        const result = await completeBooking(props.match.params.bookingId, {
            pin: Number(otpArray.join(""))
        });
        toggleDisablePage(false);
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            return;
        }
        toast.success(API_SUCCESS.APPOINTMENT_COMPLETED);
        getBookingData(props.match.params.bookingId);
        // getAllBookings(selectedDate);
    };

    const changeComment = (text, fieldName) => {
        const localComment = { ...comments };
        localComment[fieldName] = text;
        updateComments({ ...localComment });
    };

    const resetComments = () => {
        getBookingData(props.match.params.bookingId);
    };

    const saveComments = async () => {
        const reqBody = {
            forVendor: comments.forVendor.trim(),
            forCustomer: comments.forCustomer.trim()
        };

        console.log("reqqq ", reqBody);
        // return;

        toggleDisablePage(true);
        const noteResult = await submitNotes(props.match.params.bookingId, reqBody);
        toggleDisablePage(false);
        if (noteResult.data.errorCode) {
            toast.error(API_ERROR[noteResult.data.errorCode]);
            return;
        }
        if (noteResult.data.data && noteResult.data.data.errorCode) {
            toast.error(API_ERROR[noteResult.data.data.errorCode]);
            return;
        }
        console.log("noteResult ", noteResult);
        toast.success(API_SUCCESS.ADD_NOTE);
        getBookingData(props.match.params.bookingId);
    };

    const openTestModal = () => {
        toggleTestModal(true);
    };

    const openMeetingModal = () => {
        toggleMeetingModal(true);
    };

    const closeMeetingModal = (refreshNeeded) => {
        if (refreshNeeded) {
            getBookingData(props.match.params.bookingId);
        }
        toggleMeetingModal(false);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={`row booking-details ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
                <CancelModal displayCancelModal={displayCancelModal}
                    toggleCancelModal={toggleCancelModal} cancelBooking={cancelBooking} />
                {openCompleteAlert ?
                    <CompleteAlert displayModal={openCompleteAlert}
                        toggleCompleteAlert={toggleCompleteAlert} bookingDetails={bookingDetails} completeAppointment={completeAppointment} /> : null}
                {displayJoinSessionModal ?
                    <JoinSessionModal displayJoinSessionModal={displayJoinSessionModal}
                        toggleJoinSessionModal={toggleJoinSessionModal} callableSessionObject={callableSessionObject} joiningType="appointment" /> : null}
                {testModalOpened ?
                    <TestListModal displayModal={testModalOpened}
                        toggleTestModal={toggleTestModal} tests={selectedProfile.taggedTests}></TestListModal> : null}
                {meetingModalOpened ?
                    <MeetingModal displayModal={meetingModalOpened}
                        closeMeetingModal={closeMeetingModal} bookingDetails={bookingDetails}></MeetingModal> : null}
                {selectedProfile.name ?
                    <div className="col-lg-12">
                        <div className="col-12 categories-heading">
                            <h4 className={`mb-0`}><i className="fa fa-arrow-left" aria-hidden="true" style={{ marginRight: "10px", cursor: "pointer" }} onClick={goBack}></i>Booking Details</h4>
                        </div>
                        <div className="col-12">
                            <div className="row profile-details-wrapper" style={{ margin: 0 }}>
                                <div className="col-12 p-0">
                                    <div className="row" style={{ margin: 0 }}>
                                        <div className="col-md-5 col-xs-12 pr-20 left-booking-item">
                                            <div className="row m-0">
                                                <div className="profile-image-wrapper p-0">
                                                    {selectedProfile.profilePicture ?
                                                        <div style={{ backgroundImage: "url(" + selectedProfile.profilePicture + ")" }} className="profile-image" /> :
                                                        <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="profile-image" />}
                                                </div>
                                                <div className="profile-other-details profile-name-wrapper">
                                                    <div className="profile-name">{selectedProfile.name}</div>
                                                    {Object.keys(bookingDetails).length ?
                                                        <><div className="profile-designation">{bookingDetails.name}</div>
                                                            <div className="profile-designation">{bookingDetails.description}</div>
                                                        </> : null}
                                                    {bookingDetails.appointmentBookingType === 1 ?
                                                        selectedProfile.status === BookingConfig.Confirmed ?
                                                            <div className="col-12 booking-details-change-button  appointment-details-button-wrapper pl-0 text-left">
                                                                {bookingDetails?.meetingDetails?.link ?
                                                                    <button type="button" className="btn btn-primary join-button mb-2" onClick={joinTwilioCall} disabled={!bookingDetails.isCallJoinable}>Join</button> : null}
                                                                {!bookingDetails.isCallJoinable && !bookingDetails.isServiceEnd ?
                                                                    <button type="button" className="btn btn-primary join-button" onClick={openMeetingModal} style={{ width: "130px", fontSize: "12px" }}>{bookingDetails?.meetingDetails?.link && bookingDetails?.meetingDetails?.link.length ? "Edit" : "Add"} Meeting Link</button>
                                                                    : null}
                                                            </div> : null :
                                                        <div className='col-12 p-0' style={{
                                                            color: "#185BDB",
                                                            fontWeight: 500
                                                        }}>
                                                            Offline appointment
                                                        </div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-7 col-xs-12 pl-20 pr-0 booking-gallery">
                                            <div className="row m-0">
                                                {Object.keys(bookingDetails).length ?
                                                    <div className="col-12 pl-0 pr-0">
                                                        {/* <div className="profile-name">{bookingDetails.name}</div> */}
                                                        <div className="profile-date-time row ml-0 mr-0 mb-3">
                                                            <div className="col-md-4 col-6 pl-0">
                                                                <div className="label">Selected date</div>
                                                                <div>
                                                                    {/* <img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "17px", marginRight: "3px" }} />  */}
                                                                    {getDisplayFullDateFormat(new Date(bookingDetails.slotDate))}</div>
                                                            </div>
                                                            <div className="col-md-4 col-6 pl-0">
                                                                <div className="label">Time slot</div>
                                                                <div>
                                                                    {/* <img src={require('../../../images/clock.svg')} style={{ width: "17px", marginRight: "3px" }} alt="clock" /> */}
                                                                    {bookingDetails.slotDuration}</div>
                                                            </div>
                                                        </div>
                                                        {bookingDetails.bookingNumber ?
                                                        <div className="profile-date-time row ml-0 mr-0 mb-3">
                                                            <div className="col-md-4 col-6 pl-0">
                                                                <div className="label">Booking Number</div>
                                                                <div>
                                                                    {bookingDetails.bookingNumber}</div>
                                                            </div>
                                                        </div> : null}
                                                        <div className="w-100">
                                                            <div className="fee-text bor-right pl-0">Service Fee: <i className="fas fa-rupee-sign"></i>{bookingDetails.cost.toFixed(2)}</div>
                                                            <div className="fee-text bor-right">Convenience Fee: <i className="fas fa-rupee-sign"></i>{bookingDetails.convenienceFee.toFixed(2)}</div>
                                                            <div className="fee-text">GST: <i className="fas fa-rupee-sign"></i>{bookingDetails.gst.toFixed(2)}</div>
                                                        </div>
                                                        <div className="profile-amount row ml-0 mr-0">
                                                            <div className="col-sm-6 col-xs-12 pl-0">
                                                                Total: <i className="fas fa-rupee-sign"></i>{bookingDetails.total.toFixed(2)}
                                                            </div>
                                                            <div className="col-sm-6 col-xs-12 pr-0 booking-details-status" style={{
                                                                        color: selectedProfile.statusColor
                                                                    }}>
                                                                Status: {BookingNumberStatus[selectedProfile.status]}
                                                            </div>
                                                        </div>
                                                    </div> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 p-0">
                                    {Object.keys(bookingDetails).length ?
                                        <div className="row" style={{ margin: 0 }}>
                                            <div className="col-12 pl-0 pr-0 pt-20">
                                                <div className="row text-center" style={{ margin: "0 0 15px 0" }}>
                                                    <div className='col-md-5 col-xs-12 text-left test-border-768' style={{ paddingRight: 16 }}>
                                                        <p style={{ fontSize: 16, fontWeight: 600, color: '#374561', fontFamily: 'Poppins Medium' }}>Assessments Results {selectedProfile.taggedTests.length ? <button style={{ float: 'right' }} type="button" className="btn btn-primary reschedule-button" onClick={openTestModal}>View</button> : null}</p>
                                                        <p style={{ fontSize: 12, color: '#566986', fontFamily: 'Poppins Regular' }}>{selectedProfile.taggedTests.length} Assessment{selectedProfile.taggedTests.length > 1 ? 's' : null} attached </p>
                                                    </div>
                                                    {selectedProfile.status === BookingConfig.Confirmed ? <>

                                                        {bookingDetails.isCompletable ?
                                                            <div className="col-md-7 col-xs-12 mt-3 xm-0-auto">
                                                                <div className="row" style={{ margin: 0 }}>
                                                                    <div className="col-12 pl-0 pr-0 booking-details-header text-center">
                                                                        Enter Appointment Pin
                                                                    </div>
                                                                    <div className="col-12 pl-0 pr-0 mt-2">
                                                                        <div className="row ml-0 mr-0 form-group login-otp-input-wrapper">
                                                                            {otpArray.map((item, index) => {
                                                                                return <div className="col-3 pl-0" key={index}>
                                                                                    <input type="text" className="form-control text-center" value={item} onChange={e => modifyOtp(e.target.value.replace(/\D/, ''), index)} maxLength={1} id={`otp${index}`} />
                                                                                </div>
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 p-0 text-center">
                                                                        <button type="button" className="btn btn-primary btn-sm complete-appointment-button" disabled={(!otpArray[0].length || !otpArray[1].length || !otpArray[2].length || !otpArray[3].length)} onClick={() => completeAppointment(bookingDetails)}>Complete Appointment</button>
                                                                    </div>
                                                                </div>
                                                            </div> : null}
                                                    </> : null}
                                                    {selectedProfile.status === BookingConfig.Completed ?
                                                        <>
                                                            <div className="col-12 p-0 booking-details-header">
                                                                The appointment has been completed
                                                            </div>
                                                            <div className="col-12 p-0 mt-3 profile-date-time">
                                                                <div className="row m-0">
                                                                    <div className="col-sm-6 col-xs-12 text-left pl-0 note-wrapper mt-2">
                                                                        <label className="label">Note to customer</label>
                                                                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="4" placeholder={`Note to customer`} value={comments.forCustomer} disabled={disableComment || selectedProfile.alreadyCustomerNote} onChange={e => changeComment(e.target.value, 'forCustomer')}></textarea>
                                                                    </div>
                                                                    <div className="col-sm-6 col-xs-12 text-left pr-0 note-wrapper mt-2">
                                                                        <label className="label">Personal note</label>
                                                                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="4" placeholder={`Personal note`} value={comments.forVendor} disabled={disableComment} onChange={e => changeComment(e.target.value, 'forVendor')}></textarea>
                                                                    </div>
                                                                    <div className="col-12 appointment-details-button-wrapper mt-3 p-0 text-right">
                                                                        {!disableComment ? <>
                                                                            <button type="button" className="btn btn-outline-primary" onClick={resetComments}>Cancel</button>
                                                                            <button type="button" className="btn btn-primary" disabled={!comments.forCustomer.trim().length && !comments.forVendor.trim().length} onClick={saveComments}>Save</button> </> :
                                                                            <button type="button" className="btn btn-primary" onClick={() => toggleDisableComment(false)}>Edit</button>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </> : null}
                                                    {selectedProfile.status === BookingConfig.Failed ?
                                                        <div className="col-12 p-0 booking-details-header">
                                                            The appointment has been failed
                                                        </div> : null}
                                                    {selectedProfile.status === BookingConfig.CanceledByCustomer ?
                                                        <div className="col-12 p-0 booking-details-header">
                                                            The appointment has been cancelled by the customer
                                                            <div className="cancel-info">
                                                                You are eligible for a service fee of <i className="fas fa-rupee-sign"></i>{selectedProfile.refundAmount.toFixed(2)}
                                                            </div>
                                                            <div className="cancel-info">
                                                                According to the cancellation policy
                                                            </div>
                                                        </div> : null}
                                                    {selectedProfile.status === BookingConfig.CanceledByVendor ?
                                                        <div className="col-12 p-0 booking-details-header">
                                                            The appointment has been cancelled by you
                                                            <div className="cancel-info">
                                                                You are eligible for a service fee of <i className="fas fa-rupee-sign"></i>{selectedProfile.refundAmount.toFixed(2)}
                                                            </div>
                                                            <div className="cancel-info">
                                                                According to the cancellation policy
                                                            </div>
                                                        </div> : null}
                                                    {selectedProfile.status === BookingConfig.Timedout ?
                                                        <div className="col-12 p-0 booking-details-header">
                                                            The appointment has been timed out
                                                            <div className="cancel-info">
                                                                You are eligible for a service fee of <i className="fas fa-rupee-sign"></i>{selectedProfile.refundAmount.toFixed(2)}
                                                            </div>
                                                            <div className="cancel-info">
                                                                According to the cancellation policy
                                                            </div>
                                                        </div> : null}
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className="text-center well col-12 color-black pt-3">
                        loading...
                    </div>}
            </div>
        </MuiPickersUtilsProvider>
    )
};

export default injectIntl(
    connect(
        null,
        auth.actions
    )(Details)
);