import React, { useEffect } from 'react';
import "./forum.css";
import { useState } from 'react';
import { REQUIRED_ERROR, API_ERROR } from "../../config/error-message";
import { updateAppointmentMeetingDetails } from "../../crud/booking.crud";
import { toast } from 'react-toastify';
import { API_SUCCESS } from '../../config/success-message';

const MeetingDetailsModal = (props) => {
    const [meetingDetails, updateMeetingDetails] = useState({
        platform: "",
        link: ""
    });
    const [meetingDetailsError, updateMeetingDetailsError] = useState({
        platform: "",
        link: ""
    });
    const [disablePage, toggleDisablePage] = useState(false);


    const closeModal = () => {
        props.closeMeetingModal(false);
    };

    const changeMeetingDetails = (text, fieldName) => {
        const localMeeting = { ...meetingDetails };
        const localMeetingError = { ...meetingDetailsError };
        localMeeting[fieldName] = text;
        localMeetingError[fieldName] = "";
        updateMeetingDetails({ ...localMeeting });
        updateMeetingDetailsError({ ...localMeetingError });
    };

    const validateMeeting = () => {
        let noError = true;
        const localMeetingError = { ...meetingDetailsError };

        for (let item in localMeetingError) {
            if (!meetingDetails[item].toString().trim().length) {
                localMeetingError[item] = REQUIRED_ERROR;
                noError = false;
            } else {
                localMeetingError[item] = "";
            }
        }
        updateMeetingDetailsError({ ...localMeetingError });
        return noError;
    };

    const saveMeetingDetails = async () => {
        if (!validateMeeting()) {
            return;
        }

        const requestBody = {...meetingDetails};

        if (!requestBody.link.includes('http://') && !requestBody.link.includes('https://')) {
            requestBody.link = 'http://' + requestBody.link;
        }

        toggleDisablePage(true);
        const servicesResult = await updateAppointmentMeetingDetails(props.bookingDetails.bookingId, { ...requestBody });
        toggleDisablePage(false);
        if (servicesResult.data.errorCode) {
            toast.error(API_ERROR[servicesResult.data.errorCode]);
            return;
        }
        toast.success(API_SUCCESS.MEETING_DETAILS_UPDATED);
        props.closeMeetingModal(true);
    };

    useEffect(() => {
        if (props.displayModal) {
            updateMeetingDetails({
                platform: props.bookingDetails.meetingDetails.platform,
                link: props.bookingDetails.meetingDetails.link
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.displayModal]);

    const checkLink = () => {
        if (!meetingDetails.link.includes('http://') && !meetingDetails.link.includes('https://')) {
            window.open('http://' + meetingDetails.link);
        } else {
            window.open(meetingDetails.link);
        }
    };

    return (
        <div className={`row forum test-modal`} style={{ margin: 0 }}>
            <div className={`modal ask-question-modal fade ${disablePage ? "pointer-none" : ""} ${props.displayModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="col-12 pt-3 pb-3">
                                <div className='row m-0 '>
                                    <div className="col-12 category-arrows">
                                        <i className="fas fa-times close-modal pull-right mt-0" onClick={() => closeModal()}></i>
                                        <h5 className='pre-confirmation-header'>Meeting Details</h5>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Meeting Platform</label>
                                        <input type="text" className={`form-control ${meetingDetailsError.platform ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Zoom / Google Meet etc." value={meetingDetails.platform} onChange={e => changeMeetingDetails(e.target.value, "platform")} />
                                        {meetingDetailsError.platform ?
                                            <div className="input-error">{meetingDetailsError.platform}</div> : null}
                                    </div>
                                    <div className="col-12 mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Meeting Link</label>
                                        {meetingDetails.link && meetingDetails.link.trim().length ?
                                            <button type="button" class="btn btn-link p-0 pull-right" onClick={checkLink}>Check Link</button> : null}
                                        <input type="text" className={`form-control ${meetingDetailsError.link ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Meeting Link" value={meetingDetails.link} onChange={e => changeMeetingDetails(e.target.value, "link")} />
                                        {meetingDetailsError.link ?
                                            <div className="input-error">{meetingDetailsError.link}</div> : null}
                                    </div>
                                    <div className="col-12 text-right mt-3 modal-buttons">
                                        <button type="button" className="btn btn-primary" onClick={saveMeetingDetails}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MeetingDetailsModal;