import React from 'react';
import "./forum.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { monthArr } from '../../config/constants';

const defaultImage = require('../../../images/imgAvtr.png');

const ParticipantsModal = (props) => {

    const closeModal = () => {
        props.toggleModal(false);
    };

    const getDisplayedFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const year = date.getFullYear();
        return day + ' ' + monthArr[date.getMonth()] + ', ' + year;
    };

    return (
        <div className={`row forum test-modal`} style={{ margin: 0 }}>
            <div className={`modal ask-question-modal fade ${props.displayModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="col-12 pt-3 pb-3">
                                <div className='row m-0 '>
                                    <div className="col-12 category-arrows p-0">
                                        <i className="fas fa-times close-modal pull-right mt-0" onClick={() => closeModal()}></i>
                                        <h5 className='pre-confirmation-header'>Participants List</h5>
                                        <Card className={`test-category-item`}>
                                                <CardContent style={{
                                                    paddingBottom: "16px"
                                                }}>
                                                    <div style={{
                                                        color: "#248af2",
                                                        fontWeight: 600,
                                                        fontSize: "16px",
                                                        textAlign: "center"
                                                    }}>
                                                        Total users {props.participantList.length}
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        <div className='row m-0 scrollable-modal' style={{padding: "7px"}}>
                                            {props.participantList.length ? <>

                                                {props.participantList.map((participant, index) => {
                                                    return (<Card key={index} className={`test-category-item`}>
                                                        <CardContent style={{
                                                            padding: "10px"
                                                        }}>
                                                            <div>
                                                                {participant.customerRef.personalInfo.profilePicture ?
                                                                    <div style={{ backgroundImage: "url(" + participant.customerRef.personalInfo.profilePicture + ")" }} className="background-image-professional" /> :
                                                                    <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional" />}
                                                                <p style={{
                                                                    fontSize: 14,
                                                                    margin: 0,
                                                                    display: "inline-block",
                                                                    verticalAlign: "top",
                                                                    marginLeft: "15px"
                                                                }}
                                                                >
                                                                    <div style={{
                                                                        fontWeight: 600,
                                                                        margin: 0,
                                                                        paddingTop: 6,
                                                                    }}>{participant.customerRef.personalInfo.name}</div>
                                                                    <div style={{
                                                                        color: "#248af2"
                                                                    }}>Date: {getDisplayedFullDateFormat(new Date(participant.createdAt))}</div>
                                                                    {/* <div>
                                                                        Fee <i className="fas fa-rupee-sign"></i>{participant.paymentDetails.total.toFixed(2)}
                                                                    </div> */}
                                                                    <div style={{
                                                                        color: "#83d894"
                                                                    }}>Status: Confirmed</div>
                                                                    {participant.bookingNumber ?
                                                                    <div style={{
                                                                        fontWeight: 400,
                                                                        margin: 0
                                                                    }}>Booking No.: {participant.bookingNumber}</div> : null}
                                                                </p>
                                                            </div>
                                                        </CardContent>
                                                    </Card>);
                                                })}
                                            </> : null}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ParticipantsModal;