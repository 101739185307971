import axios from "./axios.interceptor";
import baseUrl from "./api.util";

export const SERVICE = `${baseUrl}/account/vendor/service/`;
export const ORGANIZATIONS = `${baseUrl}/common/organisers`;
export const SESSION = `${baseUrl}/account/vendor/event/`;
export const EVENT = `${baseUrl}/account/vendor/online-event/`;
export const WORKSHOP = `${baseUrl}/account/vendor/workshop/`;
export const APPOINTMENT = `${baseUrl}/account/vendor/`;

const localDate = new Date(new Date(new Date(new Date(new Date().setHours(5)).setMinutes(30)).setSeconds(0)).setMilliseconds(0)).toISOString();

export const getServices = async () => {
  const res = await axios.get(`${SERVICE}?skip=0&limit=0`);
  return res;
}

export const addService = async (requestBody) => {
  const res = await axios.post(SERVICE, requestBody);
  return res;
}

export const getOrganizations = async () => {
  const res = await axios.get(`${ORGANIZATIONS}?skip=0&limit=0`);
  return res;
}

export const getLocationsForOrganization = async (id) => {
  const res = await axios.get(`${ORGANIZATIONS}/${id}/addresses`);
  return res;
}

export const updateService = async (requestBody) => {
  const res = await axios.put(`${SERVICE}/${requestBody._id}`, requestBody);
  return res;
}

export const removeService = async (id) => {
  const res = await axios.delete(`${SERVICE}/${id}`);
  return res;
}

export const getSessions = async (type) => {
  const res = await axios.post(`${SESSION}${type}?skip=0&limit=0`, { todaysDate: localDate });
  return res;
}

export const addSession = async (requestBody) => {
  const res = await axios.post(`${SESSION}create`, requestBody);
  return res;
}

export const updateSession = async (requestBody) => {
  const res = await axios.patch(`${SESSION}update/${requestBody._id}`, requestBody);
  return res;
}

export const removeSession = async (id) => {
  const res = await axios.delete(`${SESSION}delete/${id}`);
  return res;
}

export const uploadEventImage = async (imageFile) => {
  const formData = new FormData();
  formData.append('image', imageFile);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  const res = await axios.post(`${EVENT}upload-anynomus-image`, formData, config);
  return res;
}

export const addEvent = async (requestBody) => {
  const res = await axios.post(`${EVENT}create`, requestBody);
  return res;
}

export const getEvents = async (type) => {
  const res = await axios.post(`${EVENT}${type}?skip=0&limit=0`, { todaysDate: localDate });
  return res;
}

export const getEventDetails = async (id) => {
  const res = await axios.get(`${EVENT}details/${id}`);
  return res;
}

export const updateEvent = async (requestBody) => {
  const res = await axios.patch(`${EVENT}update/${requestBody._id}`, requestBody);
  return res;
}

export const removeEvent = async (id) => {
  const res = await axios.put(`${EVENT}cancel/${id}`);
  return res;
}

export const getWorkships = async (type) => {
  const res = await axios.post(`${WORKSHOP}${type}?skip=0&limit=0`, { todaysDate: localDate });
  return res;
}

export const getWorkshipDetails = async (id) => {
  const res = await axios.get(`${WORKSHOP}details/${id}`);
  return res;
}

export const addWorkshop = async (requestBody) => {
  const res = await axios.post(`${WORKSHOP}create`, requestBody);
  return res;
}

export const updateWorkshop = async (requestBody) => {
  const res = await axios.patch(`${WORKSHOP}update/${requestBody._id}`, requestBody);
  return res;
}

export const removeWorkshop = async (id) => {
  const res = await axios.delete(`${WORKSHOP}delete/${id}`);
  return res;
}

export const getWorkshopBookedUsers = async (id) => {
  const res = await axios.get(`${WORKSHOP}participant-list/${id}`);
  return res;
}

export const sendCertificateApi = async (requestBody) => {
  const res = await axios.post(`${WORKSHOP}send-completion-certificate`, requestBody);
  return res;
}

export const getEventParticipants = async (id) => {
  const res = await axios.get(`${EVENT}customer-list-for-event/${id}`);
  return res;
}

export const getWorkshopParticipants = async (id) => {
  const res = await axios.get(`${WORKSHOP}customer-list-for-workshop/${id}`);
  return res;
}

export const joinEventCall = async (id) => {
  const res = await axios.post(`${EVENT}join/${id}`);
  return res;
}

export const joinWorkshopCall = async (id) => {
  const res = await axios.post(`${WORKSHOP}join/${id}`);
  return res;
}

export const joinAppointmentCall = async (id) => {
  const res = await axios.post(`${APPOINTMENT}booking/join/${id}`);
  return res;
}