import React, { useEffect, useState, useCallback } from 'react';
import Calendar from 'react-calendar';
import "./service.css";
import 'react-calendar/dist/Calendar.css';
import { globalConfig } from '../../crud/auth.crud';
import {
    getBookings,
    cancelBook,
    blockSlotAPI,
    getSlots,
    unblockAllSlots,
    unblockIndividualSlot,
    getCalendar
} from "../../crud/booking.crud";
import { joinEventCall, joinWorkshopCall } from '../../crud/service.crud';
import {
    getProfile
} from "../../crud/profile.crud";
import { toast } from 'react-toastify';
import {
    API_ERROR,
    CANCEL_TIME_PASSED,
    INVALID_FROM_TO_DATE,
    CALL_TIME_PASSED
} from "../../config/error-message";
import { API_SUCCESS } from "../../config/success-message";
import { Form } from 'react-bootstrap';
import {
    timeArrConst,
    BookingConfig,
    BookingNumberStatus,
    SessionConfigNumber,
    VendorConfigStatus,
    monthArr
} from "../../config/constants";
import DateFnsUtils from '@date-io/date-fns';
import {
    TimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import CancelModal from "../../router/cancel-modal";
import UnblockModal from "../../router/unblock-modal";
import HolidayModal from "./holiday-modal";
import JoinEventCallConfirmationModal from "./join-event-confirmation-modal";
import JoinSessionModal from "../../router/join-session-modal";

const defaultImage = require('../../../images/imgAvtr.png');
const timeArr = [...timeArrConst];
const initialLocationError = {
    selectedFromDate: ""
};

let localGD = {};
let activeSlot = 0;

const Scheduler = (props) => {
    const [selectedDate, onSelectedDateChange] = useState();
    const [bookings, updateBookings] = useState([]);
    const [disablePage, toggleDisablePage] = useState(false);
    const [selectedFromDate, handleFromDateChange] = useState(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)));
    const [selectedToDate, handleToDateChange] = useState(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(30)).setSeconds(0)));
    const [locationDetailsError, updateLocationDetailsError] = useState({ ...initialLocationError });
    const [displaySlotModal, toggleSlotModal] = useState(false);
    const [loadingBookingList, toggleLoadingBookingList] = useState(true);
    const [displayCancelModal, toggleCancelModal] = useState(false);
    const [cancelableObject, updateCancelableObject] = useState({});
    const [displayHolidayModal, toggleHolidayModal] = useState(false);
    const [displayUnblockModal, toggleUnblockModal] = useState(false);
    const [unblockableObject, updateUnblockableObject] = useState({});
    const [selectedEvent, updateSelectedEvent] = useState({});
    const [selectedWorkshop, updateSelectedWorkshop] = useState({});
    const [displayConfirmationModal, openConfirmationModal] = useState(false);
    const [selectedProfile, selectProfile] = useState({});
    const [displayJoinWorkshopModal, toggleJoinWorkshopModal] = useState(false);
    const [currentTab, updateCurrentTab] = useState("Bookings");
    const [availableSlots, updateAvailableSlots] = useState([]);
    const [markAsHolidayChecked, toggleMarkAsHoliday] = useState(false);

    let currentMonth = -1;
    let currentYear = -1;

    const getTimestamp = (date) => {
        return new Date(new Date(new Date(new Date(new Date(date).setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
    };

    const getISOString = (date) => {
        return new Date(new Date(new Date(new Date(new Date(date).setHours(5)).setMinutes(30)).setSeconds(0)).setMilliseconds(0)).toISOString();
    };

    const getFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const month = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const getDisplayedFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const year = date.getFullYear();
        return day + ' ' + monthArr[date.getMonth()] + ', ' + year;
    };

    const getProfileDetails = async () => {
        toggleDisablePage(true);
        const profileData = await getProfile();
        toggleDisablePage(false);
        if (profileData.data.errorCode) {
            toast.error(API_ERROR[profileData.data.errorCode]);
            return;
        }
        if (profileData.data.data) {
            const localSelectedProfile = {};
            if (profileData.data.data.accountStatus === VendorConfigStatus.approved &&
                !profileData.data.data.emailVerified) {
                toast.error("Please verify your email");
                props.history.push('/dashboard');
                return;
            }
            if (profileData.data.data.accountStatus !== VendorConfigStatus.approved) {
                props.history.push('/onboarding');
            }
            localSelectedProfile.businessId = profileData.data.data.businessId ? profileData.data.data.businessId : "";

            selectProfile({ ...localSelectedProfile });

        }
    };

    const getStartTime = (times) => {
        times.sort((a, b) => { return a - b });
        return timeArr[times[0] - 1];
    };

    const getEndTime = (times) => {
        times.sort((a, b) => { return a - b });
        return timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0];
    };

    const checkCurrentDay = (date) => {
        const today = new Date(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
        const day = new Date(new Date(new Date(new Date(new Date(date).setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
        console.log("today ", today, day)
        return today === day;
    };

    const getTime = (timeStr, date) => {
        const time = timeStr.slice(0, timeStr.length - 3)
        const amPm = timeStr.slice(timeStr.length - 2)
        // console.log("time ", amPm, time)
        let d = new Date();
        if (date) {
            d = new Date(date);
        }
        if (amPm === "AM" && time.split(":")[0] === "12") {
            return new Date(new Date(new Date(new Date(d.setHours(0)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
        }
        if (amPm === "PM" && time.split(":")[0] !== "12") {
            return new Date(new Date(new Date(new Date(d.setHours(Number(time.split(":")[0]) + 12)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
        }
        return new Date(new Date(new Date(new Date(d.setHours(time.split(":")[0])).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
    };

    const formatAMPM = (date) => {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    };

    const checkJoiningCallTime = useCallback((localBookingData) => {
        console.log("in here ", localBookingData)

        if (checkCurrentDay(localBookingData.slotDate)) {
            const today = new Date().getTime();
            const startTime = getTime(timeArr[localBookingData.slots[0] - 1]);
            const endTime = getTime(timeArr[localBookingData.slots[localBookingData.slots.length - 1]]);
            console.log("start-------> ", startTime, endTime, today)
            return (startTime < today && endTime > today) && localBookingData.status === BookingConfig.Confirmed;
        }
        return false;
    }, []);

    const checkCancelTime = useCallback((localBookingData) => {
        const today = new Date().getTime();
        const startTime = getTime(timeArr[localBookingData.slots[0] - 1], localBookingData.slotDate);
        const lessThirtyStartTime = new Date(startTime).setMinutes(new Date(startTime).getMinutes() - 30);
        console.log("start-------> ", lessThirtyStartTime, today)
        return (lessThirtyStartTime > today) && localBookingData.status === BookingConfig.Confirmed;
    }, []);

    const checkCompleteTime = useCallback((localBookingData) => {
        const today = new Date().getTime();
        const startTime = getTime(timeArr[localBookingData.slots[0] - 1], localBookingData.slotDate);
        console.log("start-------> ", startTime, today)
        return (startTime < today) && localBookingData.status === BookingConfig.Confirmed;
    }, []);

    const checkCurrentDayForWorkshop = (dates) => {
        const today = new Date(new Date(new Date(new Date(new Date().setHours(5)).setMinutes(30)).setSeconds(0)).setMilliseconds(0)).toISOString();

        if (dates && dates.length) {
            return dates.includes(today);
        } else {
            return false;
        }
    };

    const checkEventJoiningCallTime = useCallback((localBookingData) => {
        if (checkCurrentDay(localBookingData.date)) {
            const today = new Date().getTime();
            const startTime = getTime(timeArr[localBookingData.slots[0] - 1]);
            const endTime = getTime(timeArr[localBookingData.slots[localBookingData.slots.length - 1]]);
            console.log("start-------> ", startTime, endTime, today)
            return (startTime <= today && endTime > today) && localBookingData.status === SessionConfigNumber.Confirmed;
        }
        return false;
    }, []);

    const checkWorkshopJoiningCallTime = useCallback((localBookingData) => {
        if (checkCurrentDayForWorkshop(localBookingData.dates)) {
            const today = new Date().getTime();
            const startTime = getTime(timeArr[localBookingData.slots[0] - 1]);
            const endTime = getTime(timeArr[localBookingData.slots[localBookingData.slots.length - 1]]);
            console.log("start-------> ", startTime, endTime, today)
            return (startTime <= today && endTime > today) && localBookingData.status === SessionConfigNumber.Confirmed;
        }
        return false;
    }, []);

    const getDataForCalendar = async (chosenDate) => {
        const chosenMonth = new Date(chosenDate).getMonth();
        const chosenYear = new Date(chosenDate).getFullYear();

        if (currentMonth !== chosenMonth || currentYear !== chosenYear) {
            currentMonth = chosenMonth;
            currentYear = chosenYear;

            const now = new Date(chosenDate);

            const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);

            const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

            const reqBody = {
                start: getISOString(firstDay),
                end: getISOString(lastDay)
            }

            console.log("reqBody--> ", reqBody)

            // return;

            toggleDisablePage(true);
            const bookingResult = await getCalendar(reqBody);
            console.log("bookingResult--> ", bookingResult);

            toggleDisablePage(false);
            if (bookingResult.data.errorCode) {
                toast.error(API_ERROR[bookingResult.data.errorCode]);
                return;
            }
            if (bookingResult.data.data && bookingResult.data.data.errorCode) {
                toast.error(API_ERROR[bookingResult.data.data.errorCode]);
                return;
            }

            const allLocalEvents = [];
            // updateAllEvents([...bookingResult.data.data]);
            if (bookingResult.data.data.length) {
                bookingResult.data.data.forEach(each => {
                    if (!allLocalEvents.includes(new Date(each.date).getDate().toString())) {
                        allLocalEvents.push(new Date(each.date).getDate().toString());
                    }
                });
            }

            console.log("allLocalEvents ", allLocalEvents);

            const allDateButtons = document.getElementsByClassName("react-calendar__tile");
            let eachButton;
            let newDiv = document.createElement('div');
            newDiv.style.width = "8px";
            newDiv.style.height = "8px";
            newDiv.style.position = "absolute";
            newDiv.style.top = "-5px";
            newDiv.style.right = "-5px";
            newDiv.style.borderRadius = "50%";
            newDiv.style.backgroundColor = "rgb(0,169,255)";

            if (allDateButtons.length) {
                allDateButtons.forEach(each => {
                    eachButton = each;

                    if (each.innerText.length === 1) {
                        eachButton.getElementsByTagName("abbr")[0].style.padding = "7px 11px";
                    }

                    if (eachButton.classList
                        && !eachButton.classList.contains("react-calendar__month-view__days__day--neighboringMonth")
                        && allLocalEvents.includes(eachButton.innerText)) {
                        eachButton.getElementsByTagName("abbr")[0].style.position = "relative";
                        eachButton.getElementsByTagName("abbr")[0].append(newDiv.cloneNode(true));
                    }
                })
            }
        }

    };

    const getAllBookings = useCallback(async (date, isInitial) => {
        toggleDisablePage(true);
        toggleLoadingBookingList(true);
        const bookingResult = await getBookings(date);
        console.log("bookingResult ", bookingResult);

        toggleDisablePage(false);
        toggleLoadingBookingList(false);
        if (bookingResult.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.errorCode]);
            return;
        }
        if (bookingResult.data.data && bookingResult.data.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.data.errorCode]);
            return;
        }

        const localBookings = [];
        let bookingObj = {};
        if (bookingResult.data.data && bookingResult.data.data.length) {
            bookingResult.data.data.forEach(each => {

                if (each.type === 1) {
                    bookingObj = {};
                    bookingObj._id = each._id;
                    if (each.bookingForType === 1) {
                        bookingObj.name = each.customerRef.personalInfo.name;
                        bookingObj.profilePicture = each.customerRef.personalInfo.profilePicture;
                    } else {
                        bookingObj.name = each.bookingForName;
                        bookingObj.profilePicture = "";
                    }
                    if (each.serviceRef) {
                        bookingObj.className = each.serviceRef.title;
                    }
                    bookingObj.selected = false;
                    bookingObj.date = getFullDateFormat(new Date(each.date));
                    bookingObj.slotDate = each.date;
                    bookingObj.startTime = getStartTime(each.slots);
                    bookingObj.endTime = getEndTime(each.slots);
                    bookingObj.convenienceFee = each.paymentDetails.convenienceFee;
                    bookingObj.gst = each.paymentDetails.gst;
                    bookingObj.total = each.paymentDetails.total;
                    bookingObj.cost = each.paymentDetails.serviceFee;
                    bookingObj.status = each.status;
                    bookingObj.slots = each.slots;
                    bookingObj.isCancelable = checkCancelTime(bookingObj);
                    bookingObj.isCallJoinable = checkJoiningCallTime(bookingObj);
                    bookingObj.isCompletable = checkCompleteTime(bookingObj);
                    bookingObj.refundAmount = each.transactionDetails && each.transactionDetails.vendorDue ? each.transactionDetails.vendorDue : 0;
                    bookingObj.type = 1;
                    bookingObj.appointmentBookingType = each.appointmentBookingType || 1;
                    bookingObj.statusColor = each.status === 3 ? "#00A365" : (each.status !== 1 && each.status !== 2) ? "#E52E40" : "#185bdb";

                    localBookings.push({ ...bookingObj });
                } else {
                    bookingObj = {};
                    bookingObj._id = each._id;
                    bookingObj.name = each.slots.length === timeArr.length - 1 ? 'Marked as Holiday' : each.type === 2 ? 'Slot blocked for other reason' : each.type === 3 ? 'Slot blocked for session' : each.eventRef ? each.eventRef.title : each.workshopRef ? each.workshopRef.title : 'Slot blocked for Workshop';
                    bookingObj.selected = false;
                    bookingObj.startTime = getStartTime(each.slots);
                    bookingObj.endTime = getEndTime(each.slots);
                    bookingObj.slots = each.slots;
                    bookingObj.type = each.type;

                    if (each.type === 4 && each.eventRef) {
                        bookingObj.date = each.eventRef.date;
                        bookingObj.availableSeats = each.eventRef.availableSeats;
                        bookingObj.numberOfSeats = each.eventRef.numberOfSeats;
                        bookingObj.description = each.eventRef.description;
                        bookingObj.eventFees = each.eventRef.eventFees;
                        bookingObj.eventLink = each.eventRef.eventLink;
                        bookingObj.status = each.status;
                        bookingObj.isCallJoinable = checkEventJoiningCallTime(bookingObj);
                    }

                    if (each.type === 5 && each.workshopRef) {
                        bookingObj.dates = each.workshopRef.dates;
                        bookingObj.availableSeats = each.workshopRef.availableSeats;
                        bookingObj.numberOfSeats = each.workshopRef.numberOfSeats;
                        bookingObj.description = each.workshopRef.description;
                        bookingObj.eventFees = each.workshopRef.fees;
                        bookingObj.meetingLink = each.workshopRef.meetingLink;
                        bookingObj.meetingPlatform = each.workshopRef.meetingPlatform;
                        bookingObj.status = each.status;
                        bookingObj.isCallJoinable = checkWorkshopJoiningCallTime(bookingObj);
                    }

                    if (bookingObj.type !== 2) {
                        localBookings.push({ ...bookingObj });
                    }
                }

            });
        }
        if (isInitial) {
            if (!localBookings.length) {
                updateCurrentTab("Slots");
            } else {
                updateCurrentTab("Bookings");
            }
        }

        updateBookings([...localBookings]);
    }, [checkCancelTime, checkJoiningCallTime, checkCompleteTime, checkEventJoiningCallTime, checkWorkshopJoiningCallTime]);

    useEffect(() => {
        console.log("val ", selectedDate)
        if (selectedDate) {
            getProfileDetails();
            getAllBookings(selectedDate, true);
            getAvailableSlots(selectedDate, true);
            getDataForCalendar(selectedDate);
            props.history.push(`/scheduler?date=${JSON.stringify(new Date(selectedDate))}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate, getAllBookings, props.history]);

    // const makeCircleDate = () => {
    //     const dateCount = document.getElementsByClassName("react-calendar__tile")
    //     dateCount.forEach(each => {
    //         if (each.innerText.length === 1) {
    //             each.getElementsByTagName("abbr")[0].style.padding = "7px 11px";
    //         }
    //     });
    // };

    // useEffect(() => {
    //     makeCircleDate();

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const dateParam = urlParams.get('date');
        console.log("in here----------", new Date(JSON.parse(dateParam)))
        if (dateParam) {
            props.history.push(`/scheduler?date=${JSON.stringify(new Date(dateParam))}`);
            onSelectedDateChange(new Date(JSON.parse(dateParam)));
        } else {
            props.history.push(`/scheduler?date=${JSON.stringify(new Date())}`);
            onSelectedDateChange(new Date());
        }

        // console.log("dateParam ", JSON.parse(dateParam))
    }, [props.history]);

    const toggleBookingDisplay = (index) => {
        const localBookings = [...bookings];
        props.history.push(`/booking-details/${localBookings[index]._id}`);
    };

    const cancelBooking = async () => {
        console.log("cancel")
        if (checkCancelTime(cancelableObject)) {
            // api call
            toggleDisablePage(true);
            const cancelBookingResult = await cancelBook(cancelableObject._id);
            toggleDisablePage(false);
            if (cancelBookingResult.data.errorCode) {
                toast.error(API_ERROR[cancelBookingResult.data.errorCode]);
                return;
            }
            if (cancelBookingResult.data.data && cancelBookingResult.data.data.errorCode) {
                toast.error(API_ERROR[cancelBookingResult.data.data.errorCode]);
                return;
            }
            console.log("cancelBookingResult ", cancelBookingResult);
            toast.success(API_SUCCESS.CANCEL_SUCCESS);
            getAllBookings(selectedDate);
            toggleCancelModal(false);
            updateCancelableObject({});
        } else {
            toast.error(CANCEL_TIME_PASSED);
        }
    };

    const validateTime = () => {
        const localLocationError = { ...locationDetailsError };
        console.log("locationDetails ", locationDetailsError)
        let noError = true;

        if (new Date(selectedFromDate) > new Date(selectedToDate)) {
            localLocationError.selectedFromDate = INVALID_FROM_TO_DATE;
            noError = false;
        } else {
            localLocationError.selectedFromDate = "";
        }

        updateLocationDetailsError({ ...localLocationError });
        return noError;
    };

    const updateDateError = () => {
        const localTimeError = { ...locationDetailsError };
        localTimeError.selectedFromDate = "";
        updateLocationDetailsError({ ...localTimeError });
    };

    useEffect(() => {
        if (!displaySlotModal) {
            handleFromDateChange(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)));
            handleToDateChange(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(30)).setSeconds(0)));
        }
    }, [displaySlotModal]);

    const blockSlot = async (isHoliday, slotIndex) => {
        if (!slotIndex && !validateTime()) {
            return;
        }
        let fromIndex = !isHoliday ? (timeArr.indexOf(formatAMPM(selectedFromDate)) + 1) : 1;
        let toIndex = !isHoliday ? (timeArr.indexOf(formatAMPM(selectedToDate))) : timeArr.length - 1;
        if (slotIndex) {
            fromIndex = slotIndex;
            toIndex = slotIndex;
        }
        const localWorkingTimeSlots = [];

        if (fromIndex === toIndex) {
            localWorkingTimeSlots.push(fromIndex);
        } else {
            for (let i = fromIndex; i <= toIndex; i++) {
                localWorkingTimeSlots.push(i);
            }
        }
        const requestBody = {
            slots: localWorkingTimeSlots,
            date: getISOString(selectedDate)
        }
        toggleDisablePage(true);
        console.log("requestBody ", requestBody);
        // return;
        const result = await blockSlotAPI(requestBody);
        toggleDisablePage(false);
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            if (isHoliday) {
                toggleMarkAsHoliday(true);
            }
            return;
        }

        if (!isHoliday) {
            toast.success(API_SUCCESS.SLOT_BLOCKED);
        } else {
            toast.success(API_SUCCESS.MARK_HOLIDAY);
        }
        getAvailableSlots();

        getAllBookings(selectedDate);
    };

    const openUnblockModal = (localBooking) => {
        updateUnblockableObject({ ...localBooking });
        toggleUnblockModal(true);
    };

    const unblockSlot = async (bookingObj, isIndividual) => {
        let localBooking = bookingObj ? { ...bookingObj } : { ...unblockableObject };
        let result;
        if (!isIndividual) {
            const localBlockIds = [];
            if (availableSlots.length) {
                availableSlots.forEach(each => {
                    if (each._id) {
                        localBlockIds.push(each._id);
                    }
                });
            }
            result = await unblockAllSlots({
                blockIds: localBlockIds
            });
        } else {
            result = await unblockIndividualSlot(localBooking._id, localBooking.slot);
        }
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            return;
        }
        toggleUnblockModal(false);
        toast.success(API_SUCCESS.SLOT_UNBLOCKED);
        updateUnblockableObject({});
        getAvailableSlots();

        getAllBookings(selectedDate);
    };

    const openJoinSessionModal = async (sessionData) => {
        if (!checkEventJoiningCallTime(sessionData)) {
            toast.error(CALL_TIME_PASSED);
            return;
        }
        updateSelectedEvent({ ...sessionData });
        openConfirmationModal(true);
        const resultData = await joinEventCall(sessionData._id);
        if (resultData.data.errorCode) {
            toast.error(API_ERROR[resultData.data.errorCode]);
            return;
        }
        if (resultData.data.data && resultData.data.data.errorCode) {
            toast.error(API_ERROR[resultData.data.data.errorCode]);
            return;
        }
    };

    const openJoinWorkshopModal = async (sessionData) => {
        if (!checkWorkshopJoiningCallTime(sessionData)) {
            toast.error(CALL_TIME_PASSED);
            return;
        }
        updateSelectedWorkshop({ ...sessionData });
        toggleJoinWorkshopModal(true);
        const resultData = await joinWorkshopCall(sessionData._id);
        if (resultData.data.errorCode) {
            toast.error(API_ERROR[resultData.data.errorCode]);
            return;
        }
        if (resultData.data.data && resultData.data.data.errorCode) {
            toast.error(API_ERROR[resultData.data.data.errorCode]);
            return;
        }
    };

    const updateSlotStatus = (slotData, index) => {
        const localAvailableSlots = [...availableSlots];
        localAvailableSlots[index].status = localAvailableSlots[index].status === 3 ? 2 : 3;
        if (localAvailableSlots[index].status === 2) {
            blockSlot(false, slotData.slot);
        } else {
            unblockSlot({ ...slotData }, true);
        }
        updateAvailableSlots([...localAvailableSlots]);
    };

    const updateMarkHoliday = () => {
        toggleMarkAsHoliday(!markAsHolidayChecked);
        if (markAsHolidayChecked) {
            blockSlot(true);
        } else {
            unblockSlot();
        }
    };

    const getAvailableSlots = async (clickedDate, isInitial) => {
        let currentSlot = 1;
        const localClickedDate = !clickedDate ? selectedDate : clickedDate;
        const currentDay = new Date(localClickedDate).getDay() !== 0 ? new Date(localClickedDate).getDay() : 7;
        for (let each = 0; each < timeArr.length; each++) {
            const minHour = convertTo24HrsFormat(timeArr[each].split(' ').join(''));
            const todayActualTime = new Date().getTime();
            const todayCheckingTime = new Date(new Date().setHours(Number(minHour.hours))).setMinutes(Number(minHour.mins));
            if (todayCheckingTime > todayActualTime) {
                currentSlot = each + 1;
                break;
            }
        };
        const allSlots = await getSlots({
            daysOfWeek: currentDay,
            date: getISOString(selectedDate)
        });

        console.log("allSlots ", allSlots)
        if (allSlots.data.errorCode) {
            toast.error(API_ERROR[allSlots.data.errorCode]);
            return;
        }
        if (isInitial) {
            const globalConfigResult = await globalConfig();
            if (globalConfigResult.data.data) {
                localGD = { ...globalConfigResult.data.data };
            }
        }

        if (allSlots.data.data) {
            const localSlots = [];
            let eachSlotItem = {};
            allSlots.data.data.forEach(each => {
                eachSlotItem = {
                    slot: each.slot,
                    status: each.type
                };
                if (getTimestamp(new Date()) === getTimestamp(selectedDate) && (currentSlot === 1 || each.slot < (currentSlot + (localGD.bufferSlot ? localGD.bufferSlot : 0)))) {
                    eachSlotItem.status = 1;
                    eachSlotItem.isToday = true;
                }
                if (getTimestamp(selectedDate) < getTimestamp(new Date())) {
                    eachSlotItem.status = 1;
                }
                if (each.bookingId) {
                    eachSlotItem._id = each.bookingId;
                }
                localSlots.push({ ...eachSlotItem });
            });
            let isHoliday = localSlots.length && localSlots.every(each => each.status === 2 || each.status === 1);

            if (getTimestamp(selectedDate) < getTimestamp(new Date()) || !localSlots.length) {
                isHoliday = true;
            }
            if (getTimestamp(selectedDate) === getTimestamp(new Date()) && localSlots.length && localSlots.every(each => each.isToday)) {
                isHoliday = true;
            }

            toggleMarkAsHoliday(!isHoliday);

            for (let item = 0; item < localSlots.length; item++) {
                if (localSlots[item].status !== 3) {
                    ++activeSlot;
                } else {
                    break;
                }
            }

            if (isHoliday) {
                activeSlot = 0;
            }
            setTimeout(() => {
                if (window.innerWidth > 768) {
                    ++activeSlot;
                }
                if (getTimestamp(selectedDate) === getTimestamp(new Date()) &&
                    document.getElementsByClassName("custom-scrollable")[0]) {
                    document.getElementsByClassName("custom-scrollable")[0].scrollTo(0, activeSlot * 55);
                }
            }, 100);

            updateAvailableSlots([...localSlots]);
        }

        console.log("currentDay ", currentDay, currentSlot)
    };

    const convertTo24HrsFormat = (time) => {
        const slicedTime = time.split(/(PM|AM)/gm)[0];

        let [hours, minutes] = slicedTime.split(':');

        if (hours === '12') {
            hours = '00';
        }

        let updateHourAndMin;

        function addition(hoursOrMin) {
            updateHourAndMin =
                hoursOrMin.length < 2
                    ? (hoursOrMin = `${0}${hoursOrMin}`)
                    : hoursOrMin;

            return updateHourAndMin;
        }

        if (time.endsWith('PM')) {
            hours = parseInt(hours, 10) + 12;
        }

        return {
            hours: addition(hours),
            mins: addition(minutes)
        };
    };

    const navigateToEventOrWorkshop = (navigationType) => {
        if (navigationType === 4) {
            props.history.push('/event');
        } else {
            props.history.push('/workshop');
        }
    };

    const onMonthChange = (calendarEventData) => {
        if (calendarEventData && calendarEventData.activeStartDate) {
            getDataForCalendar(calendarEventData.activeStartDate);
        }

    };

    return (
        <div className={`booking-list row problem-area scheduler centered-for-max ${disablePage ? "pointer-none" : ""}`}>
            <CancelModal displayCancelModal={displayCancelModal}
                toggleCancelModal={toggleCancelModal} cancelBooking={cancelBooking} />
            <UnblockModal displayUnblockModal={displayUnblockModal}
                toggleUnblockModal={toggleUnblockModal} unblockSlot={unblockSlot} getAvailableSlots={getAvailableSlots} />
            <HolidayModal displayHolidayModal={displayHolidayModal}
                toggleHolidayModal={toggleHolidayModal} toggleMarkAsHoliday={toggleMarkAsHoliday} markAsHoliday={blockSlot} />
            <JoinEventCallConfirmationModal
                displayConfirmationModal={displayConfirmationModal}
                openConfirmationModal={openConfirmationModal}
                selectedEvent={selectedEvent}
                selectedProfile={selectedProfile} />
            {displayJoinWorkshopModal ?
                <JoinSessionModal displayJoinSessionModal={displayJoinWorkshopModal}
                    toggleJoinSessionModal={toggleJoinWorkshopModal} callableSessionObject={selectedWorkshop} /> : null}
            <div className="col-md-5 col-12">
                <div className="col-12 categories-heading">
                    <h4 className={`mb-0`}>My Bookings</h4>
                </div>
                <div className="col-12 p-0">
                    <div className="row m-0">
                        <Calendar
                            onChange={onSelectedDateChange}
                            value={selectedDate}
                            onActiveStartDateChange={(value) => onMonthChange(value)}
                            showNeighboringMonth={false}
                        />
                    </div>
                </div>
            </div>
            <div className="col-md-7 col-12">
                <div className="col-12 categories-heading">
                    <h4 className={`mb-0`}>{getDisplayedFullDateFormat(new Date(selectedDate))}</h4>
                </div>
                <div className="col-12 p-0">
                    <div className="row" style={{ margin: 0 }}>
                        <Form className='w-100' style={{
                            backgroundColor: "#185BDB",
                            color: "#fff",
                            padding: "10px"
                        }}>
                            <b style={{ verticalAlign: "sub" }}>{!availableSlots.length ? 'This is a non working day' : !markAsHolidayChecked && getTimestamp(selectedDate) >= getTimestamp(new Date()) ? 'You have marked it as holiday' : 'Mark as holiday/Leave'}</b>
                            {currentTab === "Bookings" ?
                                <i className="fa fa-solid fa-arrow-right" style={{
                                    float: "right",
                                    marginRight: "5px",
                                    fontSize: "16px",
                                    marginTop: "3px",
                                    cursor: "pointer"
                                }}
                                    onClick={() => {
                                        updateCurrentTab("Slots")
                                    }} /> :
                                <Form.Check
                                    style={{
                                        float: "right",
                                        marginRight: "10px"
                                    }}
                                    className={`${getTimestamp(selectedDate) < getTimestamp(new Date()) || !availableSlots.length || (getTimestamp(selectedDate) === getTimestamp(new Date()) && availableSlots.length && availableSlots.every(each => each.isToday)) ? 'gray' : markAsHolidayChecked ? 'green' : 'orange'}`}
                                    disabled={getTimestamp(selectedDate) < getTimestamp(new Date()) || !availableSlots.length || (getTimestamp(selectedDate) === getTimestamp(new Date()) && availableSlots.length && availableSlots.every(each => each.isToday))}
                                    type="switch"
                                    id={`holiday`}
                                    label=""
                                    checked={markAsHolidayChecked}
                                    onChange={() => updateMarkHoliday()}
                                />}
                        </Form>
                        {/* {getTimestamp(selectedDate) >= getTimestamp(new Date()) ? */}
                        <div className="col-12 p-0">
                            <ul className="nav nav-tabs profile-menu-options mb-3">
                                <li className="nav-item" style={{ width: "50%", textAlign: "center" }} onClick={() => updateCurrentTab("Bookings")}>
                                    <span className={`nav-link ${currentTab === "Bookings" ? 'active' : ''}`}>My Bookings {bookings.length ? `(${bookings.length})` : ''}</span>
                                </li>
                                <li className="nav-item" style={{ width: "50%", textAlign: "center" }} onClick={() => updateCurrentTab("Slots")}>
                                    <span className={`nav-link ${currentTab === "Slots" ? 'active' : ''}`}>My Slots</span>
                                </li>
                            </ul>
                        </div>
                        {/* : null} */}
                        {currentTab === "Bookings" ?
                            bookings.length ?
                                bookings.map((booking, index) => {
                                    return <div className={`col-12 professional-wrapper ${index !== bookings.length - 1 ? 'mb-15' : ''}
                                `} key={index}>
                                        <div className="row service-row" style={{ margin: 0 }}>
                                            <div className="col-12 p-0">
                                                {booking.type === 1 ?
                                                    <div className="booking-profile-image" onClick={() => booking.type === 1 ? toggleBookingDisplay(index) : () => { }}>
                                                        {booking.profilePicture ?
                                                            <div style={{ backgroundImage: "url(" + booking.profilePicture + ")" }} className="background-image-professional" /> :
                                                            <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional" />}
                                                    </div> : null}
                                                <div className={`booking-details-wrapper ${booking.type !== 1 ? 'w-100' : ''}`} onClick={booking.type === 1 ? () => toggleBookingDisplay(index) : booking.type === 4 || booking.type === 5 ? () => navigateToEventOrWorkshop(booking.type) : () => { }}>
                                                    <div className="professional-name customer-name">{booking.name}
                                                        {booking.type !== 2 ? null : <i className="fa fa-times" aria-hidden="true" onClick={() => openUnblockModal(booking)}></i>}
                                                    </div>
                                                    {(booking.type === 4 || booking.type === 5) && booking.description ?
                                                        <div className="col-12 pl-0" style={{ margin: "5px 0" }}>
                                                            <div className="service-description">{booking.description}</div>
                                                        </div>
                                                        : null}
                                                    {booking.type === 1 ?
                                                        <div className="professional-location">{booking.className}</div> : null}
                                                    <div className="professional-time">{booking.startTime} - {booking.endTime}
                                                    </div>
                                                    {booking.type === 1 ?
                                                        <>
                                                            <div className="service-hour" style={{
                                                                color: booking.statusColor
                                                            }}>Status: {BookingNumberStatus[booking.status]}
                                                            </div>
                                                            <div className="professional-time">{booking.appointmentBookingType === 1 ? "Online Appointment" : "Offline Appointment"}</div>
                                                        </> : null}
                                                    {(booking.type === 4 || booking.type === 5) && booking.numberOfSeats ?
                                                        <div className="w-100 col-12 p-0">
                                                            <div className="fee-text seat-count bor-right mt-3 pl-0">Total Seats: {booking.numberOfSeats}</div>
                                                            <div className="fee-text seat-count mt-3">Available Seats: {booking.availableSeats}</div>
                                                        </div> : null}
                                                    {(booking.type === 4 || booking.type === 5) && booking.eventFees ?
                                                        <div className="w-100 col-12 p-0">
                                                            <div className="mt-3 pl-0" style={{ display: "inline-block" }}><b>Service Fee: <i className="fas fa-rupee-sign"></i>{booking.eventFees.toFixed(2)}</b>
                                                                <div className="professional-time">{booking.type === 4 ? "Online Event" : "Online Workshop"}</div></div>

                                                            {booking.isCallJoinable ?
                                                                <button className="btn btn-primary details-button float-right" onClick={booking.type === 4 ? (e) => { e.stopPropagation(); openJoinSessionModal(booking) } : (e) => { e.stopPropagation(); openJoinWorkshopModal(booking) }}>Join</button>
                                                                : null}
                                                        </div> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                                : <div className="text-center well col-12 color-black">
                                    {!loadingBookingList ? 'No booking found!' : 'loading...'}
                                </div> : null}
                        {currentTab === "Slots" ?
                            availableSlots.length ?
                                <Form className='col-12 p-0'>
                                    <div className='row ml-0 mr-0 mb-3'>
                                        <div className='col-8 pl-0 professional-time'>Start Time - End Time</div>
                                        <div className='col-4 text-right professional-time'>Status</div>
                                    </div>
                                    <div className='custom-scrollable'>
                                        {availableSlots.map((slotData, index) => {
                                            return <div className={`row ml-0 mr-0 time-slot-row ${slotData.status === 1 ? 'slot-disabled' : ''}`} key={`slot-${index}`}>
                                                <div className='col-8 pl-0'>
                                                    <span style={{
                                                        verticalAlign: "sub"
                                                    }}>
                                                        {timeArr[slotData.slot - 1]} - {timeArr[slotData.slot] ? timeArr[slotData.slot] : timeArr[0]}
                                                    </span>
                                                </div>
                                                <div className='col-4 text-right'><Form.Check
                                                    type="switch"
                                                    className={`${slotData.status === 3 ? 'green' : slotData.status === 2 ? 'orange' : (slotData.status === 1 && !slotData.isToday && getTimestamp(selectedDate) >= getTimestamp(new Date())) ? 'blue' : 'gray'}`}
                                                    id={`slot-${index}`}
                                                    disabled={slotData.status === 1}
                                                    label=""
                                                    checked={slotData.status === 3}
                                                    onChange={() => updateSlotStatus(slotData, index)}
                                                /></div>
                                            </div>
                                        })}
                                    </div>
                                </Form>
                                :
                                <div className="text-center well col-12 color-black">
                                    {'You marked it as a non working day. You can change it anytime from the address manager'}
                                </div>
                            : null}
                    </div>
                </div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className={`modal login-modal ask-question-modal fade ${displaySlotModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Block Slots</h5>
                                </div>
                                <div className="modal-body">
                                    <div className="row" style={{ margin: 0 }}>
                                        <div className="col-12">
                                            <div className="row" style={{ margin: 0 }}>
                                                <div className="col-6 pl-0 mb-3 custom-time-picker-design">
                                                    <label for="exampleFormControlInput1" className="form-label">Time From</label><br />
                                                    <TimePicker value={selectedFromDate} className={`${locationDetailsError.selectedFromDate ? 'timepicker-error-border' : ''}`} onChange={handleFromDateChange} minutesStep={30} onClose={updateDateError} />
                                                    {locationDetailsError.selectedFromDate ?
                                                        <div className="input-error">{locationDetailsError.selectedFromDate}</div> : null}
                                                </div>
                                                <div className="col-6 pr-0 mb-3 custom-time-picker-design">
                                                    <label for="exampleFormControlInput1" className="form-label">Time To</label><br />
                                                    <TimePicker value={selectedToDate} className={`${locationDetailsError.selectedFromDate ? 'timepicker-error-border' : ''}`} onChange={handleToDateChange} onClose={updateDateError} minutesStep={30} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 login-button mt-2">
                                        <button type="button" className="btn btn-primary" onClick={() => blockSlot(false)}>Submit</button>
                                        <button type="button" onClick={() => toggleSlotModal(false)} className="btn btn-link">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MuiPickersUtilsProvider>
            </div>
        </div>
    );
}

export default Scheduler;