import React, { useCallback, useEffect, useState } from 'react';
import "./forum.css";
import { toast } from 'react-toastify';
import {
    API_ERROR
} from "../../config/error-message";
import { getNotificationList, notificationMarkSeen } from "../../crud/profile.crud";
import { notificationTypesRedirectionURLMapObj } from "../../config/constants";

const monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const Notifications = (props) => {
    const [forumList, updateForumList] = useState([]);
    const [disablePage, toggleDisablePage] = useState(false);
    const [loadingQuestions, toggleLoadingQuestions] = useState(true);

    const getDisplayFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const month = monthArr[new Date(date).getMonth()];
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const formatAMPM = (date) => {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    };

    const getAllForumList = useCallback(async () => {
        toggleDisablePage(true);
        const forumResults = await getNotificationList();
        toggleDisablePage(false);
        toggleLoadingQuestions(false);
        if (forumResults.data.errorCode) {
            toast.error(API_ERROR[forumResults.data.errorCode]);
            return;
        }
        if (forumResults.data.data && forumResults.data.data.errorCode) {
            toast.error(API_ERROR[forumResults.data.data.errorCode]);
            return;
        }
        console.log("forumResults ", forumResults)
        if (forumResults.data.data && forumResults.data.data.data && forumResults.data.data.data.length) {
            let forumObj = {};
            const forumArr = [];
            forumResults.data.data.data.forEach(each => {
                forumObj = {};
                forumObj._id = each._id;
                forumObj.question = each.inAppContent.info.message;
                forumObj.selected = false;
                forumObj.isActionable = each.isActionable;
                forumObj.seen = each.seen;
                forumObj.notificationType = each.inAppContent.notificationType;
                forumObj.redirectionId = each.inAppContent.info.redirectionId;
                forumObj.normalDate = each.createdAt;
                forumObj.date = getDisplayFullDateFormat(new Date(each.createdAt));
                forumObj.time = formatAMPM(new Date(each.createdAt));
                forumArr.push({ ...forumObj });
            });
            updateForumList([...forumArr]);
        }
    }, []);

    useEffect(() => {
        getAllForumList();
    }, [getAllForumList]);

    const openPage = async (notificationData, ind) => {
        if (notificationData.isActionable) {
            const notificationsForList = ["appointmentBooked", "appointmentCanceledByCustomer", "bookingCanceledByAdmin", "linkReminderBeforeFifteenMinute", "linkReminderAterTenMinute", "linkReminderStartTime", "linkReminderAfterFifteenMinute"];

            if (notificationData.notificationType === "eventPayout" ||
                notificationData.notificationType === "workshopPayout" ||
                notificationData.notificationType === "vendorPayout") {
                    
                const today = new Date(new Date().setDate(15));
                console.log("notificationData ", today.setMonth(Number(notificationData.redirectionId.split(',')[0]) - 1))
                const changedMonth = new Date(today.setMonth(Number(notificationData.redirectionId.split(',')[0]) - 1));
                const changedYear = new Date(changedMonth.setFullYear(Number(notificationData.redirectionId.split(',')[1])));
                
                let url = `/revenue?date=${JSON.stringify(changedYear)}`;
                if (notificationData.notificationType === "eventPayout") {
                    url += `&eventId=${notificationData.redirectionId.split(',')[2]}`;
                } else if (notificationData.notificationType === "workshopPayout") {
                    url += `&workshopId=${notificationData.redirectionId.split(',')[2]}`;
                }
                props.history.push(url);
            } else {
                if (notificationTypesRedirectionURLMapObj[notificationData.notificationType]) {
                    if (notificationsForList.includes(notificationData.notificationType)) {
                        props.history.push(`${notificationTypesRedirectionURLMapObj[notificationData.notificationType]}${notificationData.redirectionId}`);
                    } else {
                        props.history.push(`${notificationTypesRedirectionURLMapObj[notificationData.notificationType]}`);
                    }
                }
            }
        }

        if (!notificationData.seen) {
            await notificationMarkSeen(notificationData._id);
            const localList = [...forumList];
            localList[ind].seen = true;
            updateForumList([...localList]);
        }
    };

    return (
        <div className={`row forum ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
            <div className="col-lg-12 pad-0-below-500">
                <div className="row" style={{ margin: 0 }}>
                    <div className={`col-md-7 col-xs-12 categories-heading`}>
                        <h4 className={`mb-0 page-heading`} style={{ borderRight: "0" }}>Notifications</h4>
                    </div>
                </div>

                <div className="col-12">
                    <div className="row" style={{ margin: 0 }}>
                        {forumList.length ?
                            forumList.map((professional, index) => {
                                return <div className={`col-12 professional-wrapper mb-3`} key={index}>
                                    <div className="row p-0" style={{ margin: 0, cursor: "pointer" }}>
                                        <div className="col-12 p-0" onClick={() => openPage(professional, index)}>
                                            <div style={{ display: "flex" }} className={`professional-designation professional-designation-not-selected p-0 notification-wrapper ${professional.seen ? 'seen-notification' : ''}`}>
                                                <div className={`notification-date-time-wrapper`}>
                                                    <div className="only-day">
                                                        {professional.date.split('-')[0]}
                                                    </div>
                                                    <div className="month-year-wrapper">
                                                        {professional.date.split('-')[1]} <br />
                                                        {professional.date.split('-')[2]}
                                                    </div>
                                                    <div className="time-wrapper">
                                                        {professional.time}
                                                    </div>

                                                </div>
                                                <div className="notification-text-wrapper">
                                                    {professional.question}
                                                </div>
                                                {!professional.seen ?
                                                    <img src={require('../../../images/notification-dot.png')} alt="notification unseen" className="unseen-dot" /> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                            : <div className="text-center well col-12 color-black">
                                {!loadingQuestions ? 'No Notifications Available!' : 'loading...'}
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notifications;