import React from "react";
import "../dashboard.css";
import * as auth from "../../../store/ducks/auth.duck";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

const BankDetails = (props) => {
    return <div className="col-md-6 bank-details-manager" style={{
        margin: "0 auto"
    }}>
        <div className="row" style={{ margin: 0 }}>
        <div className="col-12 p-0">
                <div className="row" style={{ margin: 0 }}>
                    <div className={`col-sm-8 col-xs-12 pt-2 pl-0 pr-0 ${!props.selectedProfile.bankAdded ? '' : 'pb-3'}`}>
                    <h5 className={`mb-0`}><i class="fas fa-arrow-left mr-2" style={{cursor: "pointer"}} onClick={() => { props.selectedProfile.status === props.VendorConfigStatus.approvalPending ||
                        props.selectedProfile.status === props.VendorConfigStatus.approvalRejected ? props.nextStep() : props.backStep(true)}}></i>Bank Details</h5>
                        
                    </div>
                    {props.selectedProfile.status !== props.VendorConfigStatus.approvalPending && props.selectedProfile.status !== props.VendorConfigStatus.approvalRejected ?
                    <div className={`col-sm-4 col-xs-12 pr-0 text-right ${!props.selectedProfile.bankAdded ? '' : 'mb-3'}`}>
                        <button type="button" className="btn btn-outline-primary btn-sm ml-2" onClick={() => { props.nextStep() }}
                        >{props.selectedProfile.bankAdded ? "Next" : "Skip"}</button>
                    </div> : null}
                    {!props.selectedProfile.bankAdded ? 
                    <div className={`col-12 pb-3 pl-0 pr-0`}>
                        <span style={{
                                fontSize: "11px",
                                fontWeight: 500
                            }}>*You can skip for now and upload the details later to receive your payment.</span>
                    </div> : null}
                </div>
            </div>
            <div className="col-12 p-0">
                <div className="row" style={{ margin: 0 }}>
                    <div className="col-sm-6 col-xs-12 pl-0 pt-2">
                        
                    </div>
                    <div className="col-sm-6 col-xs-12 pr-0 text-right mb-3">
                        <button type="button" className="btn btn-outline-primary btn-sm mr-2" onClick={() => { props.updateBankDetailsError({ ...props.initialBankDetailsError }); props.getProfileDetails("bank") }}>Reset</button>
                        <button type="button" className="btn btn-primary btn-sm" onClick={props.saveBankDetails}>Save</button>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-xs-12 left-padding mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Bank Name</label>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className="fas fa-university" /></div>
                    </div>
                <input type="text" className={`form-control ${props.bankDetailsError.bankName ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter Bank Name" value={props.selectedProfile.bankDetails.bankName} onChange={e => props.updateBankDetails(e.target.value, 'bankName')} disabled={props.isCheque} />
                </div>
                {props.bankDetailsError.bankName ?
                    <div className="input-error">{props.bankDetailsError.bankName}</div> : null}
            </div>
            <div className="col-md-6 col-xs-12 right-padding mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Account Holder Name</label>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className="fa fa-user" aria-hidden="true" /></div>
                    </div>
                    <input type="text" className={`form-control ${props.bankDetailsError.accountHolderName ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter Account Holder Name" value={props.selectedProfile.bankDetails.accountHolderName} onChange={e => props.updateBankDetails(e.target.value, 'accountHolderName')} disabled={props.isCheque} />
                </div>
                {props.bankDetailsError.accountHolderName ?
                    <div className="input-error">{props.bankDetailsError.accountHolderName}</div> : null}
            </div>

            <div className="col-md-6 col-xs-12 left-padding mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Account Number</label>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className="fas fa-university" /></div>
                    </div>
                    <input type="text" className={`form-control ${props.bankDetailsError.accountNumber ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter Account Number" value={props.selectedProfile.bankDetails.accountNumber} onChange={e => props.updateBankDetails(e.target.value.replace(/\D/, ''), 'accountNumber')} disabled={props.isCheque} />
                </div>
                {props.bankDetailsError.accountNumber ?
                    <div className="input-error">{props.bankDetailsError.accountNumber}</div> : null}
            </div>
            <div className="col-md-6 col-xs-12 right-padding mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">IFSC Code</label>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className="fas fa-university" /></div>
                    </div>
                <input type="text" className={`form-control ${props.bankDetailsError.ifscCode ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter IFSC Code" value={props.selectedProfile.bankDetails.ifscCode} onChange={e => props.updateBankDetails(e.target.value, 'ifscCode')} disabled={props.isCheque} />
                </div>
                {props.bankDetailsError.ifscCode ?
                    <div className="input-error">{props.bankDetailsError.ifscCode}</div> : null}
            </div>
            <div className="col-md-6 col-xs-12 left-padding mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Branch</label>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className="fas fa-university" /></div>
                    </div>
                <input type="text" className={`form-control ${props.bankDetailsError.branchName ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter Branch Name" value={props.selectedProfile.bankDetails.branchName} disabled={props.isCheque} onChange={e => props.updateBankDetails(e.target.value, 'branchName')} />
                </div>
                {props.bankDetailsError.branchName ?
                    <div className="input-error">{props.bankDetailsError.branchName}</div> : null}
            </div>
            <div className="col-md-6 col-xs-12 right-padding mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">GST Number <span style={{
                    fontSize: "11px",
                    fontWeight: 500
                }}>(*Not mandatory)</span></label>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className="fas fa-university" /></div>
                    </div>
                <input type="text" className={`form-control`} id="exampleFormControlInput1" placeholder="Enter GST Number" value={props.selectedProfile.bankDetails.gstNumber} disabled={props.isCheque} onChange={e => props.updateBankDetails(e.target.value, 'gstNumber')} />
                </div>
            </div>
            <div className="col-12 form-check right-padding text-right">
                <input className="form-check-input" type="checkbox" id="chequeOption" value="" checked={props.isCheque} onChange={() => props.changeChequeSelection(!props.isCheque)} />
                <label className="form-check-label" htmlFor="chequeOption">
                    Opt for cheque payment
                </label>
            </div>
            <div className="col-12 p-0 cheque-message mb-3">
                Note: Opting for cheque payments will invite a processing and handling fee of <i className="fas fa-rupee-sign"></i>250 per settlement.
            </div>
            {props.isCheque ? <>
            <div className="col-md-6 col-xs-12 left-padding mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Name</label>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className="fas fa-university" /></div>
                    </div>
                <input type="text" className={`form-control ${props.chequeDetailsError.chequeName ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Your name on the cheque" value={props.selectedProfile.bankDetails.chequeName} onChange={e => props.updateBankDetails(e.target.value, 'chequeName')} disabled={!props.isCheque} />
                </div>
                {props.chequeDetailsError.chequeName ?
                    <div className="input-error">{props.chequeDetailsError.chequeName}</div> : null}
            </div>
            <div className="col-md-6 col-xs-12 right-padding mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Address</label>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className="fas fa-university" /></div>
                    </div>
                <input type="text" className={`form-control ${props.chequeDetailsError.chequeAddress ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Cheque delivery address" value={props.selectedProfile.bankDetails.chequeAddress} onChange={e => props.updateBankDetails(e.target.value, 'chequeAddress')} disabled={!props.isCheque} />
                </div>
                {props.chequeDetailsError.chequeAddress ?
                    <div className="input-error">{props.chequeDetailsError.chequeAddress}</div> : null}
            </div>
            </> : null}
        </div>
    </div>

};

const mapStateToProps = state => {
    return { data: state };
};

export default injectIntl(
    connect(
        mapStateToProps,
        auth.actions
    )(BankDetails)
);