import React from 'react';
import "./service.css";

const ProblemArea = (props) => {
    return (
        <div className="row problem-area" style={{ margin: 0 }}>

            <div className={`modal login-modal ask-question-modal fade ${props.displayRemoveModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{props.removeType === 'Event' || props.removeType === 'Workshop' ? 'Cancel': 'Remove'} {props.removeType}</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row" style={{ margin: 0 }}>
                                <h5 className="col-12 p-0 mb-3" style={{ color: "#000" }}>
                                    Are you sure you want to {props.removeType === 'Event' || props.removeType === 'Workshop' ? 'cancel': 'remove'} the {props.removeType ? props.removeType.toLowerCase() : ""}?
                                </h5>
                            </div>

                            <div className="col-12 p-0 text-right mt-3">
                                <button type="button" className="btn btn-outline-primary mr-2" onClick={() => props.openRemoveModal(false)}>Close</button>
                                <button type="button" className="btn btn-danger" onClick={props.removeData}>{props.removeType === 'Event' || props.removeType === 'Workshop' ? 'Cancel': 'Remove'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProblemArea;