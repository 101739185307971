import React from 'react';
import "./forum.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useState } from 'react';

const TestCategoryModal = (props) => {
    const [testDetails, updateTestDetails] = useState({});

    const getDisplayFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const month = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const openDetails = (testDataIndex) => {
        const localTestData = { ...props.tests[testDataIndex] };
        updateTestDetails({ ...localTestData });
        document.getElementsByClassName("scrollable-modal")[0].scrollTop = 0
    };

    const closeModal = () => {
        updateTestDetails({});
        props.toggleTestModal(false);
    };

    const backToList = () => {
        document.getElementsByClassName("scrollable-modal")[0].scrollTop = 0;
        updateTestDetails({});
    };

    return (
        <div className={`row forum test-modal`} style={{ margin: 0 }}>
            <div className={`modal ask-question-modal fade ${props.displayModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="col-12 pt-3 pb-3">
                                <div className='row m-0 '>
                                    <div className="col-12 category-arrows">
                                        <i className="fas fa-times close-modal pull-right mt-0" onClick={() => closeModal()}></i>
                                        <h5 className='pre-confirmation-header'>{Object.keys(testDetails).length ? <i className="fas fa-arrow-left mr-3" style={{cursor: 'pointer'}} onClick={backToList}></i> : null}{Object.keys(testDetails).length ? testDetails.testRef.title : 'Tests'}</h5>
                                        <div className='row m-0 scrollable-modal'>
                                            {!Object.keys(testDetails).length ?
                                                props.tests.map((category, index) => {
                                                    return (<Card key={index} className={`test-category-item`}>
                                                        <CardContent>
                                                            <div>
                                                                <p style={{ fontSize: 16, fontWeight: 600, margin: 0, paddingTop: 6 }}>{category.testRef.title}</p>
                                                                <p style={{ fontSize: 14, fontWeight: 600, margin: 0, marginTop: 8, marginBottom: 8 }}>For: {category.bookingForName}</p>
                                                                <p style={{ marginBottom: 0, position: "relative" }}>Score: {category.result.score}, Date: {getDisplayFullDateFormat(new Date(category.updatedAt))} <button style={{ position: "absolute", right: 0, bottom: 0 }} type="button" className="btn btn-primary reschedule-button test-result-list-modal-view-400" onClick={() => openDetails(index)}>View</button></p>
                                                                <div className='mt-3 text-center test-result-list-modal-view-above-400'>
                                                                <button style={{ }} type="button" className="btn btn-primary reschedule-button" onClick={() => openDetails(index)}>View</button>
                                                                </div>
                                                            </div>

                                                        </CardContent>
                                                    </Card>);
                                                }) : testDetails.questionSetType === 1 ? testDetails.questionSet.questions.map((question, ind) => {
                                                    return <Card key={ind} className={`test-category-item`}>
                                                        <CardContent>
                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                                                <div>
                                                                    <p style={{ fontSize: 14, fontWeight: 600, margin: 0, marginTop: 8, marginBottom: 8 }}>{question.title}</p>
                                                                    {question.options && question.options.length && question.selectedAnswer ?
                                                                        <p style={{ marginBottom: 0 }}>{question.options[question.selectedAnswer.optionIndex].title}</p> : null}
                                                                </div>
                                                            </div>

                                                        </CardContent>
                                                    </Card>
                                                }) : 
                                                testDetails.questionSetType2.questionStory.map((question, ind) => {
                                                    return <Card key={ind} className={`test-category-item`}>
                                                        <CardContent>
                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                                                <div>
                                                                    <p style={{ fontSize: 14, fontWeight: 600, margin: 0, marginTop: 8, marginBottom: 8 }}>{question.title}</p>
                                                                    <p className='profile-amount mt-0 text-center'>{question.contents.join(", ")}</p>
                                                                    {question.questions && question.questions.length ?
                                                                        question.questions.map((qsn, index1) => {
                                                                            return <>
                                                                            <p key={`sub-${index1}`} style={{ fontSize: 14, fontWeight: 'bold', margin: 0, marginTop: 8, marginBottom: 8 }}>{qsn.title}</p>
                                                                            <p key={`sub-${index1}`} style={{ fontSize: 14, fontWeight: 400, margin: 0, marginTop: 8, marginBottom: 8 }}>{qsn.options[qsn.selectedAnswer.optionIndex].title}</p>
                                                                            <hr style={{
                                                                                backgroundColor: "#ccc",
                                                                                margin: 0
                                                                            }} />
                                                                            </>
                                                                        })
                                                                         : null}
                                                                </div>
                                                            </div>

                                                        </CardContent>
                                                    </Card>
                                                })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TestCategoryModal;