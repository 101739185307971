import React, { useEffect, useState } from 'react';
import OtherHeader from './other-page-header';
import './dashboard.css';

const PrivacyPolicy = () => {
    const [pageInitialized, updatePageInitialization] = useState(false);
    useEffect(() => {
        if (!pageInitialized) {
            const el = document.getElementsByClassName("custom-navigation-bar")[0];
            const el1 = document.getElementsByClassName("footer")[0];
            if (el) {
                el.style.display = "none";
            }
            if (el1) {
                el1.style.display = "none";
            }
            updatePageInitialization(true);
        }
    }, [pageInitialized]);
    return <>
        <OtherHeader header="Privacy Policy" />
        <section
            class="elementor-section elementor-top-section elementor-element elementor-element-3ae160a9 elementor-section-boxed elementor-section-height-default elementor-section-height-default other-page-wrapper"
            data-id="3ae160a9" data-element_type="section">
            <h3>PRIVACY POLICY</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>This privacy policy (“Privacy Policy”) governs the collection, use, disclosure, transfer or
                otherwise processing of personal data or sensitive personal data by Parentcraft India Private
                Limited (“we”, “us” or “Parentcraft India”). Parentcraft India is the sole owner and publisher of
                the website www.parentcraftindia.com (“Website”) and the mobile application referred to as the
                “Parentcraft Services” for professionals (“App”) (The Website and the App are hereinafter
                collectively referred to as “Platform”).</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>When the users of the services, including the Consultants/ Practitioner/ Professional/
                Establishment/ Business entity/ Service Providers/ Parents/ Guidance/ Caretakers of the
                Platform (jointly and severally referred to as “you” or “Users” in this Privacy Policy), use the
                Platform, purchase and/ or sell our services or interact with us (collectively “Interactions”), we
                may collect some personal information from you (as detailed below). We are committed to
                protecting the security of your personal information and safeguarding your privacy and this
                Privacy Policy sets out the manner in which we collect personal information from you and use
                this personal information that we have collected or received about you. Our processing of your
                personal data is needed for us to deliver the service to you. We may also process your personal
                data in order to comply with our legal obligations as explained below or due to our legitimate
                interests. By clicking on 'I Accept' or using the services or by otherwise giving us your
                information, you hereby be deemed to have read, understood and agreed to be bound by the
                terms of this Privacy Policy.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>You hereby consent to our collection, use and sharing, disclosure of your information as
                described in this Privacy Policy. We reserve the right to change, modify, add or delete portions
                of the terms of this Privacy Policy, at our sole discretion, at any time. If you do not agree with
                this Privacy Policy at any time, do not use any of the services or give us any of your information.
                You acknowledge that this Privacy Policy is a part of the Terms and Conditions of the Platform
                and the other services, and you unconditionally agree that becoming a User of the Platform
                signifies your (i) assent to this Privacy Policy, and (ii) consent to Parentcraft India using,
                collecting, processing and/or disclosing your Personal Data (defined hereinafter) in the manner
                and for the purposes set out in this Privacy Policy. Your visit to the Platform and use of the
                services is subject to this Privacy Policy and the Terms and Conditions.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>If you use the services on behalf of someone else, you represent that you are authorized by
                such individual or entity to (i) accept this Privacy Policy on such individual’s or entity’s behalf,
                and (ii) consent on behalf of such individual or entity to our collection, use and disclosure of
                such individual’s or entity’s information as described in this Privacy Policy.</p>

            <h3>COMPLIANCES UNDER PRIVACY POLICY</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>This Privacy Policy is published in compliance of the provisions of the Information Technology
                Act, 2000 read with Regulation 4 of the Information Technology (Reasonable Security Practices
                and Procedures and Sensitive Personal Information) Rules, 2011 (the “SPI Rules”) and
                Regulation 3(1) of the Information Technology (Intermediary Guidelines and Digital Media Ethics
                Code) Rules, 2021.</p>

            <h3>PERSONAL INFORMATION WE COLLECT</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Our services require us to know about you and your specific requirements so that we can best
                meet your needs. When you access the services, or through any Interaction with us via the
                Platform (including chat windows, emails, telephone calls or other correspondence), we may
                ask you to provide us with certain information that personally identifies you or could be used to
                personally identify you. You hereby consent to the collection of such information by Parentcraft
                India. Without prejudice to the generality of the above, information collected by us from you may
                include the below details in the following circumstances:</p>
            <ol>
                <li style={{
                    fontSize: "16px",
                    color: "#000",
                    marginBottom: "10px"
                }}>
                    <b>When you create an account: </b>
                    A User is abstained from using the Platform’s core
                    functionality without a registered account. In order to have access to all the features and
                    benefits on our Platform, a User must first create an account in our Platform. To create
                    an account, a User is required to provide the following information, which such User
                    recognizes and expressly acknowledges is Personal Data, allowing others including
                    Parentcraft India, to identify the User: phone number. A one-time password (OTP) would
                    be sent to the phone number used for signing up. In addition to this, you may fill in your
                    name, profile picture, professional category, total experience, website details, a brief
                    description, bank details, addresses/ locations where services are offered and gallery
                    (i.e., to upload (i) government photo ID; (ii) professional qualification; (iii) RCI certificate)
                    . Other information may be requested in the future, such as camera privacy, storage
                    access, call access, media access or information which are necessary when you have to
                    attend an event for which you have registered through the Platform and this also
                    includes the willingness to receive promotional offers from Parentcraft India (which is
                    optional), from the User to help Parentcraft India to customize the Platform to deliver
                    personalized information to the User. Additional information may be collected at the time
                    of offline verification.
                </li>
                <li style={{
                    fontSize: "16px",
                    color: "#000",
                    marginBottom: "10px"
                }}>
                    <b>When you fill the Appointment Service Form: </b>
                    Professionals are required to input the
                    type of service, service fee, service time, service type, location and a brief description.
                </li>
                <li style={{
                    fontSize: "16px",
                    color: "#000",
                    marginBottom: "10px"
                }}>
                    <b>When you create a Workshop: </b>
                    To create a workshop, you are required to enter the
                    title, price, number of seats, the date and time (from and to), the meeting platform,
                    meeting link and a description of the workshop.
                </li>
                <li style={{
                    fontSize: "16px",
                    color: "#000",
                    marginBottom: "10px"
                }}>
                    <b>When you create an Event: </b>
                    To add an event, you are required to enter the title, price,
                    number of seats, the date and time (from and to), and a description of the event. You
                    may also upload any pictures for such events by allowing permissions to access camera
                    and gallery along with event details for approval. Upon approval, The Users have to give
                    permission to Parentcraft India for accessing their device’s location for accessing
                    services from Google Map API and the data is stored in the Parentcraft servers. Other

                    general permissions and permissions before starting a meeting are access to storage,
                    camera, microphone, SMS, Telephone and there may be additional permissions for
                    using event features. Once a User opens any event, the view count increments and the
                    total views are visible to other Users and these preferences, clicks and views are stored
                    by Parentcraft India. Bank details of the vendors are recorded by the payment gateway
                    provider, i.e., Paytm (“Payment Gateway”) server after a confirmed seat booking for an
                    event. Further, any ratings or reviews given by the User shall be stored. Sharing data
                    logs are not stored in Parentcraft India’s server.
                </li>
                <li style={{
                    fontSize: "16px",
                    color: "#000",
                    marginBottom: "10px"
                }}>
                    <b>When you manage bookings/ block slots: </b>
                    You can block slots and/ or mark a day as
                    a holiday on the calendar. In the event there are any events, sessions or other services
                    planned, you are required to add the said information such that the same are visible on
                    the page as a blocked slot.
                </li>
                <li style={{
                    fontSize: "16px",
                    color: "#000",
                    marginBottom: "10px"
                }}>
                    <b>When you answer questions: </b>
                    if you answer questions on the My Q&A section, the
                    content posted by you would be stored by us and any other User may view your profile.
                </li>
                <li style={{
                    fontSize: "16px",
                    color: "#000",
                    marginBottom: "10px"
                }}>
                    <b>For betterment of the services: </b>
                    Treading your usage of the services, we may disclose
                    documents, choices, preferences, appointment schedules, vendor driven queries etc. for
                    the betterment of the services.
                </li>
                <li style={{
                    fontSize: "16px",
                    color: "#000",
                    marginBottom: "10px"
                }}>Other information that you voluntarily choose to provide to us. Any such other
                    information as requested by any of Parentcraft India’s employees or agents who contact
                    you in person or by telephone will also be collected and stored. In such an event,
                    Parentcraft India will maintain this information if and until you choose to register for an
                    account, for which Parentcraft India may contact you from time to time. Parentcraft India
                    will, after such information is collected from you, send you a confirmation email
                    confirming the information provided and the fact that you will be associated with the
                    Platform. In the event you do not wish to be associated with the Platform, please inform
                    Parentcraft India immediately at <a
                        href="mailto:support@parentcraftindia.com"><span
                            style={{ fontSize: "11pt", color: "#677294", backgroundColor: "transparent", fontVariantNumeric: "normal", fontVariantEastAsian: "normal", textDecorationLine: "underline", textDecorationSkipInk: "none", verticalAlign: "baseline", whiteSpace: "pre-wrap" }}>support@parentcraftindia.com</span></a >.</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000",
                    marginBottom: "10px"
                }}>The information collected from you by Parentcraft India may constitute ‘personal
                    information’ or ‘sensitive personal data or information’ under the SPI Rules (“Personal
                    Data”).</li>
            </ol>

            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>You hereby authorize Parentcraft India to use, collect and disclose information that is freely
                available in the public domain without your consent. Parentcraft India does not collect
                information about the visitors of the mobile application/ platform from other sources, such as
                public records or bodies, or private organizations, save and except for the purposes of
                registration of the 'Users' (the collection, use, storage and disclosure of which each User must
                agree to under the Terms and Conditions in order for Parentcraft India to effectively render the
                services).</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>You may choose not to provide the consent for collection of Personal Data by us. In such an
                event, we are not obligated to provide you with the services. If you wish to withdraw the consent
                that you have provided earlier to us for processing your Personal Data, you may send a written
                request to our Data Protection Officer (contact details below). We will have the option to stop
                providing you the services in such cases. We will retain your information for as long as your
                account with the services is active and as needed to provide you the services. We shall not
                retain such information for longer than is required for the purposes for which the information
                may lawfully be used or is otherwise required under any other law for the time being in force.
                After a period of time, your data may be anonymised and aggregated, and then may be held by
                us as long as necessary for us to provide our services effectively, but our use of the
                anonymised data will be solely for analytic purposes.</p>


            <h3>PURPOSE AND USE OF PERSONAL DATA</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Parentcraft India reserves the right to use information provided by or about the User for the
                following purposes:</p>
            <ol>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>Contacting Users for offering new products or services.</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>Contacting Users for taking product and service feedback.</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>Analyzing the App usage patterns for improving product design and utility.</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>Analyzing information for commercial use.</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>To enable you to set up your account and to avail services.</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>To process your payment, facilitate billing and issue invoices, as applicable.</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>To communicate with you and to respond to your requests.</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>To provide you with customer service and support.</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>For corporate account management purposes.</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>To help keep our website safe and secure and to improve the website.</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>We use information to analyze trends, administer the Platform and track user activities
                    on the Platform if you have consented to the same.</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>We will create anonymous data records from Personal Data by excluding information
                    (such as your name) that makes the data personally identifiable to you. We use such
                    Anonymous Data records to analyze request and usage patterns so that we may
                    enhance the content of the services and improve Platform navigation.</li>
            </ol>

            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>If you have voluntarily provided your Personal Information to Parentcraft India for any of the
                purposes stated above, you hereby consent to such collection and use of such information by
                Parentcraft India and consent and grant right to Parentcraft India to contact you on your
                telephone/mobile number(s) for any purpose even if such telephone/mobile number is
                registered with the Do Not Call registry (“DNC Registry”).</p>

            <h3>SHARING OF PERSONAL DATA</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Parentcraft India maintains a strict "No-Spam" policy. Personal Data provided by you, or that we
                may obtain automatically by your use of the Platform, is not and will not be sold, rented, or
                shared by us with any third party without your prior consent. We disclose your Personal Data as
                described below.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}><b>Storage of Personal Data:</b> Your Personal Data will be transmitted, uploaded, transferred,
                stored, or backed up at Parentcraft India’s servers with our cloud providers in India. You hereby
                consent to such transfer.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}><b>Third Party Service Providers:</b> We will share your Personal Data with third party companies
                and individuals that perform Services on our behalf to help us provide the Platform to you and
                you hereby consent to such sharing of your Personal Data. Examples of services that may be
                provided by third party service providers may include, but are not limited to, processing
                payments with our payment provider. For online payments, we use the payment services of
                Payment Gateway. Parentcraft India does not process, record or maintain your credit card or
                bank account information. Parentcraft India records the payment method you have chosen. You
                hereby consent to the processing, recording and maintenance of your credit card or bank
                account information, as applicable, by Payment Gateway.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>From time to time, we may engage with different third party service providers as and when the
                need occurs. Third party service providers are only provided with such Personal Data
                reasonably required to provide the particular service for which they are retained. Our third party
                service providers are obligated to keep all of your Personal Data confidential and to collect and
                use your Personal Data only to the extent necessary to provide the Services on our behalf.
                Parentcraft India encourages the User to read the privacy policies and terms of use of such third
                party service providers and Parentcraft India is not responsible for the policies and practices
                employed by the owners of such third party service providers, including but not limited to their
                collection, use and disclosure of your Personal Data, nor does Parentcraft India offer any (and
                expressly disclaims any) guarantee, representation, warranty, or covenant of any kind with
                respect to the collection, use or disclosure of your Personal Data by any third party service
                provider that is linked from (or is otherwise associated with) the Platform.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}><b>Third Party Links:</b> This Privacy Policy applies only to services that are owned and operated by
                Parentcraft India. Parentcraft India does not exercise control over the information collected by
                the Users. Parentcraft India is not responsible or liable for any breach of information provided by
                the Users.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Accordingly, Parentcraft India does not make any representations concerning the privacy
                practices or policies of such third parties or terms of use of such websites, nor does Parentcraft
                India guarantee the accuracy, integrity, or quality of the information, data, text, software, sound,
                photographs, graphics, videos, messages or other materials available on these websites
                accessed from the Platform of the ‘Users’. The inclusion or exclusion does not imply any
                endorsement by Parentcraft India of the owner of the website, the website's provider, or the
                information on such a website. If you decide to visit a third party website linked to the Platform,
                you do this entirely at your own risk. Parentcraft India encourages the User to read the privacy
                policies and terms of use of such websites and Parentcraft India is not responsible for the
                policies and practices employed by the owners of such third party websites, including but not
                limited to their collection, use and disclosure of your Personal Data, nor does Parentcraft India
                offer any (and expressly disclaims any) guarantee, representation, warranty, or covenant of any
                kind with respect to the collection, use or disclosure of your Personal Data by any third party site
                that is linked from (or is otherwise associated with) the Website.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}><b>Advertisers and Third-Party Marketing:</b> From time to time, we may share your Personal Data
                in a way so that it is only readable by the intended advertiser to be able to serve you relevant
                information and marketing messages on that advertising platform. You hereby consent to such
                sharing of your Personal Data. We may also share aggregated demographic information and/or
                vendor ID numbers with our partners and advertisers. This is based on your consent to the
                terms of the collection and use of cookie and cache Cookie and cache as provided herein. We
                do not sell Personal Data to third parties for their own marketing purposes.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}><b>Business Transfers:</b> We may share some or all of your Personal Data in connection with or
                during negotiation of any merger, financing, acquisition or dissolution, transaction or proceeding
                involving sale, transfer, divestiture, or disclosure of all or a portion of our business or assets. In
                the event of an insolvency, bankruptcy, or receivership, Personal Data may also be transferred
                as a business asset. If another company acquires our company, business, or assets, that
                company will possess the Personal Data that we have collected and will assume the rights and
                obligations regarding your Personal Data as described in this Privacy Policy. You hereby
                consent to the sharing of your Personal Data in such circumstances. We may also need your
                consent to continue handling your data.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}><b>Compliance with Law, Court Order and Other Disclosures:</b> <br /> Parentcraft India takes your right to privacy very seriously and other than as specifically stated
                in this Privacy Policy, will only disclose your Personal Information in the event it is required to do
                so by law, rule, regulation, law enforcement agency, governmental official, legal authority or
                similar requirements or when Parentcraft India, in its sole discretion, deems it necessary in
                order to protect its rights or the rights of others, to prevent harm to persons or property, to fight
                fraud and credit risk, or to enforce or apply the Terms and Conditions.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}><b>Security of your Personal Data:</b> We employ security safeguards to protect your Personal Data against loss or theft, as well as
                against unauthorized access, disclosure, copying, use, or modification. When we transmit highly
                confidential information over the Internet, we protect it through the use of encryption technology,
                such as the Secure Socket Layer (SSL) protocol for the Website and [insert name] for the App.</p>

            <h3>YOUR RIGHTS REGARDING YOUR PERSONAL DATA</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>You can access your personal information at any time to review any personal information that
                you have supplied. You may modify any personal information that has been found to be
                inaccurate during this review. You may also request the deletion of any sensitive personal
                information that you have provided to us, by sending an email to the Grievance Officer
                mentioned below.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Please note that this may affect our ability to process or enable Interactions and may therefore
                lead to the discontinuation of those Interactions for which this Personal Data was being used
                for, at our sole discretion.</p>

            <h3>COOKIES AND CACHE</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Our services use temporary cache/ cookies to store certain (that is not sensitive personal data
                or information) that is used by Parentcraft India and its service providers for the technical
                administration of the Platform, research and development, and for User administration. In the
                course of serving advertisements or optimizing services to its 'Users', Parentcraft India may
                allow authorized third parties to place or recognize a unique cache data on the Platform. The
                cache/ cookies however, do not store any Personal Information of the User. 'Users' using
                Website, www.parentcraftindia.com have the provision to adjust their internet browser to disable
                cookies and for App,'Users' have the provision to clear the cache once they have utilized the
                app resources. Even if cookies were disabled for the Website,
                www.parentcraftindia.com is still functional. The App is also functional for 'Users' who have
                cleared their cache.
            </p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>If you do not wish to receive Cookies, or want to be notified of when they are placed, you may
                change this on the Cookie bar or set your web browser to do so or withdraw your consent with
                us. Please note that if Cookies are turned off, you may not be able to view certain parts of the
                Platform that may enhance your visit and you may be unable to use some or all of the
                functionality of the Platform.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}><b>Marketing Communication:</b> <br /> By being an active and existing User of Parentcraft India, we have a legitimate interest in
                marketing our products to you. You can unsubscribe to this kind of communication in your User
                account or at any time by following the unsubscribe instructions in communication sent to you.
                Despite your indicated opt-out preferences, we may continue to send you administrative and
                transaction related communications.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}><b>Changing, Transferring or Deleting Your Personal Data</b> <br />
                We delete your Personal Data once it is no longer necessary in relation to the purposes for
                which they were collected or otherwise processed. You may access, review, update, correct,
                delete or transfer your Personal Data by editing your account via the Platform. You may
                completely delete your account by writing to <a
                    href="mailto:support@parentcraftindia.com"><span
                        style={{ fontSize: "11pt", color: "#677294", backgroundColor: "transparent", fontVariantNumeric: "normal", fontVariantEastAsian: "normal", textDecorationLine: "underline", textDecorationSkipInk: "none", verticalAlign: "baseline", whiteSpace: "pre-wrap" }}>support@parentcraftindia.com</span></a >, with your existing
                account details and Parentcraft Admin will process the offboarding process after ensuring all of
                the worshop/ events and bookings have been completed and/or canceled. Account backups
                shall be provided for advertisements statistics, account transactions logs on profile
                advertisements, appointment logs in case of a professional, data regarding concerned ‘My Q &amp;
                A’ logs. Notwithstanding the above, in the event you have any pending appointments or
                unsettled transactions, you will be required to complete those services before initiating deletion
                of their account.
            </p>

            <h3>STATEMENT APPLICABLE FOR CASUAL VISITORS</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>No sensitive personal data or information is automatically collected by Parentcraft India from
                any casual visitors of this Platform, who are merely perusing the Platform.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Nevertheless, certain provisions of this Privacy Policy are applicable to even such casual
                visitors, and such casual visitors are also required to read and understand the privacy
                statements set out herein, failing which they are required to leave this Platform immediately.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>If you, as a casual visitor, have inadvertently browsed any other page of this Platform prior to
                agreeing to having read the Privacy Policy and Terms and Conditions, and you do not agree
                with the manner in which such information is obtained, collected, processed, stored, used,
                disclosed or retained, merely quitting this Platform should ordinarily clear all their temporary
                cookies/ cache.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>All visitors, however, are encouraged to use the “clear cookies” functionality of their browsers to
                ensure such clearing / deletion, as Parentcraft India cannot guarantee, predict or provide for the
                behavior of the equipment of all the visitors of the Platform.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>You are not a casual visitor if you have willingly submitted any Personal Data or information to
                Parentcraft India through any means, including email, post or through the registration process
                on the Platform support request. All such visitors will be deemed to be, and will be treated as,
                'Users' for the purposes of this Privacy Policy, and in which case, all the statements in this
                Privacy Policy apply to such persons/ individuals/ 'Users'.</p>

            <h3>CONFIDENTIALITY AND SECURITY</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Your Personal Data is maintained by Parentcraft India in electronic form on its equipment, and
                on the equipment of its employees. Such information may also be converted to physical form
                from time to time. Parentcraft India takes all necessary precautions to protect your Personal
                Data both online and offline, and implements reasonable security practices and measures
                including certain managerial, technical, operational and physical security control measures that
                are commensurate with respect to the information being collected and the nature of Parentcraft
                India’s business.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Be sure to log off from the Platform when finished. Parentcraft India does not undertake any
                liability for any unauthorized use of your account. If you suspect any unauthorized use of your
                account, you must immediately notify Parentcraft India by sending an email to
                <a
                    href="mailto:support@parentcraftindia.com"><span
                        style={{ fontSize: "11pt", color: "#677294", backgroundColor: "transparent", fontVariantNumeric: "normal", fontVariantEastAsian: "normal", textDecorationLine: "underline", textDecorationSkipInk: "none", verticalAlign: "baseline", whiteSpace: "pre-wrap" }}>support@parentcraftindia.com</span></a > You shall be liable to indemnify Parentcraft India for any loss
                suffered by Parentcraft India on account of unauthorized use of your account.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Parentcraft India makes all User information accessible to its employees, agents or partners and
                third parties only on a need-to-know basis and binds only its employees to strict confidentiality
                obligations.<br />
                Part of the functionality of Parentcraft India is assisting the Vendors to maintain and organize
                such information and the Users to have access to the Personal Data. Parentcraft India may,
                therefore, retain and submit all such records to the appropriate authorities, or to Vendors who
                request access to such information.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Notwithstanding the above, Parentcraft India is not responsible for the confidentiality, security or
                distribution of your Personal Data by our partners and third parties outside the scope of our
                agreement with such partners and third parties. Further, Parentcraft India shall not be
                responsible for any breach of security or for any actions of any third parties or events that are
                beyond the reasonable control of Parentcraft India including but not limited to, acts of
                government, computer hacking, unauthorized access to computer data and storage device,
                computer crashes, breach of security and encryption, poor quality of Internet service or
                telephone service of the User etc.</p>

            <h3>LIMITATION OF LIABILITY</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Parentcraft India does not control or endorse the content, messages or information found in any
                services (including events posted by Vendors) and, therefore, Parentcraft India specifically
                disclaims any liability with regard to the services and any actions resulting from participation in
                any services, and you agree that you waive any claims against Parentcraft India relating to
                same, and to the extent such waiver may be ineffective, you agree to release any claims against
                Parentcraft India relating to the same. You also understand that any User may share (via
                screenshots or otherwise) the advertisements shared on the Platform and we hold no
                responsibility for such sharing.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>You are responsible for maintaining the accuracy of the information you submit to us, such as
                your contact information provided as part of account registration. If your Personal Data changes,
                you may correct, delete inaccuracies, or amend information by making the change in your
                account but if your mobile phone number is treated as your primary identifier on the Platform. It
                is your responsibility to ensure that your mobile phone number is up to date on the Platform at
                all times. You agree to notify us promptly or write to Parentcraft India through
                support@parntcraftindia.com if your mobile phone number or e-mail address changes by
                updating the same at Application through an OTP verification, which will be done by sending a
                Short Message Service (SMS) or through call. We will make all efforts to make requested
                changes in our then active databases as soon as reasonably practicable. If you provide any
                information that is untrue, inaccurate, out of date or incomplete (or becomes untrue, inaccurate,
                out of date or incomplete), or Parentcraft India has reasonable grounds to suspect that the
                information provided by you is untrue, inaccurate, out of date or incomplete, Parentcraft India
                may, at its sole discretion, discontinue the provision of the services to you.</p>

            <h3>CHANGE TO PRIVACY POLICY</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>We reserve the right to update this Privacy Policy at any time for any reason, with or without
                advance notice and in compliance with applicable laws. An updated policy shall be uploaded on
                the Platform and will be effective since the day it is posted. In the event there are significant
                changes to this Privacy Policy we will notify you by posting notice of change on the Platform.
                Any material changes to this Privacy Policy will be effective upon 30 (thirty) calendar days
                following our posting of notice of the changes on our Platform. These changes will be effective
                immediately for new Users of our Platform.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>If a User uses the Services or accesses the Platform after a notice of changes has been sent to
                such User or published on the Platform, such User hereby provides his/her/its consent to the
                changed terms.</p>

            <h3>DATA PROTECTION OFFICER AND CONTACT INFORMATION</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>We will use reasonable efforts to respond promptly to any requests, questions or concerns,
                which you may have regarding our use of your Personal Data. You may address any complaints
                or discrepancies in relation to the processing of your Personal Data to:</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}><b>Grievance Officer: </b><br />
                <u>Contact details:</u> <br />
                Name: Arjun Singh Kak<br />
                Parentcraft India Private Limited<br />
                64 Dhar Kothi, Residency Area, Indore, Madhya Pradesh, 452001<br />
                admin@parentcraftindia.com</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>If you believe that Parentcraft India does not fulfill its obligations according to the applicable
                privacy legislation, you also have the right to lodge a complaint with a supervisory authority.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>As always, if you object to any of the changes to our terms, and you no longer wish to use the
                Services, you may contact <a
                    href="mailto:support@parentcraftindia.com"><span
                        style={{ fontSize: "11pt", color: "#677294", backgroundColor: "transparent", fontVariantNumeric: "normal", fontVariantEastAsian: "normal", textDecorationLine: "underline", textDecorationSkipInk: "none", verticalAlign: "baseline", whiteSpace: "pre-wrap" }}>support@parentcraftindia.com</span></a > to deactivate your account. Unless
                stated otherwise, Parentcraft India’s current Privacy Policy applies to all information that
                Parentcraft India has about you and your account.</p>

            <h3>CHILDREN'S AND MINOR'S PRIVACY</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Parentcraft India, though may be suitable for a User starting at 4+, however, the recommended
                age for use of the Platform is 18+. Parentcraft India strongly encourages parents and guardians
                to supervise the online activities of their minor children and consider using parental control tools
                available from online services and software manufacturers to help provide a child-friendly online
                environment. We would also prefer and encourage the parents or the guardians to use the app
                on behalf of their minor children. These tools also can prevent minors from disclosing their
                name, address, and other personally identifiable information online without parental permission.
                Although the Parentcraft India Platform and services can be used by minors in oversight of the
                respective caretakers/ guardians, Parentcraft India respects the privacy of minors who may
                inadvertently use the Platform. In no event, Parentcraft India shall be held liable for any use by
                such minors.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}><b>Last Updated Date: June 22, 2022</b></p>
        </section>
    </>
};

export default PrivacyPolicy;