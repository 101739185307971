import React from 'react';
import "../admins/forum.css";

const CompleteAlert = (props) => {

    return (
        <div className={`row forum`} style={{margin:0}}>
            <div className={`modal ask-question-modal fade ${props.displayModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Do you want to complete this appointment before the time?</h5>
                </div>
                <div className="modal-body">
                  <div className="col-12 pt-3 pb-3 text-right">
                    <button type="button" className="btn btn-outline-primary" onClick={() => props.toggleCompleteAlert(false)}>No</button>
                    <button type="button" className="btn btn-primary ml-3" onClick={() => {props.toggleCompleteAlert(false); props.completeAppointment(props.bookingDetails, true)}}>Yes</button>
                  </div>
                </div>
                </div>
            </div>
            </div>
          
        </div>
    )
}

export default CompleteAlert;