import React from "react";
import "../dashboard.css";
import * as auth from "../../../store/ducks/auth.duck";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

const Documents = (props) => {
    return <div className="col-md-7 documents-manager" style={{
        margin: "0 auto"
    }}>
        <div className="row ml-0 mr-0 mt-3 profile-details-wrapper id-details-wrapper">
            <div className="col-12 p-0">
                        <div className="row" style={{ margin: 0 }}>
                            <div className={`col-sm-8 col-xs-12 pt-2 ${props.selectedProfile.descriptionImages && props.selectedProfile.descriptionImages.length ? 'mt-2' : ''} ${props.selectedProfile.status !== props.VendorConfigStatus.active ? 'mb-2' : ''}`}>
                                <h5 className={`mb-0`}><i class="fas fa-arrow-left mr-2" style={{cursor: "pointer"}} onClick={() => { props.selectedProfile.status === props.VendorConfigStatus.approvalPending || props.selectedProfile.status === props.VendorConfigStatus.approvalRejected ? props.nextStep() : props.backStep(true)}}></i>Documents</h5>
                            </div>
                            {props.selectedProfile.status === props.VendorConfigStatus.active ?
                            <div className="col-sm-4 col-xs-12 pl-0 text-right mb-3">
                                <button type="button" className="btn btn-outline-primary btn-sm ml-2" disabled={!props.selectedProfile.idProof || !props.selectedProfile.educationProofs?.length} onClick={() => { props.nextStep() }}>Next</button>
                            </div> : null}
                        </div>
                    </div>
            <div className={`col-12 pl-0 pb-3`}>
                {!props.uploadedIdProofImage ?
                    <div className="height-100 profile-image-wrapper id-image-wrapper">
                        {props.selectedProfile.idProof ?
                            <>
                                <div className="profile-image" onClick={e => { props.openFileUploader("idProofImage") }} style={{ backgroundImage: "url(" + props.selectedProfile.idProof + ")" }}>
                                    {props.selectedProfile.status !== props.VendorConfigStatus.approved ?
                                        <div className="file-uploader">
                                            <input type="file" id="idProofImage" accept="image/*" hidden onChange={() => props.uploadProofImage("idProofImage")} />
                                            <i className="fa fa-camera" aria-hidden="true"></i>
                                        </div>
                                        : null}
                                </div>
                                <div className="text-center mt-2">Govt. Photo ID (Front)<span className='mandatory-doc-message'>*</span></div>
                            </> :
                            <>
                                <div className="profile-image without-image" onClick={e => { props.openFileUploader("idProofImage") }}>
                                    <span className="id-proof-text"><i className="fa fa-image" style={{
                                        fontSize: "35px",
                                        lineHeight: 2
                                    }}></i></span>
                                    {props.selectedProfile.status !== props.VendorConfigStatus.approved ?
                                        <div className="file-uploader">
                                            <input type="file" id="idProofImage" accept="image/*" hidden onChange={() => props.uploadProofImage("idProofImage")} />
                                            <i className="fa fa-camera" aria-hidden="true"></i>
                                        </div>
                                        : null}
                                </div>
                                <div className="text-center mt-2">Govt. Photo ID (Front)<span className='mandatory-doc-message'>*</span></div>
                            </>
                        }

                    </div>
                    :
                    <>
                        <div className="height-100 profile-image-wrapper id-image-wrapper">
                            <div className="profile-image" id="profileImageId" style={{ backgroundImage: "url(" + props.uploadedIdProofImage + ")" }} onClick={e => { props.openFileUploader("idProofImage") }}>
                                {props.selectedProfile.status !== props.VendorConfigStatus.approved ?
                                    <div className="file-uploader">
                                        <input type="file" id="idProofImage" accept="image/*" hidden onChange={() => props.uploadProofImage("idProofImage")} />
                                        <i className="fa fa-camera" aria-hidden="true"></i>
                                    </div>
                                    : null}
                            </div>
                            <div className="text-center mt-2">Govt. Photo ID (Front)<span className='mandatory-doc-message'>*</span></div>
                        </div>

                    </>
                }
                {!props.uploadedIdBackProofImage ?
                    <div className="height-100 profile-image-wrapper id-image-wrapper ml-2">
                        {props.selectedProfile.idBackProof ?
                            <>
                                <div className="profile-image" onClick={e => { props.openFileUploader("idBackProofImage") }} style={{ backgroundImage: "url(" + props.selectedProfile.idBackProof + ")" }}>
                                    {props.selectedProfile.status !== props.VendorConfigStatus.approved ?
                                        <div className="file-uploader">
                                            <input type="file" id="idBackProofImage" accept="image/*" hidden onChange={() => props.uploadProofImage("idBackProofImage")} />
                                            <i className="fa fa-camera" aria-hidden="true"></i>
                                        </div>
                                        : null}
                                </div>
                                <div className="text-center mt-2">Govt. Photo ID (Back)</div>
                            </> :
                            <>
                                <div className="profile-image without-image" onClick={e => { props.openFileUploader("idBackProofImage") }}>
                                    <span className="id-proof-text"><i className="fa fa-image" style={{
                                        fontSize: "35px",
                                        lineHeight: 2
                                    }}></i></span>
                                    {props.selectedProfile.status !== props.VendorConfigStatus.approved ?
                                        <div className="file-uploader">
                                            <input type="file" id="idBackProofImage" accept="image/*" hidden onChange={() => props.uploadProofImage("idBackProofImage")} />
                                            <i className="fa fa-camera" aria-hidden="true"></i>
                                        </div>
                                        : null}
                                </div>
                                <div className="text-center mt-2">Govt. Photo ID (Back)</div>
                            </>
                        }

                    </div>
                    :
                    <>
                        <div className="height-100 profile-image-wrapper id-image-wrapper ml-2">
                            <div className="profile-image" id="profileImageId" style={{ backgroundImage: "url(" + props.uploadedIdBackProofImage + ")" }} onClick={e => { props.openFileUploader("idBackProofImage") }}>
                                {props.selectedProfile.status !== props.VendorConfigStatus.approved ?
                                    <div className="file-uploader">
                                        <input type="file" id="idBackProofImage" accept="image/*" hidden onChange={() => props.uploadProofImage("idBackProofImage")} />
                                        <i className="fa fa-camera" aria-hidden="true"></i>
                                    </div>
                                    : null}
                            </div>
                            <div className="text-center mt-2">Govt. Photo ID (Back)</div>
                        </div>

                    </>
                }
            </div>
            <div className={`col-12 pl-0 pb-3`}>
                <div className="row" style={{ margin: "0" }}>
                    <div className="col-12 p-0">
                        <div className="row" style={{ margin: 0 }}>
                            <div className={`col-sm-7 col-xs-12 pl-0 pt-2 ${props.selectedProfile.educationProofs && props.selectedProfile.educationProofs.length ? 'mt-2' : ''}`}>
                                <h5 className={`mb-0`} style={{
                                    fontSize: "14px"
                                }}>Professional Qualification(s)<span className='mandatory-doc-message'>*</span></h5>
                                <span style={{
                                    fontSize: "11px",
                                    fontWeight: 500
                                }}>*Any one document related to psychology is sufficient.</span>
                            </div>
                            {!props.uploadedEducationProofImage && props.selectedProfile.educationProofs && props.selectedProfile.educationProofs.length ?
                                <div className="col-sm-5 col-xs-12 pr-0 text-right category-arrows" onMouseEnter={() => { props.displayNavs(true) }}>
                                    <button className="prev-button-wrapper" onClick={() => props.prevClick('qualifications-wrapper')} id="slideprev" disabled={!props.showPrevQualificationButtons}>
                                        <i className="fas fa-arrow-left"></i>
                                    </button>
                                    <button className="next-button-wrapper" onClick={() => props.nextClick('qualifications-wrapper')} id="slide" disabled={!props.showNextQualificationButtons}>
                                        <i className="fas fa-arrow-right"></i>
                                    </button>
                                </div> : null}
                        </div>
                    </div>
                    {props.selectedProfile.status !== props.VendorConfigStatus.approved && props.selectedProfile.educationProofs && props.selectedProfile.educationProofs.length < 4 ?
                        !props.uploadedEducationProofImage ?
                            <div className={`about-image-wrapper pl-0 pt-2`}>
                                <input type="file" accept="image/*" id="educationProofImage" hidden onChange={props.uploadQualificationImage} />
                                <div className="profile-image description-image" style={{ backgroundColor: "#eee", backgroundImage: "url(" + require("../../../../images/plus.svg") + ")" }} onClick={() => props.openFileUploader("educationProofImage")} />
                            </div> :
                            <div className={`about-image-wrapper pl-0 pt-2`}>
                                <input type="file" accept="image/*" id="educationProofImage" hidden onChange={props.uploadQualificationImage} />
                                <div className="profile-image description-image" style={{ backgroundColor: "#eee", backgroundImage: "url(" + URL.createObjectURL(props.uploadedEducationProofImage) + ")" }} />
                            </div> : null
                    }
                    <div className={`qualifications-wrapper gallery-images-wrapper pl-0 pr-0 pt-2 pb-0`} style={{ margin: 0 }}>
                        {props.selectedProfile.educationProofs && props.selectedProfile.educationProofs.length ?
                            props.selectedProfile.educationProofs.map((image, index) => {
                                return <div className={`about-image-wrapper pl-0`} key={index} onClick={() => window.open(image.url)}>
                                    <div className="profile-image description-image" style={{ backgroundImage: "url(" + image.url + ")" }} />
                                    {props.selectedProfile.status !== props.VendorConfigStatus.approved ?
                                        <img src={require("../../../../images/remove.svg")} onClick={e => { e.stopPropagation(); props.openRemove(image, "Certificate") }} alt={`remove certificate`} /> : null}
                                </div>
                            })
                            : null}
                    </div>
                </div>
            </div>
            <div className={`col-12 pl-0 pb-3`}>
                {!props.uploadedRCIImage ?
                    <div className="height-100 profile-image-wrapper id-image-wrapper second-id-image-wrapper ml-2">
                        {props.selectedProfile.rciCertificate ?
                            <>
                                <div className="profile-image" onClick={e => { props.openFileUploader("rciCertificateImage") }} style={{ backgroundImage: "url(" + props.selectedProfile.rciCertificate + ")" }}>
                                    {props.selectedProfile.status !== props.VendorConfigStatus.approved ?
                                        <div className="file-uploader">
                                            <input type="file" id="rciCertificateImage" accept="image/*" hidden onChange={() => props.uploadProofImage("rciCertificateImage")} />
                                            <i className="fa fa-camera" aria-hidden="true"></i>
                                        </div>
                                        : null}
                                </div>
                                <div className="text-center mt-2">RCI Certificate</div>
                            </> :
                            <>
                                <div className="profile-image without-image" onClick={e => { props.openFileUploader("rciCertificateImage") }}>
                                    <span className="education-proof-text"><i className="fa fa-image" style={{
                                        fontSize: "35px",
                                        lineHeight: 2
                                    }}></i></span>
                                    {props.selectedProfile.status !== props.VendorConfigStatus.approved ?
                                        <div className="file-uploader">
                                            <input type="file" id="rciCertificateImage" accept="image/*" hidden onChange={() => props.uploadProofImage("rciCertificateImage")} />
                                            <i className="fa fa-camera" aria-hidden="true"></i>
                                        </div>
                                        : null}
                                </div>
                                <div className="text-center mt-2">RCI Certificate</div>
                            </>
                        }

                    </div>
                    :
                    <>
                        <div className="height-100 profile-image-wrapper id-image-wrapper second-id-image-wrapper ml-2">
                            <div className="profile-image" id="profileImageId" style={{ backgroundImage: "url(" + props.uploadedRCIImage + ")" }} onClick={e => { props.openFileUploader("rciCertificateImage") }}>
                                {props.selectedProfile.status !== props.VendorConfigStatus.approved ?
                                    <div className="file-uploader">
                                        <input type="file" id="rciCertificateImage" accept="image/*" hidden onChange={() => props.uploadProofImage("rciCertificateImage")} />
                                        <i className="fa fa-camera" aria-hidden="true"></i>
                                    </div>
                                    : null}
                            </div>
                            <div className="text-center mt-2">RCI Certificate</div>
                        </div>

                    </>
                }
                {props.selectedProfile.subType === 2 ?
                    !props.uploadedGumastaImage ?
                        <div className="height-100 profile-image-wrapper id-image-wrapper second-id-image-wrapper ml-2">
                            {props.selectedProfile.gumasta ?
                                <>
                                    <div className="profile-image" onClick={e => { props.openFileUploader("gumastaImage") }} style={{ backgroundImage: "url(" + props.selectedProfile.gumasta + ")" }}>
                                        {props.selectedProfile.status !== props.VendorConfigStatus.approved ?
                                            <div className="file-uploader">
                                                <input type="file" id="gumastaImage" accept="image/*" hidden onChange={() => props.uploadProofImage("gumastaImage")} />
                                                <i className="fa fa-camera" aria-hidden="true"></i>
                                            </div>
                                            : null}
                                    </div>
                                    <div className="text-center mt-2">License to Operate/Gumasta</div>
                                </> :
                                <>
                                    <div className="profile-image without-image" onClick={e => { props.openFileUploader("gumastaImage") }}>
                                        <span className="education-proof-text"><i className="fa fa-image" style={{
                                        fontSize: "35px",
                                        lineHeight: 2
                                    }}></i></span>
                                        {props.selectedProfile.status !== props.VendorConfigStatus.approved ?
                                            <div className="file-uploader">
                                                <input type="file" id="gumastaImage" accept="image/*" hidden onChange={() => props.uploadProofImage("gumastaImage")} />
                                                <i className="fa fa-camera" aria-hidden="true"></i>
                                            </div>
                                            : null}
                                    </div>
                                    <div className="text-center mt-2">License to Operate/Gumasta</div>
                                </>
                            }

                        </div>
                        :
                        <>
                            <div className="height-100 profile-image-wrapper id-image-wrapper second-id-image-wrapper ml-2">
                                <div className="profile-image" id="profileImageId" style={{ backgroundImage: "url(" + props.uploadedGumastaImage + ")" }} onClick={e => { props.openFileUploader("gumastaImage") }}>
                                    {props.selectedProfile.status !== props.VendorConfigStatus.approved ?
                                        <div className="file-uploader">
                                            <input type="file" id="gumastaImage" accept="image/*" hidden onChange={() => props.uploadProofImage("gumastaImage")} />
                                            <i className="fa fa-camera" aria-hidden="true"></i>
                                        </div>
                                        : null}
                                </div>
                                <div className="text-center mt-2">License to Operate/Gumasta</div>
                            </div>

                        </> : null
                }
            </div>
            <div className={`col-12 pl-0 pb-3`}>
                <div className="col-12 p-0 mt-3 mandatory-doc-message">
                    *The documents are mandatory for approval. Without the mandatory documents the profile will be on hold.
                </div>
            </div>
        </div>
    </div>

};

const mapStateToProps = state => {
    return { data: state };
};

export default injectIntl(
    connect(
        mapStateToProps,
        auth.actions
    )(Documents)
);