import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import PrivacyPolicy from '../home/privacy-policy';
import TermsCondition from '../home/terms-condition';

export default function AuthPage() {
  console.log("authPage");
  const isFullWapper = () => {
    if (window.location.pathname === '/' ||
      window.location.pathname === '/privacy-policy' ||
      window.location.pathname === '/terms-and-conditions' ||
      window.location.pathname === '/cancellation-policies') {

      return true;
    }

    return false;
  };

  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile whole-auth-wrapper">

            <div className={`kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper ${isFullWapper() ? 'p-0' : ''}`}>
              <Switch>
                <Route path="/auth/login" component={Login} />
                <Route path="/auth/registration" component={Registration} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/terms-and-conditions" component={TermsCondition} />
                <Route
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
