import React, { useEffect, useState } from "react";
import "../dashboard.css";
import * as auth from "../../../store/ducks/auth.duck";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

const StatusTracker = (props) => {
    const [steps, updateSteps] = useState([{
        title: 'Profile Type',
        status: 1
    }, {
        title: 'Profile Details',
        status: 1
    }, {
        title: 'Address Manager',
        status: 1
    }, {
        title: 'Gallery',
        status: 1
    }, {
        title: 'Bank Details',
        status: 1
    }, {
        title: 'Documents',
        status: 1
    }, {
        title: 'Services',
        status: 1
    }]);

    useEffect(() => {
        console.log("props.selectedProfile ", props.selectedProfile)
        const localSteps = [...steps];
        if (props.selectedProfile && Object.keys(props.selectedProfile).length) {
            if (props.selectedProfile.descriptionImages && !props.selectedProfile.descriptionImages.length) {
                localSteps[3].status = 2;
            } else {
                localSteps[3].status = 1;
            }

            if (!props.selectedProfile.bankAdded) {
                localSteps[4].status = 2;
            } else {
                localSteps[4].status = 1;
            }
        }
       
        if (!props.hasServices) {
            localSteps[6].status = 3;
        } else {
            localSteps[6].status = 1;
        }
        updateSteps([...localSteps]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedProfile, props.hasServices]);
    useEffect(() => {
        const localSteps = [...steps];
       
        if (!props.hasAddress) {
            localSteps[2].status = 3;
        } else {
            localSteps[2].status = 1;
        }
        
        updateSteps([...localSteps]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.hasAddress]);

    return <div className="col-md-6 status-tracker-manager" style={{
        margin: "0 auto"
    }}>
        <div className="row m-0">
            <div className="col-12 p-0 mb-2">
                <div className="row" style={{ margin: 0 }}>
                    <div className={`col-12 pt-2`}>
                        <h5 className={`mb-0`}>Profile Status <i className="fa fa-info-circle cursor-pointer ml-2" style={{ fontSize: '15px', cursor: 'pointer' }} aria-hidden="true" onClick={() => props.clickInfo()}></i><i class="fas fa-sync pull-right" style={{ fontSize: '15px', cursor: 'pointer' }} onClick={() => props.getInitialProfileDetails()}></i></h5>
                        
                    </div>
                </div>
            </div>
            <div className="col-12 p-0 text-center">
                <button className="btn btn-primary" style={{
                    cursor: "default"
                }}>{props.VendorConfigTextStatus[props.selectedProfile.status]}</button>
            </div>
            <div className="col-12 p-0" style={{
                marginTop: "10px"
            }}>
                <div className="row m-0">
                    {steps.map((step, index) => {
                        return <div key={index} className='each-step col-12'>
                            <i className={`${step.status === 1 ? "fa fa-check-circle" : step.status === 2 ? "fas fa-exclamation-circle" : "fas fa-times-circle"} mr-2 mt-2 status-icon ${step.status === 1 ? "green" : step.status === 2 ? "yellow" : "red"}`} aria-hidden="true" />
                            <span className="mt-2" style={{
                                verticalAlign: "bottom"
                            }}>{step.title}</span>
                            <div className="pull-right edit-pen" onClick={() => props.openStep(index)}>
                                <i class="fas fa-pen" ></i>
                            </div>

                        </div>
                    })}
                    {props.selectedProfile.status === props.VendorConfigStatus.approvalRejected ?
                    <div className='col-12 text-center mt-2'>
                        <button className='btn btn-primary' disabled={!props.hasAddress || !props.hasServices} onClick={() => props.requestApproval(true)}>Submit for Approval</button>
                    </div> : null}
                </div>
            </div>
        </div>
    </div>

};

const mapStateToProps = state => {
    return { data: state };
};

export default injectIntl(
    connect(
        mapStateToProps,
        auth.actions
    )(StatusTracker)
);