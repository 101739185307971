import React, { useEffect, useState, useCallback } from 'react';
import "../dashboard.css"
import Compressor from 'compressorjs';
import * as auth from "../../../store/ducks/auth.duck";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import {
    getServices
} from "../../../crud/service.crud";
import {
    getProfile,
    updateBank,
    updateProfile,
    getLocationList,
    addLocation,
    updateLocation,
    removeLocation,
    getCategories,
    updateProfilePhoto,
    addPhoto,
    removeImage,
    removeCertificate,
    removePhoto,
    uploadId,
    requestForApproval,
    requestEmailOtp,
    confirmEmailOtp
} from "../../../crud/profile.crud";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import RemoveModal from "../../admins/remove-modal";
import {
    REQUIRED_ERROR,
    INVALID_FROM_TO_DATE,
    CHOOSE_ERROR,
    INVALID_EMAIL,
    CHOOSE_CATEGORY_ERROR,
    API_ERROR,
    INVALID_WEBSITE,
    INVALID_LOCATION
} from "../../../config/error-message";
import { API_SUCCESS } from "../../../config/success-message";
import {
    timeArrConst,
    daysConstant,
    VendorConfigStatus,
    VendorConfigTextStatus,
    compressionPercentage,
    compressionWidth,
    maxImageSize
} from "../../../config/constants";
import GalleryModal from "../../admins/gallery-modal";
import VendorTypeModal from "../../admins/vendor-type-modal";
import OtpModal from '../otp-modal';

import UserDetails from "./user-details";
import NextStepInfo from './next-step-info';
import AddressManager from './address-manager';
import Gallery from './gallery';
import BankDetails from './bank-details';
import Documents from './documents';
import Service from '../../admins/service';
import StatusTracker from './status-tracker';

var dayName = ["Mon", "Tue", "Wed", "Thurs", "Fri", "Sat", "Sun"];

const days = [...daysConstant];

const timeArr = [...timeArrConst];

const initialBankDetailsError = {
    accountHolderName: "",
    bankName: "",
    accountNumber: "",
    ifscCode: "",
    branchName: ""
};

const initialChequeDetailsError = {
    chequeName: "",
    chequeAddress: ""
};

const initialLocationError = {
    title: "",
    address: "",
    selectedFromDate: "",
    selectedToDate: "",
    days: ""
};

const initialLocation = {
    title: "",
    address: "",
    days: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
};

const initialProfileError = {
    name: "",
    email: "",
    category: "",
    experience: "",
    websiteUrl: ""
};

let map;
let marker;
let geocoder;
let hasAddress = false;
let myLatLng;

const Onboarding = (props) => {
    const [currentStep, updateCurrentStep] = useState(1);
    const [selectedProfile, selectProfile] = useState({});
    const [addresses, updateAddresses] = useState([]);
    const [mode, updateMode] = useState("list");
    const [selectedFromDate, handleFromDateChange] = useState(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)));
    const [selectedToDate, handleToDateChange] = useState(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(30)).setSeconds(0)));
    const [daysArray, updateDaysArray] = useState([...days]);
    const [uploadedGalleryImage, updateGalleryImage] = useState(null);
    const [displayRemoveModal, openRemoveModal] = useState(false);
    const [removeType, updateRemoveType] = useState("");
    const [bankDetailsError, updateBankDetailsError] = useState({ ...initialBankDetailsError });
    const [locationDetails, modifyLocationDetails] = useState({ ...initialLocation });
    const [locationDetailsError, updateLocationDetailsError] = useState({ ...initialLocationError });
    const [selectedLocationIdToRemove, updateLocationId] = useState("");
    const [categoryList, updateCategoryList] = useState([]);
    const [profileError, updateProfileError] = useState({ ...initialProfileError });
    const [uploadedProfileImage, updateProfileImage] = useState("");
    const [selectedImageIdToRemove, updateImageId] = useState("");
    const [selectedCertificateIdToRemove, updateCertificateId] = useState("");
    const [categoryDropdownOpened, toggleCategory] = useState(false);
    const [disablePage, toggleDisablePage] = useState(false);
    const [selectedCoordinates, updateCoordinate] = useState([]);
    const [openGalleryModal, toggleGalleryModal] = useState(false);
    const [currentImageIndex, updateCurrentImageIndex] = useState(-1);
    const [uploadedIdProofImage, updateIdProofImage] = useState("");
    const [uploadedIdBackProofImage, updateIdBackProofImage] = useState("");
    const [uploadedEducationProofImage, updateEducationProofImage] = useState("");
    const [uploadedRCIImage, updateRCIImage] = useState("");
    const [uploadedGumastaImage, updateGumastaImage] = useState("");
    const [showPrevButtons, togglePrevButton] = useState(false);
    const [showNextButtons, toggleNextButton] = useState(true);
    const [showPrevQualificationButtons, togglePrevQualificationButton] = useState(false);
    const [showNextQualificationButtons, toggleNextQualificationButton] = useState(true);
    const [isCheque, toggleCheque] = useState(false);
    const [chequeDetailsError, updateChequeDetailsError] = useState({ ...initialChequeDetailsError });
    const [displayVendorTypeModal, openVendorTypeModal] = useState(false);
    const [verifyEmailModalOpened, toggleVerifyEmailModal] = useState(false);
    const [userLocation, updateUserLocation] = useState([]);
    const [draggedAddress, updateDraggedAddress] = useState({});
    const [locationPinned, pinLocation] = useState(true);
    const [hasServices, updateHasServices] = useState(false);
    const { authData } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user != null,
            authData: auth.user
        }),
        shallowEqual
    );

    const handleNextPrevButtons = (type) => {
        if (document.getElementsByClassName(type)[0]) {
            if (document.getElementsByClassName(type)[0].clientWidth < document.getElementsByClassName(type)[0].scrollWidth) {
                if (type === "categories-wrapper") {
                    togglePrevButton(true);
                    toggleNextButton(true);
                } else if (type === "qualifications-wrapper") {
                    togglePrevQualificationButton(true);
                    toggleNextQualificationButton(true);
                }

                if (document.getElementsByClassName(type)[0].scrollLeft === 0) {
                    if (type === "categories-wrapper") {
                        togglePrevButton(false);
                    } else if (type === "qualifications-wrapper") {
                        togglePrevQualificationButton(false);
                    }
                }
                if (Math.round(document.getElementsByClassName(type)[0].scrollWidth - document.getElementsByClassName(type)[0].clientWidth) === Math.round(document.getElementsByClassName(type)[0].scrollLeft) ||
                    Math.floor(document.getElementsByClassName(type)[0].scrollWidth - document.getElementsByClassName(type)[0].clientWidth) === Math.floor(document.getElementsByClassName(type)[0].scrollLeft)) {
                    if (type === "categories-wrapper") {
                        toggleNextButton(false);
                    } else if (type === "qualifications-wrapper") {
                        toggleNextQualificationButton(false);
                    }

                }

            } else {
                if (type === "categories-wrapper") {
                    togglePrevButton(false);
                    toggleNextButton(false);
                } else if (type === "qualifications-wrapper") {
                    togglePrevQualificationButton(false);
                    toggleNextQualificationButton(false);
                }
            }
        }
    };

    const sideScroll = (element, direction, speed, distance, step, type) => {
        let scrollAmount = 0;
        let slideTimer = setInterval(function () {
            if (direction === 'left') {
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            if (scrollAmount >= distance) {
                handleNextPrevButtons(type);
                window.clearInterval(slideTimer);
            }
        }, speed);
    }

    const nextClick = (type) => {
        var container = document.getElementsByClassName(type)[0];
        sideScroll(container, 'right', 25, 100, 10, type);
    };

    const prevClick = (type) => {
        var container = document.getElementsByClassName(type)[0];
        sideScroll(container, 'left', 25, 100, 10, type);
    };

    window.onresize = () => {
        handleNextPrevButtons('categories-wrapper');
        if (selectedProfile && selectedProfile.educationProofs && selectedProfile.educationProofs.length) {
            handleNextPrevButtons('qualifications-wrapper');
        }
    };

    window.onload = () => {
        handleNextPrevButtons('categories-wrapper');
        if (selectedProfile && selectedProfile.educationProofs && selectedProfile.educationProofs.length) {
            handleNextPrevButtons('qualifications-wrapper');
        }
    };

    const displayNavs = useCallback((bool) => {
        if (bool) {
            handleNextPrevButtons('categories-wrapper');
            if (selectedProfile && selectedProfile.educationProofs && selectedProfile.educationProofs.length) {
                handleNextPrevButtons('qualifications-wrapper');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    const validateWebsite = (web) => {
        const re = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/;
        return re.test(web);
    };

    const formatAMPM = (date) => {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    };

    const getTime = (timeStr) => {
        var time = timeStr.slice(0, timeStr.length - 3)
        var amPm = timeStr.slice(timeStr.length - 2)
        var d = new Date()
        if (amPm === "AM" && time.split(":")[0] === "12") {
            return new Date(new Date(d.setHours(0)).setMinutes(time.split(":")[1]))
        }
        if (amPm === "PM" && time.split(":")[0] !== "12") {
            return new Date(new Date(d.setHours(Number(time.split(":")[0]) + 12)).setMinutes(time.split(":")[1]))
        }
        return new Date(new Date(d.setHours(time.split(":")[0])).setMinutes(time.split(":")[1]))
    };

    const getDuration = (times) => {
        times.sort((a, b) => { return a - b });
        const startTime = timeArr[times[0] - 1];
        const endTime = timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0];
        return `${startTime} - ${endTime}`;
    };

    const getStartTime = (times) => {
        times.sort((a, b) => { return a - b });
        return getTime(timeArr[times[0] - 1])
    };

    const getEndTime = (times) => {
        times.sort((a, b) => { return a - b });
        return getTime(timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0])
    };

    const getAllLocations = useCallback(async () => {
        toggleDisablePage(true);
        const locationsResult = await getLocationList();
        toggleDisablePage(false);
        if (locationsResult.data.errorCode) {
            toast.error(API_ERROR[locationsResult.data.errorCode]);
            return;
        }
        console.log("locationsResult ", locationsResult)
        const localAddresses = [];
        if (locationsResult.data.data && locationsResult.data.data.length) {
            let localSingleAddress = {};
            locationsResult.data.data.forEach(each => {
                localSingleAddress.title = each.title;
                localSingleAddress.address = each.address;
                localSingleAddress.addressLine1 = each.addressLine1;
                localSingleAddress.addressLine2 = each.addressLine2;
                localSingleAddress.addressLine3 = each.addressLine3;
                localSingleAddress._id = each._id;
                localSingleAddress.selected = false;
                localSingleAddress.days = "";
                localSingleAddress.duration = getDuration(each.workingTimeSlots);
                localSingleAddress.daysOfWeek = each.daysOfWeek;
                localSingleAddress.workingTimeSlots = each.workingTimeSlots;
                localSingleAddress.location = each.location;

                if (each.daysOfWeek && each.daysOfWeek.length) {
                    each.daysOfWeek.forEach((item, index) => {
                        localSingleAddress.days += dayName[item - 1];
                        if (index !== each.daysOfWeek.length - 1) {
                            localSingleAddress.days += ", ";
                        }
                    });
                }
                localAddresses.push({ ...localSingleAddress });
                localSingleAddress = {};
            });
        }
        if (localAddresses.length) {
            hasAddress = true;
        } else {
            hasAddress = false;
        }
        updateAddresses([...localAddresses]);
    }, []);

    const getAllCategories = async (categoryIds) => {
        toggleDisablePage(true);
        const categoriesResult = await getCategories();
        toggleDisablePage(false);
        if (categoriesResult.data.errorCode) {
            toast.error(API_ERROR[categoriesResult.data.errorCode]);
            return;
        }
        if (categoriesResult.data.data && categoriesResult.data.data.length) {
            categoriesResult.data.data.forEach(each => {
                if (categoryIds.includes(each._id)) {
                    each.selected = true;
                } else {
                    each.selected = false;
                }
                if (each.title.charAt(each.title.length - 1) === 's') {
                    each.title = each.title.substr(0, each.title.length - 1);
                }
            });
            updateCategoryList(categoriesResult.data.data);
        }
    };

    const getUserLocation = useCallback(async () => {
        if (window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition((position) => {
                if (position && position.coords && position.coords.latitude && position.coords.longitude) {
                    updateUserLocation([position.coords.longitude, position.coords.latitude]);
                }
            }, (error) => {
                if (error.code === 1) {
                    console.log("Not able access your current location. If you have denied the permission then please refresh the page or Please change the location access from your browser's settings");
                }
            });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }, []);

    const getProfileDetails = async (resetType) => {
        toggleDisablePage(true);
        const profileData = await getProfile();
        toggleDisablePage(false);
        if (profileData.data.errorCode) {
            toast.error(API_ERROR[profileData.data.errorCode]);
            return;
        }
        if (profileData.data.data) {
            const localSelectedProfile = { ...selectedProfile };
            if ((!resetType || resetType === "bank") && profileData.data.data.bankingDetails) {
                localSelectedProfile.bankDetails = { ...profileData.data.data.bankingDetails };
                if (profileData.data.data.bankingDetails.isCheque) {
                    toggleCheque(profileData.data.data.bankingDetails.isCheque);
                }

                if ((profileData.data.data.bankingDetails.isCheque && profileData.data.data.bankingDetails.chequeName)
                    || profileData.data.data.bankingDetails.bankName) {
                    localSelectedProfile.bankAdded = true;
                } else {
                    localSelectedProfile.bankAdded = false;
                }
            }
            if ((!resetType || resetType === "personalInfo") && profileData.data.data.personalInfo) {
                localSelectedProfile._id = profileData.data.data._id;
                localSelectedProfile.idProof = "";
                localSelectedProfile.idBackProof = "";
                localSelectedProfile.educationProof = "";
                localSelectedProfile.educationProofs = [];
                localSelectedProfile.rciCertificate = "";
                localSelectedProfile.profilePicture = profileData.data.data.personalInfo.profilePicture;
                localSelectedProfile.name = profileData.data.data.personalInfo.name;
                localSelectedProfile.followedBy = profileData.data.data.followedBy ? profileData.data.data.followedBy.length : 0;
                localSelectedProfile.description = profileData.data.data.personalInfo.description;
                localSelectedProfile.email = profileData.data.data.personalInfo.email;
                localSelectedProfile.status = profileData.data.data.accountStatus;
                localSelectedProfile.phoneNumber = '+91' + profileData.data.data.personalInfo.phone.number;
                localSelectedProfile.websiteUrl = profileData.data.data.personalInfo.website;
                if (profileData.data.data.personalInfo.experience && profileData.data.data.personalInfo.experience.year) {
                    localSelectedProfile.experience = {
                        ...profileData.data.data.personalInfo.experience,
                        month: "0"
                    };
                } else {
                    localSelectedProfile.experience = {
                        year: "",
                        month: "0"
                    };
                }
                localSelectedProfile.categories = [];
                if (profileData.data.data.personalInfo.categories && profileData.data.data.personalInfo.categories.length) {
                    profileData.data.data.personalInfo.categories.forEach(each => {
                        localSelectedProfile.categories.push(each._id);
                    });
                }
                if (profileData.data.data.idPhoto) {
                    if (profileData.data.data.idPhoto.govtId) {
                        localSelectedProfile.idProof = profileData.data.data.idPhoto.govtId;
                    }
                    if (profileData.data.data.idPhoto.govtIdBack) {
                        localSelectedProfile.idBackProof = profileData.data.data.idPhoto.govtIdBack;
                    }
                    if (profileData.data.data.idPhoto.gumasta) {
                        localSelectedProfile.gumasta = profileData.data.data.idPhoto.gumasta;
                    }
                    if (profileData.data.data.idPhoto.certificate) {
                        localSelectedProfile.educationProof = profileData.data.data.idPhoto.certificate;
                    }
                    if (profileData.data.data.idPhoto.certificates && profileData.data.data.idPhoto.certificates.length) {
                        localSelectedProfile.educationProofs = profileData.data.data.idPhoto.certificates;
                    }
                    if (profileData.data.data.idPhoto.rciCertificate) {
                        localSelectedProfile.rciCertificate = profileData.data.data.idPhoto.rciCertificate;
                    }
                }
            }
            if ((!resetType || resetType === "photos") && profileData.data.data.personalInfo) {
                localSelectedProfile.descriptionImages = profileData.data.data.photos;
            }
            localSelectedProfile.subType = profileData.data.data.subType || 1;
            localSelectedProfile.emailVerified = profileData.data.data.emailVerified;
            console.log("localSelectedProfile ", localSelectedProfile)
            selectProfile({ ...localSelectedProfile });
            props.updateProfile({ ...profileData.data.data });
            if (!resetType) {
                getAllCategories(localSelectedProfile.categories);
            }
            setTimeout(() => {
                displayNavs(true);
            }, 1000);
        }
    };

    const getInitialProfileDetails = useCallback(async () => {
        toggleDisablePage(true);
        const profileData = await getProfile();
        toggleDisablePage(false);
        console.log("profileData ", profileData)
        if (profileData.data.errorCode) {
            toast.error(API_ERROR[profileData.data.errorCode]);
            return;
        }
        if (profileData.data.data) {

            if (profileData.data.data.accountStatus === VendorConfigStatus.approvalPending ||
                profileData.data.data.accountStatus === VendorConfigStatus.approvalRejected) {
                console.log("authData.accountStatus ", authData.accountStatus)
                if (authData && authData.accountStatus === VendorConfigStatus.approvalPending &&
                    profileData.data.data.accountStatus === VendorConfigStatus.approvalRejected) {
                    updateCurrentStep(17)
                } else if (authData && authData.accountStatus === VendorConfigStatus.approvalRejected &&
                    profileData.data.data.accountStatus === VendorConfigStatus.approvalPending) {
                    updateCurrentStep(14)
                } else {
                    updateCurrentStep(15);
                }

            } else if (profileData.data.data.accountStatus === VendorConfigStatus.approved) {
                updateCurrentStep(16);
            } else {
                openVendorTypeModal(true);
                updateCurrentStep(1);
            }

            const localSelectedProfile = {};
            if (profileData.data.data.bankingDetails) {
                localSelectedProfile.bankDetails = { ...profileData.data.data.bankingDetails };
                if (profileData.data.data.bankingDetails.isCheque) {
                    toggleCheque(profileData.data.data.bankingDetails.isCheque);
                }

                if ((profileData.data.data.bankingDetails.isCheque && profileData.data.data.bankingDetails.chequeName)
                    || profileData.data.data.bankingDetails.bankName) {
                    localSelectedProfile.bankAdded = true;
                } else {
                    localSelectedProfile.bankAdded = false;
                }
            }
            if (profileData.data.data.personalInfo) {
                localSelectedProfile._id = profileData.data.data._id;
                localSelectedProfile.idProof = "";
                localSelectedProfile.idBackProof = "";
                localSelectedProfile.educationProof = "";
                localSelectedProfile.educationProofs = [];
                localSelectedProfile.rciCertificate = "";
                localSelectedProfile.profilePicture = profileData.data.data.personalInfo.profilePicture;
                localSelectedProfile.name = profileData.data.data.personalInfo.name;
                localSelectedProfile.followedBy = profileData.data.data.followedBy ? profileData.data.data.followedBy.length : 0;
                localSelectedProfile.description = profileData.data.data.personalInfo.description;
                localSelectedProfile.email = profileData.data.data.personalInfo.email;
                localSelectedProfile.phoneNumber = '+91' + profileData.data.data.personalInfo.phone.number;
                localSelectedProfile.websiteUrl = profileData.data.data.personalInfo.website;
                localSelectedProfile.status = profileData.data.data.accountStatus;
                if (profileData.data.data.personalInfo.experience && profileData.data.data.personalInfo.experience.year) {
                    localSelectedProfile.experience = {
                        ...profileData.data.data.personalInfo.experience,
                        month: "0"
                    };
                } else {
                    localSelectedProfile.experience = {
                        year: "",
                        month: "0"
                    };
                }
                localSelectedProfile.categories = [];
                if (profileData.data.data.personalInfo.categories && profileData.data.data.personalInfo.categories.length) {
                    profileData.data.data.personalInfo.categories.forEach(each => {
                        localSelectedProfile.categories.push(each._id);
                    });
                }
                if (profileData.data.data.idPhoto) {
                    if (profileData.data.data.idPhoto.govtId) {
                        localSelectedProfile.idProof = profileData.data.data.idPhoto.govtId;
                    }
                    if (profileData.data.data.idPhoto.govtIdBack) {
                        localSelectedProfile.idBackProof = profileData.data.data.idPhoto.govtIdBack;
                    }
                    if (profileData.data.data.idPhoto.gumasta) {
                        localSelectedProfile.gumasta = profileData.data.data.idPhoto.gumasta;
                    }
                    if (profileData.data.data.idPhoto.certificate) {
                        localSelectedProfile.educationProof = profileData.data.data.idPhoto.certificate;
                    }
                    if (profileData.data.data.idPhoto.certificates && profileData.data.data.idPhoto.certificates.length) {
                        localSelectedProfile.educationProofs = profileData.data.data.idPhoto.certificates;
                    }
                    if (profileData.data.data.idPhoto.rciCertificate) {
                        localSelectedProfile.rciCertificate = profileData.data.data.idPhoto.rciCertificate;
                    }
                }
            }
            if (profileData.data.data.personalInfo) {
                localSelectedProfile.descriptionImages = profileData.data.data.photos;
            }
            localSelectedProfile.subType = profileData.data.data.subType || 1;
            localSelectedProfile.emailVerified = profileData.data.data.emailVerified;
            console.log("localSelectedProfile ", localSelectedProfile)
            selectProfile({ ...localSelectedProfile });
            props.updateProfile({ ...profileData.data.data });
            getAllCategories(localSelectedProfile.categories);
            setTimeout(() => {
                displayNavs(true);
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayNavs]);

    useEffect(() => {
        getInitialProfileDetails();
        getAllLocations();
        getUserLocation();
        getAllServices();
    }, [getInitialProfileDetails, getAllLocations, getUserLocation]);

    useEffect(() => {
        window.onclick = (e) => {
            if (e.target && e.target.className && e.target.className.indexOf && e.target.className.indexOf("custom-category-dropdown-select") <= -1 && e.target.className.indexOf("form-check") <= -1) {
                toggleCategory(false);
            }
        };
    });

    const openFileUploader = (imageId) => {
        if (document.getElementById(imageId)) {
            document.getElementById(imageId).click();
        }
    };

    const uploadGalleryImage = () => {
        if (document.getElementById("galleryImage")) {
            let imageFile = document.getElementById("galleryImage");
            if (imageFile.files[0]) {
                console.log("imageFile.files[0] ", imageFile.files[0]);
                if (imageFile.files[0].type.indexOf("jpeg") > -1
                    || imageFile.files[0].type.indexOf("png") > -1
                    || imageFile.files[0].type.indexOf("jpg") > -1) {
                    updateGalleryImage(imageFile.files[0]);
                    saveGalleryImage(imageFile.files[0]);
                } else {
                    toast.error("Please upload .jpeg/.jpg/.png file");
                    removeUploadedImage();
                    return;
                }
            }
        }
    };

    const commonGalleryUploadFunction = async (newFile) => {
        const result = await addPhoto(newFile);
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            return;
        }
        toast.success(API_SUCCESS.ADD_IMAGE);
        getProfileDetails("photos");

        setTimeout(() => {
            removeUploadedImage();
        }, 100);
    };

    const saveGalleryImage = async (file) => {
        if (file.size <= maxImageSize) {
            commonGalleryUploadFunction(file);
        } else {
            new Compressor(file, {
                quality: compressionPercentage,
                width: compressionWidth,
                success: async (data) => {
                    const newFile = new File([data], file.name, { type: file.type });
                    await commonGalleryUploadFunction(newFile);
                },
                error: () => {
                    toast.error("Error while uploading");
                    removeUploadedImage();
                },
            });
        }
    };

    const uploadQualificationImage = () => {
        if (document.getElementById("educationProofImage")) {
            let imageFile = document.getElementById("educationProofImage");
            if (imageFile.files[0]) {
                console.log("imageFile.files[0] ", imageFile.files[0]);
                if (imageFile.files[0].type.indexOf("jpeg") > -1
                    || imageFile.files[0].type.indexOf("png") > -1
                    || imageFile.files[0].type.indexOf("jpg") > -1) {
                    updateEducationProofImage(imageFile.files[0]);
                    saveQualificationImage();
                } else {
                    toast.error("Please upload .jpeg/.jpg/.png file");
                    removeUploadedQualificationImage();
                    return;
                }
            }
        }
    };

    const commonProfileUploadFunction = async (imageFile) => {
        toggleDisablePage(true);
        const result = await updateProfilePhoto(imageFile);
        toggleDisablePage(false);
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            return;
        }
        toast.success(API_SUCCESS.UPDATE_PROFILE_IMAGE);
    };

    const uploadProfileImage = async () => {
        if (document.getElementById("profileImage")) {
            let imageFile = document.getElementById("profileImage");
            if (imageFile.files[0]) {
                console.log("imageFile.files[0] ", imageFile.files[0]);
                if (imageFile.files[0].type.indexOf("jpeg") > -1
                    || imageFile.files[0].type.indexOf("png") > -1
                    || imageFile.files[0].type.indexOf("jpg") > -1) {
                    updateProfileImage(URL.createObjectURL(imageFile.files[0]));

                    if (imageFile.files[0].size <= maxImageSize) {
                        commonProfileUploadFunction(imageFile.files[0]);
                    } else {
                        new Compressor(imageFile.files[0], {
                            quality: compressionPercentage,
                            width: compressionWidth,
                            success: async (data) => {
                                const newFile = new File([data], imageFile.files[0].name, { type: imageFile.files[0].type });
                                await commonProfileUploadFunction(newFile);
                            },
                            error: () => {
                                toast.error("Error while uploading");
                                updateProfileImage(null);
                                if (document.getElementById("profileImage")) {
                                    document.getElementById("profileImage").value = "";
                                }
                            },
                        });
                    }
                } else {
                    toast.error("Please upload .jpeg/.jpg/.png file");
                    updateProfileImage(null);
                    if (document.getElementById("profileImage")) {
                        document.getElementById("profileImage").value = "";
                    }
                    return;
                }
            }
        }
    };

    const openRemove = (item, type) => {
        updateRemoveType(type);

        if (type === "Location") {
            updateLocationId(item._id);
            if (addresses.length === 1) {
                toast.error("Minimum 1 address is mandatory");
                return;
            }
        } else if (type === "Image") {
            updateImageId(item._id);
        } else if (type === "Certificate") {
            updateCertificateId(item._id);
            if (selectedProfile.educationProofs.length === 1) {
                toast.error("Minimum 1 qualification document is mandatory");
                return;
            }
        }
        openRemoveModal(true);
    };

    const removeUploadedImage = () => {
        updateGalleryImage(null);
        if (document.getElementById("galleryImage")) {
            document.getElementById("galleryImage").value = "";
        }
    };

    const removeUploadedQualificationImage = () => {
        updateEducationProofImage(null);
        if (document.getElementById("educationProofImage")) {
            document.getElementById("educationProofImage").value = "";
        }
    };

    const updateBankDetails = (text, fieldName) => {
        const localProfile = { ...selectedProfile };
        const localBank = { ...localProfile.bankDetails };
        const localBankError = { ...bankDetailsError };
        const localChequeError = { ...chequeDetailsError };
        const localChequeStringArray = ["isCheque", "chequeName", "chequeAddress"];
        const alphaRegex = /^[a-zA-Z ]*$/;
        const alphaNumRegex = /^[a-z\d\-_\s]+$/i;
        if (fieldName === "bankName" || fieldName === "accountHolderName" || fieldName === "chequeName") {
            if (alphaRegex.test(text)) {
                localBank[fieldName] = text;
                localProfile.bankDetails = { ...localBank };
                if (!localChequeStringArray.includes(fieldName)) {
                    localBankError[fieldName] = "";
                } else {
                    localChequeError[fieldName] = "";
                }
            }
        } else if (fieldName === "ifscCode" || fieldName === "branchName") {
            if (alphaNumRegex.test(text) || !text.length) {
                localBank[fieldName] = text;
                localProfile.bankDetails = { ...localBank };
                localBankError[fieldName] = "";
            }
        } else {
            localBank[fieldName] = text;
            localProfile.bankDetails = { ...localBank };
            if (!localChequeStringArray.includes(fieldName)) {
                localBankError[fieldName] = "";
            } else {
                localChequeError[fieldName] = "";
            }
        }

        selectProfile({ ...localProfile });
        if (!localChequeStringArray.includes(fieldName)) {
            updateBankDetailsError({ ...localBankError });
        } else {
            updateChequeDetailsError({ ...localChequeError });
        }
    };

    const validateBankDetails = () => {
        const localProfile = { ...selectedProfile };
        const localBank = { ...localProfile.bankDetails };
        const localBankError = { ...bankDetailsError };
        const localChequeError = { ...chequeDetailsError };
        const localChequeStringArray = ["isCheque", "chequeName", "chequeAddress"];
        let noErrorFlag = true;
        if (!isCheque) {
            for (let item in localBank) {
                if (!localChequeStringArray.includes(item) && item !== "gstNumber" && !localBank[item].toString().trim().length) {
                    localBankError[item] = REQUIRED_ERROR;
                    noErrorFlag = false;
                } else {
                    localBankError[item] = "";
                }
            }
            updateBankDetailsError({ ...localBankError });
            updateChequeDetailsError({ ...initialChequeDetailsError });
        } else {
            for (let item in localBank) {
                if (localChequeStringArray.includes(item) && !localBank[item].toString().trim().length) {
                    localChequeError[item] = REQUIRED_ERROR;
                    noErrorFlag = false;
                } else {
                    localChequeError[item] = "";
                }
            }
            if (localBank.bankName.trim().length) {
                let hasBankNameAndOtherBankDetails = true;
                for (let item in localBank) {
                    if (!localChequeStringArray.includes(item) && item !== "gstNumber" && !localBank[item].toString().trim().length) {
                        hasBankNameAndOtherBankDetails = false;
                        noErrorFlag = false;
                    }
                }
                if (!hasBankNameAndOtherBankDetails) {
                    toast.error("Please enter other bank details or remove bank name if you want to opt for cheque payment.");
                }
            }
            updateChequeDetailsError({ ...localChequeError });
            updateBankDetailsError({ ...initialBankDetailsError });
        }
        return noErrorFlag;
    };

    const saveBankDetails = async () => {
        console.log("selectedProfile.bankDetails ", selectedProfile.bankDetails)
        if (validateBankDetails()) {
            console.log("isscuccess");
            // return;
            const localBankDetails = { ...selectedProfile.bankDetails };
            localBankDetails.isCheque = isCheque;
            if (!localBankDetails.gstNumber) {
                localBankDetails.gstNumber = "_blank_";
            }
            toggleDisablePage(true);
            const result = await updateBank(localBankDetails);
            if (result.data.errorCode) {
                toast.error(API_ERROR[result.data.errorCode]);
                toggleDisablePage(false);
                return;
            }
            toast.success(API_SUCCESS.UPDATE_BANK);
            nextStep();
            getProfileDetails("bank");
        }
    };

    const changePersonalInfo = (text, fieldName) => {
        const localProfile = { ...selectedProfile };
        const localProfileError = { ...profileError };
        if (fieldName !== "year" && fieldName !== "month") {
            localProfile[fieldName] = fieldName === "email" ? text.trim() : text;
            localProfileError[fieldName] = "";
        } else {
            localProfile.experience = { ...localProfile.experience };
            localProfile.experience[fieldName] = text;
            localProfileError.experience = "";
        }
        selectProfile({ ...localProfile });
        updateProfileError({ ...localProfileError });
    };

    const validateProfile = () => {
        const localProfileError = { ...profileError };
        let noErrorFlag = true;

        for (let item in localProfileError) {
            if (item === "name" || item === "email" || item === "websiteUrl") {
                if (item !== "websiteUrl" && !selectedProfile[item].trim().length) {
                    localProfileError[item] = REQUIRED_ERROR;
                    noErrorFlag = false;
                } else {
                    localProfileError[item] = "";
                }

                if (item === "email" && selectedProfile[item].trim().length) {
                    if (!validateEmail(selectedProfile[item])) {
                        localProfileError[item] = INVALID_EMAIL;
                        noErrorFlag = false;
                    } else {
                        localProfileError[item] = "";
                    }
                }

                if (item === "websiteUrl" && selectedProfile[item].trim().length) {
                    if (!validateWebsite(selectedProfile[item])) {
                        localProfileError[item] = INVALID_WEBSITE;
                        noErrorFlag = false;
                    } else {
                        localProfileError[item] = "";
                    }
                }
            }
            if (item === "experience") {
                console.log("selectedProfile.experience ", selectedProfile.experience);
                if (selectedProfile.experience.year === "" || selectedProfile.experience.month === "") {
                    localProfileError.experience = REQUIRED_ERROR;
                    noErrorFlag = false;
                } else {
                    localProfileError.experience = "";
                }

                // if (selectedProfile.experience.month !== "") {
                //     if (Number(selectedProfile.experience.month) > 11) {
                //         localProfileError.experience = INVALID_MONTH;
                //         noErrorFlag = false;
                //     } else {
                //         localProfileError.experience = "";
                //     }
                // }

            }
            if (item === "category") {
                let categoryError = true;
                if (categoryList.length) {
                    categoryList.forEach(each => {
                        if (each.selected) {
                            categoryError = false;
                        }
                    });
                }

                if (categoryError) {
                    localProfileError.category = CHOOSE_CATEGORY_ERROR;
                    noErrorFlag = false;
                } else {
                    localProfileError.category = "";
                }
            }
        }
        if (noErrorFlag && (!uploadedProfileImage && !selectedProfile.profilePicture)) {
            toast.error("Please upload profile picture");
            noErrorFlag = false;
        }
        if (noErrorFlag && !selectedProfile.emailVerified) {
            toast.error("Please verify email");
            noErrorFlag = false;
        }
        console.log("localProfileError ", localProfileError)
        updateProfileError({ ...localProfileError });
        return noErrorFlag;
    };

    const savePersonalInfo = async () => {
        console.log("profile--------- ", selectedProfile);
        if (validateProfile()) {
            const localSelectedCategories = [];
            if (categoryList.length) {
                categoryList.forEach(each => {
                    if (each.selected) {
                        localSelectedCategories.push(each._id);
                    }
                });
            }
            const requestBody = {
                name: selectedProfile.name,
                email: selectedProfile.email,
                categories: localSelectedCategories,
                experience: {
                    year: Number(selectedProfile.experience.year),
                    month: Number(selectedProfile.experience.month)
                }
            };
            if (selectedProfile.websiteUrl) {
                requestBody.website = selectedProfile.websiteUrl;
                if (!requestBody.website.includes('http://') && !requestBody.website.includes('https://')) {
                    requestBody.website = 'http://' + requestBody.website;
                }
            } else {
                requestBody.website = "_blank_";
            }
            if (selectedProfile.description) {
                requestBody.description = selectedProfile.description;
            } else {
                requestBody.description = "_blank_";
            }

            toggleDisablePage(true);
            const result = await updateProfile(requestBody);
            if (result.data.errorCode) {
                toast.error(API_ERROR[result.data.errorCode]);
                toggleDisablePage(false);
                return;
            }
            toast.success(API_SUCCESS.UPDATE_PROFILE);
            getProfileDetails("personalInfo");

            if (selectedProfile.status === VendorConfigStatus.approvalPending || selectedProfile.status === VendorConfigStatus.approvalRejected) {
                updateCurrentStep(15);
            } else {
                nextStep();
            }
            // props.updateProfile({ ...result.data.data });
        }
    };

    const updateLocationDetails = (text, fieldName, isChosen) => {
        const localLocation = { ...locationDetails };
        const localLocationError = { ...locationDetailsError };

        localLocationError[fieldName] = "";
        if (fieldName === "address" && isChosen) {
            localLocation.addressLine1 = text.formatted_address;
            localLocation[fieldName] = text.formatted_address;
            for (let i = 0; i < text.address_components.length; i++) {
                for (let j = 0; j < text.address_components[i].types.length; j++) {
                    if (text.address_components[i].types[j] === "postal_code") {
                        localLocation.addressLine2 = text.address_components[i].long_name;
                    }
                }
            }
            // pinLocation(true);
        } else {
            localLocation[fieldName] = text;
        }
        console.log("localLocation ", localLocation, locationDetails)
        updateLocationDetailsError({ ...localLocationError });
        modifyLocationDetails({ ...localLocation });
    };

    const toggleDaySelection = (index) => {
        const localDays = [...daysArray];
        const localLocationError = { ...locationDetailsError };
        localDays[index].selected = !localDays[index].selected;
        localLocationError.days = "";
        updateDaysArray([...localDays]);
        updateLocationDetailsError({ ...localLocationError });
    };

    const updateDateError = () => {
        const localLocationError = { ...locationDetailsError };
        localLocationError.selectedFromDate = "";
        updateLocationDetailsError({ ...localLocationError });
    };

    const validateLocation = () => {
        const localLocationError = { ...locationDetailsError };
        console.log("locationDetails ", locationDetails)
        let daysError = true;
        let noError = true;
        if (!locationDetails.title.trim().length) {
            localLocationError.title = REQUIRED_ERROR;
            noError = false;
        } else {
            localLocationError.title = "";
        }

        if (!locationDetails.address.trim().length) {
            localLocationError.address = REQUIRED_ERROR;
            noError = false;
        } else {
            localLocationError.address = "";
        }

        if (new Date(selectedFromDate) > new Date(selectedToDate)) {
            localLocationError.selectedFromDate = INVALID_FROM_TO_DATE;
            noError = false;
        } else {
            localLocationError.selectedFromDate = "";
        }

        daysArray.forEach(each => {
            if (each.selected) {
                daysError = false;
            }
        });

        if (daysError) {
            localLocationError.days = CHOOSE_ERROR;
            noError = false;
        } else {
            localLocationError.days = "";
        }

        if (!selectedCoordinates.length) {
            localLocationError.address = INVALID_LOCATION;
            noError = false;
        } else {
            localLocationError.address = "";
        }

        updateLocationDetailsError({ ...localLocationError });
        return noError;
    };

    const openLocationList = () => {
        const localDays = [...daysArray];
        localDays.forEach(each => {
            each.selected = false;
        });
        updateDaysArray([...localDays]);
        updateMode("list");
        updateLocationDetailsError({ ...initialLocationError });
        getAllLocations();
        modifyLocationDetails({
            ...initialLocation
        });
        updateCoordinate([]);
        handleFromDateChange(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)));
        handleToDateChange(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(30)).setSeconds(0)));
    };

    const saveLocation = async (currentMode) => {
        if (!validateLocation()) {
            return;
        }
        let result;
        const fromIndex = (timeArr.indexOf(formatAMPM(selectedFromDate)) + 1);
        const toIndex = (timeArr.indexOf(formatAMPM(selectedToDate)));
        const localWorkingTimeSlots = [];
        const localDays = [];
        daysArray.forEach(each => {
            if (each.selected) {
                localDays.push(each.day);
            }
        });
        if (fromIndex === toIndex) {
            localWorkingTimeSlots.push(fromIndex);
        } else {
            for (let i = fromIndex; i <= toIndex; i++) {
                localWorkingTimeSlots.push(i);
            }
        }
        if (!localWorkingTimeSlots.length) {
            toast.error("Please choose correct start and end time");
            return;
        }
        const requestBody = {
            title: locationDetails.title,
            address: locationDetails.address,
            addressLine1: locationDetails.addressLine1,
            addressLine2: locationDetails.addressLine2,
            addressLine3: locationDetails.addressLine3,
            workingTimeSlots: localWorkingTimeSlots,
            "location": {
                "coordinates": selectedCoordinates
            },
            daysOfWeek: localDays
        }
        toggleDisablePage(true);
        // return;
        if (currentMode === "add") {
            result = await addLocation(requestBody);
        } else {
            requestBody._id = locationDetails._id;
            result = await updateLocation(requestBody);
        }
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            toggleDisablePage(false);
            return;
        }
        toast.success(currentMode === "add" ? API_SUCCESS.ADD_LOCATION : API_SUCCESS.UPDATE_LOCATION);

        openLocationList();

    };

    const chooseLocation = (locationData) => {
        console.log("locationdata ", locationData)
        updateMode("edit");
        pinLocation(true);
        const localLocationDetails = { ...locationDetails };
        const localDays = [...daysArray];
        localLocationDetails.title = locationData.title;
        localLocationDetails.address = locationData.address;
        localLocationDetails.addressLine1 = locationData.addressLine1;
        localLocationDetails.addressLine2 = locationData.addressLine2;
        localLocationDetails.addressLine3 = locationData.addressLine3;
        localLocationDetails._id = locationData._id;
        handleFromDateChange(getStartTime(locationData.workingTimeSlots));
        handleToDateChange(getEndTime(locationData.workingTimeSlots));
        updateCoordinate(locationData.location.coordinates);

        if (locationData.daysOfWeek && locationData.daysOfWeek.length) {
            locationData.daysOfWeek.forEach((each, index) => {
                localDays.forEach(each1 => {
                    if (each === each1.day) {
                        each1.selected = true;
                    }
                });
            });
        }
        updateDaysArray([...localDays]);
        modifyLocationDetails({ ...localLocationDetails });
        initMap(locationData.location.coordinates);
    };

    const removeData = async () => {
        if (removeType === "Location") {
            toggleDisablePage(true);
            const result = await removeLocation(selectedLocationIdToRemove);
            if (result.data.errorCode) {
                toast.error(API_ERROR[result.data.errorCode]);
                toggleDisablePage(false);
                return;
            }
            toast.success(API_SUCCESS.REMOVE_LOCATION);
            updateLocationId("");
            openLocationList();
            openRemoveModal(false);
        } else if (removeType === "Image") {
            toggleDisablePage(true);
            const result = await removeImage(selectedImageIdToRemove);
            if (result.data.errorCode) {
                toast.error(API_ERROR[result.data.errorCode]);
                toggleDisablePage(false);
                return;
            }
            toast.success(API_SUCCESS.REMOVE_IMAGE);
            updateImageId("");
            getProfileDetails("photos");
            openRemoveModal(false);
        } else if (removeType === "Certificate") {
            toggleDisablePage(true);
            const result = await removeCertificate(selectedCertificateIdToRemove);
            if (result.data.errorCode) {
                toast.error(API_ERROR[result.data.errorCode]);
                toggleDisablePage(false);
                return;
            }
            toast.success(API_SUCCESS.REMOVE_IMAGE);
            updateCertificateId("");
            getProfileDetails("personalInfo");
            openRemoveModal(false);
        }
    };

    const toggleCategorySelection = (categoryValue, index) => {
        const localCateories = [...categoryList];
        const localProfileError = { ...profileError };
        const localProfileDetails = { ...selectedProfile };

        localCateories[index].selected = !localCateories[index].selected;
        localProfileError.category = "";

        if (localProfileDetails.categories.includes(categoryValue)) {
            localProfileDetails.categories.splice(localProfileDetails.categories.indexOf(categoryValue), 1);
        } else {
            if (localCateories[index].selected) {
                localProfileDetails.categories.push(categoryValue);
            }
        }

        selectProfile({ ...localProfileDetails });

        console.log("selectedProfile ", localProfileDetails.categories, categoryValue)
        updateCategoryList([...localCateories]);
        updateProfileError({ ...localProfileError });
    };

    const saveQualificationImage = () => {
        uploadProofImage("educationProofImage");
    };

    const initAutocomplete = () => {
        updateCoordinate([]);
        if (window.google) {
            // const map = new window.google.maps.Map(document.getElementById("map"), {
            //     center: { lat: -33.8688, lng: 151.2195 },
            //     zoom: 13,
            //     mapTypeId: "roadmap",
            // });
            // Create the search box and link it to the UI element.
            const input = document.getElementById("location-search-input");
            const searchBox = new window.google.maps.places.Autocomplete(input);

            searchBox.setComponentRestrictions({
                country: ["in"],
            });
            // map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);
            // Bias the SearchBox results towards current map's viewport.
            map.addListener("bounds_changed", () => {
                searchBox.setBounds(map.getBounds());
            });
            // Listen for the event fired when the user selects a prediction and retrieve
            // more details for that place.
            searchBox.addListener("place_changed", () => {
                const place = searchBox.getPlace();

                if (!place || (place && !Object.keys(place).length)) {
                    return;
                }

                // For each place, get the icon, name and location.
                const bounds = new window.google.maps.LatLngBounds();
                // places.forEach((place) => {
                if (!place.geometry || !place.geometry.location) {
                    console.log("Returned place contains no geometry");
                    return;
                }

                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
                marker.setMap(null);
                myLatLng = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                };
                setMarker({
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                });
                updateCoordinate([place.geometry.location.lng(), place.geometry.location.lat()]);
                updateLocationDetails(place, 'address', true);
                // console.log("places ",place)
                // });
                map.fitBounds(bounds);
            });
        }

    };

    const chooseGalleryImage = (index) => {
        updateCurrentImageIndex(index);
        toggleGalleryModal(true);
    };

    const removeProfilePicture = async () => {
        toggleDisablePage(true);
        const result = await removePhoto();
        toggleDisablePage(false);
        console.log("result ", result);
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            return;
        }

        toast.success(API_SUCCESS.PROFILE_IMAGE_REMOVED);
        if (document.getElementById("profileImageId")) {
            document.getElementById("profileImageId").style.backgroundImage = "url(" + require("../../../../images/imgAvtr.png") + ")";
        }
        if (document.getElementById("profileImage")) {
            document.getElementById("profileImage").value = "";
        }
        getProfileDetails();
    };

    const commonProofUploadFunction = async (imageFile, id) => {
        toggleDisablePage(true);
        const result = await uploadId(imageFile, id === 'idProofImage' ? 'govtId' : id === 'educationProofImage' ? 'certificates' : id === 'idBackProofImage' ? 'govtIdBack' : id === "gumastaImage" ? 'gumasta' : 'rciCertificate');
        toggleDisablePage(false);
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            return;
        }
        toast.success(id === 'idProofImage' ? API_SUCCESS.GOVT_ID_UPLOADED : id === 'educationProofImage' ? API_SUCCESS.CERT_UPLOADED : id === 'idBackProofImage' ? API_SUCCESS.GOVT_ID_BACK_UPLOADED : id === "gumastaImage" ? API_SUCCESS.GUMASTA_UPLOADED : API_SUCCESS.RCI_UPLOADED);

        getProfileDetails();
        if (id === "educationProofImage") {
            setTimeout(() => {
                removeUploadedQualificationImage();
            }, 100);
        }
    };

    const uploadProofImage = async (id) => {
        if (document.getElementById(id)) {
            let imageFile = document.getElementById(id);
            if (imageFile.files[0]) {
                console.log("imageFile.files[0] ", imageFile.files[0]);
                if (imageFile.files[0].type.indexOf("jpeg") > -1
                    || imageFile.files[0].type.indexOf("png") > -1
                    || imageFile.files[0].type.indexOf("jpg") > -1) {
                    if (id === "idProofImage") {
                        updateIdProofImage(URL.createObjectURL(imageFile.files[0]));
                    } else if (id === "idBackProofImage") {
                        updateIdBackProofImage(URL.createObjectURL(imageFile.files[0]));
                    } else if (id === "educationProofImage") {
                        updateEducationProofImage(imageFile.files[0]);
                    } else if (id === "gumastaImage") {
                        updateGumastaImage(URL.createObjectURL(imageFile.files[0]));
                    } else {
                        updateRCIImage(URL.createObjectURL(imageFile.files[0]));
                    }
                    // return
                    if (imageFile.files[0].size <= maxImageSize) {
                        commonProofUploadFunction(imageFile.files[0], id);
                    } else {
                        new Compressor(imageFile.files[0], {
                            quality: compressionPercentage,
                            width: compressionWidth,
                            success: async (data) => {
                                const newFile = new File([data], imageFile.files[0].name, { type: imageFile.files[0].type });
                                await commonProofUploadFunction(newFile, id);
                            },
                            error: () => {
                                toast.error("Error while uploading");
                                removeUploadedQualificationImage();
                            },
                        });
                    }

                } else {
                    toast.error("Please upload .jpeg/.jpg/.png file");
                    removeUploadedQualificationImage();
                    return;
                }
            }
        }
    };

    const getAllServices = async () => {
        toggleDisablePage(true);
        const servicesResult = await getServices();
        toggleDisablePage(false);
        if (servicesResult.data.errorCode) {
            toast.error(API_ERROR[servicesResult.data.errorCode]);
            return;
        }
        console.log("servicesResult ", servicesResult);
        if (servicesResult.data.data && servicesResult.data.data.length) {
            updateHasServices(true);
        } else {
            updateHasServices(false);
        }
    };

    const requestApproval = async (isFromTracker) => {
        toggleDisablePage(true);
        const result = await requestForApproval();
        toggleDisablePage(false);
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            return;
        }
        toast.success(API_SUCCESS.APPROVAL_REQUESTED);
        // if (!isFromTracker) {
        updateCurrentStep(14);
        // }
        getProfileDetails();
    };

    const changeChequeSelection = (chequeSelected) => {
        toggleCheque(chequeSelected);
        if (!chequeSelected) {
            updateChequeDetailsError({ ...initialChequeDetailsError });
        } else {
            updateBankDetailsError({ ...initialBankDetailsError });
        }
    };

    const saveVendorType = async (vendorType) => {
        toggleDisablePage(true);
        const result = await updateProfile({ subType: Number(vendorType) });
        toggleDisablePage(false);
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            return;
        }
        toast.success(API_SUCCESS.SAVE_VENDOR_TYPE);
        getAllServices();
        getAllLocations();
        openVendorTypeModal(false);
        getProfileDetails("personalInfo");
    };

    const openVerifyEmailModal = async (isFromModal) => {
        toggleDisablePage(true);
        const result = await requestEmailOtp({
            "email": selectedProfile.email
        });
        toggleDisablePage(false);
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            return;
        }
        console.log("result ", result);
        if (!isFromModal && result.data.success) {
            toggleVerifyEmailModal(true);
        }
        toast.success(API_SUCCESS.EMAIL_SENT);
    };

    const verifyOtp = async (otp) => {
        toggleDisablePage(true);
        const result = await confirmEmailOtp({
            "otp": otp
        });
        toggleDisablePage(false);
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            return;
        }
        console.log("result ", result);
        if (result.data.success) {
            toast.success(API_SUCCESS.EMAIL_VERIFIED);
            toggleVerifyEmailModal(false);
            getProfileDetails("emailVerify");
        }
    };

    const geocodePosition = (pos) => {
        geocoder.geocode({
            latLng: pos
        }, (responses) => {
            console.log("responses ", responses)
            if (responses && responses.length > 0) {
                updateCoordinate([pos.lng(), pos.lat()]);
                updateDraggedAddress(responses[0]);
            } else {
                console.log('Cannot determine address at this location.');
            }
        });
    };

    useEffect(() => {
        if (draggedAddress && Object.keys(draggedAddress).length) {
            updateLocationDetails(draggedAddress, 'address', true);
            setTimeout(() => {
                updateDraggedAddress({});
            }, 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [draggedAddress]);


    const setMarker = (pos, isCurrent) => {
        marker = new window.google.maps.Marker({
            position: pos,
            map,
            draggable: true
        });
        if (isCurrent) {
            geocodePosition(marker.getPosition());
        }
        marker.addListener("dragend", () => {
            // console.log("getpos ", marker.getPosition().lat(), marker.getPosition().lng());
            // geocodePosition(marker.getPosition());
            myLatLng = { lat: marker.getPosition().lat(), lng: marker.getPosition().lng() };
        });
    };

    const initMap = (locationData) => {
        setTimeout(() => {
            geocoder = new window.google.maps.Geocoder();
            myLatLng = { lat: 22.7196, lng: 75.8577 };
            if (locationData && locationData.length) {
                myLatLng = { lat: locationData[1], lng: locationData[0] }
            } else if (userLocation.length) {
                myLatLng = { lat: userLocation[1], lng: userLocation[0] }
            }
            map = new window.google.maps.Map(document.getElementById("mapAddress"), {
                center: myLatLng,
                zoom: 18,
                mapTypeId: "roadmap",
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControlOptions: {
                    position: window.google.maps.ControlPosition.LEFT_BOTTOM
                }
            });
            setMarker(myLatLng);
        }, 100);
    };

    const nextStep = () => {
        if (selectedProfile.status === VendorConfigStatus.approvalPending || selectedProfile.status === VendorConfigStatus.approvalRejected) {
            updateCurrentStep(15);
        } else {
            updateCurrentStep(currentStep + 1);
        }
    };

    const getServiceList = (services) => {
        if (services.length) {
            updateHasServices(true);
        } else {
            updateHasServices(false);
        }
    };

    const openStep = (stepIndex) => {
        const stepObj = {
            1: 3,
            2: 5,
            3: 7,
            4: 9,
            5: 11,
            6: 13
        };
        if (!stepIndex) {
            openVendorTypeModal(true);
        } else {
            updateCurrentStep(stepObj[stepIndex]);
        }
    };

    const backStep = (isTwice) => {
        if (!isTwice) {
            updateCurrentStep(currentStep - 1);
        } else {
            updateCurrentStep(currentStep - 2);
        }

    };

    const openHome = () => {
        props.history.push('/dashboard');
    };

    const clickInfo = () => {
        if (selectedProfile.status === VendorConfigStatus.approvalPending) {
            updateCurrentStep(14);
        } else if (selectedProfile.status === VendorConfigStatus.approvalRejected) {
            updateCurrentStep(17);
        }
    };

    const setCurrentLocation = () => {
        setMarker(myLatLng, true);
    };

    const chooseCurrentUserLocation = () => {
        console.log("userLocation ", userLocation)
        if (userLocation.length) {
            marker.setMap(null);
            myLatLng = { lat: userLocation[1], lng: userLocation[0] };
            setMarker(myLatLng);
            map.setCenter(myLatLng);
        } else {
            if (window.navigator.geolocation) {
                window.navigator.geolocation.getCurrentPosition((position) => {
                    if (position && position.coords && position.coords.latitude && position.coords.longitude) {
                        updateUserLocation([position.coords.longitude, position.coords.latitude]);
                        marker.setMap(null);
                        myLatLng = { lat: position.coords.latitude, lng: position.coords.longitude };
                        setMarker(myLatLng);
                        map.setCenter(myLatLng);
                    }
                }, (error) => {
                    if (error.code === 1) {
                        toast.error("Not able access your current location. If you have denied the permission then please refresh the page or Please change the location access from your browser's settings");
                    }
                });
            } else {
                toast.error("Geolocation is not supported by this browser.");
            }
        }
    };

    const checkLink = () => {
        if (!selectedProfile.websiteUrl.includes('http://') && !selectedProfile.websiteUrl.includes('https://')) {
            window.open('http://' + selectedProfile.websiteUrl);
        } else {
            window.open(selectedProfile.websiteUrl);
        }
    };

    return <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className={`row dashboard onboarding ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0, height: "100%" }}>
            {displayRemoveModal ? <RemoveModal
                displayRemoveModal={displayRemoveModal}
                openRemoveModal={openRemoveModal}
                removeType={removeType}
                removeData={removeData} /> : null}
            {verifyEmailModalOpened ?
                <OtpModal displayModal={verifyEmailModalOpened}
                    closeModal={toggleVerifyEmailModal} verifyOtp={verifyOtp} resend={openVerifyEmailModal} /> : null}
            {displayVendorTypeModal ?
                <VendorTypeModal displayModal={displayVendorTypeModal} closeModal={openVendorTypeModal}
                    saveVendorType={saveVendorType} subType={selectedProfile.subType} /> : null}
            {selectedProfile.descriptionImages && openGalleryModal ?
                <GalleryModal openGalleryModal={openGalleryModal} galleryImages={selectedProfile.descriptionImages} currentImageIndex={currentImageIndex} toggleGalleryModal={toggleGalleryModal} updateCurrentImageIndex={updateCurrentImageIndex} /> : null}
            {currentStep === 1 ?
                <NextStepInfo
                    text1={"Let's Begin"}
                    text2={"Your Journey"}
                    currentStep={currentStep}
                    buttonText="Start"
                    nextStep={nextStep}
                /> : null}
            {currentStep === 2 ?
                <NextStepInfo
                    backStep={backStep}
                    text1={"Mandatory"}
                    text2={"Requirements"}
                    currentStep={currentStep}
                    buttonText="Continue"
                    nextStep={nextStep}
                    lists={["Profile Picture", "Valid Govt Photo Id"]}
                /> : null}
            {currentStep === 3 ?
                <UserDetails
                    VendorConfigTextStatus={VendorConfigTextStatus}
                    nextStep={nextStep}
                    addresses={addresses}
                    savePersonalInfo={savePersonalInfo}
                    updateProfileError={updateProfileError}
                    initialProfileError={initialProfileError}
                    getProfileDetails={getProfileDetails}
                    uploadedProfileImage={uploadedProfileImage}
                    selectedProfile={selectedProfile}
                    uploadProfileImage={uploadProfileImage}
                    openFileUploader={openFileUploader}
                    removeProfilePicture={removeProfilePicture}
                    profileError={profileError}
                    changePersonalInfo={changePersonalInfo}
                    validateEmail={validateEmail}
                    VendorConfigStatus={VendorConfigStatus}
                    categoryList={categoryList}
                    categoryDropdownOpened={categoryDropdownOpened}
                    toggleCategory={toggleCategory}
                    checkLink={checkLink}
                    toggleCategorySelection={toggleCategorySelection}
                    openVerifyEmailModal={openVerifyEmailModal} />
                : null}
            {currentStep === 4 ?
                <NextStepInfo
                    backStep={backStep}
                    text1={"Please add the location of your"}
                    text2={"service / practice, your working"}
                    text3={"days and hours"}
                    currentStep={currentStep}
                    buttonText="Continue"
                    nextStep={nextStep}
                /> : null}
            {currentStep === 5 ?
                <AddressManager
                    mode={mode}
                    chooseCurrentUserLocation={chooseCurrentUserLocation}
                    selectedProfile={selectedProfile}
                    addresses={addresses}
                    chooseLocation={chooseLocation}
                    openRemove={openRemove}
                    updateLocationDetailsError={updateLocationDetailsError}
                    initialLocationError={initialLocationError}
                    openLocationList={openLocationList}
                    saveLocation={saveLocation}
                    locationDetailsError={locationDetailsError}
                    locationDetails={locationDetails}
                    updateLocationDetails={updateLocationDetails}
                    pinLocation={pinLocation}
                    selectedCoordinates={selectedCoordinates}
                    initAutocomplete={initAutocomplete}
                    selectedFromDate={selectedFromDate}
                    handleFromDateChange={handleFromDateChange}
                    updateDateError={updateDateError}
                    selectedToDate={selectedToDate}
                    handleToDateChange={handleToDateChange}
                    daysArray={daysArray}
                    toggleDaySelection={toggleDaySelection}
                    updateMode={updateMode}
                    locationPinned={locationPinned}
                    setCurrentLocation={setCurrentLocation}
                    initMap={initMap}
                    nextStep={nextStep}
                    VendorConfigStatus={VendorConfigStatus}
                    backStep={backStep}
                />
                : null}
            {currentStep === 6 ?
                <NextStepInfo
                    backStep={backStep}
                    text1={"Please add some pictures to"}
                    text2={"the gallery to showcase your"}
                    text3={"services and facilities"}
                    text4={"*Not mandatory - You can upload them later"}
                    currentStep={currentStep}
                    buttonText="Continue"
                    nextStep={nextStep}
                /> : null}
            {currentStep === 7 ?
                <Gallery
                    backStep={backStep}
                    selectedProfile={selectedProfile}
                    uploadedGalleryImage={uploadedGalleryImage}
                    displayNavs={displayNavs}
                    prevClick={prevClick}
                    nextClick={nextClick}
                    removeUploadedImage={removeUploadedImage}
                    saveGalleryImage={saveGalleryImage}
                    openFileUploader={openFileUploader}
                    uploadGalleryImage={uploadGalleryImage}
                    chooseGalleryImage={chooseGalleryImage}
                    openRemove={openRemove}
                    showPrevButtons={showPrevButtons}
                    showNextButtons={showNextButtons}
                    nextStep={nextStep}
                    VendorConfigStatus={VendorConfigStatus}
                />
                : null}
            {currentStep === 8 ?
                <NextStepInfo
                    backStep={backStep}
                    text1={"Please provide your bank details"}
                    text2={"to receive fund credit from all"}
                    text3={"your bookings"}
                    text4={"*You can skip for now and upload the details later to receive your payment"}
                    currentStep={currentStep}
                    buttonText="Continue"
                    nextStep={nextStep}
                /> : null}
            {currentStep === 9 ?
                <BankDetails
                    updateBankDetailsError={updateBankDetailsError}
                    initialBankDetailsError={initialBankDetailsError}
                    getProfileDetails={getProfileDetails}
                    saveBankDetails={saveBankDetails}
                    bankDetailsError={bankDetailsError}
                    selectedProfile={selectedProfile}
                    updateBankDetails={updateBankDetails}
                    isCheque={isCheque}
                    changeChequeSelection={changeChequeSelection}
                    chequeDetailsError={chequeDetailsError}
                    backStep={backStep}
                    VendorConfigStatus={VendorConfigStatus}
                    nextStep={nextStep}
                /> : null}
            {currentStep === 10 ?
                <NextStepInfo
                    backStep={backStep}
                    text1={"Please provide the relevant"}
                    text2={"documents for approval"}
                    currentStep={currentStep}
                    buttonText="Continue"
                    nextStep={nextStep}
                /> : null}
            {currentStep === 11 ?
                <Documents
                    selectedProfile={selectedProfile}
                    nextStep={nextStep}
                    uploadedIdProofImage={uploadedIdProofImage}
                    VendorConfigStatus={VendorConfigStatus}
                    openFileUploader={openFileUploader}
                    uploadProofImage={uploadProofImage}
                    uploadedIdBackProofImage={uploadedIdBackProofImage}
                    uploadedEducationProofImage={uploadedEducationProofImage}
                    prevClick={prevClick}
                    showPrevQualificationButtons={showPrevQualificationButtons}
                    nextClick={nextClick}
                    showNextQualificationButtons={showNextQualificationButtons}
                    removeUploadedQualificationImage={removeUploadedQualificationImage}
                    saveQualificationImage={saveQualificationImage}
                    uploadQualificationImage={uploadQualificationImage}
                    openRemove={openRemove}
                    uploadedRCIImage={uploadedRCIImage}
                    uploadedGumastaImage={uploadedGumastaImage}
                    displayNavs={displayNavs}
                    backStep={backStep}
                />
                : null}
            {currentStep === 12 ?
                <NextStepInfo
                    backStep={backStep}
                    text1={"Please add atleast 1 service to"}
                    text2={"make your profile visible to the"}
                    text3={"users"}
                    currentStep={currentStep}
                    buttonText="Continue"
                    nextStep={nextStep}
                /> : null}
            {currentStep === 13 ?
                <div className='col-12 service'>
                    {selectedProfile.status === VendorConfigStatus.active ?
                        <div className='row m-0'>
                            <div className='col-12 text-center'>
                                <button className='btn btn-primary' disabled={!hasServices} onClick={() => requestApproval(false)}>Submit for Approval</button>
                            </div>
                        </div>
                        : null}
                    <Service getServiceList={getServiceList}
                        nextStep={nextStep}
                        VendorConfigStatus={VendorConfigStatus}
                        selectedProfile={selectedProfile}
                        backStep={backStep} />

                </div>

                : null}
            {currentStep === 14 ?
                <NextStepInfo
                    text1={"Thank you for"}
                    text2={"completing registration,"}
                    text3={"your profile is pending verification"}
                    currentStep={currentStep}
                    buttonText="View Details"
                    nextStep={nextStep}
                /> : null}
            {currentStep === 15 ?
                <StatusTracker
                    VendorConfigTextStatus={VendorConfigTextStatus}
                    selectedProfile={selectedProfile}
                    openStep={openStep}
                    hasAddress={hasAddress}
                    hasServices={hasServices}
                    requestApproval={requestApproval}
                    VendorConfigStatus={VendorConfigStatus}
                    clickInfo={clickInfo}
                    getInitialProfileDetails={getInitialProfileDetails}
                />
                : null}
            {currentStep === 16 ?
                <NextStepInfo
                    currentStep={currentStep}
                    buttonText="Continue"
                    openHome={openHome}
                /> : null}
            {currentStep === 17 ?
                <NextStepInfo
                    currentStep={currentStep}
                    buttonText="View Details"
                    nextStep={() => updateCurrentStep(15)}
                /> : null}
        </div>
    </MuiPickersUtilsProvider>
};

const mapStateToProps = state => {
    return { data: state };
};

export default injectIntl(
    connect(
        mapStateToProps,
        auth.actions
    )(Onboarding)
);