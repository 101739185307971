import React from 'react';
import "./gallery.css";

const GalleryModal = (props) => {

    const openNext = () => {
        if (props.galleryImages.length - 1 === props.currentImageIndex) {
            props.updateCurrentImageIndex(0);
        } else {
            let localInd = props.currentImageIndex;
            props.updateCurrentImageIndex(++localInd);
        }
    }

    const openPrev = () => {
        if (props.currentImageIndex === 0) {
            props.updateCurrentImageIndex(props.galleryImages.length - 1);
        } else {
            let localInd = props.currentImageIndex;
            props.updateCurrentImageIndex(--localInd);
        }
    }

    return (
        <div className={`row gallery`} style={{ margin: 0 }}>
            <div className={`modal ask-question-modal fade ${props.openGalleryModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Gallery</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => props.toggleGalleryModal(false)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12 p-0">
                                <img src={props.galleryImages[props.currentImageIndex].url} alt="gallery" style={{width: "100%", height: "400px"}} />
                            </div>
                            <div className="col-12 p-0 text-center arrow-wrapper">
                                <i className="fa fa-angle-left left-arrow" aria-hidden="true" onClick={openPrev}></i>
                                <i className="fa fa-angle-right right-arrow" aria-hidden="true" onClick={openNext}></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default GalleryModal;