import React, { useEffect, useState, useCallback } from 'react';
import "./forum-new.css";
import { getQAList } from "../../crud/qa-forum-crud";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import {
    API_ERROR
} from "../../config/error-message";

const qaStatus = {
    1: "Pending",
    2: "Approved",
    3: "Rejected"
};

const monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const defaultImage = require('../../../images/imgAvtr.png');

const MyQA = (props) => {
    const [qaList, updateQA] = useState([]);
    const [disablePage, toggleDisablePage] = useState(false);
    const [loadingQuestions, toggleLoadingQuestions] = useState(true);
    const { authData } = useSelector(
        ({ auth }) => ({
            authData: auth.user
        }),
        shallowEqual
    );

    const getFullDate = (date) => {
        const d = new Date(date);
        return d.getDate() + ' ' + monthArr[d.getMonth()] + ' ' + d.getFullYear();
    };

    const getAllQaList = useCallback(async () => {
        toggleDisablePage(true);
        const qaResults = await getQAList();
        toggleDisablePage(false);
        toggleLoadingQuestions(false);
        if (qaResults.data.errorCode) {
            toast.error(API_ERROR[qaResults.data.errorCode]);
            return;
        }
        if (qaResults.data.data && qaResults.data.data.errorCode) {
            toast.error(API_ERROR[qaResults.data.data.errorCode]);
            return;
        }
        console.log("qaResults ", qaResults, authData)
        if (qaResults.data.data && qaResults.data.data.data && qaResults.data.data.data.length) {
            let qaObj = {};
            const qaArr = [];
            qaResults.data.data.data.forEach(each => {
                qaObj = {};
                qaObj._id = each._id;
                qaObj.questionText = each.question;
                qaObj.selected = false;
                qaObj.questionedByMe = false;
                qaObj.answerByMe = false;
                qaObj.date = getFullDate(each.createdAt);
                qaObj.status = Number(each.status); // status need from response
                if (each.customerId &&
                    authData &&
                    each.customerId === authData._id) {
                    qaObj.questionedByMe = true;
                }
                if (!qaObj.questionedByMe) {
                    qaObj.answerByMe = true;
                }
                qaObj.answers = [];
                qaArr.push({ ...qaObj });
            });
            updateQA([...qaArr]);
        }
    }, [authData]);

    useEffect(() => {
        getAllQaList();
    }, [getAllQaList]);

    const chooseAnswer = (item) => {
        props.history.push(`/myqa-details/${item._id}`);
    };

    return (
        <div className={`row forum-new ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
            <div className="col-lg-12 p-0">
                <div className="row" style={{ margin: 0 }}>
                    <div className={`col-6 categories-heading`}>
                        <h4 className={`mb-0`}>My Answers</h4>
                    </div>
                    <div className={`col-6 text-right language-dropdown-wrapper`}>
                        <button type="button" className="btn btn-outline-primary" onClick={() => props.history.push("/forum")}>Forum</button>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row" style={{ margin: 0 }}>
                        {qaList.length ?
                            qaList.map((professional, index) => {
                                return <div className={`col-12 professional-wrapper ${professional.selected ? 'professional-wrapper-selected' : ''}
                                `} key={index}>
                                    <div className="row professional-wrapper-row p-0" style={{ margin: 0 }}>
                                        <div className="col-12 p-0">
                                            <div className={`professional-designation row m-0 ${!professional.selected ? 'professional-designation-not-selected' : 'professional-designation-selected'}`} onClick={professional.status === 2 ? () => chooseAnswer({ ...professional }) : () => { }}>
                                                <div className="col-12 pl-0 pr-0 pb-1">
                                                    <div className="row m-0">
                                                        <div className="col-sm-9 col-xs-12 pl-0">
                                                            <div className="qa-date">{professional.date}</div>
                                                            <div className="qa-by-you">
                                                                {professional.answerByMe ? "Answered by you" : ""}
                                                                {professional.questionedByMe ? "Asked by you" : ""}
                                                            </div>
                                                        </div>
                                                        {professional.questionedByMe ? 
                                                        <div className={`col-sm-3 col-xs-12 text-right pt-2 status-text ${professional.status === 2 ? 'confirmed-status' : ''}`}>
                                                            {qaStatus[professional.status]}
                                                        </div> : null}
                                                    </div>
                                                </div>
                                                <div className="col-12 p-0">
                                                    {professional.selected ?
                                                        professional.askedByProfilePhoto ?
                                                            <div style={{ backgroundImage: "url(" + professional.askedByProfilePhoto + ")" }} className="background-image-professional mb-0" /> :
                                                            <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional mb-0" /> : null}
                                                    {!professional.selected ?
                                                        professional.questionText :
                                                        <div className="question-asked-details">
                                                            <div className="question-commentedby">{professional.askedByName}</div>
                                                            {professional.questionText}
                                                        </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                            : <div className="text-center well col-12 color-black">
                                {!loadingQuestions ? 'No Questions Available!' : 'loading...'}
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyQA;