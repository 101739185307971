import React, { useEffect, useState } from 'react';
import "./forum.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { monthArr } from '../../config/constants';

const defaultImage = require('../../../images/imgAvtr.png');

const CertificateModal = (props) => {
    const [localParticipants, updateLocalParticipants] = useState([]);
    const [isAllSelected, updateSelectAll] = useState(false);
    const closeModal = () => {
        props.openCertificateModal(false);
    };

    useEffect(() => {
        if (props.displayCertificateModal && props.participantList) {
            const users = [...props.participantList];
            const notSentUsers = [];
            users.forEach(each => {
                if (!each.certificateSent) {
                    each.selected = false;
                }
                notSentUsers.push({ ...each });
            });
            updateLocalParticipants([...notSentUsers]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.displayCertificateModal]);

    const chooseUser = (localUser, index) => {
        const users = [...localParticipants];
        users[index].selected = !users[index].selected;
        updateLocalParticipants([...users]);
        if (users.every(each => each.selected)) {
            updateSelectAll(true);
        } else {
            updateSelectAll(false);
        }
    };

    const selectAll = () => {
        const users = [...localParticipants];
        users.forEach(each => {
            if (!each.certificateSent) {
                each.selected = !isAllSelected;
            }
        });
        updateLocalParticipants([...users]);
        updateSelectAll(!isAllSelected);
    };

    const getDisplayedFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const year = date.getFullYear();
        return day + ' ' + monthArr[date.getMonth()] + ', ' + year;
    };


    return (
        <div className={`row forum test-modal`} style={{ margin: 0 }}>
            <div className={`modal ask-question-modal fade ${props.displayCertificateModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="col-12 pt-3 pb-3">
                                <div className='row m-0 '>
                                    <div className="col-12 category-arrows">
                                        <i className="fas fa-times close-modal pull-right mt-0" onClick={() => closeModal()}></i>
                                        <h5 className='pre-confirmation-header'>Send Certificate</h5>
                                        <div className='row m-0 scrollable-modal'>
                                            {localParticipants.length ? <>
                                                {localParticipants.filter(each => !each.certificateSent).length ?
                                                <div className="col-12 form-check right-padding mt-3 mr-0">
                                                    <input className="form-check-input" type="checkbox" id="certificate-select-all" value="" checked={isAllSelected} onChange={() => selectAll(isAllSelected)} />
                                                    <label className="form-check-label" htmlFor='certificate-select-all' style={{
                                                        fontSize: "14px"
                                                    }}>
                                                        {isAllSelected ? "Deselect" : "Select"} All
                                                    </label>
                                                </div> : null}
                                                {localParticipants.map((participant, index) => {
                                                    return (<Card key={index} className={`test-category-item`}>
                                                        <CardContent>
                                                            <div>
                                                                {participant.customerRef.personalInfo.profilePicture ?
                                                                    <div style={{ backgroundImage: "url(" + participant.customerRef.personalInfo.profilePicture + ")" }} className="background-image-professional m-0" onClick={() => chooseUser(participant, index)} /> :
                                                                    <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional m-0" onClick={() => chooseUser(participant, index)} />}
                                                                <p style={{
                                                                    fontSize: 14, margin: 0,
                                                                    cursor: 'pointer',
                                                                    display: "inline-block",
                                                                    verticalAlign: "top",
                                                                    marginLeft: "15px",
                                                                    width: "calc(100% - 70px)"
                                                                }}
                                                                    onClick={() => chooseUser(participant, index)}
                                                                >
                                                                    <div><span style={{
                                                                        fontWeight: 600
                                                                    }}>User name:</span> {participant.customerRef.personalInfo.name}
                                                                        {!participant.certificateSent ? !participant.selected ?
                                                                            <i className="far fa-circle float-right" style={{
                                                                                fontSize: "22px",
                                                                                cursor: 'pointer'
                                                                            }}></i> : <i className="far fa-check-circle float-right" style={{
                                                                                fontSize: "22px",
                                                                                cursor: 'pointer'
                                                                            }}></i> : null} </div>
                                                                    <div><span style={{
                                                                        fontWeight: 600
                                                                    }}>Booking date:</span> {getDisplayedFullDateFormat(new Date(participant.createdAt))}</div>
                                                                    <div><span style={{
                                                                        fontWeight: 600
                                                                    }}>Remark:</span> {participant.joinedMeeting ? "Joined" : "Not Joined"}</div>
                                                                    {participant.certificateSent ?
                                                                    <div style={{
                                                                        color: "#248af2"
                                                                    }}>Certificate Sent</div> : null}
                                                                </p>
                                                            </div>
                                                        </CardContent>
                                                    </Card>);
                                                })}
                                            </> : null}
                                        </div>
                                        <div className='col-12 pr-0 pl-0 pt-3 text-right'>
                                            <button className="btn btn-primary details-button" onClick={() => props.sendCertificate([...localParticipants])} disabled={!localParticipants.some(each => each.selected)}>Send</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CertificateModal;