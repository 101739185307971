import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import "./forum-new.css";
import { toast } from 'react-toastify';
import {
  API_ERROR
} from "../../config/error-message";
import { VendorConfigStatus } from "../../config/constants";
import { addAnswer, getForumDetails, flagAnswer } from "../../crud/qa-forum-crud";
import FlagModal from './flag-modal';
import EditAnswerModal from './answer-modal';
import { getProfile } from '../../crud/profile.crud';

const monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const defaultImage = require('../../../images/imgAvtr.png');
const adminProfileImage = require('../../../images/admin-logo.png');
const flagSelectedImage = require('../../../images/flag-selected.png');
const flagNotSelectedImage = require('../../../images/flag-not-selected.png');

const Forum = (props) => {
  const [questionDetails, updateQuestionDetails] = useState({});
  const [myAnswer, updateMyAnswer] = useState("");
  const [disablePage, toggleDisablePage] = useState(false);
  const [loadingQuestions, toggleLoadingQuestions] = useState(true);
  const { authData, isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      authData: auth.user
    }),
    shallowEqual
  );
  const [flagModalOpened, toggleFlagModal] = useState(false);
  const [selectedFlaggedQuestion, updateSelectedFlaggedQuestion] = useState({});
  const [flagAnswerModalOpened, toggleFlagAnswerModal] = useState(false);
  const [isEditable, toggleEditable] = useState(false);
  const [editableAnswer, updateSelectedAnswerForEdit] = useState({});
  const [profileData, selectProfile] = useState({});

  const getFullDate = (date) => {
    const d = new Date(date);
    return d.getDate() + ' ' + monthArr[d.getMonth()] + ', ' + d.getFullYear();
  };

  const isEditableTime = (date) => {
    const currentTime = (new Date()).getTime();
    const d = new Date(date);
    const min = d.getMinutes();
    const laterTime = d.setMinutes(min + 60);

    return currentTime <= laterTime;
  };

  const getQuestionDetails = useCallback(async (id, fromAnswer) => {
    toggleDisablePage(true);
    const localQuestionDetails = await getForumDetails(id);
    toggleDisablePage(false);
    toggleLoadingQuestions(false);
    if (localQuestionDetails.data.errorCode) {
      toast.error(API_ERROR[localQuestionDetails.data.errorCode]);
      return;
    }
    if (localQuestionDetails.data.data && localQuestionDetails.data.data.errorCode) {
      toast.error(API_ERROR[localQuestionDetails.data.data.errorCode]);
      return;
    }
    console.log("localQuestionDetails ", localQuestionDetails.data);
    const localProf = { ...questionDetails };
    let answerObj = {};
    localProf.askedByName = localQuestionDetails.data.data.askedBy.personalInfo.name || '';
    localProf.askedByProfilePhoto = localQuestionDetails.data.data.askedBy.personalInfo.profilePicture || '';
    localProf.isMe = authData && localQuestionDetails.data.data.askedBy._id === authData._id ? true : false;
    localProf._id = localQuestionDetails.data.data._id;
    localProf.question = localQuestionDetails.data.data.question;
    localProf.selected = true;
    if (!window.location.pathname.includes("forum")) {
      localProf.date = getFullDate(localQuestionDetails.data.data.createdAt);
      localProf.questionedByMe = false;
      localProf.answerByMe = false;
      if (localQuestionDetails.data.data.customerId &&
        authData &&
        localQuestionDetails.data.data.customerId === authData._id) {
        localProf.questionedByMe = true;
      }
      if (!localProf.questionedByMe) {
        localProf.answerByMe = true;
      }
    }
    localProf.isFlagged = false;
    if (localQuestionDetails.data.data.flaggedBy && localQuestionDetails.data.data.flaggedBy.length) {
      localQuestionDetails.data.data.flaggedBy.forEach(each => {
        if (each.vendorId === authData._id) {
          localProf.isFlagged = true;
        }
      });
    }
    localProf.answers = [];
    if (localQuestionDetails.data.data && localQuestionDetails.data.data.answers && localQuestionDetails.data.data.answers.length) {
      localQuestionDetails.data.data.answers.forEach(item => {
        answerObj = {};
        answerObj.answerText = item.answer;
        answerObj._id = item._id;
        answerObj.date = item.time;
        answerObj.name = "";
        answerObj.isMe = authData && item.answeredBy._id === authData._id ? true : false;
        answerObj.profilePicture = "";
        if (item.answeredBy && item.answeredBy.personalInfo) {
          answerObj.name = item.answeredBy.personalInfo.name;
          answerObj.profilePicture = item.answeredBy.personalInfo.profilePicture;
          answerObj.userType = item.answeredBy.userType;
          answerObj.userId = item.answeredBy._id;
        }
        console.log("answerObj ", answerObj);
        answerObj.isFlagged = false;
        if (item.flaggedBy && item.flaggedBy.length) {
          item.flaggedBy.forEach(each1 => {
            if (each1.vendorId === authData._id) {
              answerObj.isFlagged = true;
            }
          });
        }
        localProf.answers.push({ ...answerObj });
      });
    }
    localProf.noOfComments = localProf.answers.length;
    console.log("localProf ", localProf)
    updateQuestionDetails({ ...localProf });
    // selectAnswer(id);
    if (fromAnswer) {
      setTimeout(() => {
        const el = document.getElementsByClassName("answers-wrapper")[0];
        if (el) {
          el.scrollTop = el.scrollHeight;
        }
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialProfileDetails = useCallback(async () => {
    const profileData = await getProfile();
    if (profileData.data.errorCode) {
      toast.error(API_ERROR[profileData.data.errorCode]);
      return;
    }
    if (profileData.data.data) {
      const localSelectedProfile = {};
      
      if (profileData.data.data.accountStatus) {
        localSelectedProfile._id = profileData.data.data._id;
        localSelectedProfile.accountStatus = profileData.data.data.accountStatus;
      }
      console.log("localSelectedProfile ", localSelectedProfile)
      selectProfile({ ...localSelectedProfile });
      
    }
  }, []);

  useEffect(() => {
    getQuestionDetails(props.match.params.id);
    getInitialProfileDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const giveAnswer = async () => {
    console.log("myAnswer ", myAnswer);
    if (myAnswer.trim().length) {
      toggleDisablePage(true);
      const answerResult = await addAnswer(props.match.params.id, { answer: myAnswer.trim() });
      toggleDisablePage(false);
      console.log("answerResult ", answerResult);
      if (answerResult.data.errorCode) {
        toast.error(API_ERROR[answerResult.data.errorCode]);
        return;
      }
      if (answerResult.data.data && answerResult.data.data.errorCode) {
        toast.error(API_ERROR[answerResult.data.data.errorCode]);
        return;
      }

      updateMyAnswer("");
      getQuestionDetails(props.match.params.id, true);
    }
  };

  const clickUnflagAnswer = async (profData, answer) => {
    toggleDisablePage(true);
    let favProbResult = await flagAnswer(profData._id, answer._id, { flagChoise: false });
    toggleDisablePage(false);
    if (favProbResult.data.errorCode) {
      toast.error(API_ERROR[favProbResult.data.errorCode]);
      return;
    }
    if (favProbResult.data.data && favProbResult.data.data.errorCode) {
      toast.error(API_ERROR[favProbResult.data.data.errorCode]);
      return;
    }
    getQuestionDetails(props.match.params.id);
    updateSelectedFlaggedQuestion({});
  };

  const openFlagAnswerModal = (profData, answer) => {
    if (!isAuthorized) {
      toast.info("Please login to flag the question");
      return;
    }
    let localQuestion = { ...profData };
    localQuestion.selectedAnswer = { ...answer };
    toggleFlagAnswerModal(true);
    updateSelectedFlaggedQuestion({ ...localQuestion });
  };

  const editAnswer = (answerData) => {
    toggleEditable(true);
    const localAnswer = { ...answerData };
    localAnswer.qId = questionDetails._id;
    updateSelectedAnswerForEdit({ ...localAnswer });
  };

  return (
    <div className={`row forum-new ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
      {isEditable ?
        <EditAnswerModal displayModal={isEditable} toggleModal={toggleEditable} editableAnswer={editableAnswer} updateSelectedAnswerForEdit={updateSelectedAnswerForEdit} getQuestionDetails={getQuestionDetails} /> : null}
      {flagModalOpened ?
        <FlagModal flagModalOpened={flagModalOpened} toggleFlagModal={toggleFlagModal} type="question" from="details" selectedFlaggedQuestion={selectedFlaggedQuestion} updateSelectedFlaggedQuestion={updateSelectedFlaggedQuestion} getQuestionDetails={getQuestionDetails} /> : null}
      {flagAnswerModalOpened ?
        <FlagModal flagModalOpened={flagAnswerModalOpened} toggleFlagModal={toggleFlagAnswerModal} type="answer" from="details" selectedFlaggedQuestion={selectedFlaggedQuestion} updateSelectedFlaggedQuestion={updateSelectedFlaggedQuestion} getQuestionDetails={getQuestionDetails} /> : null}
      <div className="col-lg-12 pad-0-below-500">
        <div className="row" style={{ margin: 0 }}>
          <div className={`col-md-7 col-xs-12 categories-heading`}>
            <h4 className={`mb-0 page-heading`}>{`${window.location.pathname.includes('forum') ? 'Forum' : 'My Q & A'}`} Details</h4>
            <button type="button" className="btn btn-link pt-0" onClick={() => window.location.pathname.includes('forum') ? props.history.push("/forum") : props.history.push("/myQA")}>{`Back to ${window.location.pathname.includes('forum') ? 'Forum' : 'My Q & A'}`}</button>
          </div>
        </div>

        <div className="col-12">
          <div className="row" style={{ margin: 0 }}>
            {Object.keys(questionDetails).length ?
              <div className={`col-12 professional-wrapper ${questionDetails.selected ? 'professional-wrapper-selected' : ''}`}>
                <div className="row professional-wrapper-row p-0" style={{ margin: 0 }}>
                  <div className="col-12 p-0">
                    <div className={`professional-designation row m-0 ${!questionDetails.selected ? 'professional-designation-not-selected' : 'professional-designation-selected'}`}>
                      <div className="col-12 p-0">
                        {!window.location.pathname.includes("forum") ?
                          <div className="row m-0">
                            <div className="col-12">
                              <div className="qa-date">{questionDetails.date}</div>
                              <div className="qa-by-you">
                                {questionDetails.answerByMe ? "Answered by you" : ""}
                                {questionDetails.questionedByMe ? "Asked by you" : ""}
                              </div>
                            </div>
                          </div> : null}
                      </div>
                      <div className="col-12 p-0">
                        {questionDetails.selected ?
                          questionDetails.askedByProfilePhoto ?
                          questionDetails.askedByProfilePhoto !== "null" ?
                            <div style={{ backgroundImage: "url(" + questionDetails.askedByProfilePhoto + ")" }} className="background-image-professional mb-0" /> :
                            <div style={{ backgroundImage: "url(" + adminProfileImage + ")" }} className="background-image-professional mb-0" /> :
                            <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional mb-0" /> : null}
                        {!questionDetails.selected ? <>
                          {questionDetails.question} <span> ({questionDetails.noOfComments} comment{questionDetails.noOfComments > 1 ? "s" : ""}) </span> </>
                          : <div className="question-asked-details">
                            <div className="question-commentedby">{questionDetails.askedByName}</div>
                            {questionDetails.question} <span> ({questionDetails.noOfComments} comment{questionDetails.noOfComments > 1 ? "s" : ""}) </span>
                          </div>}
                      </div>

                    </div>
                    {!questionDetails.selected ?
                      null :
                      <div className="professional-location answer">
                        <div className="row answers-wrapper" style={{ margin: 0 }}>
                          <div className="col-12 p-0">
                            {questionDetails.answers.length ?
                              questionDetails.answers.map((answer, index) => {
                                return <div className="row" style={{ margin: 0 }} key={index}>
                                  {answer.userType !== 3 ?
                                    answer.profilePicture ?
                                      <div style={{ backgroundImage: "url(" + answer.profilePicture + ")" }} className="background-image-professional" /> :
                                      <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional" /> :
                                    <div style={{ backgroundImage: "url(" + adminProfileImage + ")" }} className="background-image-professional" />}
                                  <div className=" answer-text">
                                    <div className="answer-commentedby">{answer.userType !== 3 ? <span>{answer.name}</span> : 'Parentcraft Admin'}
                                      <span className="answer-date"> {getFullDate(answer.date)}
                                        {window.location.pathname.includes("forum") && !answer.isMe ?
                                          answer.isFlagged ? <img className="ml-2" src={flagSelectedImage} alt="Flagged answer" onClick={e => { e.stopPropagation(); clickUnflagAnswer(questionDetails, answer) }} />
                                            : <img className="ml-2" src={flagNotSelectedImage} alt="Not flagged answer" onClick={e => { e.stopPropagation(); openFlagAnswerModal(questionDetails, answer) }} /> : null}
                                        {window.location.pathname.includes("forum") && answer.isMe && isEditableTime(answer.date) ?
                                          <i className="ml-2 fas fa-pencil-alt" onClick={() => editAnswer(answer)}></i> : null}
                                      </span>
                                    </div>
                                    <div className="answer-text-span-wrapper">{answer.answerText.split('\n').map((item, key) => {
                                      return <span key={key}>{item}<br /></span>
                                    })}</div>
                                  </div>
                                </div>
                              })
                              : null}
                          </div>
                        </div>
                        {(profileData.accountStatus === VendorConfigStatus.approved) ?
                          <div className="row" style={{ margin: 0, paddingTop: "15px" }}>
                            {authData && authData.personalInfo && authData.personalInfo.profilePicture ?
                              <div style={{ backgroundImage: "url(" + authData.personalInfo.profilePicture + ")" }} className="background-image-professional" /> :
                              <div style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional" />}
                            <div className="message-input">
                              <textarea className="form-control textarea" placeholder="Type your answer" value={myAnswer} onChange={(e) => updateMyAnswer(e.target.value)} disabled={(authData && authData.personalInfo && !authData.personalInfo.name)} rows="2"></textarea>
                              <button disabled={!myAnswer.trim().length || (authData && authData.personalInfo && !authData.personalInfo.name)} className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={giveAnswer}><img src={require("../../../images/message-send.svg")} alt="send message" /></button>
                              {authData && authData.personalInfo && !authData.personalInfo.name ?
                                <div className="col-12 p-0 text-info">
                                  Please update your name from profile
                                </div> : null}
                            </div>

                          </div> : null}
                      </div>}
                  </div>
                </div>
              </div>
              : <div className="text-center well col-12 color-black">
                {!loadingQuestions ? 'No Questions Details Available!' : 'loading...'}
              </div>}
          </div>
        </div>
      </div>

    </div>
  )
}

export default Forum;