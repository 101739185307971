import React, { useEffect, useState, useCallback } from 'react';
import "./forum.css";
import { getEventDetails, addEvent } from "../../crud/service.crud";
// import { bookSessionApi } from "../../crud/home.crud";
import { toast } from 'react-toastify';
import {paytmUrl} from "../../crud/api.util";
import {
    API_ERROR
} from "../../config/error-message";

const FailurePayment = (props) => {
    const [bookingDetails, updateBookingDetails] = useState({});
    const [disablePage, toggleDisablePage] = useState(false);
    const [pageInitialized, updatePageInitialization] = useState(false);
    const [paymentInitialized, togglePaymentInitialization] = useState(false);

    const getBookingData = useCallback(async (id) => {
        toggleDisablePage(true);
        const bookingResult = await getEventDetails(id);
        toggleDisablePage(false);
        if (bookingResult.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.errorCode]);
            return;
        }
        if (bookingResult.data.data && bookingResult.data.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.data.errorCode]);
            return;
        }
        console.log("bookingResult ", bookingResult);
        // return;
        const resultData = bookingResult.data.data;

        const localProfessionalObj = {
            bookingId: resultData._id
        };
        
        updateBookingDetails({ ...localProfessionalObj });
        
        const myScript = document.createElement("script");
        myScript.setAttribute("src", paytmUrl);
        myScript.setAttribute("crossorigin", "anonymous");
        myScript.setAttribute("type", "application/javascript");
        document.body.appendChild(myScript);

    }, []);

    useEffect(() => {
        if (!pageInitialized) {
            const el = document.getElementsByClassName("custom-navigation-bar")[0];
            const el1 = document.getElementsByClassName("footer")[0];
            const urlParams = new URLSearchParams(window.location.search);
            const bookingIdParam = urlParams.get('id');
            if (el) {
                el.style.visibility = "hidden";
            }
            if (el1) {
                el1.style.visibility = "hidden";
            }
            if (bookingIdParam) {
                getBookingData(bookingIdParam);
            }
            updatePageInitialization(true);
        }
    }, [getBookingData, pageInitialized]);

    const cancelBooking = () => {
        props.history.push(`/dashboard`);
    };

    const initiatePayment = (orderId, token, amount) => {
        var config = {
			"root": "",
			"flow": "DEFAULT",
			"data": {
			  "orderId": orderId, /* update order id */
			  "token": token, /* update token value */
			  "tokenType": "TXN_TOKEN",
			  "amount": amount.toString() /* update amount */
			},
			"handler": {
			  "notifyMerchant": function(eventName,data){
				console.log("notifyMerchant handler function called");
				console.log("eventName => ",eventName);
				console.log("data => ",data);
			  } 
			}
		  };
          console.log("here ", config)
		  if(window.Paytm && window.Paytm.CheckoutJS){
              console.log("window.Paytm.CheckoutJS ",window.Paytm.CheckoutJS)
			//   window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
				  // initialze configuration using init method 
				  window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
					  // after successfully updating configuration, invoke JS Checkout
                      togglePaymentInitialization(false);
					  window.Paytm.CheckoutJS.invoke();
				  }).catch(function onError(error){
					  console.log("error => ",error);
				//   });
			  });
		  }
    };

    const retryPayment = async () => {
        const requestBody = {
            eventId: bookingDetails.bookingId
        };

        // console.log("requestBody ", requestBody);
        // // initiatePayment("ORD-60a6afa2d8501ecbe8153302-1621536674355", "183ced21f9c143af822138d7a2cf29a11621536675340", "35.4");
        // // return;
        
        toggleDisablePage(true);
        const bookSlotResult = await addEvent(requestBody);
        console.log("bookSlotResult ", bookSlotResult);
        toggleDisablePage(false);
        if (bookSlotResult.data.errorCode) {
            toast.error(API_ERROR[bookSlotResult.data.errorCode]);
            return;
        }
        if (bookSlotResult.data.data && bookSlotResult.data.data.errorCode) {
            toast.error(API_ERROR[bookSlotResult.data.data.errorCode]);
            return;
        }
        // console.log("success");
        togglePaymentInitialization(true);
        const paytmToken = bookSlotResult.data.data.paytmTxnToken;
        const paytmOrderId = bookSlotResult.data.data.orderId;
        const paytmAmount = bookSlotResult.data.data.paymentDetails.total;
        initiatePayment(paytmOrderId, paytmToken, paytmAmount);
    };

    return (
        <div className={`forum success-payment-outside ${disablePage || paymentInitialized ? "pointer-none" : ""}`}>
            <div className={`success-payment-wrapper`}>
                {!disablePage ?
                <>
                    <h3 className="header-1">
                        Payment Failed
                    </h3>
                    <div className="button-wrapper mt-3">
                        <button className="btn btn-outline-primary" onClick={cancelBooking}>Cancel</button>
                        <button className="btn btn-primary" onClick={!paymentInitialized ? retryPayment : () => {}}>{paymentInitialized ? 'Initialize payment...' : 'Retry Payment'}</button>
                    </div>
                </> : <div>processing...</div> }
            </div>
        </div>
    )
}

export default FailurePayment;