import React, { useEffect, useState, useCallback } from 'react';
// import { shallowEqual, useSelector } from "react-redux";
import "./service.css";
import { timeArrConst, monthArrConfig, monthArr } from "../../config/constants";
import { getRevenue, getEventsRevenue, getEventDetailsRevenue, getWorkshopDetailsRevenue, getWorkshopsRevenue } from "../../crud/booking.crud";
import { toast } from 'react-toastify';
import { API_ERROR } from "../../config/error-message";
import RevenueModal from './revenue-details-modal';
import { globalConfig } from '../../crud/auth.crud';
const timeArr = [...timeArrConst];

const minYear = 2021;
const minMonth = 0;
const maxYear = new Date().getFullYear();
const maxMonth = new Date().getMonth();
let globalData = {};

const Revenue = (props) => {
    const [disablePage, toggleDisablePage] = useState(false);
    const [revenueDetails, updateRevenueDetails] = useState({});
    const [eventRevenueDetails, updateEventRevenueDetails] = useState({});
    const [workshopRevenueDetails, updateWorkshopRevenueDetails] = useState({});
    const [bookingArray, updateBookingArray] = useState([]);

    const [curMonth, updateCurMonth] = useState(0);
    const [curYear, updateCurYear] = useState(new Date().getFullYear());
    const [isActive, setActiveTab] = useState('appointment');
    const [isInvoiceOpen, toggleInvoiceModal] = useState(false);
    const [selectedInvoice, updateSelectedInvoice] = useState({});
    const [eventArray, updateEventArray] = useState([]);
    const [workshopArray, updateWorkshopArray] = useState([]);
    // const { authData } = useSelector(
    //     ({ auth }) => ({
    //         authData: auth.user
    //     }),
    //     shallowEqual
    // );
    // const [vendorDetails, updateVendorDetails] = useState({});

    // useEffect(() => {
    //     const localProfessionalObj = {};
    //     if (authData.bankingDetails) {
    //         localProfessionalObj.bankDetails = authData.bankingDetails;
    //     }
    //     updateVendorDetails({ ...localProfessionalObj });
    // }, [authData]);

    const getFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const year = date.getFullYear();
        return day + ' ' + monthArr[date.getMonth()] + ', ' + year;
    };

    const getStartTime = (times) => {
        times.sort((a, b) => { return a - b });
        return timeArr[times[0] - 1];
    };

    const getEndTime = (times) => {
        times.sort((a, b) => { return a - b });
        return timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0];
    };

    const getAllRevenue = useCallback(async (reqBody) => {
        toggleDisablePage(true);
        const bookingResult = await getRevenue(reqBody);
        console.log("bookingResult ", bookingResult);

        toggleDisablePage(false);
        if (bookingResult.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.errorCode]);
            return;
        }
        if (bookingResult.data.data && bookingResult.data.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.data.errorCode]);
            return;
        }

        const bookingObj = {
            netPayable: 0,
            totalServiceCharge: 0,
            orgFee: 0,
            status: "DUE",
            paymentDate: ""
        };
        const localBookingArr = [];
        let localBookObj = {};
        if (bookingResult.data.data && bookingResult.data.data.paymentDetails && bookingResult.data.data.paymentDetails.services &&
            bookingResult.data.data.paymentDetails.services.length) {
            bookingResult.data.data.paymentDetails.services.forEach(each => {

                localBookObj = {
                    ...each,
                    name: each.bookingForName,
                    displayDate: getFullDateFormat(new Date(each.date)),
                    startTime: getStartTime(each.bookingSlots),
                    endTime: getEndTime(each.bookingSlots),
                    serviceFee: each.amount,
                    serviceType: each.bookingType,
                    serviceName: each.name,
                    slots: each.bookingSlots
                };
                localBookingArr.push({ ...localBookObj });

            });
            bookingObj.status = "DUE";
            bookingObj.netPayable = bookingResult.data.data.paymentDetails.totalFees;
        }
        bookingObj.otherPayment = 0;
        if (bookingResult.data.data && bookingResult.data.data.vendorPayout && bookingResult.data.data.vendorPayout.date) {
            bookingObj.status = "PAID";
            bookingObj.paymentDate = getFullDateFormat(new Date(bookingResult.data.data.vendorPayout.date));
            bookingObj.bankDetails = bookingResult.data.data.vendorPayout.bankingDetails || {};
            bookingObj.otherPayment = Number(bookingResult.data.data.vendorPayout.deduction || 0);
            bookingObj.paymentMode = bookingResult.data.data.vendorPayout.paymentMode;
            bookingObj.referenceNumber = bookingResult.data.data.vendorPayout.referenceNumber;
        }
        bookingObj.netPayable = bookingObj.netPayable - bookingObj.otherPayment;

        updateRevenueDetails({ ...bookingObj });
        updateBookingArray([...localBookingArr]);
    }, []);

    const getAllRevenueForEvents = useCallback(async (reqBody, eventParam) => {
        toggleDisablePage(true);
        const bookingResult = await getEventsRevenue(reqBody);
        console.log("bookingResult ", bookingResult);

        toggleDisablePage(false);
        if (bookingResult.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.errorCode]);
            return;
        }
        if (bookingResult.data.data && bookingResult.data.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.data.errorCode]);
            return;
        }

        const localBookingArr = [];
        let localBookObj = {};
        const localRevenueDetails = {
            paid: 0,
            due: 0
        };
        if (bookingResult.data.data && bookingResult.data.data.data && bookingResult.data.data.data.length) {
            bookingResult.data.data.data.forEach(each => {
                localBookObj = {
                    ...each,
                    amountAfterVendorCut: each.amount ? each.amount : each.totalFees,
                    date: each.event.date,
                    status: each.status === 2 ? "PAID" : "DUE"
                }

                if (each.status === 2) {
                    localRevenueDetails.paid += localBookObj.amountAfterVendorCut;
                } else {
                    localRevenueDetails.due += localBookObj.amountAfterVendorCut;
                }
                localBookingArr.push({ ...localBookObj });
            });


        }
        updateEventRevenueDetails({...localRevenueDetails});
        updateEventArray([...localBookingArr]);
        if (eventParam) {
            setActiveTab("event");
        }
    }, []);

    const getAllRevenueForWorkshops = useCallback(async (reqBody, workshopParam) => {
        toggleDisablePage(true);
        const bookingResult = await getWorkshopsRevenue(reqBody);
        console.log("bookingResult ", bookingResult);

        toggleDisablePage(false);
        if (bookingResult.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.errorCode]);
            return;
        }
        if (bookingResult.data.data && bookingResult.data.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.data.errorCode]);
            return;
        }

        const localBookingArr = [];
        let localBookObj = {};
        const localRevenueDetails = {
            paid: 0,
            due: 0
        };
        if (bookingResult.data.data && bookingResult.data.data.data && bookingResult.data.data.data.length) {
            bookingResult.data.data.data.forEach(each => {
                localBookObj = {
                    ...each,
                    amountAfterVendorCut: each.amount ? each.amount : each.totalFees,
                    dates: each.workshop.dates,
                    status: each.status === 2 ? "PAID" : "DUE"
                }
                const vendorCut = (Number(localBookObj.amountAfterVendorCut) * (each.workshop.commissionPercentage/100));

                localBookObj.amountAfterVendorCut = Number(localBookObj.amountAfterVendorCut) - ((vendorCut || 0) + ((vendorCut || 0) * ((globalData.gst || 0) / 100)));

                if (each.status === 2) {
                    localRevenueDetails.paid += localBookObj.amountAfterVendorCut;
                } else {
                    localRevenueDetails.due += localBookObj.amountAfterVendorCut;
                }
                localBookingArr.push({ ...localBookObj });
            });
        }
        updateWorkshopRevenueDetails({...localRevenueDetails});
        updateWorkshopArray([...localBookingArr]);
        if (workshopParam) {
            setActiveTab("workshop");
        }
    }, []);

    const getGlobalConfig = async (eventParam, workshopParam, month, year) => {
        const localGlobalData = await globalConfig();
        console.log("localGlobalData---- ", localGlobalData);

        if (localGlobalData.data.errorCode) {
            toast.error(API_ERROR[localGlobalData.data.errorCode]);
            return;
        }

        if (localGlobalData.data.data.errorCode) {
            toast.error(API_ERROR[localGlobalData.data.data.errorCode]);
            return;
        }

        globalData = {
            gst: localGlobalData.data.data.gstPercentage
        };

        getAllRevenueForWorkshops({ month: month + 1, year: year }, workshopParam);

        if (eventParam) {
            openInvoiceModalEvent({event: {_id: eventParam}}, month, year);
        } else if (workshopParam) {
            openInvoiceModalWorkshop({workshop: {_id: workshopParam}}, month, year);
        }
    };

    useEffect(() => {

        const urlParams = new URLSearchParams(window.location.search);
        const dateParam = urlParams.get('date');
        const workshopParam = urlParams.get('workshopId');
        const eventParam = urlParams.get('eventId');

        let date = new Date();

        if (dateParam) {
            props.history.replace(`/revenue`);
            date = new Date(JSON.parse(dateParam));
        }

        const currentMonth = date.getMonth() + 1;
        const currentYear = date.getFullYear();

        updateCurMonth(date.getMonth());
        updateCurYear(date.getFullYear());

        getGlobalConfig(eventParam, workshopParam, date.getMonth(), date.getFullYear());

        getAllRevenue({ month: currentMonth, year: currentYear });
        getAllRevenueForEvents({ month: currentMonth, year: currentYear }, eventParam);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const prev = () => {
        if (curYear === minYear && curMonth === minMonth) {
            return;
        }
        const currentMonth = curMonth === 0 ? 11 : curMonth - 1;
        const currentYear = curMonth === 0 ? curYear - 1 : curYear;

        updateCurMonth(currentMonth);
        updateCurYear(currentYear);
        getAllRevenue({ month: currentMonth + 1, year: currentYear });
        setActiveTab("appointment");
        getAllRevenueForEvents({ month: currentMonth + 1, year: currentYear });
        getAllRevenueForWorkshops({ month: currentMonth + 1, year: currentYear });

    };

    const next = () => {
        if (curYear === maxYear && curMonth === maxMonth) {
            return;
        }

        const currentMonth = curMonth === 11 ? 0 : curMonth + 1;
        const currentYear = curMonth === 11 ? curYear + 1 : curYear;

        updateCurMonth(currentMonth);
        updateCurYear(currentYear);
        getAllRevenue({ month: currentMonth + 1, year: currentYear });
        setActiveTab("appointment");
        getAllRevenueForEvents({ month: currentMonth + 1, year: currentYear });
        getAllRevenueForWorkshops({ month: currentMonth + 1, year: currentYear });
    };

    const openInvoiceModal = (invoiceData) => {
        const localData = { ...invoiceData };
        localData.wholeStatus = revenueDetails.status;
        localData.paymentDate = revenueDetails.paymentDate;
        localData.bankDetails = revenueDetails.bankDetails;
        if (revenueDetails.status === "PAID") {
            localData.paymentMode = revenueDetails.paymentMode;
            localData.referenceNumber = revenueDetails.referenceNumber;
            // localData.otherPayment = revenueDetails.otherPayment;
        }
        localData.revenueType = "appointment";
        localData.gstPercentage = globalData.gst || 0;
        updateSelectedInvoice({ ...localData });
        toggleInvoiceModal(true);
    };

    const openInvoiceModalEvent = async (invoiceData, localMonth, localYear) => {
        const localData = {};
        toggleDisablePage(true);
        const bookingResult = await getEventDetailsRevenue({ 
            month: localMonth ? localMonth + 1 :curMonth + 1, 
            year: localYear ? localYear : curYear, 
            eventId: invoiceData.event._id
         });
        console.log("bookingResult---- ", bookingResult);

        toggleDisablePage(false);
        if (bookingResult.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.errorCode]);
            return;
        }

        if (bookingResult.data.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.data.errorCode]);
            return;
        }

        localData.eventName = bookingResult.data.data.paymentDetails.event.title;
        localData.eventDate = getFullDateFormat(new Date(bookingResult.data.data.paymentDetails.event.date));
        localData.startTime = getStartTime(bookingResult.data.data.paymentDetails.event.slots);
        localData.endTime = getEndTime(bookingResult.data.data.paymentDetails.event.slots);
        localData.bookings = bookingResult.data.data.paymentDetails.bookings;

        localData.amountAfterVendorCut = bookingResult.data.data.paymentDetails.totalFees;

        localData.paymentDate = bookingResult.data.data.vendorPayout && bookingResult.data.data.vendorPayout.date ? getFullDateFormat(new Date(bookingResult.data.data.vendorPayout.date)) : "";

        localData.gstPercentage = globalData.gst || 0;
        
        localData.gst = 0;
        localData.totalPayable = bookingResult.data.data.paymentDetails.totalFees;
        localData.wholeStatus = "DUE";
        localData.otherPayment = 0;

        if (bookingResult.data.data.vendorPayout) {
            localData.otherPayment = Number(bookingResult.data.data.vendorPayout.deduction || 0);
            localData.commissionPercentage = bookingResult.data.data.vendorPayout.commissionPercentage;
            localData.commission = bookingResult.data.data.vendorPayout.commission;
            localData.gst = bookingResult.data.data.vendorPayout.commission ? bookingResult.data.data.vendorPayout.commission * ((globalData.gst || 0) / 100) : 0;
            localData.totalPayable = localData.totalPayable - (bookingResult.data.data.vendorPayout.commission || 0) - localData.gst - localData.otherPayment;
            localData.wholeStatus = "PAID";
            localData.bankDetails = bookingResult.data.data.vendorPayout.bankingDetails || {};
            localData.paymentMode = bookingResult.data.data.vendorPayout.paymentMode;
            localData.referenceNumber = bookingResult.data.data.vendorPayout.referenceNumber;
        } else {
            localData.commissionPercentage = invoiceData.commissionPercentage;
            localData.commission = invoiceData.commission;
            localData.gst = invoiceData.commission ? invoiceData.commission * ((globalData.gst || 0) / 100) : 0;
            localData.totalPayable = localData.totalPayable - (invoiceData.commission || 0) - localData.gst;
        }
        
        localData.revenueType = "event";
        updateSelectedInvoice({ ...localData });
        toggleInvoiceModal(true);
    };

    const openInvoiceModalWorkshop = async (invoiceData, localMonth, localYear) => {
        const localData = {};
        toggleDisablePage(true);
        const bookingResult = await getWorkshopDetailsRevenue({ 
            month: localMonth ? localMonth + 1 : curMonth + 1, 
            year: localYear ? localYear : curYear, 
            workshopId: invoiceData.workshop._id
         });
        console.log("bookingResult---- ", bookingResult);

        toggleDisablePage(false);
        if (bookingResult.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.errorCode]);
            return;
        }

        if (bookingResult.data.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.data.errorCode]);
            return;
        }

        localData.eventName = bookingResult.data.data.paymentDetails.workshop.title;
        localData.eventStartDate = getFullDateFormat(new Date(bookingResult.data.data.paymentDetails.workshop.dates[0]));
        if (bookingResult.data.data.paymentDetails.workshop.dates[1]) {
            localData.eventEndDate = getFullDateFormat(new Date(bookingResult.data.data.paymentDetails.workshop.dates[bookingResult.data.data.paymentDetails.workshop.dates.length - 1]));
        }
        localData.startTime = getStartTime(bookingResult.data.data.paymentDetails.workshop.slots);
        localData.endTime = getEndTime(bookingResult.data.data.paymentDetails.workshop.slots);
        localData.bookings = bookingResult.data.data.paymentDetails.bookings;

        localData.amountAfterVendorCut = bookingResult.data.data.paymentDetails.totalFees;

        localData.gstPercentage = globalData.gst || 0;

        localData.gst = 0;
        localData.totalPayable = bookingResult.data.data.paymentDetails.totalFees;
        localData.wholeStatus = "DUE";
        localData.otherPayment = 0;

        if (bookingResult.data.data.vendorPayout) {
            localData.otherPayment = Number(bookingResult.data.data.vendorPayout.deduction || 0);
            localData.commissionPercentage = bookingResult.data.data.vendorPayout.commissionPercentage;
            localData.commission = bookingResult.data.data.vendorPayout.commission;
            localData.gst = bookingResult.data.data.vendorPayout.commission ? bookingResult.data.data.vendorPayout.commission * ((globalData.gst || 0) / 100) : 0;
            localData.totalPayable = localData.totalPayable - (bookingResult.data.data.vendorPayout.commission || 0) - localData.gst - localData.otherPayment;
            localData.wholeStatus = "PAID";
            localData.bankDetails = bookingResult.data.data.vendorPayout.bankingDetails || {};
            localData.paymentMode = bookingResult.data.data.vendorPayout.paymentMode;
            localData.referenceNumber = bookingResult.data.data.vendorPayout.referenceNumber;
        } else {
            localData.commissionPercentage = invoiceData.workshop.commissionPercentage;
            localData.commission = (Number(localData.totalPayable) * (invoiceData.workshop.commissionPercentage/100));
            localData.gst = localData.commission ? localData.commission * ((globalData.gst || 0) / 100) : 0;
            localData.totalPayable = localData.totalPayable - (localData.commission || 0) - localData.gst;
        }

        localData.paymentDate = bookingResult.data.data.vendorPayout && bookingResult.data.data.vendorPayout.date ? getFullDateFormat(new Date(bookingResult.data.data.vendorPayout.date)) : "";

        localData.revenueType = "workshop";
        updateSelectedInvoice({ ...localData });
        toggleInvoiceModal(true);
    };

    return (
        <div className={`row problem-area payment ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
            <div className="col-12">
                {isInvoiceOpen ?
                    <RevenueModal
                        displayModal={isInvoiceOpen}
                        closeModal={toggleInvoiceModal}
                        selectedInvoice={selectedInvoice}
                    /> : null}
                <div className="col-12 categories-heading pl-0">
                    <h4 className={`mb-0`}>Payment Summary</h4>
                </div>
                <div className="row text-center mb-3" style={{ margin: 0 }}>
                    <div className='col-12 pl-0 pr-0'>
                        <button className="prev-button-wrapper" style={{ top: "-7px", left: 0, color: "#fff", border: "1px solid #185BDB", backgroundColor: `${curYear === minYear && curMonth === minMonth ? "#ccc" : "#185BDB"}` }} onClick={prev} disabled={curYear === minYear && curMonth === minMonth}>
                            <i className="fas fa-angle-left"></i>
                        </button>
                        <span className='fee-text'>{monthArrConfig[curMonth]} {curYear}</span>
                        <button className="next-button-wrapper" style={{ top: "-7px", right: 0, color: "#fff", border: "1px solid #185BDB", backgroundColor: `${curYear === maxYear && curMonth === maxMonth ? "#ccc" : "#185BDB"}` }} onClick={next} disabled={curYear === maxYear && curMonth === maxMonth}>
                            <i className="fas fa-angle-right"></i>
                        </button>
                    </div>
                </div>
                <div className="row mr-0 ml-0 mt-3">
                    <div className={`col-4 pl-0 pr-0`}>
                        <div className={` tabs ${isActive === "appointment" ? 'active' : ''}`} onClick={() => setActiveTab('appointment')}>Appointments</div>

                    </div>
                    <div className={`col-4`}>
                        <div className={` tabs ${isActive === "event" ? 'active' : ''}`} onClick={() => setActiveTab('event')}>Events</div>
                    </div>
                    <div className={`col-4 pr-0`}>
                        <div className={` tabs ${isActive === "workshop" ? 'active' : ''}`} onClick={() => setActiveTab('workshop')}>Workshops</div>
                    </div>
                </div>

                <div className="row ml-0 mr-0 mt-3 mb-3">
                    {Object.keys(revenueDetails).length ?
                    isActive === "appointment" ?
                        <div className="col-md-6 col-xs-12 revenue-left-section">
                            <div className="row m-0 professional-near-you-wrapper payment-each-wrapper ">
                                <div className={`col-4 pl-0 status-text ${revenueDetails.status === "PAID" ? 'paid' : ''}`} style={{ lineHeight: 3 }}>
                                    {revenueDetails.status}
                                </div>
                                <div className="col-4 pl-0 text-center fee-text total-payment-text">
                                    Total <br /> Payment
                                </div>
                                <div className="col-4 pr-0 fee-text text-right" style={{ lineHeight: 2 }}>
                                    <i className="fas fa-rupee-sign"></i>{revenueDetails.netPayable.toFixed(2)}
                                </div>
                            </div>
                        </div> :
                        <>
                            <div className="col-md-4 col-xs-6 revenue-left-section">
                                <div className="row m-0 professional-near-you-wrapper payment-each-wrapper ">
                                    <div className="col-5 pl-0 text-left fee-text total-payment-text">
                                        Total <br /> Amount
                                    </div>
                                    <div className="col-7 pr-0 fee-text text-right" style={{ lineHeight: 1, color: "#fd924b" }}>
                                        <i className="fas fa-rupee-sign"></i>{isActive === "event" ? eventRevenueDetails?.due?.toFixed(2) : workshopRevenueDetails?.due?.toFixed(2)} <br />
                                        <span className={`status-text`} style={{ lineHeight: 2.5 }}>
                                            DUE
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-xs-6 revenue-left-section">
                                <div className="row m-0 professional-near-you-wrapper payment-each-wrapper ">
                                    <div className="col-5 pl-0 text-left fee-text total-payment-text">
                                        Total <br /> Amount
                                    </div>
                                    <div className="col-7 pr-0 fee-text text-right" style={{ lineHeight: 1, color: "#08b12a" }}>
                                        <i className="fas fa-rupee-sign"></i>{isActive === "event" ? eventRevenueDetails.paid.toFixed(2) : workshopRevenueDetails.paid.toFixed(2)} <br />
                                        <span className={`status-text paid`} style={{ lineHeight: 2.5 }}>
                                            PAID
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </>
                    : null}
                    <div className={`${isActive === "appointment" ? 'col-md-6' : 'col-md-4'} col-xs-12 revenue-right-section`} style={{ margin: "0 auto" }}>
                        <div className="row m-0 professional-near-you-wrapper payment-each-wrapper ">
                            <div className="col-8 fee-text pl-0" style={{ fontSize: "15px", lineHeight: 3 }}>
                                Total {isActive === "appointment" ? "Bookings" : isActive === "event" ? "Events" : "Workshops"}
                            </div>
                            <div className="col-4 pr-0 fee-text text-right" style={{ fontSize: "15px", lineHeight: 3 }}>
                                {isActive === "appointment" ? bookingArray.length : isActive === "event" ? eventArray.length : workshopArray.length}
                            </div>
                        </div>
                    </div>
                    {Object.keys(revenueDetails).length && revenueDetails.status === "PAID" && isActive === "appointment" ?
                        <div className="col-12 p-3 mt-3 payment-each-wrapper">
                            <div className='row m-0'>
                                <div className="mt-2 col-8 pl-0 fee-label">
                                    Payment settled on
                                </div>
                                <div className="mt-2 col-4 pr-0 fee-text text-right">
                                    {revenueDetails.paymentDate ? revenueDetails.paymentDate : 'N/A'}
                                </div>
                                <div className="mt-2 col-12 p-0">
                                    <b>Note: Amount will be credited {revenueDetails.bankDetails.isCheque ? 'via cheque.' : 'to your bank account provided within 3 working days.'}</b> <br />
                                    {revenueDetails.otherPayment ?
                                    <span className='fee-label'>The amount of <i className="fas fa-rupee-sign"></i>{revenueDetails.otherPayment.toFixed(2)} has been deducted</span> : null}
                                </div>
                            </div> </div> : null}
                    <div className="col-12 p-0 mt-3">
                        <div className="row m-0">
                            {isActive === "appointment" && bookingArray.length ?
                                bookingArray.map((booking, ind) => {
                                    return <div key={ind} className={`col-sm-6 col-xs-12 professional-wrapper
                                                ${ind % 2 === 0 ? 'problem-more-768-pr-15-for-2' : ''}
                                                ${ind !== bookingArray.length - 1 && ind !== bookingArray.length - 2 && ind !== bookingArray.length - 3 ? 'problem-more-768-pb-15' : ''}
                                                ${ind !== bookingArray.length - 1 && ind !== bookingArray.length - 2 ? 'problem-more-768-pb-15-for-2' : ''}
                                                ${ind !== bookingArray.length - 1 ? 'problem-more-768-pb-15-for-1' : ''}
                                                `}
                                        onClick={() => openInvoiceModal(booking)}>
                                        <div className={`row m-0 professional-near-you-wrapper payment-each-wrapper ${revenueDetails.status === "PAID" ? 'paid-border' : 'due-border'}`}>
                                            <div className="col-12 pl-0 pr-0 fee-label mt-2">
                                                {booking.bookingForName}
                                            </div>
                                            <div className="col-12 pl-0 pr-0 fee-label mt-2">
                                                <b>{booking.serviceName}</b>
                                            </div>
                                            <div className="col-8 pl-0 fee-label mt-2">
                                                Type: {booking.appointmentBookingType === 2 ? "Offline" : "Online"} Appointment
                                            </div>
                                            <div className="mt-2 col-4 pr-0 fee-text text-right" style={{ fontSize: "16px" }}>
                                                <i className="fas fa-rupee-sign"></i>{booking.amountAfterVendorCut.toFixed(2)}
                                            </div>
                                            <div className="mt-2 col-12 pl-0 pr-0 fee-label">
                                                {getFullDateFormat(new Date(booking.date))}
                                            </div>
                                            <div className={`mt-2 col-7 status-text pl-0 pr-0 ${revenueDetails.status === "PAID" ? 'paid' : ''}`} style={{ fontSize: "14px" }}>
                                                Status: {revenueDetails.status === "PAID" ? "Payment Settled" : "Settlement Pending"} {revenueDetails.status === "PAID" ? <i className='fa fa-check-circle'></i> : <i className="fas fa-hourglass-end"></i>}
                                            </div>
                                            <div className="mt-2 col-5 pr-0 fee-text text-right" style={{ fontSize: "14px" }}>
                                                <span className='badge badge-primary' style={{
                                                    cursor: "pointer"
                                                }}>View Details</span>
                                            </div>
                                        </div>
                                    </div>
                                }) : null}
                            {isActive === "event" && eventArray.length ?
                                eventArray.map((booking, ind) => {
                                    return <div key={ind} className={`col-sm-6 col-xs-12 professional-wrapper
                                                ${ind % 2 === 0 ? 'problem-more-768-pr-15-for-2' : ''}
                                                ${ind !== eventArray.length - 1 && ind !== eventArray.length - 2 && ind !== eventArray.length - 3 ? 'problem-more-768-pb-15' : ''}
                                                ${ind !== eventArray.length - 1 && ind !== eventArray.length - 2 ? 'problem-more-768-pb-15-for-2' : ''}
                                                ${ind !== eventArray.length - 1 ? 'problem-more-768-pb-15-for-1' : ''}
                                                `}
                                        onClick={() => openInvoiceModalEvent(booking)}>
                                        <div className={`row m-0 professional-near-you-wrapper payment-each-wrapper ${booking.status === "PAID" ? 'paid-border' : 'due-border'}`}>
                                            <div className="col-12 pl-0 pr-0 fee-label mt-2">
                                                Event Name: {booking.event.title}
                                            </div>
                                            <div className="col-8 pl-0 fee-label mt-2">
                                                Type: Online Event
                                            </div>
                                            <div className="mt-2 col-4 pr-0 fee-text text-right" style={{ fontSize: "16px" }}>
                                                <i className="fas fa-rupee-sign"></i>{booking.amountAfterVendorCut.toFixed(2)}
                                            </div>
                                            <div className="mt-2 col-12 pl-0 pr-0 fee-label">
                                                {getFullDateFormat(new Date(booking.date))}
                                            </div>
                                            <div className={`mt-2 col-7 status-text pl-0 pr-0 ${booking.status === "PAID" ? 'paid' : ''}`} style={{ fontSize: "14px" }}>
                                                Status: {booking.status === "PAID" ? "Payment Settled" : "Settlement Pending"} {booking.status === "PAID" ? <i className='fa fa-check-circle'></i> : <i className="fas fa-hourglass-end"></i>}
                                            </div>
                                            <div className="mt-2 col-5 pr-0 fee-text text-right" style={{ fontSize: "14px" }}>
                                                <span className='badge badge-primary' style={{
                                                    cursor: "pointer"
                                                }}>View Details</span>
                                            </div>
                                        </div>
                                    </div>
                                }) : null}
                            {isActive === "workshop" && workshopArray.length ?
                                workshopArray.map((booking, ind) => {
                                    return <div key={ind} className={`col-sm-6 col-xs-12 professional-wrapper
                                                ${ind % 2 === 0 ? 'problem-more-768-pr-15-for-2' : ''}
                                                ${ind !== workshopArray.length - 1 && ind !== workshopArray.length - 2 && ind !== workshopArray.length - 3 ? 'problem-more-768-pb-15' : ''}
                                                ${ind !== workshopArray.length - 1 && ind !== workshopArray.length - 2 ? 'problem-more-768-pb-15-for-2' : ''}
                                                ${ind !== workshopArray.length - 1 ? 'problem-more-768-pb-15-for-1' : ''}
                                                `}
                                        onClick={() => openInvoiceModalWorkshop(booking)}>
                                        <div className={`row m-0 professional-near-you-wrapper payment-each-wrapper ${booking.status === "PAID" ? 'paid-border' : 'due-border'}`}>
                                            <div className="col-12 pl-0 pr-0 fee-label mt-2">
                                                Workshop Name: {booking.workshop.title}
                                            </div>
                                            <div className="col-8 pl-0 fee-label mt-2">
                                                Type: Online Workshop
                                            </div>
                                            <div className="mt-2 col-4 pr-0 fee-text text-right" style={{ fontSize: "16px" }}>
                                                <i className="fas fa-rupee-sign"></i>{booking.amountAfterVendorCut.toFixed(2)}
                                            </div>
                                            <div className="mt-2 col-12 pl-0 pr-0 fee-label">
                                                {getFullDateFormat(new Date(booking.dates[0]))} {booking.dates[1] ? `- ${getFullDateFormat(new Date(booking.dates[booking.dates.length - 1]))}` : ''}
                                            </div>
                                            <div className={`mt-2 col-7 status-text pl-0 pr-0 ${booking.status === "PAID" ? 'paid' : ''}`} style={{ fontSize: "14px" }}>
                                                Status: {booking.status === "PAID" ? "Payment Settled" : "Settlement Pending"} {booking.status === "PAID" ? <i className='fa fa-check-circle'></i> : <i className="fas fa-hourglass-end"></i>}
                                            </div>
                                            <div className="mt-2 col-5 pr-0 fee-text text-right" style={{ fontSize: "14px" }}>
                                                <span className='badge badge-primary' style={{
                                                    cursor: "pointer"
                                                }}>View Details</span>
                                            </div>
                                        </div>
                                    </div>
                                }) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Revenue;