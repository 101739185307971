import React, { useEffect, useState } from 'react';
import "./service.css";

const VendorTypeModal = (props) => {

    const [vendorType, chooseVendorType] = useState("1");
    const [isConfirmation, toggleConfirmation] = useState(false);

    useEffect(() => {

        if (props.subType) {
            chooseVendorType(props.subType.toString());
        }

    }, [props.subType]);

    const saveVendorType = () => {
        if (vendorType.toString() === "1" && props.subType.toString() === "2") {
            toggleConfirmation(true);
        } else {
            props.saveVendorType(vendorType);
        }
    };

    return (
        <div className="row problem-area" style={{ margin: 0 }}>

            <div className={`modal login-modal ask-question-modal fade ${props.displayModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{!isConfirmation ? "Choose Profile Type" : "Alert"}</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row" style={{ margin: 0 }}>
                                <h5 className="col-12 p-0 mb-3" style={{ color: "#000" }}>
                                    {!isConfirmation ? "Signing up as:" : "If you change the profile type saved addresses and services will be removed. You will have to create new addresses and services as an individual."}
                                    {!isConfirmation ? "" : <><br /> Do you wish to proceed?</>}
                                </h5>
                                {!isConfirmation ?
                                <div>
                                    <input style={{
                                        verticalAlign: "middle",
                                        marginRight: "5px"
                                    }} type="radio" id="individual" name="vendorType" value="1" checked={vendorType === "1"} onChange={() => chooseVendorType("1")} />
                                    <label htmlFor="individual">An Individual Practitioner</label> <br />
                                    <p style={{
                                        paddingLeft: "17px"
                                    }}>Ideal for professionals working from single location</p>
                                    <input style={{
                                        verticalAlign: "middle",
                                        marginRight: "5px"
                                    }} type="radio" id="organization" name="vendorType" value="2" checked={vendorType === "2"} onChange={() => chooseVendorType("2")} />
                                    <label htmlFor="organization">An Organisation</label>
                                    <p style={{
                                        paddingLeft: "17px"
                                    }}>Ideal for organisations working in multiple locations</p>
                                </div> : null}
                            </div>

                            <div className="col-12 p-0 text-right mt-3">
                                {!isConfirmation ?
                                <button type="button" className="btn btn-primary" onClick={() => saveVendorType(vendorType)}>Continue</button> : 
                                <>
                                    <button type="button" className="btn btn-outline-primary" onClick={() => props.closeModal(false)}>Cancel</button>
                                    <button type="button" className="btn btn-primary ml-3" onClick={() => props.saveVendorType(vendorType)}>Continue</button>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VendorTypeModal;