import React from 'react';
import "./forum.css";

const ReadMoreModal = (props) => {
 
  return (
    <div className={`row forum`} style={{ margin: 0 }}>
      <div className={`modal ask-question-modal fade ${props.displayModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title w-100"><img src={require("../../../images/logo-blue.png")} style={{ width: "40px", height: "40px" }} alt="login logo" /></h5>
            </div>
            <div className="modal-body pt-0">
              <div>
                <div className="form-group question-input mt-2">
                  <label htmlFor="exampleFormControlTextarea1">{props.description}</label>
                </div>
              </div>

              <div className="col-12 login-button">
                
                <button type="button" className="btn btn-default" onClick={() => props.toggleModal(false)}>Ok</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ReadMoreModal;