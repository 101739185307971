import axios from "./axios.interceptor";
import baseUrl from "./api.util";

export const REQUEST_LOGIN_OTP = `${baseUrl}/vendor/auth/request-otp`;
export const VERIFY_LOGIN_OTP = `${baseUrl}/vendor/auth/verify-otp`;
export const LOGOUT = `${baseUrl}/account/vendor/profile/logout`;
export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const GLOBAL_CONFIG = `${baseUrl}/customer/common/global-config`;

export const ME_URL = "api/me";

export const requestLoginOtp = async (requestBody) => {
  const res = await axios.post(REQUEST_LOGIN_OTP, requestBody);
  return res;
}

export const confirmLoginOtp = async (requestBody) => {
  const res = await axios.post(VERIFY_LOGIN_OTP, requestBody);
  return res;
}

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function logout() {
  return axios.post(LOGOUT,{});
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export const globalConfig = async () => {
  const res = await axios.get(`${GLOBAL_CONFIG}`);
  return res;
}
