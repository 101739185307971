import React from "react";
import "../dashboard.css"
import * as auth from "../../../store/ducks/auth.duck";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { TimePicker } from "@material-ui/pickers";
import {MyLocation} from '@material-ui/icons';

const AddressManager = (props) => {
    return <div className="col-md-8 address-manager" style={{
        margin: "0 auto"
    }}>
        {props.mode === "list" ?
            <div className="row" style={{ margin: 0 }}>
                <div className="col-12 p-0">
                    <div className="row" style={{ margin: 0 }}>
                        <div className="col-sm-8 col-xs-12 pl-0 pt-2 mb-3">
                            <h5 className={`mb-0`}>
                                <i class="fas fa-arrow-left mr-2" style={{ cursor: "pointer" }} onClick={() => { props.selectedProfile.status === props.VendorConfigStatus.approvalPending || props.selectedProfile.status === props.VendorConfigStatus.approvalRejected ? props.nextStep() : props.backStep(true) }}></i> Address Manager</h5>
                        </div>

                        <div className="col-sm-4 col-xs-12 pr-0 text-right mb-3">
                            {(props.selectedProfile.subType === 1 && props.addresses.length < 1) ||
                                props.selectedProfile.subType === 2 ?
                                <button type="button" className="btn btn-primary btn-sm" onClick={() => { props.updateMode("add"); props.pinLocation(true) }}>Add Address</button>
                                : null}
                            {props.addresses.length && props.selectedProfile.status !== props.VendorConfigStatus.approvalPending && props.selectedProfile.status !== props.VendorConfigStatus.approvalRejected ?
                                <button type="button" className="btn btn-outline-primary btn-sm ml-2" onClick={() => { props.nextStep() }}>Next</button>
                                : null}
                        </div>
                    </div>
                </div>
                {props.addresses.length ?
                    props.addresses.map((professional, index) => {
                        return <div className={`col-sm-6 col-xs-12 professional-wrapper
                                        ${index % 2 === 0 ? 'problem-more-768-pr-15-for-2' : ''}
                                        ${index !== props.addresses.length - 1 && index !== props.addresses.length - 2 && index !== props.addresses.length - 3 ? 'problem-more-768-pb-15' : ''}
                                        ${index !== props.addresses.length - 1 && index !== props.addresses.length - 2 ? 'problem-more-768-pb-15-for-2' : ''}
                                        ${index !== props.addresses.length - 1 ? 'problem-more-768-pb-15-for-1' : ''}
                                        `} key={index} onClick={() => props.chooseLocation(professional)}>
                            <div className="row service-row" style={{ margin: 0 }}>
                                <div className="col-12 p-0">
                                    <div className="row" style={{ margin: 0 }}>
                                        <div className="professional-name col-12 p-0">{professional.title}
                                        </div>
                                        <div className="professional-location service-name col-12 p-0">{professional.addressLine1 ? professional.addressLine1 : professional.address}</div>
                                        <div className="col-5 pl-0">
                                            <div className="service-cost">{professional.days}</div>
                                        </div>
                                        <div className="col-7 pr-0 text-right">
                                            <div className="service-hour">{professional.duration}</div>
                                        </div>
                                    </div>
                                </div>
                                {props.addresses.length > 1 ?
                                    <img src={require("../../../../images/remove.svg")} onClick={(e) => { e.stopPropagation(); props.openRemove(professional, "Location") }} alt="remove location" /> : null}
                            </div>
                        </div>
                    })
                    : <div className="text-center well col-12 mb-3 color-black">
                        No saved address
                    </div>}
            </div> : null}
        {props.mode === "add" || props.mode === "edit" ?
            <div className="row" style={{ margin: 0 }}>
                <div className="col-12 p-0">
                    <div className="row" style={{ margin: 0 }}>
                        <div className="col-sm-6 col-xs-12 pl-0 pt-2">
                            <h5 className={`mb-0`}>{props.mode === "add" ? "Add" : "Edit"} Location</h5>
                        </div>
                        <div className="col-sm-6 col-xs-12 pr-0 text-right mb-3">
                            <button type="button" className="btn btn-outline-primary btn-sm mr-2" onClick={() => { props.updateLocationDetailsError({ ...props.initialLocationError }); props.openLocationList() }}>Cancel</button>
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => props.saveLocation(props.mode)}>Save</button>
                        </div>
                    </div>
                    <div className="row" style={{ margin: 0 }}>
                        <div className="col-12 text-center mb-3 form-label">
                            Your Current Location Of Practice
                        </div>
                        <div className="col-md-6 col-xs-12 left-padding mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">City, State</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="fas fa-map-marker-alt" /></div>
                                </div>
                            <input type="text" className={`form-control ${props.locationDetailsError.title ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter City, State" value={props.locationDetails.title} onChange={e => props.updateLocationDetails(e.target.value, "title")} />
                            </div>
                            {props.locationDetailsError.title ?
                                <div className="input-error">{props.locationDetailsError.title}</div> : null}
                        </div>
                        <div className="col-md-6 col-xs-12 right-padding mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">{!props.locationPinned ? "Search on Google maps" : "Choose map location"}</label>
                            <br />
                            {props.locationPinned ?
                                <button className='btn btn-default' style={{
                                    backgroundColor: "#eff5ff",
                                    color: "#566986"
                                }} onClick={() => { props.pinLocation(false); props.initMap(props.selectedCoordinates.length ? props.selectedCoordinates : []) }}>
                                    {props.selectedCoordinates.length ?
                                        <i className={`fa fa-check-circle mr-2 status-icon`} style={{ color: "#48cc39" }} aria-hidden="true" /> : <i className={`fa fa-exclamation-circle mr-2 status-icon`} style={{ color: "#f29900" }} aria-hidden="true" />}
                                    {!props.selectedCoordinates.length ? 'Choose map location ' : 'Location selected'}</button> :
                                <input type="text" className={`form-control ${props.locationDetailsError.address ? 'error-border' : ''}`} id="location-search-input" placeholder="Search on Google maps" value={props.locationDetails.address} onChange={e => { props.initAutocomplete(); props.updateLocationDetails(e.target.value, "address") }} />}
                            {props.locationDetailsError.address ?
                                <div className="input-error">{props.locationDetailsError.address}</div> : null}
                            {!props.locationPinned ?
                            <>
                                <button className="btn btn-sm btn-primary" onClick={() => {props.pinLocation(true); props.setCurrentLocation()}} style={{
                                    position: 'absolute',
                                    right: "10px",
                                    bottom: "-60px",
                                    zIndex: 9
                                }}>Confirm Location</button>
                                <button className="btn btn-sm btn-primary" onClick={() => { props.chooseCurrentUserLocation()}} style={{
                                    position: 'absolute',
                                    right: "10px",
                                    bottom: "-110px",
                                    zIndex: 9
                                }}><MyLocation /></button></> : null}
                        </div>
                        {!props.locationPinned ?
                            <div id="mapAddress" className='col-12 mb-3' style={{
                                height: "250px"
                            }} /> : null}
                        <div className="col-md-6 col-xs-12 left-padding mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Full address</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="fas fa-map-marker-alt" /></div>
                                </div>
                            <input type="text" className={`form-control ${props.locationDetailsError.addressLine1 ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter full address" value={props.locationDetails.addressLine1} onChange={e => props.updateLocationDetails(e.target.value, "addressLine1")} />
                            </div>
                            {props.locationDetailsError.addressLine1 ?
                                <div className="input-error">{props.locationDetailsError.addressLine1}</div> : null}
                        </div>
                        <div className="col-md-6 col-xs-12 right-padding mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Pincode</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="fas fa-map-marker-alt" /></div>
                                </div>
                            <input type="text" className={`form-control ${props.locationDetailsError.addressLine2 ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter pincode" value={props.locationDetails.addressLine2} maxLength={6} onChange={e => props.updateLocationDetails(e.target.value.replace(/\D/, ''), "addressLine2")} />
                            </div>
                            {props.locationDetailsError.addressLine2 ?
                                <div className="input-error">{props.locationDetailsError.addressLine2}</div> : null}
                        </div>
                        <div className="col-md-6 col-xs-12 left-padding mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Landmark</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="fas fa-map-marker-alt" /></div>
                                </div>
                                <input type="text" className={`form-control ${props.locationDetailsError.addressLine3 ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter landmark" value={props.locationDetails.addressLine3} onChange={e => props.updateLocationDetails(e.target.value, "addressLine3")} />
                            </div>
                            {props.locationDetailsError.addressLine3 ?
                                <div className="input-error">{props.locationDetailsError.addressLine3}</div> : null}
                        </div>

                        <div className="col-12 text-center mb-3 form-label">
                            Your work hours and workdays
                        </div>
                        <div className="col-md-6 col-xs-12 left-padding mb-3 custom-time-picker-design">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Start time</label><br />
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="far fa-hourglass" /></div>
                                </div>
                                <TimePicker className={`${props.locationDetailsError.selectedFromDate ? 'timepicker-error-border' : ''} time-picker-with-icon`} value={props.selectedFromDate} onChange={props.handleFromDateChange} onClose={props.updateDateError} minutesStep={30} />
                            </div>
                            {props.locationDetailsError.selectedFromDate ?
                                <div className="input-error">{props.locationDetailsError.selectedFromDate}</div> : null}
                        </div>
                        <div className="col-md-6 col-xs-12 right-padding mb-3 custom-time-picker-design">
                            <label htmlFor="exampleFormControlInput1" className="form-label">End time</label><br />
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="far fa-hourglass" /></div>
                                </div>
                            <TimePicker className={`${props.locationDetailsError.selectedFromDate ? 'timepicker-error-border' : ''} time-picker-with-icon`} value={props.selectedToDate} onChange={props.handleToDateChange} minutesStep={30} onClose={props.updateDateError} />
                            </div>
                        </div>
                        <div className="col-12 p-0 mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Choose Day(s)</label>
                            <div className="row" style={{ maargin: 0 }}>
                                {props.daysArray.length ? props.daysArray.map((dayValue, index) => {
                                    return <div className="col-md-3 col-6" key={index}>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id={`${dayValue.text}-checkbox`} checked={dayValue.selected} onChange={() => props.toggleDaySelection(index)} />
                                            <label className="form-check-label" htmlFor={`${dayValue.text}-checkbox`}>
                                                {dayValue.text}
                                            </label>
                                        </div>
                                    </div>
                                }) : null}
                            </div>
                            {props.locationDetailsError.days ?
                                <div className="input-error">{props.locationDetailsError.days}</div> : null}
                        </div>
                    </div>
                </div>
            </div>
            : null}
    </div>
};

const mapStateToProps = state => {
    return { data: state };
};

export default injectIntl(
    connect(
        mapStateToProps,
        auth.actions
    )(AddressManager)
);