import React from "react";
import "../dashboard.css";
import * as auth from "../../../store/ducks/auth.duck";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
const congratsImage = require('../../../../images/congrats.png');
const NextStepInfo = (props) => {
    return <div className="col-md-6 next-step-info">
        {props.currentStep !== 1 && props.currentStep !== 14 && props.currentStep !== 16 && props.currentStep !== 17 ?
            <h5 className={`mb-0`} style={{ position: "absolute", top: "15px" }}>
                <i class="fas fa-arrow-left mr-2" style={{ cursor: "pointer" }} onClick={() => { props.backStep() }}></i></h5> : null}
        <div className="row" style={{margin: "0 auto"}}>
            <div className="col-12 p-0 headers">
                <h3>Parentcraft</h3>
                <h5>SERVICES</h5>
            </div>
            {props.currentStep !== 16 && props.currentStep !== 17 ?
                <div className="col-12 p-0 normal-text">
                    {props.text1}
                    {props.text2 ? <>
                        <br />
                        {props.text2}
                    </> : null}
                    {props.text3 ? <>
                        <br />
                        {props.text3}
                    </> : null}
                    {props.text4 ? <>
                        <br />
                        <br />
                        {props.text4}
                    </> : null}
                </div> :
                props.currentStep === 16 ?
                <div className="col-12 p-0 normal-text">
                    <img src={congratsImage} alt="Confirmation" style={{ width: "150px" }} />
                    <h4 style={{color: "#185BDB", fontSize: "22px", marginTop: "15px"}}>Congratulations</h4>
                    <div>Your profile is now live!</div>
                </div> : <div className="col-12 p-0 normal-text">
                    <h4 style={{fontSize: "22px", marginTop: "15px"}}>SEE YOU BACK SOON</h4>
                    <div style={{fontSize: "15px"}}>Your profile was rejected due to compliance issues. <br /> Please check your email for more details or write to us at:</div>
                    <span style={{color: "#185BDB", fontSize: "14px"}}><a href="mailto:support@parentcraftindia.com">support@parentcraftindia.com</a></span>
                </div>}
            {props.currentStep === 2 && props.lists && props.lists.length ?
                <div className="text-left" style={{ margin: "auto" }}>
                    <ul className="col-12 p-0 normal-text">
                        {props.lists.map(item => {
                            return <li key={item}>{item}</li>
                        })}
                    </ul>
                </div>
                : null}
            <div className="col-12 p-0 mt-5">
                <button type="button" className="btn btn-primary" onClick={props.currentStep !== 16 ? props.nextStep : props.openHome}>{props.buttonText}</button>
            </div>
        </div>
    </div>

};

const mapStateToProps = state => {
    return { data: state };
};

export default injectIntl(
    connect(
        mapStateToProps,
        auth.actions
    )(NextStepInfo)
);