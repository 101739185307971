import React from 'react';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    WhatsappShareButton,
    TwitterShareButton
} from "react-share";
import "./service.css";
import { toast } from 'react-toastify';

const defaultImage = require('../../../images/imgAvtr.png');

const ShareToSocialModal = (props) => {
    const copyToClipboard = () => {
        const tempInput = document.createElement("input");
        tempInput.value = `https://parentcraftindia.com/professional-details/${props.bookingDetails._id}/${props.bookingDetails.name.split(" ").join("-")}`;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        toast.success("Link Copied");
    };
    return (
        <div className="row problem-area" style={{ margin: 0 }}>

            <div className={`modal login-modal ask-question-modal fade ${props.displayShareModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Share Profile</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row" style={{ margin: 0 }}>
                                <div className="col-12 p-0 mb-3">
                                    <FacebookShareButton children={<img src={require('../../../images/fb.png')} alt="fb" style={{ width: "45px", marginRight: "5px", marginBottom: "5px" }} />} quote={`${props.bookingDetails.name}`} url={`https://parentcraftindia.com/professional-details/${props.bookingDetails._id}/${props.bookingDetails.name.split(" ").join("-")}`} />
                                    <EmailShareButton children={<img src={require('../../../images/mail.png')} alt="mail" style={{ width: "45px", marginRight: "5px", marginBottom: "5px" }} />} body={`${props.bookingDetails.name}`} url={`https://parentcraftindia.com/professional-details/${props.bookingDetails._id}/${props.bookingDetails.name.split(" ").join("-")}`} />
                                    <LinkedinShareButton children={<img src={require('../../../images/linkedin.png')} alt="linkedin" style={{ width: "45px", marginRight: "5px", marginBottom: "5px" }} />} title={`${props.bookingDetails.name}`} summary={`${props.bookingDetails.designation}`} url={`https://parentcraftindia.com/professional-details/${props.bookingDetails._id}/${props.bookingDetails.name.split(" ").join("-")}`} />
                                    <PinterestShareButton description={`${props.bookingDetails.name}`} children={<img src={require('../../../images/pinterest.png')} alt="pinterest" style={{ width: "45px", marginRight: "5px", marginBottom: "5px" }} />} url={`https://parentcraftindia.com/professional-details/${props.bookingDetails._id}/${props.bookingDetails.name.split(" ").join("-")}`} media={props.bookingDetails.profilePicture ? props.bookingDetails.profilePicture : defaultImage} />
                                    <WhatsappShareButton title={`${props.bookingDetails.name}`} children={<img src={require('../../../images/whatsapp.png')} alt="whatsapp" style={{ width: "45px", marginRight: "5px", marginBottom: "5px" }} />} url={`https://parentcraftindia.com/professional-details/${props.bookingDetails._id}/${props.bookingDetails.name.split(" ").join("-")}`} />
                                    <TwitterShareButton children={<img src={require('../../../images/twitter.png')} alt="twitter" style={{ width: "45px", marginRight: "5px", marginBottom: "5px" }} />} title={`${props.bookingDetails.name}`} url={`https://parentcraftindia.com/professional-details/${props.bookingDetails._id}/${props.bookingDetails.name.split(" ").join("-")}`} />
                                </div>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" value={`https://parentcraftindia.com/professional-details/${props.bookingDetails._id}/${props.bookingDetails.name.split(" ").join("-")}`} disabled />
                                    <div className="input-group-append">
                                        <button type="button" className="btn btn-primary" onClick={copyToClipboard}>Copy</button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 p-0 text-right mt-3 modal-buttons">
                                <button type="button" className="btn btn-outline-primary" onClick={() => props.toggleShareModal(false)}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShareToSocialModal;