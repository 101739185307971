import React from 'react';
import "../admins/forum.css";

const EmailAlert = (props) => {

    return (
        <div className={`row forum`} style={{ margin: 0 }}>
            <div className={`modal ask-question-modal fade ${props.displayModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Email not verified!</h5>
                        </div>
                        <div className="modal-body">
                            <div className="col-12 pt-3 pb-3 pl-0">
                                <div style={{
                                    fontSize: "16px",
                                    fontWeight: 400
                                }}>Please verify your email</div>
                            </div>
                            <div className="col-12 pt-3 pb-3 text-right">

                                <button type="button" className="btn btn-primary ml-3" onClick={() => { props.toggleEmailAlert(false) }}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default EmailAlert;